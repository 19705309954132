import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastService } from "angular-toastify";
import { NgxFileDropEntry, FileSystemFileEntry } from "ngx-file-drop";
import { AudioFiles } from "../../models/audio-upload.model";
import { AudioUploaderService } from "../../services/audio-uploader.service";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-audio-uploader",
	templateUrl: "./audio-uploader.component.html",
	styleUrls: ["./audio-uploader.component.scss"],
})
export class AudioUploaderComponent implements OnInit {

	@Input() audioId!: string | null;

	@Output() audioUploaded = new EventEmitter<string | null>();

	loading: boolean = true;

	audioUploading: boolean = false;
	audioURL!: string | undefined;
	audioName!: string | undefined;

	constructor(
		private readonly toastService: ToastService,
		private readonly audioService: AudioUploaderService,
	) {}

	ngOnInit() {
		this.processInputs();
	}

	dropped(files: NgxFileDropEntry[]) {
		const droppedFile = files[0];

		if (droppedFile.fileEntry.isFile) {
			this.audioUploading = true;
			const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
			fileEntry.file((file: File) => {
				const audioBlob = new Blob([file], {type: file.type});

				if (!(audioBlob.type === 'audio/mpeg')) 	{
					this.toastService.warn('File should be MP3 audio.');
					this.audioUploading = false;
					return;
				}

				if (audioBlob.size > 11000000) {
					this.toastService.warn('File size should be less than 10MB.');
					this.audioUploading = false;
					return;
				}

				this.audioService.uploadPlacementAudio(audioBlob).subscribe(res => {
					this.audioUploading = false;
					this.audioURL = res.url;
					this.audioName = file.name;
					this.audioUploaded.emit(this.getAudioId(this.audioURL));
				}, err => {
					this.audioUploading = false;
					this.toastService.warn("Failed to upload audio, please try again.")
				});
			});
		} else {
			this.toastService.warn("This is not a file.");
			this.audioUploading = false;
		}
	}

	createURLFromId(audioId: string | undefined) {
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/audios-placement/${audioId}.mp3`
	}

	getAudioId(audioUrl: string | undefined) {
		const splitUrl = audioUrl?.split('/') || [];
		let audioId = splitUrl.length > 0 ? splitUrl[splitUrl.length - 1] : '';
		audioId = audioId.substring(0, audioId.lastIndexOf('.'));
		return audioId;
	}

	deleteAudio() {
		this.audioURL = undefined;
		this.audioName = undefined;
		this.audioUploaded.emit(null);
		// const audioId = this.getAudioId(this.audioURL);
		// this.deleteSub = this.audioService.deletePlacementAudio(audioId).subscribe(
		// 	() => {
		// 		this.audioURL = undefined;
		// 		this.audioName = undefined;
		// 		this.audioUploaded.emit(null);
		// 	},
		// 	err => {
		// 		this.toastService.warn("Failed to delete audio, please try again.");
		// 	}
		// );
	}

	private processInputs(): void {
		if (this.audioId && this.audioId !== '') {
			this.audioURL = this.createURLFromId(this.audioId);
			this.audioName = this.audioId;
			this.loading = true;

			this.audioService.verifyAudiosExists(this.audioURL).subscribe(res => {
					this.loading = false;
					if (!res) {
						this.toastService.warn("Audio does not exist on bucket anymore, please upload new audio.");
						this.loading = false;
						this.audioURL = undefined;
						this.audioName = undefined;
						this.audioUploaded.emit(null);
					}
			});
		} else {
			this.loading = false;
		}
	}
}
