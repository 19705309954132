import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { Observable, Subscription, catchError, forkJoin, of } from 'rxjs';
import { CreateQuestionDTO, Question, UpdateQuestionDTO, VALID_CEFRS, VALID_GAMES, VALID_SKILLS } from 'src/app/shared/models/placement-question.model';
import { AuthService } from 'src/app/shared/services/auth.service';
// import { createMockQuestion } from './question-mock';
import { CreateQuestionDialog } from './create-question/create-question.component';
import { PlacementQuestionsStore } from 'src/app/shared/services/stores/placement-questions.store.service';
import { VALID_GAME_TYPES } from './create-question/game-type';
import { DeleteConfirmationDialog } from 'src/app/shared/components/delete-confirmation/delete-confirmation.component';
import { PlacementQuestionsService } from 'src/app/shared/services/placement-questions.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
	selector: 'app-placement-questions',
	templateUrl: './placement-questions.component.html',
	styleUrls: ['./placement-questions.component.scss']
})
export class PlacementQuestionsComponent implements OnInit, OnChanges {

	questions: Question[] = [];
	loading = true;

	skills = VALID_SKILLS;
	games = VALID_GAME_TYPES;
	cefrs = VALID_CEFRS;

	skillControl: FormControl;
	cefrControl: FormControl;
	gameControl: FormControl;
	statusControl: FormControl;

	globalfilter = '';
	filteredValues = {
		skill: '',
		game: '',
		cefr: '',
		status: null,
	}

	displayedColumns: string[] = ['name', 'actions', 'game', 'skill', 'cefr', 'status'];
	dataSource!: MatTableDataSource<Question>;
	questionsSelected: { [key: string]: boolean } = {};
	selected: string[] = [];
	defaultPageSize!: number;

	@ViewChild(MatPaginator) paginator!: MatPaginator;

	subscriptions: Subscription;

	constructor(
		private dialog: MatDialog,
		private router: Router,
		private placementQuestionsStore: PlacementQuestionsStore,
		private placementQuestionsService: PlacementQuestionsService,
		private authService: AuthService,
		private toastService: ToastService,
		private clipboard: Clipboard,
	) {
		this.dataSource = new MatTableDataSource(this.questions);
		this.skillControl = new FormControl();
		this.gameControl = new FormControl();
		this.cefrControl = new FormControl();
		this.statusControl = new FormControl();
		this.subscriptions = new Subscription();
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.questions) {
			this.dataSource = new MatTableDataSource(this.questions);

			this.dataSource.filterPredicate = this.customFilterPredicate();

			this.globalfilter = sessionStorage.getItem('questionFilter') ?? '';
			this.defaultPageSize = +(sessionStorage.getItem('questionsPageSize') ?? 5);
		}
	}

	ngOnInit() {

		this.loadQuestions();

		this.globalfilter = sessionStorage.getItem('questionFilter') ?? '';
		this.defaultPageSize = +(sessionStorage.getItem('questionsPageSize') ?? 5);

		const skillSub = this.skillControl.valueChanges.subscribe(skillValue => {
			this.filteredValues['skill'] = skillValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const cefrSub = this.cefrControl.valueChanges.subscribe(cefrValue => {
			this.filteredValues['cefr'] = cefrValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const mediaSub = this.gameControl.valueChanges.subscribe(mediaValue => {
			this.filteredValues['game'] = mediaValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const statusSub = this.statusControl.valueChanges.subscribe(statusValue => {
			this.filteredValues['status'] = statusValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		this.subscriptions.add(cefrSub);
		this.subscriptions.add(skillSub);
		this.subscriptions.add(mediaSub);
		this.subscriptions.add(statusSub);
	}

	ngAfterViewInit(): void {
		this.setPaginator();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	loadQuestions(forceUpdate: boolean = false) {
		return new Promise(() => {
			const sub = this.placementQuestionsStore.listQuestions(forceUpdate)
				.subscribe(res => {
					if (res) {
						this.questions = this.setGameNames(res);
						this.dataSource = new MatTableDataSource(this.questions);
						this.questions.forEach(question => { this.questionsSelected[question.uid ?? 0] = false; });
						this.dataSource.filterPredicate = this.customFilterPredicate();
						if (this.paginator) {
							this.setPaginator();
						}
						this.loading = false;
					}
				});

			this.subscriptions.add(sub);
		});
	}

	private setPaginator() {
		this.dataSource.filter = JSON.stringify(this.filteredValues);
		this.dataSource.paginator = this.paginator;
		this.paginator.pageIndex = +(sessionStorage.getItem('questionPageIndex') ?? 0);
	}

	home() {
		this.router.navigate(['navbar'])
	}

	async logout() {
		let data = await this.authService.logout();
		if (data) {
			this.router.navigate(['auth/login'])
		}
	}

	selectAll(event: MatCheckboxChange) {
		for (let questionId in this.questionsSelected) {
			this.questionsSelected[questionId] = event.checked;
		}
	}

	select(event: MatCheckboxChange, checkedId: string) {
		this.questionsSelected[checkedId] = event.checked;
	}

	customFilterPredicate() {
		const questionFilter = (data: Question, filter: string): boolean => {
			let parsedSearch = JSON.parse(filter);
			const selectedSkill = parsedSearch.skill;
			let hasSkill = selectedSkill ? data.skill === selectedSkill : true;
			const selectedCefr = parsedSearch.cefr;
			let hasCefr = selectedCefr ? data.cefr === selectedCefr : true;
			const selectedGame = parsedSearch.game;
			let hasTime = selectedGame ? data.game?.activity === selectedGame.activity : true;
			const selectedStatus = parsedSearch.status;
			const hasStatus = selectedStatus === null ? true : data.published === selectedStatus;

			let selectMatch = hasSkill && hasCefr && hasTime && hasStatus;
			let globalMatch = !this.globalfilter;

			if (this.globalfilter) {
				const lowerCaseFilter = this.globalfilter.toLowerCase();
				const nameIncludes = data.name != null && data.name.toLowerCase().includes(lowerCaseFilter);
				let gameDescIncludes = false;

				globalMatch = nameIncludes || gameDescIncludes;
			}

			return globalMatch && selectMatch;
		}
		return questionFilter;
	}

	applyFilter(filter: string) {
		sessionStorage.setItem('questionFilter', filter);
		this.globalfilter = filter;
		this.dataSource.filter = JSON.stringify(this.filteredValues);

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	paginatorChanged(event: any) {
		sessionStorage.setItem('questionPageIndex', event.pageIndex);
		sessionStorage.setItem('questionsPageSize', event.pageSize);
	}

	copyToClipboard(text: string): void {
		this.clipboard.copy(text);
		this.toastService.success(`QuestionId copied to clipboard`);
	}

	setGameNames(questions: Question[]): Question[] {
		return questions.map(question => {
			const matchingGameType = VALID_GAME_TYPES.find(gameType => gameType.activity === question.game.activity);
			if (matchingGameType) {
				question.game.name = matchingGameType.name;
			}
			return question;
		});
	}

	createQuestion() {
		const dialogRef = this.dialog.open(CreateQuestionDialog, {
			width: 'auto',
		})

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				this.loadQuestions(true);
				this.toastService.success(`Question created with successfully`);
			}
		})
	}

	editQuestion(question: Question) {
		const dialogRef = this.dialog.open(CreateQuestionDialog, {
			width: 'auto',
			data: {
				game: question.game,
				name: question.name,
				skill: question.skill,
				cefr: question.cefr,
				questionId: question.questionId,
				published: question.published,
				version: question.version,
			} as UpdateQuestionDTO,
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				this.loadQuestions(true);
				this.toastService.success(`Question edited with successfully`);
			}
		});
	}

	private getSelected() {
		const selectedIds = [];

		for (let id in this.questionsSelected) {
			const isFiltered = this.dataSource.filteredData.find(step => step.uid === id);
			if (this.questionsSelected[id] && isFiltered) {
				selectedIds.push(id);
			}
		}

		return selectedIds;
	}

	removeSelectedQuestions() {
		const selectedIds = this.getSelected();
		if (selectedIds.length === 0) {
			this.toastService.error(`You must select at least a single question to delete!`);
			return;
		}

		if (selectedIds.length > 5) {
			this.toastService.error(`You can only delete 5 questions at a time!`);
			return;
		}

		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			selectedIds.forEach((id: string) => {
				this.placementQuestionsStore.removeQuestion(id);
				this.loadQuestions(true);
			});
		});
	}

	publishQuestions(release: boolean) {

		let selectedIds = this.getSelected();
		if (selectedIds.length === 0) {
			this.toastService.error(`You must select at least a single question to publish/un-publish!`);
			return;
		}

		selectedIds = selectedIds.filter(questionId => {
			const question = this.questions.find(q => q.questionId === questionId);
			if (question && question.uploadStatus?.status !== "done") {
				return false;
			}
			return true;
		});

		if (selectedIds.length === 0) {
			this.toastService.error(`All selected questions must have the upload status 'done' in order to be published.`);
			return;
		}

		const requests: Observable<boolean>[] = [];

		selectedIds.forEach(questionId => {
			const request = this.placementQuestionsService.publishQuestion(questionId, release)
				.pipe(
					catchError(error => {
						this.toastService.error(`Failed to publish question ${questionId}`)
						console.log(`Failed to publish question ${questionId}`, error);
						return of(false);
					})
				);
			requests.push(request);
		});

		forkJoin(requests).subscribe(
			{
				next: (res) => {
					if (!res.includes(false)) {
						this.loadQuestions(true);
						this.toastService.success('Questions updated successfully!');
					}
				}
			}
		)
	};
}
