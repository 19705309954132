<div class="lesson-view">
	<div class="lesson-header">
		<mat-toolbar class="header" color="primary">
			<button mat-icon-button (click)="returnToLessons()">
				<mat-icon>backspace icon</mat-icon>
			</button>
			<h1 class="title">{{lessonId}}</h1>
		</mat-toolbar>
	</div>

	<div class="classroom-games-content">
		<mat-tab-group mat-align-tabs="center">
			<mat-tab label="Heads Up">
				<ng-template matTabContent>
					<app-classroom-game [lesson]="lessonId" [draft]="isDraft" [data]="classroomData" [gameId]="'heads-up'" (gamesUpdated)="returnToLessons()"></app-classroom-game>
				</ng-template>
			</mat-tab>
			<mat-tab label="Hot Potato">
				<ng-template matTabContent>
					<app-classroom-game [lesson]="lessonId" [draft]="isDraft" [data]="classroomData" [gameId]="'hot-potato'" (gamesUpdated)="returnToLessons()"></app-classroom-game>
				</ng-template>
			</mat-tab>
			<mat-tab label="Snakes and Ladders">
				<ng-template matTabContent>
					<app-classroom-game [lesson]="lessonId" [draft]="isDraft" [data]="classroomData" [gameId]="'snakes-ladders'" (gamesUpdated)="returnToLessons()"></app-classroom-game>
				</ng-template>
			</mat-tab>
			<mat-tab label="Temple Run">
				<ng-template matTabContent>
					<app-classroom-game [lesson]="lessonId" [draft]="isDraft" [data]="classroomData" [gameId]="'temple-run'" (gamesUpdated)="returnToLessons()"></app-classroom-game>
				</ng-template>
			</mat-tab>
			<mat-tab label="Tic Tac Toe">
				<ng-template matTabContent>
					<app-classroom-game [lesson]="lessonId" [draft]="isDraft" [data]="classroomData" [gameId]="'tic-tac-toe'" (gamesUpdated)="returnToLessons()"></app-classroom-game>
				</ng-template>
			</mat-tab>
			<mat-tab label="Flash Cards">
				<ng-template matTabContent>
					<app-classroom-game [lesson]="lessonId" [draft]="isDraft" [data]="classroomData" [gameId]="'flash-cards'" (gamesUpdated)="returnToLessons()"></app-classroom-game>
				</ng-template>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>