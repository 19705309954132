<div mat-dialog-title color="primary">
	CREATE DIALOGUE
</div>
<lib-toastify-toast-container
	position="bottom-right"
></lib-toastify-toast-container>
<div mat-dialog-content class='dialog-content' style="align-items: center;">
	<ng-container class="dialogue-id-content">
		<mat-form-field appearance="outline">
			<mat-label>Subject</mat-label>
			<input matInput [formControl]="subjectControl">
			<button *ngIf="subjectControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="subjectControl.reset()">
				<mat-icon>close</mat-icon>
			</button>
			<mat-error *ngIf="subjectControl.hasError('required')">
				Subject is <strong>required</strong>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<ng-container class="dialogue-lines-content" *ngFor="let line of data.lines; let lineIndex=index;">
		<p>LINE {{lineIndex + 1}}</p>
		<mat-form-field appearance="outline">
			<mat-label>Character</mat-label>
			<input matInput [(ngModel)]="line.character" [formControl]="characterControl">
			<button *ngIf="line.character" matSuffix mat-icon-button aria-label="Clear" (click)="line.character=''">
				<mat-icon>close</mat-icon>
			</button>
			<mat-error *ngIf="characterControl.hasError('required')">
				Character is <strong>required</strong>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>Voice Actor</mat-label>
			<mat-select [(ngModel)]="line.voice">
				<mat-option *ngFor="let voice of voiceActors" [value]="voice">{{voice}}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>Line</mat-label>
			<input matInput [(ngModel)]="line.text" [formControl]="lineControl">
			<button *ngIf="line.text" matSuffix mat-icon-button aria-label="Clear" (click)="line.text=''">
				<mat-icon>close</mat-icon>
			</button>
			<mat-error *ngIf="lineControl.hasError('required')">
				Line is <strong>required</strong>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<ng-container class="dialogue-image-content">
		<mat-form-field appearance="outline">
			<mat-label>ID</mat-label>
			<input matInput [ngModel]="data.imageId" (ngModelChange)="changeImage($event)">
			<button *ngIf="data.imageId" matSuffix mat-icon-button aria-label="Clear" (click)="changeImage('')">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<button mat-button [disabled]="data.imageId === ''" class="verify-button" (click)="verifyImage()">Verify Image</button>
	</ng-container>

	<ng-container>
		<app-image-uploader *ngIf="toVerify" [imageId]="imageVerify" (uploadFiles)="setFile($event)" (exists)="imageExists=true; data.imageId=$event"></app-image-uploader>
	</ng-container>
</div>

<div mat-dialog-actions>
	<button mat-button (click)="closeDialog()">Discard</button>
	<button mat-button (click)="createDialogue()" cdkFocusInitial>Save</button>
</div>