<div class="quiz-tab-content">
    <div class="table header">
      <mat-toolbar class="header-base" color="primary">
        <button mat-mini-fab color="white" (click)="addVideoSession()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-toolbar>
    </div>
  
    <div class="grid-container">
      <ng-container *ngFor="let video of videos; let indexOfelement = index">
        <div class="grid-item">
          <mat-card style="text-align: center">
            <button mat-icon-button matTooltip="Delete" class="delete-button" (click)="remove(indexOfelement)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Copy image name" class="copy-button" (click)="copyImageName(indexOfelement)">
              <mat-icon color="primary">collections</mat-icon>
            </button>
            <div class="image-container" (click)="editImage(indexOfelement, video)">
              <img mat-card-image [src]="createImage(video.thumbnail)" style="width: 100%;">
              <div class="image-text" style="text-align: center;">Change Image</div>
              <div class="background"></div>
            </div>
            <div class="image-container" (click)="watchVideo(indexOfelement, video)">
              <img mat-card-image class="thumbnail-video" [src]="createVideo(video.videoId)" />
              <div class="image-text" style="text-align: center">
                Watch and Change Video
              </div>
              <div class="background"></div>
            </div>
            <mat-card-content style="text-align: center">
              <ng-container class="lesson-name-content">
  
                <mat-form-field appearance="outline">
                  <mat-label>Start Seconds</mat-label>
                  <input matInput type="number" [(ngModel)]="video.videoStart" />
                  <button *ngIf="video.videoStart" matSuffix mat-icon-button aria-label="Clear"
                    (click)="video.videoStart = 0">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
  
                <mat-form-field appearance="outline">
                  <mat-label>End Seconds</mat-label>
                  <input matInput type="number" [(ngModel)]="video.videoEnd" />
                  <button *ngIf="video.videoEnd" matSuffix mat-icon-button aria-label="Clear"
                    (click)="video.videoEnd = 0">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </ng-container>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>