import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import { PlacementQuestionsService } from '../placement-questions.service';
import { CreateQuestionDTO, Question, UpdateQuestionDTO } from '../../models/placement-question.model';

@Injectable({
	providedIn: 'root'
})
export class PlacementQuestionsStore {

	private _questions = new BehaviorSubject<Question[] | null>(null);

	constructor(
		private placementQuestionsService: PlacementQuestionsService,
		private toastrService: ToastService,
		private router: Router,
	) { }

	setQuestions(questions: Question[]) {
		this._questions.next(questions);
	}

	private reloadQuestions() {
		this.placementQuestionsService.getAllQuestions()
			.pipe(
				catchError(err => {
					this.toastrService.error('Failed to load questions');
					return of(false)
				})
			).subscribe(res => {
				if (res) {
					this.setQuestions(res as Question[]);
				}
			})
	}

	listQuestions(forceUpdate: boolean): Observable<Question[] | null> {
		if (this._questions.value !== null && !forceUpdate) {
			this._questions.next(this._questions.value);
		} else {
			this.reloadQuestions();
		}

		return this._questions.asObservable();
	}

	createQuestion(question: CreateQuestionDTO) {
		this.placementQuestionsService.createQuestion(question)
			.pipe(
				catchError(err => {
					console.log(err)
					this.toastrService.error('Failed to create question');
					return of(null)
				})
			)
			.subscribe(res => {
				if (res !== null) {
					this.reloadQuestions();
					this.router.navigate(['/navbar/placement-questions']);
					this.toastrService.success('Question created');
				}
			})
	}

	updateQuestion(question: UpdateQuestionDTO, questionId: string) {
		this.placementQuestionsService.updateQuestion(question, questionId)
			.pipe(
				catchError(err => {
					console.log(err);
					this.toastrService.error('Failed to update question');
					return of(false)
				})
			)
			.subscribe(res => {
				if (res !== false) {
					this.toastrService.success('Question updated');
					this.reloadQuestions();
				}
			})
	}

	removeQuestion(questionId: string) {
		this.placementQuestionsService.deleteQuestion(questionId)
			.pipe(
				catchError(err => {
					console.log(err);
					this.toastrService.error('Failed to delete question');
					return of(false);
				})
			)
			.subscribe(res => {
				if (res !== false) {
					this.reloadQuestions();
					this.toastrService.success('Question deleted');
				}
			})
	}

}
