import { environment } from "src/environments/environment";

export const IClassroomGamesGenerics = [
	{
		name: 'Board Game - Vocabulary quiz',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_board_game_2.jpg`,
		gameType: 'snakes-ladders',
	},
	{
		name: 'Board Game - Test your knowledge',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_board_game_1.jpg`,
		gameType: 'snakes-ladders',
	},
	{
		name: 'Escape! - Vocabulary quiz',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_escape_2.jpg`,
		gameType: 'temple-run',
	},
	{
		name: 'Escape! - Test your knowledge',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_escape_1.jpg`,
		gameType: 'temple-run',
	},
	{
		name: 'Flipper - Vocabulary Cards',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_flash_cards_vocabulary.jpg`,
		gameType: 'flash-cards',
	},
	{
		name: 'Flipper - Question Cards',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_flash_cards_questions.jpg`,
		gameType: 'flash-cards',
	},
	{
		name: 'Flipper - Affirmative Cards',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_flash_cards_affirmative.jpg`,
		gameType: 'flash-cards',
	},
	{
		name: 'Flipper - False Cards',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_flash_cards_false.jpg`,
		gameType: 'flash-cards',
	},
	{
		name: 'Hot Potato - Vocabulary quiz',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_hot_potato_2.jpg`,
		gameType: 'hot-potato',
	},
	{
		name: 'Hot Potato - Test your knowledge',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_hot_potato_1.jpg`,
		gameType: 'hot-potato',
	},
	{
		name: 'Mirror Mirror - Vocabulary quiz',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_mirror_mirror_2.jpg`,
		gameType: 'heads-up',
	},
	{
		name: 'Mirror Mirror - Test your knowledge',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_mirror_mirror_1.jpg`,
		gameType: 'heads-up',
	},
	{
		name: 'Tic Tac Toe - Vocabulary quiz',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_tic_tac_toe_2.jpg`,
		gameType: 'tic-tac-toe',
	},
	{
		name: 'Tic Tac Toe - Test your knowledge',
		imageUrl: `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_tic_tac_toe_1.jpg`,
		gameType: 'tic-tac-toe',
	},
];

export interface IClassroomGameGeneric {
	name: string;
	imageUrl: string;
	gameType: string;
};
