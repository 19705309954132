import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaChange, MediaObserver,  } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { AddQuizVideoDialog } from './add-quiz-video/add-quiz-video.component';
import { DomSanitizer } from '@angular/platform-browser';
import { WatchVideoDialog } from 'src/app/shared/components/watch-video/watch-video.component';
import { DeleteConfirmationDialog } from 'src/app/shared/components/delete-confirmation/delete-confirmation.component';
import { DIFFICULTY, IVideoConfig, IVideoQuizRow } from 'src/app/shared/models/lesson-content.model';

import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-quiz-video',
  templateUrl: './quiz-video.component.html',
  styleUrls: ['./quiz-video.component.scss']
})
export class QuizVideoComponent implements OnInit {

	readonly difficulties = DIFFICULTY;

	videos!: IVideoQuizRow[];

	@Input() set data(value: IVideoQuizRow[]) {
		this.videos = value;
	}

	@Output() newData = new EventEmitter<IVideoQuizRow[]>();

	cols: number = 0;
	gridByBreakpoint = {
		xl: 5,
		lg: 4,
		md: 3,
		sm: 2,
		xs: 1,
	}

	constructor(
		public dialog: MatDialog,
		private observableMedia: MediaObserver,
		private _sanitizer: DomSanitizer,
	) {
		this.cols = 5;
	}

	ngAfterContentInit() {
		this.observableMedia.asObservable().subscribe((change : MediaChange[]) => {
			for (let [key, value] of Object.entries(this.gridByBreakpoint)) {
				if (key === change[0].mqAlias) {
					this.cols = value;
				}
			}
		});
	}

	ngOnInit(): void {

	}

	addQuizVideo() {
		const dialogRef = this.dialog.open(AddQuizVideoDialog, {
			width: '550px',
			data: {
				uuid: uuidv4(),
				statement: '',
				rightAnswer: '',
				wrongAnswers: [],
				difficulty: 1,
				isGrammar: false,
				videoId: '',
				videoStart: 0,
				videoEnd: 0,
			} as IVideoQuizRow,
		})


		dialogRef.afterClosed().subscribe((result: IVideoQuizRow) => {
			if (!result) {
				return;
			}

			this.videos.push(result);
			this.sendData();
		})
	}

	createVideo(id: string) {
		return this._sanitizer.bypassSecurityTrustResourceUrl(`http://img.youtube.com/vi/${id}/0.jpg`);
	}

	watchVideo(index: number, video: IVideoQuizRow) {
		const dialogRef = this.dialog.open(WatchVideoDialog, {
			width: '750px',
			data: {
				videoId: video.videoId,
				videoStart: video.videoStart,
				videoEnd: video.videoEnd,
			} as IVideoConfig,
		});

		dialogRef.afterClosed().subscribe((result: IVideoQuizRow) => {
			if (!result) {
				return;
			}
			video.videoId = result.videoId;
			video.videoStart = result.videoStart;
			video.videoEnd = result.videoEnd;
			this.videos[index] = video;
			this.sendData();
		});
	}

	remove(index: number) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
			data: index,
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			this.videos.splice(index, 1);
			this.sendData();
		});
	}

	sendData() {
		this.newData.emit(this.videos);
	}
}
