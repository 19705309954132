import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, of, take } from "rxjs";
import { IActiveLesson } from "../../models/lesson.model";
import { LessonService } from "../lesson.service";

@Injectable({
	providedIn: 'root',
})
export class LessonResolver implements Resolve<IActiveLesson | null> {

	constructor(
		private lessonService: LessonService,
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IActiveLesson | null> {
		const lessonId = route.paramMap.get('lessonId');

		if (lessonId) {
			return this.lessonService.getLessonContent(lessonId).pipe(take(1));
		} else {
			return of(null);
		}
	}

}
