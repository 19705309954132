export const VALID_CEFRS = ['Pre-A1', 'A1', 'A2', 'B1', 'B2'] as const;
export type Cefr = typeof VALID_CEFRS[number];

export const VALID_SKILLS = ['Listening', 'Speaking', 'Reading', 'Writing'] as const;
export type Skill = typeof VALID_SKILLS[number];

export const VALID_GAMES = ['Fill The Gaps Text', 'Drag and Drop', 'Ordering Dialogue', 'Multichoice Listening com várias perguntas', 'Bubble Quiz Match', 'Fill The Gaps Typing', 'Fill The Gaps Multiplechoice'];
export type Game = typeof VALID_GAMES[number];

export const VALID_STATUS = ["waiting", "done", "error"] as const;
export type UploadingStatus = typeof VALID_STATUS[number];

export const VALID_VOICE_ACTORS = [
	'female_US_0',
	'female_US_1',
	'female_AU_2',
	'female_GB_3',
	'female_IN_4',
	'male_US_0',
	'male_US_1',
	'male_US_2',
	'male_AU_3',
	'male_GB_4',
	'male_IN_5',
];
export type VoiceActor = typeof VALID_VOICE_ACTORS[number];

export class CreateQuestionDTO {
	game?: IPlacementGame;
	name!: string;
	skill?: Skill;
	cefr?: Cefr;
	published!: boolean;
}

export class UpdateQuestionDTO {
	game!: IPlacementGame;
	name !: string;
	skill!: Skill;
	cefr!: Cefr;
	questionId!: string;
	published!: boolean;
	version!: number;
}

export interface Question {
	uid: string;
	game: IPlacementGame;
	name: string;
	skill: Skill;
	cefr: Cefr;
	questionId: string;
	published: boolean;
	lastUpdate: string;
	uploadStatus?: IUploadStatus;
	version: number;
}

export interface IUploadStatus {
	isUploading: boolean;
	status: UploadingStatus;
}

export interface IPlacementGame {
	activity: string;
	name?: string;
	content: AnyGame;
}

export type AnyGame = ITextGame | IDialogueGame | ISpeakingDialogueGame | IDragDropGame | IMatchGame | IMultichoiceGame | IColoringGame | IOrderingLettersGame | IMultichoiceFillTheGapsGame | IFillTheGapsImageGame;

export interface ITextGame {
	text: string;
	image: string;
}

export interface IDragDropGame {
	image: string;
	audioTutorialA: string;
	audioTutorialB: string;
	audio: string;
	elements: IDragDropElement[];
	wrongElements: string[];
	targets: IDragDropTarget[];
}

export interface IDragDropElement {
	text: string;
	targets: number[];
}

export interface IDragDropElementEditor {
	text: string;
	targets: boolean[];
}

export interface IDragDropTarget {
	upperLeft: Vector2;
	lowerRight: Vector2;
}

export interface Vector2 {
	x: number;
	y: number;
}

export interface IDialogueGame {
	title: string;
	charA: IDialogueCharacter;
	charB: IDialogueCharacter;
	lines: IDialogueLine[];
}

export interface ISpeakingDialogueGame {
	titleText: string;
	titleVoiceActor: string;
	titleImage: string;
	charVoiceActor: string;
	charA: IDialogueCharacter;
	charB: IDialogueCharacter;
	entries: IDialogueEntry[];
}

export interface IDialogueEntry {
	lineA: string;
	lineB: string;
}

export interface IDialogueCharacter {
	name: string;
	imageId: string;
}

export interface IDialogueLine {
	lineA: string;
	lineB: string;
}

export interface IMultichoiceGame {
	audioTutorialA: string;
	audioTutorialB: string;
	audio: string;
	questions: IMultichoiceQuestion[];
}

export interface IMultichoiceQuestion {
	question: string;
	images: IMultichoiceImage[];
}

export interface IMultichoiceImage {
	imageId: string;
	isCorrect: boolean;
}

export interface IMatchGame {
	question: string;
	images: IMatchPair[];
	audioTutorialA: string;
	audioTutorialB: string;
	audio: string;
}

export interface IMatchPair {
	imageA: string;
	imageB: string;
}

export interface IQuestionGame {
	image: string;
	question: string;
	keywords: string[];
	minWords: number;
}

export interface IColoringGame {
	audioTutorialA: string;
	audioTutorialB: string;
	audioId: string;
	imageId: string;
	targetPoints: IColoringGameTarget[];
}

export interface IColoringGameTarget {
	groupId: number;
	colorId: number;
	x: number;
	y: number;
}

export interface IOrderingLettersGame {
	questions: IOrderingLettersGameQuestion[];
}

export interface IOrderingLettersGameQuestion {
	imageId: string;
	text: string;
}

export interface IMultichoiceFillTheGapsGame {
	image: string;
	text: string;
	choices: IMultichoiceFillTheGapsChoices[];
}

export interface IMultichoiceFillTheGapsChoices {
	id: number;
	wrong: string[];
}

export interface IFillTheGapsImageGame {
	image: string;
	text: string;
	choices: IFillTheGapsImageChoices[];
	wrongChoices: IFillTheGapsImageWrongChoices[];
}

export interface IFillTheGapsImageChoices {
	id: number;
	image: string;
}

export interface IFillTheGapsImageWrongChoices {
	id: number;
	text: string;
	image: string;
}
