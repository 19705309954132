import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "app-delete-confirmation",
	templateUrl: "./delete-confirmation.component.html",
	styleUrls: ["./delete-confirmation.component.scss"],
})
export class DeleteConfirmationDialog implements OnInit {

	constructor(
		public dialogRef: MatDialogRef<DeleteConfirmationDialog>,
		) {
	}

	ngOnInit() {}

	cancel() {
		this.dialogRef.close(false);
		return;
	}

	confirm() {
		this.dialogRef.close(true);
		return;
	}
}
