
export interface ILessonContent {
	lessonId: string;
	dialogues: IDialogueRow[];
	dragDrop: IDragAndDropRow[];
	questions: IQuestionsRow[];
	quizImage: IImageQuizRow[];
	quizQuestion: IQuizQuestionRow[];
	quizText: ITextQuizRow[];
	quizVideo: IVideoQuizRow[];
	sentences: ISentenceRow[];
	texts: ITextsRow[];
	vocabulary: IVocabularyRow[];
	videoSession: IVideoSessionRow[];
	lessonParameters: string[];
	uploadId: string;
}

export interface IUpdateLessonContent {
	version?: number;
	lessonId?: string;
	vocabulary?: IVocabularyRow[];
	sentences?: ISentenceRow[];
	dialogues?: IDialogueRow[];
	texts?: ITextsRow[];
	questions?: IQuestionsRow[];
	quizVideo?: IVideoQuizRow[];
	quizImage?: IImageQuizRow[];
	quizText?: ITextQuizRow[];
	quizQuestion?: IQuizQuestionRow[];
	dragDrop?: IDragAndDropRow[];
	videoSession?: IVideoSessionRow[];
	lessonParameters?: string[];
	uploadId?: string;
}

export interface ICreateLessonContent {
	vocabulary?: IVocabularyRow[];
	sentences?: ISentenceRow[];
	dialogue?: IDialogueRow[];
	texts?: ITextsRow[];
	questions?: IQuestionsRow[];
	quizVideo?: IVideoQuizRow[];
	quizImage?: IImageQuizRow[];
	quizText?: ITextQuizRow[];
	quizQuestion?: IQuizQuestionRow[];
	dragDrop?: IDragAndDropRow[];
	videoSession?: IVideoSessionRow[];
	lessonParameters?: string[];
}

export interface IVocabularyRow {
	uuid: string;
	text: string;
	imageId: string;
}

export interface ISentenceRow {
	uuid: string;
	affirmativeTrue: string;
	affirmativeFalse?: string;
	negativeTrue?: string;
	negativeFalse?: string;
	posQuestion?: string;
	posAnswer?: string;
	negQuestion?: string;
	negAnswer?: string;
	whQuestion?: string;
	imageId: string;
}

export interface ITextsRow {
	uuid: string;
	title: string;
	text: string;
	imageId: string;
}

export interface IDialogueRow {
	uuid: string;
	subject: string;
	lines: IDialogueLine[];
	imageId: string;
}

export interface IDialogueLine {
	character: string;
	voice: IVoiceActor;
	text: string;
}

export enum IVoiceActor {
	FEMALE_US = 'female_US_0',
	FEMALE_US_1 = 'female_US_1',
	FEMALE_AU = 'female_AU_2',
	FEMALE_GB = 'female_GB_3',
	FEMALE_IN = 'female_IN_4',
	MALE_US = 'male_US_0',
	MALE_US_1 = 'male_US_1',
	MALE_US_2 = 'male_US_2',
	MALE_AU = 'male_AU_3',
	MALE_GB = 'male_GB_4',
	MALE_IN = 'male_IN_5',
}

export interface IQuestionsRow {
	uuid: string;
	question: string;
	keywords: string[];
	difficulty: Difficulty;
	minWords: number;
	imageId: string;
	//NOVAS COLUNAS DE ALINE
	type?: any; //MOMENTO DA LESSON ( A SER DEFINIDO)
	objective?: string;
	description?: string;
	guidance?: string;
	dashboardTitle?: string;
}

export interface IVideoQuizRow {
	uuid: string;
	statement: string;
	rightAnswer: string;
	wrongAnswers: string[];
	difficulty: Difficulty;
	isGrammar: boolean;
	videoId: string;
	videoStart: number;
	videoEnd: number;
	//NOVAS COLUNAS DE ALINE
	thumbnail?: string;
	type?: any; //MOMENTO DA LESSON ( A SER DEFINIDO)
	objective?: string;
	description?: string;
	guidance?: string;
	dashboardTitle?: string;
}

export interface IVideoConfig {
	videoId: string;
	videoStart: number;
	videoEnd: number;
}

export interface IImageQuizRow {
	uuid: string;
	statement: string;
	rightAnswer: string;
	wrongAnswers: string[];
	difficulty: Difficulty;
	isGrammar: boolean;
	imageId: string;
	//NOVAS COLUNAS DE ALINE
	type?: any; //MOMENTO DA LESSON ( A SER DEFINIDO)
	objective?: string;
	description?: string;
	guidance?: string;
	dashboardTitle?: string;
}

export interface ITextQuizRow {
	uuid: string;
	statement: string;
	rightAnswer: string;
	wrongAnswers: string[];
	difficulty: Difficulty;
	isGrammar: boolean;
	text: string;
	//NOVAS COLUNAS DE ALINE
	type?: any; //MOMENTO DA LESSON ( A SER DEFINIDO)
	objective?: string;
	description?: string;
	guidance?: string;
	dashboardTitle?: string;
}

export interface IQuizQuestionRow {
	uuid: string;
	statement: string;
	rightAnswer: string;
	wrongAnswers: string[];
	difficulty: Difficulty;
	isGrammar: boolean;
	//NOVAS COLUNAS DE ALINE
	type?: any; //MOMENTO DA LESSON ( A SER DEFINIDO)
	objective?: string;
	description?: string;
	guidance?: string;
	dashboardTitle?: string;
}

export interface IDragAndDropRow {
	uuid: string;
	statement: string;
	elementType: 'image' | 'text';
	elements: IDragDropElement[];
	targets: IDragDropTarget[];
    backgroundId: string;
}

export interface IDragDropElement {
	name: string;
	image?: string;
	targets: number[];
}

export interface IDragDropTarget {
	upperLeft: Vector2;
	lowerRight: Vector2;
}

export interface Vector2 {
	x: number;
	y: number;
}

export interface ICreateDragDrop {
	uuid: string;
	statement: string;
	elementType: 'image' | 'text';
	targets: IDragDropTarget[];
    backgroundId: string;
	elements: ICreateDragDropElement[];
}

export interface ICreateDragDropElement {
	name: string;
	image: string;
	targets: boolean[];
	verifyImage: boolean;
	imageExists: boolean;
}

export interface IVideoSessionRow {
	uuid: string;
	videoId: string;
	videoStart: number;
	videoEnd: number;
	thumbnail: string;
	//NOVAS COLUNAS DE ALINE
	type?: any; //MOMENTO DA LESSON ( A SER DEFINIDO)
	objective?: string;
	description?: string;
	guidance?: string;
	dashboardTitle?: string;
}

export type ITabTypes = 'vocabulary' | 'sentences' | 'dialogues'| 'texts' | 'questions' | 'quizVideo' | 'quizImage' | 'quizText' |  'quizQuestion' | 'dragDrop';

export const DIFFICULTY = [1, 2, 3, 4] as const;
export type Difficulty = typeof DIFFICULTY[number];

