import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import {
	IUploadImage,
	ImageFiles,
} from "src/app/shared/models/image-upload.model";
import {
	AnyGame,
	CreateQuestionDTO,
	IMultichoiceGame,
	IMultichoiceImage,
	IMultichoiceQuestion,
	UpdateQuestionDTO,
} from "src/app/shared/models/placement-question.model";
import { CreateQuestionDialog } from "../create-question.component";
import { Subscription } from "rxjs";
import { ToastService } from "angular-toastify";
import { PlacementQuestionsService } from "src/app/shared/services/placement-questions.service";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";

interface IFormQuestion {
	question: string;
	images: IFormImage[];
}

interface IFormImage {
	imageId: string;
	isCorrect: boolean;
	imageExists: boolean;
	verifyImage: boolean;
	file: ImageFiles;
}

@Component({
	selector: "app-multichoice-placement-media",
	templateUrl: "./multichoice-placement-media.component.html",
	styleUrls: ["./multichoice-placement-media.component.scss"],
})
export class MultichoicePlacementMediaComponent implements OnInit, OnDestroy {
	@Input() data!: CreateQuestionDTO | UpdateQuestionDTO;
	@Input() isUpdate!: boolean;

	@Output() questionUploaded = new EventEmitter<boolean>();

	questionsForm!: FormGroup;

	private uploadSubscription!: Subscription;
	private addSubscription!: Subscription;

	hasAudioTutorialA!: boolean;
	audioTutorialAUrl!: string | null;
	hasAudioTutorialB!: boolean;
	audioTutorialBUrl!: string | null;
	hasAudio!: boolean;
	audioUrl!: string | null;

	constructor(
		public dialogRef: MatDialogRef<CreateQuestionDialog>,
		private placementService: PlacementQuestionsService,
		private uploadService: ImageUploaderService,
		private readonly toastService: ToastService,
		private fb: FormBuilder
	) {
		this.addSubscription = new Subscription();
		this.uploadSubscription = new Subscription();

		this.questionsForm = this.fb.group({
			questions: this.fb.array([]),
		});
		this.addLine();
	}

	ngOnInit() {
		if (this.data.game && this.isMultichoiceGame(this.data.game.content)) {
			this.questions.clear();
			this.audioTutorialAUrl = this.data.game.content.audioTutorialA;
			this.hasAudioTutorialA = true;
			this.audioTutorialBUrl = this.data.game.content.audioTutorialB;
			this.hasAudioTutorialB = true;
			this.audioUrl = this.data.game.content.audio;
			this.hasAudio = true;
			this.data.game.content.questions.forEach((data) => {
				const images = this.fb.array([]);

				data.images.forEach((image) => {
					images.push(
						this.fb.group({
							imageId: new FormControl(
								image.imageId,
								Validators.required
							),
							isCorrect: new FormControl(
								image.isCorrect,
								Validators.required
							),
							imageExists: new FormControl(
								true,
								Validators.required
							),
							verifyImage: new FormControl(
								true,
								Validators.required
							),
							file: new FormControl(null, Validators.required),
						})
					);
				});

				this.questions.push(
					this.fb.group({
						question: new FormControl(
							data.question,
							Validators.required
						),
						images: images,
					})
				);
			});
		}
	}

	ngOnDestroy(): void {
		if (this.addSubscription) {
			this.addSubscription.unsubscribe();
		}

		if (this.uploadSubscription) {
			this.uploadSubscription.unsubscribe();
		}
	}

	isMultichoiceGame(game: AnyGame): game is IMultichoiceGame {
		const hasBasicProps =
			typeof (game as IMultichoiceGame).audio === "string" &&
			typeof (game as IMultichoiceGame).audioTutorialA === "string" &&
			typeof (game as IMultichoiceGame).audioTutorialB === "string" &&
			Array.isArray((game as IMultichoiceGame).questions);

		if (hasBasicProps) {
			return (game as IMultichoiceGame).questions.every(
				(question: IMultichoiceQuestion) => {
					const isQuestion =
						typeof question.question === "string" &&
						Array.isArray(question.images);

					return !isQuestion
						? false
						: question.images.every(
							(imageInfo) =>
								typeof imageInfo.imageId === "string" &&
								typeof imageInfo.isCorrect === "boolean"
						);
				}
			);
		}

		return false;
	}

	audioTutorialAUploaded(url: string | null) {
		this.hasAudioTutorialA = url ? true : false;
		this.audioTutorialAUrl = url;
	}

	audioTutorialBUploaded(url: string | null) {
		this.hasAudioTutorialB = url ? true : false;
		this.audioTutorialBUrl = url;
	}

	audioUploaded(url: string | null) {
		this.hasAudio = url ? true : false;
		this.audioUrl = url;
	}

	clearQuestionImage(newValue: string, form: AbstractControl | null) {
		if (form !== null) {
			(form as FormControl)
				.get("imageId")
				?.setValue(newValue, { emitEvent: true });
			(form as FormControl)
				.get("verifyImage")
				?.setValue(false, { emitEvent: true });
			(form as FormControl)
				.get("imageExists")
				?.setValue(false, { emitEvent: true });
			(form as FormControl)
				.get("file")
				?.setValue(null, { emitEvent: true });
		}
	}

	verifyQuestionImage(form: AbstractControl | null) {
		if (form !== null) {
			(form as FormControl)
				.get("verifyImage")
				?.setValue(true, { emitEvent: true });
		}
	}

	setQuestionFile(file: ImageFiles | null, form: AbstractControl | null) {
		if (form !== null) {
			(form as FormControl)
				.get("imageId")
				?.setValue(file?.name ?? "", { emitEvent: true });
			(form as FormControl)
				.get("file")
				?.setValue(file, { emitEvent: true });

			if (file === null) {
				(form as FormControl)
					.get("verifyImage")
					?.setValue(false, { emitEvent: true });
				(form as FormControl)
					.get("imageExists")
					?.setValue(false, { emitEvent: true });
			}
		}
	}

	existsFile(
		form: AbstractControl | null,
		imageExists: boolean,
		imageId: string
	) {
		if (form !== null) {
			(form as FormControl)
				.get("imageExists")
				?.setValue(imageExists, { emitEvent: true });
			(form as FormControl)
				.get("imageId")
				?.setValue(imageId, { emitEvent: true });
		}
	}

	get questions(): FormArray {
		return this.questionsForm.get("questions") as FormArray;
	}

	getFormGroup(form: AbstractControl | null) {
		return form as FormGroup;
	}

	getImagesArray(form: AbstractControl): FormArray {
		return (form as FormGroup).get("images") as FormArray;
	}

	createImageGroups(): FormArray {
		const imageGroupArray = this.fb.array([
			this.fb.group({
				imageId: new FormControl(null, Validators.required),
				isCorrect: new FormControl(true, Validators.required),
				imageExists: new FormControl(false, Validators.required),
				verifyImage: new FormControl(false, Validators.required),
				file: new FormControl(null, Validators.required),
			}),
			this.fb.group({
				imageId: new FormControl(null, Validators.required),
				isCorrect: new FormControl(false, Validators.required),
				imageExists: new FormControl(false, Validators.required),
				verifyImage: new FormControl(false, Validators.required),
				file: new FormControl(null, Validators.required),
			}),
			this.fb.group({
				imageId: new FormControl(null, Validators.required),
				isCorrect: new FormControl(false, Validators.required),
				imageExists: new FormControl(false, Validators.required),
				verifyImage: new FormControl(false, Validators.required),
				file: new FormControl(null, Validators.required),
			}),
		]);
		return imageGroupArray;
	}

	createQuestionLine(): FormGroup {
		return this.fb.group({
			question: new FormControl(null, Validators.required),
			images: this.createImageGroups(),
		});
	}

	addLine(): void {
		this.questions.push(this.createQuestionLine());
	}

	removeLine(index: number): void {
		this.questions.removeAt(index);
	}

	uploadQuestion() {
		let canUpload: boolean = true;

		if (!this.hasAudioTutorialA && this.audioTutorialAUrl == null) {
			this.toastService.error("No tutorial audio A has been uploaded.");
			canUpload = false;
		}

		if (!this.hasAudioTutorialB && this.audioTutorialBUrl == null) {
			this.toastService.error("No tutorial audio B has been uploaded.");
			canUpload = false;
		}

		if (!this.hasAudio && this.audioUrl == null) {
			this.toastService.error("No audio has been uploaded.");
			canUpload = false;
		}

		if (this.questions.controls.length <= 0) {
			this.toastService.error("No questions have been created.");
			canUpload = false;
		}

		let allImages = true;
		const fileBlobs: Blob[] = [];
		const fileIds: IUploadImage[] = [];

		this.questions.controls.forEach((element, index) => {
			const questionForm = element.get("question");
			if (
				!questionForm ||
				!questionForm.value ||
				questionForm.value.trim() == ""
			) {
				questionForm?.markAsTouched();
				canUpload = false;
			}
			const imagesForm = (element as FormGroup).get(
				"images"
			) as FormArray;
			imagesForm.controls.forEach((imageElement) => {
				const imageId = imageElement.get("imageId");
				const exists = imageElement.get("imageExists");
				const file = imageElement.get("file");
				let errors = false;
				if (!imageId || !imageId.value || imageId.value.trim() == "") {
					imageId?.markAsTouched();
					canUpload = false;
					errors = true;
				}

				if ((!exists || !exists.value) && (!file || !file.value)) {
					allImages = false;
					errors = true;
					canUpload = false;
				}

				if (!errors && file?.value) {
					fileBlobs.push(
						new Blob([file.value.file], {
							type: "image/jpeg",
						})
					);

					fileIds.push({
						id: file.value.name,
						extension: "jpg",
					} as IUploadImage);
				}
			});
		});

		if (!allImages) {
			this.toastService.error(`There are one or more images missing.`);
		}

		if (!canUpload) {
			this.toastService.error(
				"There are one or more errors in upload form. Please fix them and try again."
			);
			this.questionUploaded.emit(false);
			return;
		}

		this.data = {
			...this.data,
			game: {
				activity: "multichoice-placement",
				content: {
					audioTutorialA: this.audioTutorialAUrl,
					audioTutorialB: this.audioTutorialBUrl,
					audio: this.audioUrl,
					questions: (this.questions.value as IFormQuestion[]).map(data => {
						return {
							question: data.question,
							images: data.images.map(image => {
								return {
									imageId: image.imageId,
									isCorrect: image.isCorrect,
								} as IMultichoiceImage;
							})
						} as IMultichoiceQuestion
					}),
				} as IMultichoiceGame,
			},
		};

		if (fileBlobs.length > 0) {
			this.uploadService
				.uploadPlacementImage(fileBlobs, fileIds)
				.subscribe((res) => {
					if (!res) {
						this.toastService.error("Failed to upload images");
						this.questionUploaded.emit(false);
						return;
					} else {
						this.toastService.success(
							"Images were uploaded successfully"
						);

						if (!this.isUpdate) {
							this.createQuestion(this.data as CreateQuestionDTO);
						} else {
							this.updateQuestion(this.data as UpdateQuestionDTO);
						}
					}
				});
		} else {
			if (!this.isUpdate) {
				this.createQuestion(this.data as CreateQuestionDTO);
			} else {
				this.updateQuestion(this.data as UpdateQuestionDTO);
			}
		}
	}

	createQuestion(createData: CreateQuestionDTO) {
		this.addSubscription = this.placementService
			.createQuestion(createData)
			.pipe()
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to create question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to create question.");
					this.questionUploaded.emit(false);
				},
			});
	}

	updateQuestion(updateData: UpdateQuestionDTO) {
		this.addSubscription = this.placementService
			.updateQuestion(updateData, updateData.questionId)
			.pipe()
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to update question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to update question.");
					this.questionUploaded.emit(false);
				},
			});
	}
}
