<lib-toastify-toast-container></lib-toastify-toast-container>
<div class="body">

    <div class="selector">

        <div *ngIf="!videoId || !videoUrl" class="no-video-warning">
            Adicione um link válido do youtube abaixo
        </div>

        <iframe *ngIf="videoId && videoUrl" width="500" height="250" [src]="videoUrl" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>

        <mat-form-field appearance="standard" *ngIf="!timeGroup">
            <mat-label>Youtube link</mat-label>
            <input matInput class="video-url-input" [formControl]="videoUrlControl" />
            <button mat-mini-fab color="primary" (click)="searchVideo()">
                <mat-icon>find_replace</mat-icon>
            </button>
        </mat-form-field>

    </div>


    <div *ngIf="timeGroup" class="video-config-container">

        <div class="config-container">
            <label>Iniciar vídeo em</label>
            <div class="time-selector" [formGroup]="timeGroup">
                <input type="number" formControlName="minHourControl">
                :
                <input type="number" formControlName="minMinutesControl">
                :
                <input type="number" formControlName="minSecsControl">
            </div>
        </div>

        <div class="config-container">
            <label>Terminar vídeo em</label>
            <div class="time-selector" [formGroup]="timeGroup">
                <input type="number" formControlName="maxHourControl">
                :
                <input type="number" formControlName="maxMinutesControl">
                :
                <input type="number" formControlName="maxSecsControl">
            </div>
        </div>

        <button [disabled]="!timeGroup.valid || !videoId" mat-raised-button color="primary" (click)="submitYoutubeStep()">Finalizar</button>
    </div>
</div>