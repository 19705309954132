import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularToastifyModule, ToastService } from "angular-toastify";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NavbarRoutingModule } from "../navbar-routing.module";
import { LessonComponent } from "./lesson.component";
import { LessonInfoComponent } from "./tabs/lesson-info/lesson-info.component";
import { VocabularyComponent } from "./tabs/vocabulary/vocabulary.component";
import { SentencesComponent } from "./tabs/sentences/sentences.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { AddVocabularyDialog } from "./tabs/vocabulary/add-vocabulary/add-vocabulary.component";
import { AddSentenceDialog } from "./tabs/sentences/add-sentence/add-sentence.component";
import { AddTextDialog } from "./tabs/texts/add-text/add-text.component";
import { AddQuestionDialog } from "./tabs/questions/add-question/add-question.component";
import { SharedModule } from "src/app/shared/components/shared.module";
import { TextsComponent } from "./tabs/texts/texts.component";
import { DialogueComponent } from "./tabs/dialogue/dialogue.component";
import { AddDialogueDialog } from "./tabs/dialogue/add-dialogue/add-dialogue.component";
import { QuestionsComponent } from "./tabs/questions/questions.component";
import { MatStepperModule } from "@angular/material/stepper";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { QuizQuestionsComponent } from "./tabs/quiz-questions/quiz-questions.component";
import { AddQuizQuestionDialog } from "./tabs/quiz-questions/add-quiz-question/add-quiz-question.component";
import { QuizTextComponent } from "./tabs/quiz-text/quiz-text.component";
import { AddQuizTextDialog } from "./tabs/quiz-text/add-quiz-text/add-quiz-text.component";
import { QuizImageComponent } from "./tabs/quiz-image/quiz-image.component";
import { AddQuizImageDialog } from "./tabs/quiz-image/add-quiz-image/add-quiz-image.component";
import { QuizVideoComponent } from "./tabs/quiz-video/quiz-video.component";
import { AddQuizVideoDialog } from "./tabs/quiz-video/add-quiz-video/add-quiz-video.component";
import { ResizableModule } from "angular-resizable-element";
import { AddDragDropDialog } from "./tabs/drag-drop/add-drag-drop/add-drag-drop.component";
import { DragDropComponent } from "./tabs/drag-drop/drag-drop.component";
import { LessonParametersComponent } from "./tabs/lesson-parameters/lesson-parameters.component";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { VideoSessionComponent } from "./tabs/video-session/video-session.component";
import { AddVideoSessionDialog } from "./tabs/video-session/add-video-session/add-video-session.component";

@NgModule({
	declarations: [
		LessonComponent,
		LessonInfoComponent,
		LessonParametersComponent,
		VocabularyComponent,
		SentencesComponent,
		TextsComponent,
		QuestionsComponent,
		QuizQuestionsComponent,
		QuizTextComponent,
		QuizImageComponent,
		QuizVideoComponent,
		DragDropComponent,
		VideoSessionComponent,
		AddVocabularyDialog,
		AddSentenceDialog,
		AddTextDialog,
		AddQuestionDialog,
		AddQuizQuestionDialog,
		AddQuizTextDialog,
		AddQuizImageDialog,
		AddQuizVideoDialog,
		DialogueComponent,
		AddDialogueDialog,
		DragDropComponent,
		AddDragDropDialog,
		AddVideoSessionDialog,
	],
	imports: [
		NavbarRoutingModule,
		AngularToastifyModule,
		CommonModule,
		FormsModule,
		DragDropModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatDialogModule,
		MatFormFieldModule,
		MatGridListModule,
		MatExpansionModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatPaginatorModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		MatStepperModule,
		MatTableModule,
		MatExpansionModule,
		ReactiveFormsModule,
		SharedModule,
		ResizableModule,
	],
	providers: [ToastService, ImageUploaderService],
})
export class LessonModule {}
