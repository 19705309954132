import { Component, EventEmitter, Input, Output, ViewChild, ViewChildren, ElementRef, QueryList, ChangeDetectorRef, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, forkJoin } from 'rxjs';
import { PlacementQuestionsService } from 'src/app/shared/services/placement-questions.service';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';
import { AudioService } from 'src/app/shared/services/audio.service';
import { ToastService } from 'angular-toastify';
import { round } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { AnyGame, CreateQuestionDTO, IDragDropElement, IDragDropElementEditor, IDragDropGame, IDragDropTarget, UpdateQuestionDTO } from 'src/app/shared/models/placement-question.model';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ResizeEvent } from 'angular-resizable-element';
import { AddDragDropDialog } from 'src/app/navbar/lesson/tabs/drag-drop/add-drag-drop/add-drag-drop.component';
import { AudioFiles } from 'src/app/shared/models/audio-upload.model';
import { ImageFiles, IUploadImage } from 'src/app/shared/models/image-upload.model';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-drag-drop-media',
  templateUrl: './drag-drop-media.component.html',
  styleUrls: ['./drag-drop-media.component.scss']
})
export class DragDropMediaComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() data!: CreateQuestionDTO | UpdateQuestionDTO;
  @Input() isUpdate!: boolean;

  @Output() questionUploaded = new EventEmitter<boolean>();

  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild("boundBox", { static: false }) boundBox!: ElementRef;
  @ViewChildren("dragElement") dragElementRefs!: QueryList<ElementRef>;
  dragElements: IDragDropTarget[] = [];

  completed1: boolean = false;

  public files: ImageFiles[] = [];
  public elementFiles: Map<number, ImageFiles> = new Map<number, ImageFiles>();

  // arquivos para os tutoriais de áudio
  public audioFiles: AudioFiles[] = [];
  public elementAudioFiles: Map<number, AudioFiles> = new Map<number, AudioFiles>();

  image = '';
  imageExists: boolean = false;
  imageToVerify: boolean = false;
  imageVerify!: string;

  hasAudio!: boolean;
  audioUrl!: string | null;

  audioTutorialAUrl!: string | null;
  audioTutorialBUrl!: string | null;

  targets!: IDragDropTarget[];
  elements!: IDragDropElementEditor[];
  wrongElements: string[] = [];

  positionX = 0;
  positionY = 0;

  uploading: boolean = false;
  addSubscription!: Subscription;

  constructor(
    public dialogRef: MatDialogRef<AddDragDropDialog>,
    private placementQuestionsService: PlacementQuestionsService,
    private imageService: ImageUploaderService,
    private audioService: AudioService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    this.addSubscription = new Subscription();
  }

  ngOnInit(): void {
    this.targets = [];
    this.elements = [];

    if (this.data.game && this.isDragDropGame(this.data.game.content)) {
      this.image = this.data.game.content.image;
      this.imageExists = true;
      this.audioUrl = this.data.game.content.audio;
      this.audioTutorialAUrl = this.data.game.content.audioTutorialA;
      this.audioTutorialBUrl = this.data.game.content.audioTutorialB;

      const elementsLength = this.data.game.content.elements.length;

      this.elements = this.data.game.content.elements.map(element => {
        const targets = new Array(elementsLength).fill(false);

        element.targets.forEach(targetIndex => {
          if (targetIndex >= 0 && targetIndex < targets.length) {
            targets[targetIndex] = true;
          }
        });

        return {
          text: element.text,
          targets: targets
        };
      });

      this.targets = this.data.game.content.targets;

      // Initialize wrong elements if updating
      this.wrongElements = this.data.game.content.wrongElements || [];
    }

    if (this.image !== "") {
      this.imageExists = true;
      this.completed1 = true;
    }
  }

  ngOnDestroy(): void {
    if (this.addSubscription) {
      this.addSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.dragElementRefs.changes.subscribe(() => {
      this.initializeTargets();
    });
    this.initializeTargets();
  }

  isDragDropGame(game: AnyGame): game is IDragDropGame {
    return (
      (game as IDragDropGame).image !== undefined &&
      (game as IDragDropGame).audio !== undefined &&
      (game as IDragDropGame).audioTutorialA !== undefined &&
      (game as IDragDropGame).audioTutorialB !== undefined &&
      (game as IDragDropGame).elements !== undefined &&
      (game as IDragDropGame).targets !== undefined
    );
  }

  close() {
    this.dialogRef.close();
  }

  changeImage(newValue: string) {
    this.image = newValue;
    this.imageToVerify = false;
    this.files = [];
    this.imageExists = false;
    this.completed1 = false;
  }

  verifyImage() {
    this.imageVerify = this.image;
    this.imageToVerify = true;
  }

  setFile(file: ImageFiles | null) {
    if (file === null) {
      this.completed1 = false;
      this.image = "";
      this.files = [];
      return;
    }
    this.completed1 = true;
    this.image = file.name;
    this.files.push(file);
  }

  getImage() {
    if (this.imageExists) {
      return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images-placement/${this.image}.png`;
    } else {
      return this.files[0].path;
    }
  }

  audioUploaded(url: string | null) {
    this.hasAudio = url ? true : false;
    this.audioUrl = url;
  }

  audioTutorialAUploaded(url: string | null) {
    this.audioTutorialAUrl = url;
  }

  audioTutorialBUploaded(url: string | null) {
    this.audioTutorialBUrl = url;
  }

  getTargetInfo(element: IDragDropTarget) {
    const { x, y, width, height } = this.getPosition(element);
    return `x: ${x} | y: ${y} | width: ${width} | height: ${height}`;
  }

  initializeTargets() {
    this.dragElementRefs.forEach((dragElement, index) => {
      const target = this.targets[index];
      const style = this.getPosition(target);
      dragElement.nativeElement.style.left = style.x + "px";
      dragElement.nativeElement.style.top = style.y + "px";
      dragElement.nativeElement.style.width = style.width + "px";
      dragElement.nativeElement.style.height = style.height + "px";
    });
    this.cdr.detectChanges();
  }

  calculateTargetPos(element: IDragDropTarget) {
    const { x, y, width, height } = this.getPosition(element);
    return {
      left: x + "px",
      top: y + "px",
      width: width + "px",
      height: height + "px",
    };
  }

  getTargetImagePosition(element: IDragDropTarget) {
    const parentPos = document
      .getElementById("bound-box")
      ?.getBoundingClientRect() as DOMRect;
    const x = round(element.upperLeft.x * parentPos.width);
    const y = round(element.upperLeft.y * parentPos.height);
    return {
      positionX: x,
      positionY: y,
    };
  }

  getPosition(element: IDragDropTarget) {
    const parentPos = document
      .getElementById("bound-box")
      ?.getBoundingClientRect() as DOMRect;
    const offsetX = 22; // Deslocamento de 22px no eixo x
    const x = round(element.upperLeft.x * parentPos.width) + offsetX;
    const y = round(element.upperLeft.y * parentPos.height);
    const width = round(
      Math.max(
        80,
        (element.lowerRight.x - element.upperLeft.x) * parentPos.width
      )
    );
    const height = round(
      Math.max(
        50,
        (element.lowerRight.y - element.upperLeft.y) * parentPos.height
      )
    );
    return {
      x,
      y,
      width,
      height,
    };
  }

  addTarget() {
    this.targets.push({
      lowerRight: { x: 0.13, y: 0.1 },
      upperLeft: { x: 0, y: 0 },
    });

    this.elements = this.elements.map((element) => {
      if (!Array.isArray(element.targets)) {
        element.targets = [];
      }
      element.targets.push(false);
      return element;
    });
  }

  removeTarget(index: number) {
    this.targets.splice(index, 1);
    if (this.elements.length > 0) {
      this.elements = this.elements.map((element) => {
        element.targets.splice(index, 1);
        return element;
      });
    }
  }

  onDragEnded(event: CdkDragEnd, target: IDragDropTarget, index: number) {
    const parentPos = document
      .getElementById("bound-box")
      ?.getBoundingClientRect() as DOMRect;
    const childPos =
      event.source.element.nativeElement.getBoundingClientRect() as DOMRect;
    const xRatio = parentPos.width;
    const yRatio = parentPos.height;
    target.upperLeft.x = Math.max(
      (childPos.left - parentPos.left) / xRatio,
      0
    );
    target.upperLeft.y = Math.max(
      (childPos.top - parentPos.top) / yRatio,
      0
    );
    target.lowerRight.x = Math.min(
      (childPos.left - parentPos.left + childPos.width) / xRatio,
      1
    );
    target.lowerRight.y = Math.min(
      (childPos.top - parentPos.top + childPos.height) / yRatio,
      1
    );
    this.targets[index] = target;
  }

  onResizeEnd(
    event: ResizeEvent,
    target: IDragDropTarget,
    index: number
  ): void {
    const parentPos = document
      .getElementById("bound-box")
      ?.getBoundingClientRect() as DOMRect;
    const xRatio = parentPos.width;
    const yRatio = parentPos.height;
    target.lowerRight.x =
      target.upperLeft.x + (event.rectangle.width as number) / xRatio;
    target.lowerRight.y =
      target.upperLeft.y + (event.rectangle.height as number) / yRatio;
    this.targets[index] = target;

    const style = this.getPosition(target);
    const dragElement = this.dragElementRefs.toArray()[index];
    dragElement.nativeElement.style.width = style.width + "px";
    dragElement.nativeElement.style.height = style.height + "px";
  }

  addElement() {
    if (!Array.isArray(this.elements)) {
      this.elements = [];  // Garante que elements seja uma array
    }
    this.elements.push({
      text: "",
      targets: this.targets.map((target) => false),
    });
  }

  removeElement(index: number) {
    this.elements.splice(index, 1);
  }

  addWrongElement() {
    this.wrongElements.push('');
  }

  removeWrongElement(index: number) {
    this.wrongElements.splice(index, 1);
  }

  getSelected(element: IDragDropElementEditor) {
    const values: string[] = [];
    element.targets.map((target, index) => {
      if (target) {
        values.push(this.getSelectionValue(index));
      }
    });
    return values;
  }

  getSelectionValue(targetIndex: number) {
    return `Target ${targetIndex}`;
  }

  toggleTarget(
    event: MatOptionSelectionChange,
    targetIndex: number,
    elementIndex: number
  ) {
    this.elements[elementIndex].targets[targetIndex] =
      event.source.selected;
  }

  uploadQuestion() {
    this.uploading = true;
    let validationErrors = false;

    if (!this.imageExists && this.files.length === 0) {
      this.toastService.error("No image has been selected.");
      validationErrors = false;
    }

    if (!this.audioUrl || !this.audioTutorialAUrl || !this.audioTutorialBUrl) {
      this.toastService.error("Audio or tutorials are empty.");
      validationErrors = true;
    }

    if (!this.targets.length) {
      this.toastService.error("There are no targets for the background image.");
      validationErrors = true;
    }

    if (!this.elements.length) {
      this.toastService.error("There are no drag elements.");
      validationErrors = true;
    }

    const dragDropElements: IDragDropElement[] = this.elements.map((value, key) => {
      const targets = value.targets.map((value, index) => {
        return value ? index : -1;
      }).filter(index => index !== -1);

      if (targets.length === 0) {
        this.toastService.error(`Element ${key + 1} has no defined targets.`);
        validationErrors = true;
      }

      if (!value.text.trim()) {
        this.toastService.error(`The text for element ${key + 1} is empty.`);
        validationErrors = true;
      }

      return {
        text: value.text.trim() ? value.text : `Element ${key}`,
        targets: targets,
      };
    });

    if (validationErrors) {
      this.uploading = false;
      this.questionUploaded.emit(false);
      return;
    }

    this.data = {
      ...this.data,
      game: {
        activity: 'drag-drop-placement',
        content: {
          image: this.image,
          audio: this.audioUrl,
          audioTutorialA: this.audioTutorialAUrl,
          audioTutorialB: this.audioTutorialBUrl,
          elements: dragDropElements,
          wrongElements: this.wrongElements,
          targets: this.targets
        } as IDragDropGame
      }
    };

    const fileBlobs: Blob[] = this.files.map(file => new Blob([file.file], { type: "image/png" }));
    const fileIds: IUploadImage[] = this.files.map(file => ({ id: file.name, extension: "png" }));

    const audioBlobs: Blob[] = this.audioFiles.map(file => new Blob([file.file], { type: "audio/mp3" }));
    const audioIds: IUploadImage[] = this.audioFiles.map(file => ({ id: file.name, extension: "mp3" }));

    const uploads = [];
    if (fileBlobs.length > 0) {
      uploads.push(this.imageService.uploadPlacementImage(fileBlobs, fileIds));
    }
    if (audioBlobs.length > 0) {
      uploads.push(this.audioService.uploadPlacementAudio(audioBlobs, audioIds));
    }

    if (uploads.length > 0) {
      forkJoin(uploads).subscribe({
        next: (results) => {
          this.uploading = false;
          const allUploadsSuccessful = results.every(result => result === true);
          if (!allUploadsSuccessful) {
            this.toastService.error("Failed to upload files");
            this.questionUploaded.emit(false);
            return;
          }
          this.toastService.success("Files were uploaded successfully");
        },
        error: (error) => {
          this.uploading = false;
          this.toastService.error("An error occurred during file upload");
          console.error('Upload error:', error);
          this.questionUploaded.emit(false);
        },
        complete: () => {
          this.handleQuestionCreationOrUpdate();
        }
      });
    } else {
      // If no files need to be uploaded, proceed directly.
      this.handleQuestionCreationOrUpdate();
    }
  }

  private handleQuestionCreationOrUpdate() {
    if (!this.isUpdate) {
      const createOperation = this.placementQuestionsService.createQuestion(this.data);
      this.addSubscription = createOperation.subscribe({
        next: (res: { uid: string }) => {
          if (!res || !res.uid) {
            console.error('Error in question creation: No UID returned');
            this.questionUploaded.emit(false);
            return;
          }
          this.questionUploaded.emit(true);
        },
        error: (error: any) => {
          console.error('Question creation error', error);
          this.questionUploaded.emit(false);
        }
      });
    } else {
      const updateData = this.data as UpdateQuestionDTO;
      const updateOperation = this.placementQuestionsService.updateQuestion(updateData, updateData.questionId);
      this.addSubscription = updateOperation.subscribe({
        next: (res: boolean) => {
          if (!res) {
            console.error('Error in question update: Update failed');
            this.questionUploaded.emit(false);
            return;
          }
          this.questionUploaded.emit(true);
        },
        error: (error: any) => {
          console.error('Question update error', error);
          this.questionUploaded.emit(false);
        }
      });
    }
  }
}
