import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'angular-toastify';
import { ImageFiles, IUploadImage } from 'src/app/shared/models/image-upload.model';
import { ISentenceRow } from 'src/app/shared/models/lesson-content.model';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';

@Component({
  selector: 'app-add-sentence',
  templateUrl: './add-sentence.component.html',
  styleUrls: ['./add-sentence.component.scss']
})
export class AddSentenceDialog implements OnInit {

	public files: ImageFiles[] = [];

	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	affirmativeTrueControl = new FormControl(this.data.affirmativeTrue, Validators.required);
	affirmativeFalseControl = new FormControl(this.data.affirmativeFalse);
	negativeTrueControl = new FormControl(this.data.negativeTrue);
	negativeFalseControl = new FormControl(this.data.negativeFalse);
	questionPositiveControl = new FormControl(this.data.posQuestion);
	shortPositiveAnswerControl = new FormControl(this.data.posAnswer);
	questionNegativeControl = new FormControl(this.data.negQuestion);
	shortNegativeAnswerControl = new FormControl(this.data.negAnswer);
	whQuestionControl = new FormControl(this.data.whQuestion);

	constructor(
		public dialogRef: MatDialogRef<AddSentenceDialog>,
		@Inject(MAT_DIALOG_DATA) public data: ISentenceRow,
		private toastService: ToastService,
		private uploadService: ImageUploaderService,
	) {}

	ngOnInit(): void {

	}

	closeDialog() {
		this.dialogRef.close()
	}

	changeImage(newValue: string) {
		this.data.imageId = newValue;
		this.toVerify = false;
		this.files = [];
		this.imageExists = false;
	}

	verifyImage() {
		this.imageVerify = this.data.imageId;
		this.toVerify = true;
	}

	setFile(file: ImageFiles | null) {
		if (file === null) {
			this.data.imageId = '';
			this.files = [];
			return;
		}
		this.data.imageId = file.name;
		this.files.push(file);
	}

	createSentence() {
		let canUpload = true;

		this.data.affirmativeTrue = this.affirmativeTrueControl.value;

		if (this.affirmativeFalseControl.value) {
			this.data.affirmativeFalse = this.affirmativeFalseControl.value;
		}

		if (this.negativeTrueControl.value) {
			this.data.negativeTrue = this.negativeTrueControl.value;
		}

		if (this.negativeFalseControl.value) {
			this.data.negativeFalse = this.negativeFalseControl.value;
		}

		if (this.questionPositiveControl.value) {
			this.data.posQuestion = this.questionPositiveControl.value;
		}

		if (this.shortPositiveAnswerControl.value) {
			this.data.posAnswer = this.shortPositiveAnswerControl.value;
		}

		if (this.questionNegativeControl.value) {
			this.data.negQuestion = this.questionNegativeControl.value;
		}

		if (this.shortNegativeAnswerControl.value) {
			this.data.negAnswer = this.shortNegativeAnswerControl.value;
		}

		if (this.whQuestionControl.value) {
			this.data.whQuestion = this.whQuestionControl.value;
		}

		if (this.data.affirmativeTrue === '') {
			this.affirmativeTrueControl.markAsTouched();
			canUpload = false;
		}

		if (!this.imageExists && this.files.length === 0) {
			this.toastService.error('No image has been selected.')
			return;
		}

		if (!canUpload) {
			this.toastService.error('Failed to save new sentence.')
			return;
		}

		if (this.files.length > 0) {
			const uploadFileIds = this.files.map(file => {
				return {
					id: file.name,
					extension: 'jpg',
				} as IUploadImage;
			})

			const fileBlobs = this.files.map(fileInfo => {
				const imageBlob = new Blob([fileInfo.file], {type: `image/jpeg`})
				return imageBlob;
			});

			this.uploadService.uploadImages(fileBlobs, uploadFileIds).subscribe(res => {
				if (!res) {
					this.toastService.error('Failed to upload image');
					return;
				} else {
					this.toastService.success('Image was uploaded successfully');
					this.toastService.success('Sentence added.')
					this.dialogRef.close(this.data);
				}
			});
		} else {
			this.toastService.success('Sentence added.')
			this.dialogRef.close(this.data);
		}
	}

}
