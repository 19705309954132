import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreateStepDTO, Step, UpdateStepDTO } from '../models/steps.model';

@Injectable({
  providedIn: 'root'
})
export class StepsService {

	constructor(
		private http: HttpClient,
	) {}

	public createStep(step: CreateStepDTO) : Observable<{uid: string}> {
		return this.http.post<{uid: string}>(`${environment.SERVICES.LESSON}steps`, step);
	}

	public updateStep(step: UpdateStepDTO, stepId: string) : Observable<void> {
		return this.http.put<void>(`${environment.SERVICES.LESSON}steps/${stepId}`, step);
	}

	public getAllSteps(): Observable<Step[]> {
		return this.http.get<Step[]>(`${environment.SERVICES.LESSON}steps`)
		.pipe(
			switchMap(res => {
				return of(res);
			}),
		);
	}

	public getStep(stepId: string): Observable<Step> {
		return this.http.get<Step>(`${environment.SERVICES.LESSON}steps/${stepId}`)
		.pipe(
			switchMap(res => {
				return of(res);
			}),
		);
	}

	public deleteStep(stepId: string): Observable<void> {
		return this.http.delete<void>(`${environment.SERVICES.LESSON}steps/${stepId}`);
	}
}
