<div class="content-view">
  <lib-toastify-toast-container position="bottom-right"></lib-toastify-toast-container>
  <div class="header">
    <mat-toolbar class="header" color="primary">
      <button mat-icon-button color="white" (click)="home()">
        <mat-icon>home</mat-icon>
      </button>
      <div class="start-header">
        <h1 class="title">Steps for Lesson Plans</h1>
      </div>
      <div class="end-header">
        <button mat-mini-fab color="white" (click)="logout()">
          <mat-icon>logout</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  </div>

  <div class="component-container">
    <div class="actions-container">
      <div class="select-all-container">
        <mat-label>select all</mat-label>
        <mat-checkbox (change)="selectAll($event)"></mat-checkbox>
      </div>

      <form class="actions-filter">
        <mat-form-field class="keyword-field" appearance="fill">
          <mat-label>Palavra chave</mat-label>
          <input matInput [ngModel]="globalfilter" (ngModelChange)="applyFilter($event)"
            [ngModelOptions]="{ standalone: true }" />
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="fill">
          <mat-label>Habilidades</mat-label>
          <mat-select [formControl]="skillControl">
            <mat-option>nenhum</mat-option>
            <mat-option *ngFor="let skill of skills" [value]="skill">
              {{ skill }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="fill">
          <mat-label>Momento</mat-label>
          <mat-select [formControl]="momentControl">
            <mat-option>nenhum</mat-option>
            <mat-option *ngFor="let moment of moments" [value]="moment">
              {{ moment }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="fill">
          <mat-label>CEFR</mat-label>
          <mat-select [formControl]="cefrControl">
            <mat-option>nenhum</mat-option>
            <mat-option *ngFor="let cefr of cefrs" [value]="cefr">
              {{ cefr }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="fill">
          <mat-label>Idade</mat-label>
          <mat-select [formControl]="ageControl">
            <mat-option>nenhum</mat-option>
            <mat-option *ngFor="let age of ages" [value]="age">
              {{ age }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="fill">
          <mat-label>Mídia</mat-label>
          <mat-select [formControl]="mediaControl">
            <mat-option>nenhum</mat-option>
            <mat-option *ngFor="let m of media" [value]="m">
              {{ m }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>

      <div class="action-buttons-container">
        <div class="top-actions">
          <button mat-mini-fab color="primary" (click)="createStep()">
            <mat-icon>add</mat-icon>
          </button>

          <button class="action-button" mat-icon-button color="primary" (click)="removeSelectedSteps()">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="loading" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>

    <div class="step-table" *ngIf="!loading">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> NAME </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="name-cell" [style.background-color]="row.media.type === 'activity' && !row.media.content.descriptionName ? '#DE350B' : '#8E58A4'">
              {{ row.name }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> ACTIONS </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index">
            <div class="buttons-action">
              <mat-checkbox class="checkbox" [checked]="stepsSelected[row.uid]" (change)="select($event, row.uid)">
              </mat-checkbox>

              <button class="edit-button" mat-icon-button color="primary" (click)="editStep(row.uid)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="image">

          <mat-header-cell *matHeaderCellDef> IMAGE </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <img [src]="row.media.thumbnailUrl" class="step-img">
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="skills">
          <mat-header-cell *matHeaderCellDef mat-sort-header> SKILLS </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let row" class="text-cell">
            <div>
              <h3>Habilidades</h3>
              <div class="skills-container">
                <button class="skill-button" [ngClass]="{ active: row.skills?.includes('Listening') }" translate>
                  <mat-icon>hearing</mat-icon>
                  Listening
                </button>

                <button class="skill-button" [ngClass]="{ active: row.skills?.includes('Reading') }" translate>
                  <mat-icon>chrome_reader_mode</mat-icon>
                  Reading
                </button>

                <button class="skill-button" [ngClass]="{ active: row.skills?.includes('Writing') }" translate>
                  <mat-icon>create</mat-icon>
                  Writing
                </button>

                <button class="skill-button" [ngClass]="{ active: row.skills?.includes('Speaking') }" translate>
                  <mat-icon>mic</mat-icon>
                  Speaking
                </button>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="attachments">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> ATTACHMENTS </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let row" class="text-cell">
            <div>
              <h3>Anexos</h3>
              <div class="attachment" *ngIf="row.attachments && row.attachments.length > 0">
                <span>{{ row.attachments?.length }}</span>
              </div>
              <div class="attachment" *ngIf="!row.attachments || row.attachments.length === 0">
                <span>Empty</span>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="moment">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> MOMENT </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let row" class="text-cell">
            <div>
              <h3>Momento</h3>
              <div class="moment" [ngClass]="setMomentClass(row.moment)">
                <div class="tag-border"></div>
                <div class="tag">
                  <div class="tag-circle"></div>
                  <p *ngIf="row.moment">{{ row.moment }}</p>
                  <p *ngIf="!row.moment">-</p>
                </div>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="media">
          <mat-header-cell mat-header-cell *matHeaderCellDef> MEDIA </mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-cell">
            <div>
              <h3>Mídia</h3>
              <div class="type" *ngIf="row.media">
                {{getMediaDescription(row)}}
                <mat-icon class="type-icon" *ngIf="row.media.type === 'image'">
                  photo
                </mat-icon>

                <mat-icon class="type-icon" *ngIf="row.media.type === 'youtube'">
                  video_library
                </mat-icon>
              </div>
              <div class="type" *ngIf="!row.media">
                <mat-icon class="type-icon">
                  cancel
                </mat-icon>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="cefr">
          <mat-header-cell mat-header-cell *matHeaderCellDef> CEFR </mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-cell">
            <div>
              <h3>CEFR</h3>
              <div class="lesson-tag-container">
                <span *ngFor="let cefr of row.cefr">{{cefr}}, </span>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="age">
          <mat-header-cell mat-header-cell *matHeaderCellDef> Age </mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-cell">
            <div>
              <h3>Idade</h3>
              <div class="lesson-tag-container">
                <span *ngFor="let age of row.age">{{age}}, </span>
              </div>
            </div>
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="time">
          <mat-header-cell mat-header-cell *matHeaderCellDef> TIME </mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-cell">
            <div>
              <h3>Tempo estimado</h3>
              <p class="time" *ngIf="row.time">{{row.time}} minutos</p>
              <p class="time" *ngIf="!row.time">0 minutos</p>
            </div>
          </mat-cell>
        </ng-container>


        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="defaultPageSize" (page)="paginatorChanged($event)"
      aria-label="Select page of steps" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>