import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from "@angular/core";
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import {
	AnyGame,
	CreateQuestionDTO,
	IMatchGame,
	IMatchPair,
	UpdateQuestionDTO,
} from "src/app/shared/models/placement-question.model";
import { CreateQuestionDialog } from "../create-question.component";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastService } from "angular-toastify";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";
import { PlacementQuestionsService } from "src/app/shared/services/placement-questions.service";
import {
	IUploadImage,
	ImageFiles,
} from "src/app/shared/models/image-upload.model";

interface IFormPair {
	imageA: IFormImage;
	imageB: IFormImage;
}

interface IFormImage {
	imageId: string;
	imageExists: boolean;
	verifyImage: boolean;
	file: ImageFiles;
}

@Component({
	selector: "app-match-game-media",
	templateUrl: "./match-game-media.component.html",
	styleUrls: ["./match-game-media.component.scss"],
})
export class MatchGameMediaComponent implements OnInit, OnDestroy {
	@Input() data!: CreateQuestionDTO | UpdateQuestionDTO;
	@Input() isUpdate!: boolean;

	@Output() questionUploaded = new EventEmitter<boolean>();

	questionControl = new FormControl(null, Validators.required);
	imagesForm!: FormGroup;

	private uploadSubscription!: Subscription;
	private addSubscription!: Subscription;

	hasAudio!: boolean;
	audioUrl!: string | null;
	audioTutorialAUrl!: string | null;
	audioTutorialBUrl!: string | null;

	constructor(
		public dialogRef: MatDialogRef<CreateQuestionDialog>,
		private placementService: PlacementQuestionsService,
		private uploadService: ImageUploaderService,
		private readonly toastService: ToastService,
		private fb: FormBuilder
	) {
		this.addSubscription = new Subscription();
		this.uploadSubscription = new Subscription();

		this.imagesForm = this.fb.group({
			imagePairs: this.fb.array([]),
		});
		this.addLine();
	}

	ngOnInit() {
		if (this.data.game && this.isMatchGame(this.data.game.content)) {
			this.imagePairs.clear();
			this.audioUrl = this.data.game.content.audio;
			this.audioTutorialAUrl = this.data.game.content.audioTutorialA;
			this.audioTutorialBUrl = this.data.game.content.audioTutorialB;
			this.hasAudio = true;
			this.questionControl.setValue(this.data.game.content.question);
			this.data.game.content.images.forEach((data) => {
				const imagePair = this.fb.group({
					imageA: this.fb.group({
						imageId: new FormControl(
							data.imageA,
							Validators.required
						),
						imageExists: new FormControl(true, Validators.required),
						verifyImage: new FormControl(true, Validators.required),
						file: new FormControl(null, Validators.required),
					}),
					imageB: this.fb.group({
						imageId: new FormControl(
							data.imageB,
							Validators.required
						),
						imageExists: new FormControl(true, Validators.required),
						verifyImage: new FormControl(true, Validators.required),
						file: new FormControl(null, Validators.required),
					}),
				});

				this.imagePairs.push(imagePair);
			});
		}
	}

	isMatchGame(game: AnyGame): game is IMatchGame {
		const hasBasicProps =
			Array.isArray((game as IMatchGame).images) &&
			typeof (game as IMatchGame).audio === "string" &&
			typeof (game as IMatchGame).question === "string";

		if (hasBasicProps) {
			return (game as IMatchGame).images.every(
				(imagePair: IMatchPair) => {
					return (
						typeof imagePair.imageA === "string" &&
						typeof imagePair.imageB === "string"
					);
				}
			);
		}
		return false;
	}

	ngOnDestroy(): void {
		if (this.addSubscription) {
			this.addSubscription.unsubscribe();
		}

		if (this.uploadSubscription) {
			this.uploadSubscription.unsubscribe();
		}
	}

	getGroup(form: AbstractControl | null) {
		return form as FormGroup;
	}

	getControl(
		form: AbstractControl | null,
		control: string,
		field: string
	): FormControl | null {
		if (!form) return null;
		return (
			((form.get(control) as FormGroup)?.controls[
				field
			] as FormControl) ?? null
		);
	}

	getValue(
		form: AbstractControl | null,
		control: string,
		field: string
	): any | null {
		if (!form) return null;
		return (form.get(control) as FormGroup)?.controls[field]?.value ?? null;
	}

	audioUploaded(url: string | null) {
		this.hasAudio = url ? true : false;
		this.audioUrl = url;
	}

	audioTutorialAUploaded(url: string | null) {
		this.audioTutorialAUrl = url;
	}

	audioTutorialBUploaded(url: string | null) {
		this.audioTutorialBUrl = url;
	}

	clearPairImage(
		newValue: string,
		form: AbstractControl | null,
		control: string
	) {
		if (form !== null) {
			const formControl = (form.get(control) as FormGroup)?.controls;
			(formControl["imageId"] as FormControl).setValue(newValue, {
				emitEvent: true,
			});
			(formControl["verifyImage"] as FormControl).setValue(false, {
				emitEvent: true,
			});
			(formControl["imageExists"] as FormControl).setValue(false, {
				emitEvent: true,
			});
			(formControl["file"] as FormControl).setValue(null, {
				emitEvent: true,
			});
		}
	}

	verifyPairImage(form: AbstractControl | null) {
		if (form !== null) {
			(form as FormControl).setValue(true, { emitEvent: true });
		}
	}

	setPairFile(
		file: ImageFiles | null,
		form: AbstractControl | null,
		control: string
	) {
		if (form !== null) {
			const formControl = (form.get(control) as FormGroup)?.controls;
			(formControl["imageId"] as FormControl).setValue(file?.name ?? "", {
				emitEvent: true,
			});
			(formControl["file"] as FormControl).setValue(file, {
				emitEvent: true,
			});

			if (file === null) {
				(formControl["verifyImage"] as FormControl).setValue(false, {
					emitEvent: true,
				});
				(formControl["imageExists"] as FormControl).setValue(false, {
					emitEvent: true,
				});
			}
		}
	}

	existsFile(
		form: AbstractControl | null,
		control: string,
		imageExists: boolean,
		imageId: string
	) {
		if (form !== null) {
			const formControl = (form.get(control) as FormGroup)?.controls;
			(formControl["imageExists"] as FormControl).setValue(imageExists, {
				emitEvent: true,
			});
			(formControl["imageId"] as FormControl).setValue(imageId, {
				emitEvent: true,
			});
		}
	}

	get imagePairs(): FormArray {
		return this.imagesForm.get("imagePairs") as FormArray;
	}

	createPairsLine(): FormGroup {
		return this.fb.group({
			imageA: this.fb.group({
				imageId: new FormControl(null, Validators.required),
				imageExists: new FormControl(false, Validators.required),
				verifyImage: new FormControl(false, Validators.required),
				file: new FormControl(null, Validators.required),
			}),
			imageB: this.fb.group({
				imageId: new FormControl(null, Validators.required),
				imageExists: new FormControl(false, Validators.required),
				verifyImage: new FormControl(false, Validators.required),
				file: new FormControl(null, Validators.required),
			}),
		});
	}

	addLine(): void {
		this.imagePairs.push(this.createPairsLine());
	}

	removeLine(index: number): void {
		this.imagePairs.removeAt(index);
	}

	uploadQuestion() {
		let canUpload: boolean = true;

		if (
			!this.questionControl ||
			!this.questionControl.value ||
			this.questionControl.value.trim() === ""
		) {
			this.questionControl.markAsTouched();
			canUpload = false;
		}

		if (!this.hasAudio && this.audioUrl == null) {
			this.toastService.error("No audio has been uploaded.");
			canUpload = false;
		}

		if (this.imagePairs.controls.length <= 0) {
			this.toastService.error("No matches have been created.");
			canUpload = false;
		}

		let allImages = true;
		const fileBlobs: Blob[] = [];
		const fileIds: IUploadImage[] = [];

		this.imagePairs.controls.forEach((element) => {
			["imageA", "imageB"].forEach((imageData) => {
				let errors = false;
				const image = (element.get(imageData) as FormGroup)?.controls;
				const imageId = image["imageId"];
				const exists = image["imageExists"];
				const file = image["file"];

				if (!imageId || !imageId.value || imageId.value.trim() == "") {
					imageId?.markAsTouched();
					canUpload = false;
					errors = true;
				}

				if ((!exists || !exists.value) && (!file || !file.value)) {
					allImages = false;
					errors = true;
					canUpload = false;
				}

				if (!errors && file?.value) {
					fileBlobs.push(
						new Blob([file.value.file], {
							type: "image/jpeg",
						})
					);

					fileIds.push({
						id: file.value.name,
						extension: "jpg",
					} as IUploadImage);
				}
			});
		});

		if (!allImages) {
			this.toastService.error(`There are one or more images missing.`);
		}

		if (!canUpload) {
			this.toastService.error(
				"There are one or more errors in upload form. Please fix them and try again."
			);
			this.questionUploaded.emit(false);
			return;
		}

		this.data = {
			...this.data,
			game: {
				activity: "bubble-quiz-match",
				content: {
					question: this.questionControl.value,
					audio: this.audioUrl,
					audioTutorialA: this.audioTutorialAUrl,
					audioTutorialB: this.audioTutorialBUrl,
					images: (this.imagePairs.value as IFormPair[]).map(
						(pair) => {
							return {
								imageA: pair.imageA.imageId,
								imageB: pair.imageB.imageId,
							} as IMatchPair;
						}
					),
				} as IMatchGame,
			},
		};

		if (fileBlobs.length > 0) {
			this.uploadService
				.uploadPlacementImage(fileBlobs, fileIds)
				.subscribe((res) => {
					if (!res) {
						this.toastService.error("Failed to upload images");
						this.questionUploaded.emit(false);
						return;
					} else {
						this.toastService.success(
							"Images were uploaded successfully"
						);

						if (!this.isUpdate) {
							this.createQuestion(this.data as CreateQuestionDTO);
						} else {
							this.updateQuestion(this.data as UpdateQuestionDTO);
						}
					}
				});
		} else {
			if (!this.isUpdate) {
				this.createQuestion(this.data as CreateQuestionDTO);
			} else {
				this.updateQuestion(this.data as UpdateQuestionDTO);
			}
		}
	}

	createQuestion(createData: CreateQuestionDTO) {
		this.addSubscription = this.placementService
			.createQuestion(createData)
			.pipe()
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to create question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to create question.");
					this.questionUploaded.emit(false);
				},
			});
	}

	updateQuestion(updateData: UpdateQuestionDTO) {
		this.addSubscription = this.placementService
			.updateQuestion(updateData, updateData.questionId)
			.pipe()
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to update question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to update question.");
					this.questionUploaded.emit(false);
				},
			});
	}
}
