import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { IClassroomGameResolver } from "../../models/classroom-games.model";
import { ClassroomGamesService } from "../classroom-games.service";

@Injectable({
	providedIn: 'root',
})
export class ClassroomGamesResolver implements Resolve<IClassroomGameResolver | null> {

	constructor() {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IClassroomGameResolver | null> {
		const lessonId = route.paramMap.get('lessonId');
		const mode = route.paramMap.get('mode');
		const isDraft = mode === 'draft' ? true : false;

		if (lessonId) {
			return of({
				isDraft,
				lessonId,
			} as IClassroomGameResolver)
		} else {
			return of(null);
		}
	}

}
