<div mat-dialog-title color="primary">CREATE SENTENCE</div>
<lib-toastify-toast-container
  position="bottom-right"
></lib-toastify-toast-container>
<div mat-dialog-content class="dialog-content" style="align-items: center">
  <ng-container class="sentence-content">
    <mat-form-field appearance="outline">
      <mat-label>True affirmative</mat-label>
      <input
        matInput
        [formControl]="affirmativeTrueControl"
      />
      <button
        *ngIf="affirmativeTrueControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="affirmativeTrueControl.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="affirmativeTrueControl.hasError('required')">
        Sentence true affirmative is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="sentence-content">
    <mat-form-field appearance="outline">
      <mat-label>False affirmative</mat-label>
      <input
        matInput
        [formControl]="affirmativeFalseControl"
      />
      <button
        *ngIf="affirmativeFalseControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="affirmativeFalseControl.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="sentence-content">
    <mat-form-field appearance="outline">
      <mat-label>True negative</mat-label>
      <input
        matInput
        [formControl]="negativeTrueControl"
      />
      <button
        *ngIf="negativeTrueControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="negativeTrueControl.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="sentence-content">
    <mat-form-field appearance="outline">
      <mat-label>False negative</mat-label>
      <input
        matInput
        [formControl]="negativeFalseControl"
      />
      <button
        *ngIf="negativeFalseControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="negativeFalseControl.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="sentence-content">
    <mat-form-field appearance="outline">
      <mat-label>Positive question</mat-label>
      <input
        matInput
        [formControl]="questionPositiveControl"
      />
      <button
        *ngIf="questionPositiveControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="questionPositiveControl.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="sentence-content">
    <mat-form-field appearance="outline">
      <mat-label>Positive short answer</mat-label>
      <input
        matInput
        [formControl]="shortPositiveAnswerControl"
      />
      <button
        *ngIf="shortPositiveAnswerControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="shortPositiveAnswerControl.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="sentence-content">
    <mat-form-field appearance="outline">
      <mat-label>Negative question</mat-label>
      <input
        matInput
        [formControl]="questionNegativeControl"
      />
      <button
        *ngIf="questionNegativeControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="questionNegativeControl.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="sentence-content">
    <mat-form-field appearance="outline">
      <mat-label>Negative short answer</mat-label>
      <input
        matInput
        [formControl]="shortNegativeAnswerControl"
      />
      <button
        *ngIf="shortNegativeAnswerControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="shortNegativeAnswerControl.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="sentence-content">
    <mat-form-field appearance="outline">
      <mat-label>WH question</mat-label>
      <input
        matInput
        [formControl]="whQuestionControl"
      />
      <button
        *ngIf="shortNegativeAnswerControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="shortNegativeAnswerControl.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="lesson-image-content">
    <mat-form-field appearance="outline">
      <mat-label>Image</mat-label>
      <input
        matInput
        [ngModel]="data.imageId"
        (ngModelChange)="changeImage($event)"
      />
      <button
        *ngIf="data.imageId"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="changeImage('')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button
      mat-button
      [disabled]="data.imageId === ''"
      class="verify-button"
      (click)="verifyImage()"
    >
      Verify Image
    </button>
  </ng-container>

  <ng-container>
    <app-image-uploader
      *ngIf="toVerify"
      [imageId]="imageVerify"
      (uploadFiles)="setFile($event)"
      (exists)="imageExists = true; data.imageId = $event"
    ></app-image-uploader>
  </ng-container>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Discard</button>
  <button mat-button (click)="createSentence()" cdkFocusInitial>Save</button>
</div>
