import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { ILessonClassroomData } from 'src/app/shared/models/classroom-games.model';
import { ClassroomGamesService } from 'src/app/shared/services/classroom-games.service';

@Component({
  selector: 'app-classroom-games',
  templateUrl: './classroom-games.component.html',
  styleUrls: ['./classroom-games.component.scss']
})
export class ClassroomGamesComponent implements OnInit {

	lessonId!: string;
	classroomData!: ILessonClassroomData;
	isDraft!: boolean;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private readonly classroomGamesService: ClassroomGamesService,
	) {
	}

	ngOnInit(): void {
		this.route.data.subscribe((data) => {
			if (!data) {
				this.router.navigate(['navbar/lessons']);
				return;
			}
			this.lessonId = data.gamesResolverInfo.lessonId;
			this.isDraft = data.gamesResolverInfo.isDraft;
			this.loadClassroomGames();
		})
	}

	loadClassroomGames() {
		this.classroomGamesService.getLessonClassroomGames(this.lessonId, this.isDraft).pipe(take(1)).subscribe(res => {
			if (!res) {
				this.router.navigate(['navbar/lessons']);
				return;
			}

			this.classroomData = res.classroomData;
		})
	}

	returnToLessons() {
		this.router.navigate(['navbar/lessons']);
	}

}
