import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastService } from 'angular-toastify';
import { catchError, combineLatest, Observable, of, Subscription } from 'rxjs';
import { DeleteConfirmationDialog } from 'src/app/shared/components/delete-confirmation/delete-confirmation.component';
import { Age, Cefr } from 'src/app/shared/constants';
import { IAccount } from 'src/app/shared/models/account.model';
import { IContentTag } from 'src/app/shared/models/content-tags.model';
import { ICreateLessonProgramBody, ILessonProgram, OwnerType } from 'src/app/shared/models/programs.model';
import { ProgramService } from 'src/app/shared/services/program.service';
import { AccountStore } from 'src/app/shared/services/stores/account.store.service';
import { ContentTagStore } from 'src/app/shared/services/stores/content.store.service';
import { ProgramStore } from 'src/app/shared/services/stores/program.store.service';
import { environment } from 'src/environments/environment';
import { AddProgramsDialog } from './add-programs/add-programs.component';


/*
	This interface was created to help filtering programs, since the original
	has ids instead of usefull filtering values for fields like owner, publishedStatus and
	tags. This interface should only be used here.
*/
export interface ILessonProgramView {
	uid: string;
	name: string;
	ownerType: OwnerType;
	ownerId: string;
	description: string;
	image: string;
	targetCefr: Cefr;
	targetAgeRange: Age;
	lessons: string[];
	tagIds: string[];
	tags: IContentTag[];
	isPublished: boolean;
	ownerName: string; // extra field for filtering
	publishedStatus: string; // extra field for filtering
	tagValues: string[]; // extra field for filtering
}


@Component({
	selector: 'app-programs',
	templateUrl: './programs.component.html',
	styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit, OnDestroy {

	@ViewChild(MatPaginator) paginator !: MatPaginator;

	columnsToDisplay = ['select', 'image', 'name', 'description', 'owner', 'lessons', 'cefr', 'age', 'tags', 'published', 'edit', 'delete'];
	dataSource !: MatTableDataSource<ILessonProgramView>;
	availableAccounts?: IAccount[];
	availableTags?: IContentTag[];
	loading = true;
	selectedPrograms: ILessonProgram[] = [];
	subscriptions = new Subscription();
	defaultPageSize: number
	defaultFilter: string;

	constructor(
		private programStore: ProgramStore,
		private dialog: MatDialog,
		private accountStore: AccountStore,
		private tagStore: ContentTagStore,
	) {
		this.defaultPageSize = +(sessionStorage.getItem('programsPageSize') ?? 10)
		this.defaultFilter = sessionStorage.getItem('programFilter') ?? '';
	}

	ngOnInit(): void {
		console.log('init')
		this.loadData()
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	private loadData() {
		this.loading = true;
		const programs = this.programStore.listPrograms();
		const programTags = this.tagStore.getProgramTags();
		const accounts = this.accountStore.listAccounts();

		const sub = combineLatest([programs, programTags, accounts])
			.subscribe(([programs, tags, accounts]) => {
				if (programs === null || tags === null || accounts === null) {
					return
				}
				this.availableAccounts = accounts as IAccount[];
				this.availableTags = tags as IContentTag[]
				const availablePrograms = programs as ILessonProgram[]
				const programsView: ILessonProgramView[] = availablePrograms.map(program => (
					{
						...program,
						ownerName: this.getOwnerName(program),
						publishedStatus: this.getPublishedStatus(program),
						tagValues: this.getTags(program)
					}
				));
				this.dataSource = new MatTableDataSource<ILessonProgramView>(programsView)
				this.dataSource.filter = this.defaultFilter;
				this.dataSource.paginator = this.paginator
				this.loading = false;
			})
		this.subscriptions.add(sub)
	}

	createImage(id: string) {
		if (!id) {
			return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_default.jpg`;
		}
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.jpg`;
	}

	select(event: any, program: ILessonProgram): void {
		if (event.checked === true) {
			this.selectedPrograms.push(program);
		} else {
			this.selectedPrograms = this.selectedPrograms.filter(pr => pr.uid !== program.uid)
		}
		console.log(this.selectedPrograms);
	}

	isSelected(program: ILessonProgram): boolean {
		return this.selectedPrograms.some(pr => pr.uid === program.uid);
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
		this.dataSource.filter = filterValue;

		sessionStorage.setItem('programFilter', filterValue);
		this.defaultFilter = filterValue;

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	getPublishedStatus(program: ILessonProgram) {
		if (program.isPublished == null) {
			return '-';
		}

		return program.isPublished ? 'Published' : 'Not Published';
	}

	getTags(program: ILessonProgram): string[] {
		const tags = program.tags
			?.filter(tag => tag.language === 'en')
			.map(tag => tag.value)

		return tags ?? ['-'];
	}

	getNumberOfLessons(program: ILessonProgram) {
		if (program.lessons) {
			return program.lessons.length;
		} else {
			return '-'
		}
	}

	getOwnerName(program: ILessonProgram): string {
		if (program.ownerType === 'system') {
			return 'system';
		}
		return this.availableAccounts?.find(acc => acc.uid === program.ownerId)?.displayName ?? '';
	}

	openProgramDialog() {
		this.dialog.open(AddProgramsDialog)
			.afterClosed()
			.subscribe((res?: { reloading: boolean }) => {
				if (res?.reloading) {
					this.loading = true;
				}
			})
	}

	editProgram(program: ILessonProgram) {
		this.dialog.open(AddProgramsDialog, { data: program })
	}

	deleteProgram(program: ILessonProgram) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px"
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) { return; }

			this.programStore.deleteProgram(program.uid)

		});
	}

	private convertToProgramBody(program: ILessonProgram): ICreateLessonProgramBody {
		const body: ICreateLessonProgramBody = {
			name: program.name,
			description: program.description,
			targetAgeRange: program.targetAgeRange,
			targetCefr: program.targetCefr,
			image: program.image,
			isPublished: program.isPublished,
			ownerType: program.ownerType,
			ownerId: program.ownerId,
			tagIds: program.tagIds,
			tags: program.tags,
			lessons: program.lessons,
		}

		return body
	}

	changePublishStatus(isPublished: boolean) {
		this.selectedPrograms.map(program => {
			const body = this.convertToProgramBody(program);
			body.isPublished = isPublished;
			return this.programStore.updateProgram(program.uid, body)
		});
	}

	paginatorChanged(event: any) {
		console.log(event)
		sessionStorage.setItem('programsPageSize', event.pageSize);
	}
}
