import { AfterContentInit, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ToastService } from "angular-toastify";
import { DeleteConfirmationDialog } from "src/app/shared/components/delete-confirmation/delete-confirmation.component";
import { EditImageDialog } from "src/app/shared/components/edit-image/edit-image.component";
import {
	ImageFiles,
	IUploadImage,
} from "src/app/shared/models/image-upload.model";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";
import { environment } from "src/environments/environment";
import { ICard, IClassroomContent, IAddClassroomGame, IClassroomGame, ICreateClassroomGameResult, IClassroomGameData, IClassroomContentData, VALID_TAGS, VALID_SKILLS } from "src/app/shared/models/classroom-games.model";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { ClassroomGamesService } from "src/app/shared/services/classroom-games.service";
import { AddCardDialog } from "../add-card/add-card.component";

@Component({
	selector: "app-add-classroom-game",
	templateUrl: "./add-classroom-game.component.html",
	styleUrls: ["./add-classroom-game.component.scss"],
})
export class AddClassroomGameDialog implements AfterContentInit, OnDestroy {
	files: ImageFiles[] = [];

	cards: ICard[] = [];

	readonly tags = VALID_TAGS;
	readonly skills = VALID_SKILLS;

	completed1: boolean = false;

	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	isUploading: boolean = false;
	edit: boolean = false;
	newContent: number = 0;
	contentSelected: string = "";

	content: IClassroomContent = {
		lessonId: '',
		name: "",
		cards: [],
	};

	gameName: string = '';
	cols: number = 0;
	gridByBreakpoint = {
		xl: 5,
		lg: 4,
		md: 3,
		sm: 2,
		xs: 1,
	};

	nameControl = new FormControl(this.data.game.name, Validators.required);
	objectiveControl = new FormControl(this.data.game.objective, Validators.required);
	instructionControl = new FormControl(this.data.game.instruction, Validators.required);
	contentNameControl = new FormControl('', Validators.required);

	constructor(
		public dialogRef: MatDialogRef<AddClassroomGameDialog>,
		@Inject(MAT_DIALOG_DATA) public data: IAddClassroomGame,
		public dialog: MatDialog,
		private toastService: ToastService,
		private classroomGameService: ClassroomGamesService,
		private uploadService: ImageUploaderService,
		private observableMedia: MediaObserver,
	) {
		this.content.lessonId = this.data.game.lessonId;
		this.getGameName(this.data.game.gameType);
		if (data.gameId) {
			this.edit = true;
			this.imageExists = true;
			this.completed1 = true;
			this.contentSelected = data.game.content;
			const content = this.data.contents.find(value => value.id === this.contentSelected);
			if (content) {
				this.contentNameControl.setValue(content.name);
			}
			this.changeContent();
		}
	}

	ngOnDestroy(): void {
		// throw new Error("Method not implemented.");
	}

	ngAfterContentInit() {
		this.observableMedia
		.asObservable()
		.subscribe((change: MediaChange[]) => {
			for (let [key, value] of Object.entries(
				this.gridByBreakpoint
			)) {
				if (key === change[0].mqAlias) {
					this.cols = value;
				}
			}
		});
	}

	closeDialog() {
		this.dialogRef.close();
	}

	getGameName(gameId: string) {
		switch(gameId) {
			case 'heads-up':
				this.gameName = 'Heads Up';
				break;
			case 'hot-potato':
				this.gameName = 'Hot Potato';
				break;
			case 'snakes-ladders':
				this.gameName = 'Snakes and Ladders';
				break;
			case 'temple-run':
				this.gameName = 'Temple Run';
				break;
			case 'tic-tac-toe':
				this.gameName = 'Tic Tac Toe';
				break;
			case 'flash-cards':
				this.gameName = 'Flash Cards';
				break;
			default:
				this.gameName = 'Classroom Game';
				break;
		}
	}

	createImage(id: string) {
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.jpg`;
	}

	editImage(index: number, card: ICard) {
		const dialogRef = this.dialog.open(EditImageDialog, {
			width: "350px",
			data: card.imageId,
		});

		dialogRef.afterClosed().subscribe((result: string) => {
			if (!result) {
				return;
			}
			card.imageId = result;
			this.cards[index] = card;
		});
	}

	changeContent() {
		const content = this.data.contents.find(value => value.id === this.contentSelected);
		if (content) {
			this.cards = content.cards
		}
	}

	remove(index: number) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
			data: index,
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			this.cards.splice(index, 1);
		});
	}

	addCard() {
		const dialogRef = this.dialog.open(AddCardDialog, {
			width: "350px",
			data: {
				text: "",
				imageId: "",
			} as ICard,
		});

		dialogRef.afterClosed().subscribe((result: ICard) => {
			if (!result) {
				return;
			}

			this.cards.push(result);
		});
	}

	verifyImage() {
		this.imageVerify = this.data.game.imageId;
		this.toVerify = true;
	}

	changeImage(newValue: string) {
		this.data.game.imageId = newValue;
		this.toVerify = false;
		this.files = [];
		this.imageExists = false;
	}

	setFile(file: ImageFiles | null) {
		if (file === null) {
			this.data.game.imageId = "";
			this.files = [];
			return;
		}
		this.data.game.imageId = file.name;
		this.files.push(file);
	}

	createClassroomGame() {
		this.isUploading = true;
		let canUpload = true;

		this.data.game.name = this.nameControl.value;
		this.data.game.objective = this.objectiveControl.value;
		this.data.game.instruction = this.instructionControl.value;
		this.content.name = this.contentNameControl.value;

		if (this.data.game.name === null) {
			this.nameControl.markAsTouched();
			canUpload = false;
		}

		if (this.data.game.objective === null) {
			this.objectiveControl.markAsTouched();
			canUpload = false;
		}

		if (this.data.game.instruction === null) {
			this.instructionControl.markAsTouched();
			canUpload = false;
		}

		if (this.content.name === null) {
			this.contentNameControl.markAsTouched();
			canUpload = false;
		}

		if (!this.imageExists && this.files.length === 0) {
			this.toastService.error("No image has been selected.");
			this.isUploading = false;
			return;
		}

		if (!canUpload) {
			this.toastService.error("Failed to save new classroom game.");
			this.isUploading = false;
			return;
		}

		if (this.files.length > 0) {
			const uploadFileIds = this.files.map((file) => {
				return {
					id: file.name,
					extension: "jpg",
				} as IUploadImage;
			});

			const fileBlobs = this.files.map((fileInfo) => {
				const imageBlob = new Blob([fileInfo.file], {
					type: `image/jpeg`,
				});
				return imageBlob;
			});

			this.uploadService.uploadImages(fileBlobs, uploadFileIds).subscribe(res => {
				if (!res) {
					this.toastService.error('Failed to upload image');
					this.isUploading = false;
					return;
				} else {
					this.toastService.success('Image was uploaded successfully');
				}
			});
		}

		this.content.cards = this.cards;
		this.data.game.levelId = this.data.game.name.toLowerCase()
		.replace(/[^a-zA-Z0-9]/g,' ')
		.trim()
		.replace(/ +/g, "_");

		if (this.edit) {
			if (!this.data.gameId) {
				this.toastService.error("Failed to save classroom game.")
				this.isUploading = false;
				return;
			}
			this.updateClassroomGame(this.data.game.lessonId, this.data.gameId, this.data.game, this.data.game.content, this.content);
		} else {
			this.uploadClassroomGame(this.data.game.lessonId, this.data.game, this.content)
		}
	}

	uploadClassroomGame(lessonId: string, game: IClassroomGame, content: IClassroomContent) {
		this.classroomGameService.createLessonClassroomGame(lessonId, game, content)
		.subscribe(res => {
			if (!res) {
				this.toastService.error("Failed to upload classroom game.")
				this.isUploading = false;
				return;
			}

			this.toastService.success("Classroom Game saved.");
			this.dialogRef.close(res);
		});

	}

	updateClassroomGame(lessonId: string, gameId: string, game: IClassroomGame, contentId: string, content: IClassroomContent) {
		this.classroomGameService.updateLessonClassroomGame(lessonId, gameId, game, contentId, content)
		.subscribe(res => {
			if (!res) {
				this.toastService.error("Failed to save classroom game.")
				this.isUploading = false;
				return;
			}

			this.toastService.success("Classroom Game saved.");

			(game as IClassroomGameData).id = gameId;
			(content as IClassroomContentData).id = contentId;
 			this.dialogRef.close({
				content,
				game,
			} as ICreateClassroomGameResult);
		})
	}
}
