<div mat-dialog-title color="primary">CREATE VIDEO SESSION</div>
<lib-toastify-toast-container position="bottom-right"></lib-toastify-toast-container>
<div mat-dialog-content class="dialog-content" style="align-items: center">
  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Start Seconds</mat-label>
      <input matInput type="number" [(ngModel)]="data.videoStart" />
      <button *ngIf="data.videoStart" matSuffix mat-icon-button aria-label="Clear" (click)="data.videoStart = 0">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>End Seconds</mat-label>
      <input matInput type="number" [formControl]="videoEndControl" />
      <button *ngIf="videoEndControl.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="videoEndControl.reset()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="videoEndControl.hasError('required')">
        End Seconds is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="image-content">
    <mat-form-field appearance="outline">
      <mat-label>Video Link</mat-label>
      <input matInput (ngModelChange)="changeVideo($event)" [formControl]="videoIdControl" />
      <button *ngIf="videoIdControl.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="videoIdControl.reset()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="videoIdControl.hasError('required')">
        Video Link is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="image-content" *ngIf="data.videoId">
    <iframe [src]="createVideo(data.videoId)" frameborder="0" allowfullscreen></iframe>
  </ng-container>

  <ng-container class="lesson-image-content">
		<mat-form-field appearance="outline">
			<mat-label>ID</mat-label>
			<input matInput [ngModel]="data.thumbnail" (ngModelChange)="changeImage($event)">
			<button *ngIf="data.thumbnail" matSuffix mat-icon-button aria-label="Clear" (click)="changeImage('')">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<button mat-button [disabled]="data.thumbnail === ''" class="verify-button" (click)="verifyImage()">Verify Image</button>
	</ng-container>

	<ng-container>
		<app-image-uploader *ngIf="toVerify" [imageId]="imageVerify" (uploadFiles)="setFile($event)" (exists)="imageExists=true; data.thumbnail=$event"></app-image-uploader>
	</ng-container>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Discard</button>
  <button mat-button (click)="createQuizVideo()" cdkFocusInitial>Save</button>
</div>