import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastService } from "angular-toastify";
import { ImageFiles } from "src/app/shared/models/image-upload.model";
import { DIFFICULTY, ITextQuizRow } from "src/app/shared/models/lesson-content.model";

@Component({
	selector: "app-add-quiz-text",
	templateUrl: "./add-quiz-text.component.html",
	styleUrls: ["./add-quiz-text.component.scss"],
})
export class AddQuizTextDialog implements OnInit {
	readonly difficulties = DIFFICULTY;

	public files: ImageFiles[] = [];

	statementControl = new FormControl(this.data.statement, Validators.required);
	rightAnswerControl = new FormControl(this.data.rightAnswer, Validators.required);
	wrongAnswerControl = new FormControl(this.data.wrongAnswers[0], Validators.required);
	grammarControl = new FormControl(this.data.isGrammar, Validators.required);
	textControl = new FormControl(this.data.text, Validators.required);

	constructor(
		public dialogRef: MatDialogRef<AddQuizTextDialog>,
		@Inject(MAT_DIALOG_DATA) public data: ITextQuizRow,
		private toastService: ToastService
	) {}

	ngOnInit(): void {}

	closeDialog() {
		this.dialogRef.close();
	}

	createQuizText() {
		let canUpload = true;

		this.data.statement = this.statementControl.value;
		this.data.rightAnswer = this.rightAnswerControl.value;
		this.data.isGrammar = this.grammarControl.value;
		this.data.text = this.textControl.value;

		if (this.data.statement === "") {
			this.statementControl.markAsTouched();
			canUpload = false;
		}

		if (this.data.rightAnswer === "") {
			this.rightAnswerControl.markAsTouched();
			canUpload = false;
		}

		if (this.wrongAnswerControl.value === "") {
			this.wrongAnswerControl.markAsTouched();
			canUpload = false;
		} else {
			this.data.wrongAnswers[0] = this.wrongAnswerControl.value;
		}

		if (this.data.text === "") {
			this.textControl.markAsTouched();
			canUpload = false;
		}

		if (!canUpload) {
			this.toastService.error("Failed to save new quiz text.");
			return;
		}

		// Create array of wrong answers

		this.toastService.success("Quiz text saved.");
		this.dialogRef.close(this.data);
	}
}
