import { Injectable } from '@angular/core';
import { ToastService } from 'angular-toastify';
import { BehaviorSubject, catchError, of } from 'rxjs';
import { IContentTag } from '../../models/content-tags.model';
import { ContentTagsService } from '../content-tags.service';

@Injectable({
	providedIn: 'root'
})
export class ContentTagStore {

	private _tags = new BehaviorSubject<IContentTag[] | null>(null);

	constructor(
		private tagService: ContentTagsService,
		private toastrService: ToastService,
	) { }

	setTags(tags: IContentTag[], options?: { append: boolean }) {
		if (options?.append) {
			const newValue = this._tags.value;
			newValue?.push(...tags);
			this._tags.next(newValue);
		} else {
			this._tags.next(tags);
		}
	}

	getProgramTags(options?: { forceUpdate: boolean }) {
		if (this._tags.value !== null && !options?.forceUpdate) {
			this._tags.next(this._tags.value);
		} else {
			this.tagService.getProgramTags()
				.pipe(
					catchError(err => {
						this.toastrService.error('failed to load program tags');
						return of(false);
					})
				).subscribe(res => {
					if (res) {
						this.setTags(res as IContentTag[]);
					} else {
						this.setTags([]);
					}
				});
		}

		return this._tags.asObservable();
	}

	createTags(tags: IContentTag[]) {
		this.tagService.createTags(tags)
			.pipe(
				catchError(err => {
					this.toastrService.error('failed to create program tags');
					return of(false);
				})
			).subscribe(res => {
				if(res !== false){
					this.getProgramTags({ forceUpdate: true })
				}
			});
	}
}
