<div class="quiz-tab-content">
  <div class="table header">
    <mat-toolbar class="header-base" color="primary">
      <button mat-mini-fab color="white" (click)="addQuizVideo()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div class="grid-container">
    <ng-container *ngFor="let video of videos; let indexOfelement = index">
      <div class="grid-item">
        <mat-card style="text-align: center">
          <button mat-icon-button class="delete-button" (click)="remove(indexOfelement)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
          <div class="image-container" (click)="watchVideo(indexOfelement, video)">
            <img mat-card-image [src]="createVideo(video.videoId)" />
            <div class="image-text" style="text-align: center">
              Watch and Change Video
            </div>
            <div class="background"></div>
          </div>
          <mat-card-content style="text-align: center">
            <ng-container class="lesson-name-content">
              <mat-form-field appearance="outline">
                <mat-label>Statement</mat-label>
                <input matInput [(ngModel)]="video.statement" />
                <button *ngIf="video.statement" matSuffix mat-icon-button aria-label="Clear"
                  (click)="video.statement = ''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Right Answer</mat-label>
                <input matInput [(ngModel)]="video.rightAnswer" />
                <button *ngIf="video.rightAnswer" matSuffix mat-icon-button aria-label="Clear"
                  (click)="video.rightAnswer = ''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Wrong Answer 1</mat-label>
                <input matInput [(ngModel)]="video.wrongAnswers[0]" />
                <button *ngIf="video.wrongAnswers[0]" matSuffix mat-icon-button aria-label="Clear"
                  (click)="video.wrongAnswers[0] = ''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Wrong Answer 2</mat-label>
                <input matInput [(ngModel)]="video.wrongAnswers[1]" />
                <button *ngIf="video.wrongAnswers[1]" matSuffix mat-icon-button aria-label="Clear"
                  (click)="video.wrongAnswers[1] = ''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Wrong Answer 3</mat-label>
                <input matInput [(ngModel)]="video.wrongAnswers[2]" />
                <button *ngIf="video.wrongAnswers[2]" matSuffix mat-icon-button aria-label="Clear"
                  (click)="video.wrongAnswers[2] = ''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Difficulty</mat-label>
                <mat-select [(ngModel)]="video.difficulty">
                  <ng-container *ngFor="let difficulty of difficulties">
                    <mat-option [value]="difficulty">{{difficulty}}</mat-option>
                  </ng-container>
                </mat-select>
                <button *ngIf="video.difficulty" matSuffix mat-icon-button aria-label="Clear"
                  (click)="video.difficulty = 1">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>


              <mat-form-field appearance="outline">
                <mat-label>Is Grammar?</mat-label>
                <mat-select [(ngModel)]="video.isGrammar">
                  <mat-option [value]="false">No</mat-option>
                  <mat-option [value]="true">Yes</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Start Seconds</mat-label>
                <input matInput type="number" [(ngModel)]="video.videoStart" />
                <button *ngIf="video.videoStart" matSuffix mat-icon-button aria-label="Clear"
                  (click)="video.videoStart = 0">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>End Seconds</mat-label>
                <input matInput type="number" [(ngModel)]="video.videoEnd" />
                <button *ngIf="video.videoEnd" matSuffix mat-icon-button aria-label="Clear"
                  (click)="video.videoEnd = 0">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>