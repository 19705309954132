import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarModule } from './navbar/navbar.module';
import { AngularToastifyModule, ToastService } from 'angular-toastify';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { INTERCEPTORS } from './shared/interceptor';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFirestoreModule,
		AngularFireAuthModule,
		AngularToastifyModule,
		AppRoutingModule,
		HttpClientModule,
		NavbarModule,
		BrowserAnimationsModule,
		AuthModule
	],
	providers: [
		...INTERCEPTORS,
		ToastService,
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
