<div mat-dialog-title color="primary">CREATE TEXT</div>
<lib-toastify-toast-container
  position="bottom-right"
></lib-toastify-toast-container>
<div mat-dialog-content class="dialog-content" style="align-items: center">
  <ng-container class="sentence-content">
    <mat-form-field appearance="outline">
      <mat-label>Title</mat-label>
      <input matInput [formControl]="titleControl" />
      <button
        *ngIf="titleControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="titleControl.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="titleControl.hasError('required')">
        Title is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Text</mat-label>
      <textarea
        matInput
        [formControl]="textControl"
      ></textarea>
      <button
        *ngIf="textControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="textControl.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="textControl.hasError('required')">
        Text is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="lesson-image-content">
    <mat-form-field appearance="outline">
      <mat-label>Image</mat-label>
      <input
        matInput
        [ngModel]="data.imageId"
        (ngModelChange)="changeImage($event)"
      />
      <button
        *ngIf="data.imageId"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="changeImage('')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button
      mat-button
      [disabled]="data.imageId === ''"
      class="verify-button"
      (click)="verifyImage()"
    >
      Verify Image
    </button>
  </ng-container>

  <ng-container>
    <app-image-uploader
      *ngIf="toVerify"
      [imageId]="imageVerify"
      (uploadFiles)="setFile($event)"
      (exists)="imageExists = true; data.imageId = $event"
    ></app-image-uploader>
  </ng-container>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Discard</button>
  <button mat-button (click)="createText()" cdkFocusInitial>Save</button>
</div>