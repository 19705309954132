import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'angular-toastify';
import { switchMap, catchError, of, Subscription } from 'rxjs';
import { VALID_AGES, VALID_CEFRS } from 'src/app/shared/constants';
import { IAccount } from 'src/app/shared/models/account.model';
import { ImageFiles, IUploadImage } from 'src/app/shared/models/image-upload.model';
import { ILesson} from 'src/app/shared/models/lesson.model';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';
import { LessonService } from 'src/app/shared/services/lesson.service';
import { AccountStore } from 'src/app/shared/services/stores/account.store.service';
import { Owner } from 'src/environments/environment.model';

@Component({
	selector: 'app-add-lesson',
	templateUrl: './add-lesson.component.html',
	styleUrls: ['./add-lesson.component.scss']
})
export class AddLessonDialog implements OnInit, OnDestroy {

	private addSubscription!: Subscription;

	isUploading = false;

	public files: ImageFiles[] = [];

	readonly ages = VALID_AGES;
	readonly cefrs = VALID_CEFRS;
	owners: Owner[] = [];

	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	idControl = new FormControl(null, Validators.required);
	nameControl = new FormControl(null, Validators.required);
	cefrControl = new FormControl(null, Validators.required);
	ageControl = new FormControl(null, Validators.required);
	captionControl = new FormControl(null, Validators.required);
	vocabularyControl = new FormControl(null, Validators.required);
	grammarControl = new FormControl(null, Validators.required);
	aimControl = new FormControl(null, Validators.required);
	ownerControl = new FormControl(null, Validators.required);

	constructor(
		public dialogRef: MatDialogRef<AddLessonDialog>,
		@Inject(MAT_DIALOG_DATA) public data: ILesson,
		private toastService: ToastService,
		private lessonService: LessonService,
		private uploadService: ImageUploaderService,
		private accountStore: AccountStore,
	) {
		this.addSubscription = new Subscription();
	 }

	ngOnDestroy(): void {
		if (this.addSubscription) {
			this.addSubscription.unsubscribe();
		}
	}

	ngOnInit(): void {
		const accountSub = this.accountStore.listAccounts()
			.subscribe(res => {
				const systemOwner: Owner = { displayName: 'SYSTEM', id: 'system/system' };
				const devOwner: Owner = { displayName: 'DEV', id: 'dev/dev' };
				const placementOwner: Owner = { displayName: 'PLACEMENT', id: 'placement/placement' };
				const accountOwners: Owner[] = (res as IAccount[])
					.sort((a, b) => a.displayName.localeCompare(b.displayName))
					.map(acc => ({ id: `accounts/${acc.uid}`, displayName: acc.displayName }));
				this.owners = [systemOwner, devOwner, placementOwner, ...accountOwners];
			});
		this.addSubscription.add(accountSub);
	}

	closeDialog() {
		this.dialogRef.close()
	}

	changeImage(newValue: string) {
		this.data.image = newValue;
		this.toVerify = false;
		this.files = [];
		this.imageExists = false;
	}

	verifyImage() {
		this.imageVerify = this.data.image;
		this.toVerify = true;
	}

	setFile(file: ImageFiles | null) {
		if (file === null) {
			this.data.image = '';
			this.files = [];
			return;
		}
		this.data.image = file.name;
		this.files.push(file);
	}

	verifyAndUpload() {
		let canUpload = true;
		this.isUploading = true;

		// if (this.data.uid === '') {
		// 	this.idControl.markAsTouched();
		// 	canUpload = false;
		// }

		if (this.data.displayName === '') {
			this.nameControl.markAsTouched();
			canUpload = false;
		}
		if (this.data.cefr as any === '') {
			this.cefrControl.markAsTouched();
			canUpload = false;
		}
		if (this.data.age as any === '') {
			this.ageControl.markAsTouched();
			canUpload = false;
		}
		if (this.data.caption === '') {
			this.captionControl.markAsTouched();
			canUpload = false;
		}
		if (this.data.vocabulary === '') {
			this.vocabularyControl.markAsTouched();
			canUpload = false;
		}
		if (this.data.grammar === '') {
			this.grammarControl.markAsTouched();
			canUpload = false;
		}
		if (this.data.aim === '') {
			this.aimControl.markAsTouched();
			canUpload = false;
		}
		if (this.data.owner === '') {
			this.ownerControl.markAsTouched();
			canUpload = false;
		}
		if (!this.imageExists && this.files.length === 0) {
			this.toastService.error('No image has been selected.');
			this.isUploading = false;
			return;
		}

		if (!canUpload) {
			this.toastService.error('Failed to save new lesson.');
			this.isUploading = false;
			return;
		}

		if (this.files.length > 0) {
			const uploadFileIds = this.files.map(file => {
				return {
					id: file.name,
					extension: 'jpg',
				} as IUploadImage;
			})

			const fileBlobs = this.files.map(fileInfo => {
				const imageBlob = new Blob([fileInfo.file], { type: `image/jpeg` })
				return imageBlob;
			});

			this.uploadService.uploadImages(fileBlobs, uploadFileIds).subscribe(res => {
				if (!res) {
					this.toastService.error('Failed to upload image');
					this.isUploading = false;
					return;
				} else {
					this.toastService.success('Image was uploaded successfully');
					this.uploadLesson();
				}
			});
		} else {
			this.uploadLesson();
		}

	}

	uploadLesson() {
		this.addSubscription = this.lessonService.createLessonInfo(this.data).pipe(
			switchMap(res => this.lessonService.createLessonContent(res.uid, {})),
			catchError(error => {
				this.toastService.error(`Failed to create lesson.`)
				console.log('CREATE LESSON', error);
				this.isUploading = false;
				return of(null);
			}),
		).subscribe(res => {
			if (!res) {
				return;
			}
			this.data.uid = res.uid;
			(this.data as ILesson).isUploading = true;

			this.dialogRef.close(this.data as ILesson);
		})
	}
}
