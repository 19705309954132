import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ILesson } from 'src/app/shared/models/lesson.model';
import { LessonService } from 'src/app/shared/services/lesson.service';

@Component({
	selector: 'app-lesson-selector',
	templateUrl: './lesson-selector.component.html',
	styleUrls: ['./lesson-selector.component.scss']
})
export class LessonSelectorComponent implements OnInit {

	@ViewChild('lessonpaginator') lessonPaginator !: MatPaginator;

	lessonColumns = ['name', 'cefr', 'age', 'caption', 'pick'];
	lessonDataSource !: MatTableDataSource<ILesson>;
	selectedLessons: ILesson[] = [];
	globalfilter='';

	constructor(
		private lessonService: LessonService,
		private dialogRef: MatDialogRef<LessonSelectorComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ILesson[]
	) {
		this.selectedLessons = [...data]
	}

	ngOnInit(): void {
		this.lessonService.getLessons()
		.subscribe(res => {
			this.lessonDataSource = new MatTableDataSource(res);
			this.lessonDataSource.paginator = this.lessonPaginator;
		})
	}

	applyFilter(filterValue: string) {
		this.lessonDataSource.filter = filterValue.trim().toLowerCase();

		if (this.lessonDataSource.paginator) {
			this.lessonDataSource.paginator.firstPage();
		}
	}

	addLesson(lesson: ILesson) {
		this.selectedLessons.push(lesson);
	}

	removeLesson(index: number) {
		this.selectedLessons.splice(index, 1);
	}

	confirmSelected() {
		this.dialogRef.close(this.selectedLessons);
	}

}
