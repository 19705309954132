<div class="text-tab-content">
  <div class="table header">
    <mat-toolbar
      class="header-base"
      color="primary">
      <button mat-mini-fab color="white" (click)="addText()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div class="table">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef style="width: 170px">IMAGE</th>
        <td
          mat-cell
          *matCellDef="let text; let i = index"
          style="width: 170px; padding-top: 5px"
        >
          <div class="image-container">
            <button
              mat-icon-button
              matTooltip="Copy image name"
              class="copy-button"
              (click)="copyImageName(i)"
            >
              <mat-icon color="primary">collections</mat-icon>
            </button>
            <img
              (click)="editImage(i, text)"
              [src]="createImage(text.imageId)"
              style="
                max-width: 170px;
                margin-right: 5px;
                align-self: flex-start;
                overflow-y: none;
              "
            />
            <div class="image-text" style="text-align: center">
              Change Image
            </div>
            <div class="background"></div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TITLE</th>
        <td mat-cell *matCellDef="let text">
          <mat-form-field appearance="outline">
            <mat-label>Title</mat-label>
            <input matInput [(ngModel)]="text.title" />
            <button
              *ngIf="text.title"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="text.title = ''"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="text">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TEXT</th>
        <td mat-cell *matCellDef="let text" class="textarea">
          <mat-form-field appearance="outline">
            <mat-label>Text</mat-label>
            <textarea matInput [(ngModel)]="text.text"></textarea>
            <button
              *ngIf="text.text"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="text.text = ''"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          class="delete-button"
          *matCellDef="let text; let i = index"
        >
          <button mat-icon-button matTooltip="Delete" color="warn" (click)="remove(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr
        class="table-header"
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></tr>
      <tr mat-row *matRowDef="let text; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
