<ng-container>
  <h3>ORDERING LETTERS GAME</h3>

  <ng-container>
    <div class="header">
      <mat-toolbar class="header" color="primary">
        <div class="start-header" style="width: 50%">
          <h1 class="title">Questions</h1>
        </div>
        <div class="end-header" style="display: flex; flex-direction: row-reverse; width: 50%">
          <button mat-mini-fab color="white" (click)="addQuestion()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-toolbar>
    </div>

    <form [formGroup]="questionsForm">
      <div formArrayName="questions">

        <mat-accordion>

          <mat-expansion-panel hideToggle *ngFor="let questionGroup of questions.controls; let i = index"
            class="line-container">

            <mat-expansion-panel-header>

              <mat-panel-title style="align-items: center;">
                Question {{i + 1}}
              </mat-panel-title>

              <mat-panel-description style="align-items: center">

                <div style="display: flex; width: 95%"> {{ questionGroup.get('keyword')?.value }} </div>

                <div class="delete-div">
                  <button mat-icon-button class="delete-button" (click)="removeQuestion(i)">
                    <mat-icon color="warn">delete</mat-icon>
                  </button>
                </div>

              </mat-panel-description>

            </mat-expansion-panel-header>

            <ng-container [formGroupName]="i">

              <div class="lesson-title-content">
                <mat-form-field appearance="outline">
                  <mat-label>Keyword</mat-label>
                  <input matInput formControlName="keyword">
                  <mat-error *ngIf="questionGroup.get('keyword')?.errors?.required">
                    Keyword is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="lesson-image-content">
                <mat-form-field appearance="outline">
                  <mat-label>Image</mat-label>
                  <input matInput formControlName="imageId">
                  <button *ngIf="questionGroup.get('imageId')?.value" matSuffix mat-icon-button
                    aria-label="Clear" (click)="clearQuestionImage('', questionGroup)">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <button mat-button [disabled]="!questionGroup.get('imageId')?.value" class="verify-button"
                  (click)="verifyQuestionImage(questionGroup)">Verify Image</button>
              </div>

              <app-image-uploader *ngIf="questionGroup.get('verifyImage')?.value"
                [imageId]="questionGroup.get('imageId')?.value" [isPlacement]="true"
                (uploadFiles)="setQuestionFile($event, questionGroup)"
                (exists)="existsFile(questionGroup, true, $event)"></app-image-uploader>

            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </form>
  </ng-container>
</ng-container>
