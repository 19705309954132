<div class="body">

  <div class="selector">
      <mat-spinner *ngIf="imageUploading" [diameter]="100"></mat-spinner>

      <ngx-file-drop *ngIf="!imageUrl && !imageUploading" dropZoneLabel="Arraste a imagem" [multiple]="false"
          (onFileDrop)="dropped($event)">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              Arraste uma imagem ou
              <button class="upload" mat-mini-fab matSuffix color="primary" (click)="openFileSelector()">
                  <mat-icon>attach_file</mat-icon>
              </button>
          </ng-template>
      </ngx-file-drop>

      <div *ngIf="imageUrl && !imageUploading" class="image-container">
          <img class="uploaded-image" [src]="imageUrl">
      </div>
  </div>
</div>