import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastService } from "angular-toastify";
import { catchError, Observable, of, switchMap } from "rxjs";

@Injectable({
	providedIn: 'root',
})
export class ImageService {

	constructor(
		private http: HttpClient,
		private toastService: ToastService,
	){}

	verifyImageExists(imageLink: string) : Observable<boolean>{
		return this.http.get(imageLink, { responseType: 'blob' })
		.pipe(
			switchMap(res => {
				return of(true);
			}),
			catchError(error => {
				console.log('VERIFY IMAGE', error);
				return of(false);
			}),
		)
	}
}
