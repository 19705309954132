<div class="dialog">
	<div mat-dialog-title color="primary" *ngIf="edit == false">Create {{gameName}}</div>
	<div mat-dialog-title color="primary" *ngIf="edit == true">Edit {{gameName}}</div>
	<lib-toastify-toast-container position="bottom-right"></lib-toastify-toast-container>

	<div mat-dialog-content class="drag-drop-content" style="align-items: center">
		<mat-stepper orientation="vertical" [linear]="true" #stepper>
			<mat-step [completed]="completed1">
				<ng-template matStepLabel class="label-step">Step 1 - Informations</ng-template>
				<ng-template matStepContent>
					<mat-form-field appearance="fill">
						<mat-label>Name</mat-label>
						<input matInput [formControl]="nameControl" />
						<button *ngIf="nameControl.value" matSuffix mat-icon-button aria-label="Clear"
							(click)="nameControl.reset()">
							<mat-icon>close</mat-icon>
						</button>
						<mat-error *ngIf="nameControl.hasError('required')">
							Name is <strong>required</strong>
						</mat-error>
					</mat-form-field>

					<mat-form-field appearance="fill">
						<mat-label>Objective</mat-label>
						<textarea matInput class="objective-textarea" [formControl]="objectiveControl"></textarea>
						<button *ngIf="objectiveControl.value" matSuffix mat-icon-button aria-label="Clear"
							(click)="objectiveControl.reset()">
							<mat-icon>close</mat-icon>
						</button>
						<mat-error *ngIf="objectiveControl.hasError('required')">
							Objective is <strong>required</strong>
						</mat-error>
					</mat-form-field>

					<mat-form-field appearance="fill">
						<mat-label>Instructions</mat-label>
						<textarea matInput class="instructions-textarea" [formControl]="instructionControl"></textarea>
						<button *ngIf="instructionControl.value" matSuffix mat-icon-button aria-label="Clear"
							(click)="instructionControl.reset()">
							<mat-icon>close</mat-icon>
						</button>
						<mat-error *ngIf="instructionControl.hasError('required')">
							Instructions is <strong>required</strong>
						</mat-error>
					</mat-form-field>

					<mat-form-field appearance="fill">
						<mat-label>Tags</mat-label>
						<mat-select multiple [(value)]="data.game.tags">
							<mat-option *ngFor="let tag of tags" [value]="tag">{{tag}}</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field appearance="fill">
						<mat-label>Skills</mat-label>
						<mat-select multiple [(value)]="data.game.skills">
							<mat-option *ngFor="let skill of skills" [value]="skill">{{skill}}</mat-option>
						</mat-select>
					</mat-form-field>

					<ng-container class="image-content">
						<mat-form-field appearance="fill">
							<mat-label>Image</mat-label>
							<input matInput [ngModel]="data.game.imageId" (ngModelChange)="changeImage($event)" />
							<button *ngIf="data.game.imageId" matSuffix mat-icon-button aria-label="Clear"
								(click)="changeImage('')">
								<mat-icon>close</mat-icon>
							</button>
						</mat-form-field>
						<button mat-button [disabled]="data.game.imageId === ''" class="verify-button"
							(click)="verifyImage()">
							Verify Image
						</button>
					</ng-container>

					<ng-container>
						<app-image-uploader *ngIf="toVerify" [imageId]="imageVerify" (uploadFiles)="setFile($event)"
							(exists)="
							imageExists=true;
							data.game.imageId=$event;
							completed1=true
						">
						</app-image-uploader>
					</ng-container>

					<div>
						<button mat-button matStepperNext [disabled]="!completed1">
							Next
						</button>
					</div>
				</ng-template>
			</mat-step>

			<mat-step>
				<ng-template matStepLabel>Step 2 - Create Content</ng-template>
				<ng-template matStepContent>
					<mat-form-field appearance="fill">
						<mat-label>Content Name</mat-label>
						<input matInput [formControl]="contentNameControl" />
						<button *ngIf="contentNameControl.value" matSuffix mat-icon-button aria-label="Clear"
							(click)="contentNameControl.reset()">
							<mat-icon>close</mat-icon>
						</button>
						<mat-error *ngIf="contentNameControl.hasError('required')">
							Content Name is <strong>required</strong>
						</mat-error>
					</mat-form-field>

					<div *ngIf="edit == false">
						<mat-card>
							<div class="ask-text">
								<h3>Do you want to import cards from another content?</h3>
							</div>
							<div class="ask-buttons" style="margin-top: 10px">
								<button mat-button class="no-button" (click)="newContent = 1"
									style="margin-right: 10px">
									No
								</button>
								<button mat-button class="yes-button" (click)="newContent = 2">
									Yes
								</button>
							</div>

							<div *ngIf="newContent == 1" class="answer">
								<h3>So let's go to the next step to create the cards!</h3>
							</div>

							<div *ngIf="newContent == 2" class="answer">
								<mat-form-field appearance="fill">
									<mat-label>Select Content</mat-label>
									<mat-select [(ngModel)]="contentSelected" (ngModelChange)="changeContent()">
										<mat-option *ngFor="let content of data.contents" value="{{content.id}}">
											{{content.name}}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</mat-card>
					</div>

					<div class="next">
						<button mat-button matStepperNext>Next</button>
					</div>
				</ng-template>
			</mat-step>

			<mat-step>
				<ng-template matStepLabel>Step 3 - Create Cards</ng-template>
				<ng-template matStepContent>
					<div class="cards-content">
						<div class="table header">
							<mat-toolbar class="header" color="primary" style="
                  display: flex;
                  flex-direction: row-reverse;
                  margin-bottom: 10px;
                  margin-right: 10px;
                ">
								<button mat-mini-fab color="white" (click)="addCard()">
									<mat-icon>add</mat-icon>
								</button>
							</mat-toolbar>
						</div>

						<mat-grid-list [cols]="cols" rowHeight="1:1" [gutterSize]="'5px'">
							<ng-container *ngFor="let card of cards; let indexOfelement = index">
								<mat-grid-tile>
									<mat-card style="text-align: center">
										<button mat-icon-button class="delete-button" (click)="remove(indexOfelement)">
											<mat-icon color="warn">delete</mat-icon>
										</button>
										<div class="image-container" (click)="editImage(indexOfelement, card)">
											<img mat-card-image [src]="createImage(card.imageId)" style="width: 100%" />
											<div class="image-text" style="text-align: center">
												Change Image
											</div>
											<div class="background"></div>
										</div>
										<mat-card-content>
											<ng-container class="card-text-content">
												<mat-form-field appearance="fill">
													<mat-label>Text</mat-label>
													<input matInput [(ngModel)]="card.text" />
													<button *ngIf="card.text" matSuffix mat-icon-button
														aria-label="Clear" (click)="card.text = ''">
														<mat-icon>close</mat-icon>
													</button>
												</mat-form-field>
											</ng-container>
										</mat-card-content>
									</mat-card>
								</mat-grid-tile>
							</ng-container>
						</mat-grid-list>
					</div>

					<div>
						<button [disabled]="isUploading" mat-button (click)="createClassroomGame()" style="margin-top: 15px" cdkFocusInitial>
							Save
						</button>
						<mat-spinner *ngIf="isUploading" [diameter]="50"></mat-spinner>
					</div>
				</ng-template>
			</mat-step>
		</mat-stepper>
	</div>

	<div mat-dialog-actions>
		<button [disabled]="isUploading" mat-button (click)="closeDialog()">Discard</button>
	</div>
</div>