<div class="sentences-tab-content">
  <div class="table header">
    <mat-toolbar class="header-base" color="primary">
      <button mat-mini-fab color="white" (click)="addSentence()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div class="grid-container">
    <ng-container *ngFor="let sent of sentences; let indexOfelement = index">
      <div class="grid-item">
        <mat-card>
          <button
            mat-icon-button
            matTooltip="Delete"
            class="delete-button"
            (click)="remove(indexOfelement)"
          >
            <mat-icon color="warn">delete</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Copy image name"
            class="copy-button"
            (click)="copyImageName(indexOfelement)"
          >
            <mat-icon color="primary">collections</mat-icon>
          </button>
          <div
            class="image-container"
            (click)="editImage(indexOfelement, sent)"
          >
            <img mat-card-image [src]="createImage(sent.imageId)" />
            <div class="image-text" style="text-align: center">
              Change Image
            </div>
            <div class="background"></div>
          </div>
          <mat-card-content style="text-align: center">
            <ng-container class="lesson-name-content">
              <mat-form-field appearance="outline">
                <mat-label>True affirmative</mat-label>
                <input matInput [(ngModel)]="sent.affirmativeTrue" />
                <button
                  *ngIf="sent.affirmativeTrue"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="sent.affirmativeTrue = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>False affirmative</mat-label>
                <input matInput [(ngModel)]="sent.affirmativeFalse" />
                <button
                  *ngIf="sent.affirmativeFalse"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="sent.affirmativeFalse = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>True negative</mat-label>
                <input matInput [(ngModel)]="sent.negativeTrue" />
                <button
                  *ngIf="sent.negativeTrue"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="sent.negativeTrue = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>False negative</mat-label>
                <input matInput [(ngModel)]="sent.negativeFalse" />
                <button
                  *ngIf="sent.negativeFalse"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="sent.negativeFalse = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Positive question</mat-label>
                <input matInput [(ngModel)]="sent.posQuestion" />
                <button
                  *ngIf="sent.posQuestion"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="sent.posQuestion = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Positive short answer</mat-label>
                <input matInput [(ngModel)]="sent.posAnswer" />
                <button
                  *ngIf="sent.posAnswer"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="sent.posAnswer = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Negative question</mat-label>
                <input matInput [(ngModel)]="sent.negQuestion" />
                <button
                  *ngIf="sent.negQuestion"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="sent.negQuestion = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Negative short answer</mat-label>
                <input matInput [(ngModel)]="sent.negAnswer" />
                <button
                  *ngIf="sent.negAnswer"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="sent.negAnswer = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>WH question</mat-label>
                <input matInput [(ngModel)]="sent.whQuestion" />
                <button
                  *ngIf="sent.whQuestion"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="sent.whQuestion = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>
