import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { INavigationItem, NAVIGATION_BUTTONS } from './navbar-menu';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

	navButtons: INavigationItem[] = []

	constructor(
		private router: Router,
		private authService: AuthService,
	) { }

	ngOnInit(): void {
		this.navButtons = NAVIGATION_BUTTONS;
	}

	async logout() {
		let data = await this.authService.logout();
		if (data) {
			this.router.navigate(['auth/login'])
		}
	}

}
