import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, of, switchMap } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: 'root',
})
export class AudioUploaderService {

	constructor(
		private http: HttpClient,
	) {
	}

	uploadPlacementAudio(file: Blob): Observable<{ url: string }> {
		const formData = new FormData();
		formData.append('audioFile', file);

		return this.http.post<{ url: string }>(`${environment.SERVICES.ASSET_DELIVERY}audios/placement-question`, formData);
	}

	deletePlacementAudio(audioId: string): Observable<boolean | void> {
		return this.http.delete<void>(`${environment.SERVICES.ASSET_DELIVERY}audios/placement-question/${audioId}`);
	}

	verifyAudiosExists(URL: string) : Observable<boolean>{
		return this.http.get(URL, { responseType: 'blob' })
		.pipe(
			switchMap(res => {
				return of(true);
			}),
			catchError(error => {
				console.log('VERIFY AUDIO', error);
				return of(false);
			}),
		)
	}

}
