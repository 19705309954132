import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastService } from 'angular-toastify';
import { Duration } from 'luxon';
import { GenericStepMedia } from 'src/app/shared/models/steps.model';
import { YoutubeService } from 'src/app/shared/services/youtube.service';

@Component({
	selector: 'app-youtube-selector',
	templateUrl: './youtube-selector.component.html',
	styleUrls: ['./youtube-selector.component.scss']
})
export class YoutubeSelectorComponent implements OnInit {

	@Input() planId!: string;
	@Output() mediaSubmitted: EventEmitter<GenericStepMedia> = new EventEmitter();

	videoUrlControl: FormControl;
	videoId?: string;
	videoUrl?: SafeUrl;
	videoDuration?: string;

	timeGroup !: FormGroup;

	constructor(
		private sanitizer: DomSanitizer,
		private youtubeService: YoutubeService,
		private toastService : ToastService,
	) {
		this.videoUrlControl = new FormControl('', [Validators.required]);
	}

	ngOnInit(): void {
	}

	searchVideo() {
		try {
			let url = new URL(this.videoUrlControl.value);
			let videoId;

			if (url.host.includes('youtube.com')) { // default urls like youtube.com/watch?v=id or m.youtube.com/watch?v=id
				videoId = url.searchParams.get('v');
			} else if (url.host.includes('youtu.be')) { // url shortened version like youtu.be/id
				const idUrl = url.href.split('youtu.be/')[1];
				videoId = idUrl.split('?')[0];
			}

			if (!videoId) {
				this.videoId = undefined;
				this.videoUrl = undefined;
				throw new Error();
			}

			this.setVideoInfo(videoId);

		} catch (err) {
			this.toastService.error('Invalid video URL')
		}
	}

	submitYoutubeStep() {
		const startTime = (this.timeGroup.get('minHourControl')?.value * 3600) + (this.timeGroup.get('minMinutesControl')?.value * 60) + (this.timeGroup.get('minSecsControl')?.value);
		const endTime = (this.timeGroup.get('maxHourControl')?.value * 3600) + (this.timeGroup.get('maxMinutesControl')?.value * 60) + (this.timeGroup.get('maxSecsControl')?.value);

		if (startTime > endTime || !this.videoId) {
			this.toastService.error('Invalid start and end times')
			return;
		}

		const thumbnail = `https://i.ytimg.com/vi/${this.videoId}/mqdefault.jpg`;

		this.mediaSubmitted.emit({ type: 'youtube', thumbnailUrl: thumbnail, content: { videoId: this.videoId as string, startTime, endTime } });
	}

	private setVideoInfo(videoId: string) {
		this.youtubeService.getVideoContentDetails(videoId)
			.subscribe((data) => {
				this.videoId = videoId;
				this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.videoId}`);
				const duration = Duration.fromISO(data.items[0].contentDetails.duration)
				this.timeGroup = new FormGroup({
					minHourControl: new FormControl(0, [Validators.min(0), Validators.max(12)]),
					minMinutesControl: new FormControl(0, [Validators.min(0), Validators.max(59)]),
					minSecsControl: new FormControl(0, [Validators.min(0), Validators.max(59)]),
					maxHourControl: new FormControl(duration.hours || 0, [Validators.min(0), Validators.max(12)]),
					maxMinutesControl: new FormControl(duration.minutes || 0, [Validators.min(0), Validators.max(59)]),
					maxSecsControl: new FormControl(duration.seconds || 0, [Validators.min(0), Validators.max(59)]),
				});
			});
	}


}
