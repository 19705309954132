import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastService } from "angular-toastify";
import { ImageFiles, IUploadImage } from "src/app/shared/models/image-upload.model";
import { IVideoSessionRow } from "src/app/shared/models/lesson-content.model";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";

@Component({
  selector: 'app-add-video-session',
  templateUrl: './add-video-session.component.html',
  styleUrls: ['./add-video-session.component.scss']
})
export class AddVideoSessionDialog implements OnInit {

	public files: ImageFiles[] = [];

	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	videoEndControl = new FormControl(this.data.videoEnd, Validators.required);
	videoIdControl = new FormControl(this.data.videoId, Validators.required);

	constructor(
		public dialogRef: MatDialogRef<AddVideoSessionDialog>,
		@Inject(MAT_DIALOG_DATA) public data: IVideoSessionRow,
		private toastService: ToastService,
		private uploadService: ImageUploaderService,
		private _sanitizer: DomSanitizer,
	) {}

	ngOnInit(): void {}

	closeDialog() {
		this.dialogRef.close();
	}

	changeVideo(newVideo: string) {
		if (newVideo.length == 43) {
			this.videoIdControl.setValue(newVideo.slice(32));
		} else if (newVideo.length == 28) {
			this.videoIdControl.setValue(newVideo.slice(17));
		} else {
			this.videoIdControl.setValue(newVideo);
		}
	}

	createVideo(id: string) {
		return this._sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${id}?start=${this.data.videoStart}&end=${this.data.videoEnd}`);
	}

	createQuizVideo() {
		let canUpload = true;

		this.data.videoEnd = this.videoEndControl.value;
		this.data.videoId = this.videoIdControl.value;


		if (this.data.videoEnd === 0) {
			this.videoEndControl.markAsTouched();
			canUpload = false;
		}

		if (this.data.videoId === "") {
			this.videoIdControl.markAsTouched();
			canUpload = false;
		}

		if (!canUpload) {
			this.toastService.error("Failed to save new quiz video.");
			return;
		}

		if (this.files.length > 0) {
			const uploadFileIds = this.files.map(file => {
				return {
					id: file.name,
					extension: 'jpg',
				} as IUploadImage;
			})

			const fileBlobs = this.files.map(fileInfo => {
				const imageBlob = new Blob([fileInfo.file], {type: `image/jpeg`})
				return imageBlob;
			});

			this.uploadService.uploadImages(fileBlobs, uploadFileIds).subscribe(res => {
				if (!res) {
					this.toastService.error('Failed to upload image');
					return;
				} else {
					this.toastService.success('Image was uploaded successfully');
					this.toastService.success("Quiz video saved.");
					this.dialogRef.close(this.data);
				}
			});
		} else {
			this.toastService.success("Quiz video saved.");
			this.dialogRef.close(this.data);
		}
	}

	changeImage(newValue: string) {
		this.data.thumbnail = newValue;
		this.toVerify = false;
		this.files = [];
		this.imageExists = false;
	}

	verifyImage() {
		this.imageVerify = this.data.thumbnail;
		this.toVerify = true;
	}

	setFile(file: ImageFiles | null) {
		if (file === null) {
			this.data.thumbnail = '';
			this.files = [];
			return;
		}
		this.data.thumbnail = file.name;
		this.files.push(file);
	}
}
