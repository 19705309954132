import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IContentTag } from '../models/content-tags.model';

@Injectable({
	providedIn: 'root'
})
export class ContentTagsService {

	constructor(
		private http: HttpClient
	) { }

	getProgramTags() {
		return this.http.get<IContentTag[]>(`${environment.SERVICES.LESSON}content-tags`,
			{
				params: {
					type: 'program'
				}
			});
	}

	createTags(tags: IContentTag[]) {
		return this.http.post(`${environment.SERVICES.LESSON}content-tags`, { tags: tags })
	}
}
