import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { catchError, forkJoin, Observable, of, Subscription } from 'rxjs';
import { DeleteConfirmationDialog } from 'src/app/shared/components/delete-confirmation/delete-confirmation.component';
import { VALID_AGES, VALID_CEFRS } from 'src/app/shared/constants';
import { IAccount } from 'src/app/shared/models/account.model';
import { ICreateLesson, ILesson } from 'src/app/shared/models/lesson.model';
import { LessonService } from 'src/app/shared/services/lesson.service';
import { MetagamelinkService } from 'src/app/shared/services/metagamelink.service';
import { AccountStore } from 'src/app/shared/services/stores/account.store.service';
import { environment } from 'src/environments/environment';
import { Owner } from 'src/environments/environment.model';
import { AddLessonDialog } from '../add-lesson/add-lesson.component';
import { ContentValidationDialog } from '../content-validation/content-validation.component';
import { CopyLessonDialog } from '../copy-lesson/copy-lesson.component';
import { UploadStateDialog } from '../upload-state/upload-state.component';

@Component({
	selector: 'app-lesson-list',
	templateUrl: './lesson-list.component.html',
	styleUrls: ['./lesson-list.component.scss']
})
export class LessonListComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {

	@Input('lessons') lessons!: ILesson[];
	@Input('oppositeLessons') oppositeLessons !: ILesson[];
	@Input('isDraft') isDraft!: boolean;
	@Output('lessonUpdated') lessonUpdated = new EventEmitter<boolean>();

	cefrs = VALID_CEFRS;
	ages = VALID_AGES;
	displayedColumns: string[] = ['name', 'actions', 'image', 'cefr', 'age', 'vocabulary', 'grammar', 'aim', 'caption', 'owner', 'status'];
	dataSource!: MatTableDataSource<ILesson>;
	lessonsSelected: { [key: string]: boolean } = {};
	draftAhead: { [key: string]: { isAhead: boolean, version: number} } = {};
	noLive: string[] = [];
	selected: string[] = [];
	defaultPageSize!: number;

	cefrControl: FormControl;
	ageControl: FormControl;
	ownerControl: FormControl;
	versionDifControl: FormControl;
	endStatusControl: FormControl;
	statusControl: FormControl;

	globalfilter = '';
	filteredValues = {
		cefr: '',
		age: '',
		owner: '',
		versionDif: '',
		endStatus: null,
		status: null,
	}

	subscriptions: Subscription;

	owners !: Owner[];

	@ViewChild(MatPaginator) paginator!: MatPaginator;

	constructor(
		private dialog: MatDialog,
		private router: Router,
		private lessonService: LessonService,
		private toastService: ToastService,
		private metagameLinkService: MetagamelinkService,
		private accountStore: AccountStore,
	) {
		this.cefrControl = new FormControl();
		this.ageControl = new FormControl();
		this.ownerControl = new FormControl();
		this.versionDifControl = new FormControl();
		this.endStatusControl = new FormControl();
		this.statusControl = new FormControl();
		this.subscriptions = new Subscription();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.lessons) {
			this.dataSource = new MatTableDataSource(this.lessons);
			this.noLive = [];
			this.lessons.forEach(lesson => {
				this.lessonsSelected[lesson.uid] = false;
				const oppositeLesson = this.oppositeLessons.find(l => l.uid === lesson.uid)
				if (!oppositeLesson) this.noLive.push(lesson.uid);
				this.draftAhead[lesson.uid] = {
					isAhead: oppositeLesson ? !(oppositeLesson.version === lesson.version) : true,
					version: oppositeLesson ? oppositeLesson.version : -1,
				};
			});

			this.dataSource.filterPredicate = this.customFilterPredicate();
			if (this.paginator) {
				this.setPaginator();
			}
		}

		if (changes.isDraft) {
			this.globalfilter = this.isDraft ? sessionStorage.getItem('draft-lessonFilter') ?? '' : sessionStorage.getItem('live-lessonFilter') ?? '';
			this.defaultPageSize = this.isDraft ? +(sessionStorage.getItem('draft-lessonsPageSize') ?? 10) : +(sessionStorage.getItem('live-lessonsPageSize') ?? 10);
		}
	}

	ngOnInit(): void {

		const accountSub = this.accountStore.listAccounts()
			.subscribe(res => {
				if (res == null) {
					return;
				}
				const systemOwner: Owner = { displayName: 'SYSTEM', id: 'system/system' };
				const devOwner: Owner = { displayName: 'DEV', id: 'dev/dev' };
				const placementOwner: Owner = { displayName: 'PLACEMENT', id: 'placement/placement' };
				const accountOwners: Owner[] = (res as IAccount[])
					.sort((a, b) => a.displayName.localeCompare(b.displayName))
					.map(acc => ({ id: `accounts/${acc.uid}`, displayName: acc.displayName }));
				this.owners = [systemOwner, devOwner, placementOwner, ...accountOwners];
			});

		const cefrSub = this.cefrControl.valueChanges.subscribe(cefrValue => {
			this.filteredValues['cefr'] = cefrValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const ageSub = this.ageControl.valueChanges.subscribe(ageValue => {
			this.filteredValues['age'] = ageValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const ownerSub = this.ownerControl.valueChanges.subscribe(ownerValue => {
			this.filteredValues['owner'] = ownerValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const versionDifSub = this.versionDifControl.valueChanges.subscribe(versionDifValue => {
			this.filteredValues['versionDif'] = versionDifValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const endStatusSub = this.endStatusControl.valueChanges.subscribe(endStatusValue => {
			this.filteredValues['endStatus'] = endStatusValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const statusSub = this.statusControl.valueChanges.subscribe(statusValue => {
			this.filteredValues['status'] = statusValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		this.subscriptions.add(accountSub);
		this.subscriptions.add(cefrSub);
		this.subscriptions.add(ageSub);
		this.subscriptions.add(ownerSub);
		this.subscriptions.add(versionDifSub);
		this.subscriptions.add(endStatusSub);
		this.subscriptions.add(statusSub);
	}

	ngAfterViewInit(): void {
		this.setPaginator();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	private setPaginator() {
		this.dataSource.filter = JSON.stringify(this.filteredValues);
		this.dataSource.paginator = this.paginator;
		this.paginator.pageIndex = this.isDraft ? +(sessionStorage.getItem('draft-lessonPageIndex') ?? 0) : +(sessionStorage.getItem('live-lessonPageIndex') ?? 0);
	}

	customFilterPredicate() {
		const lessonFilter = (data: ILesson, filter: string): boolean => {

			let parsedSearch = JSON.parse(filter);
			const selectedCefr = parsedSearch.cefr;
			const hasCefr = selectedCefr ? data.cefr === selectedCefr : true;
			const selectedAge = parsedSearch.age;
			const hasAge = selectedAge ? data.age === selectedAge : true;
			const selectedOwner = parsedSearch.owner;
			const hasOwner = selectedOwner ? data.owner === selectedOwner.id : true;
			const diffVersion = parsedSearch.versionDif;
			const isNotLive = this.noLive.includes(data.uid);
			const onlyDifVersion = !diffVersion ? true : diffVersion === "notLive" ? isNotLive : diffVersion === "same" ?
			!isNotLive && !this.draftAhead[data.uid].isAhead : !isNotLive && this.draftAhead[data.uid].isAhead;
			const selectedEndStatus = parsedSearch.endStatus;
			const hasEndStatus = selectedEndStatus === null ? true : data.isContentValid === selectedEndStatus;
			const selectedStatus = parsedSearch.status;
			const hasStatus = selectedStatus === null ? true : data.releasePhase === parsedSearch.status;
			let selectMatch = hasCefr && hasAge && hasOwner && onlyDifVersion && hasEndStatus && hasStatus;

			let globalMatch = !this.globalfilter;

			if (this.globalfilter) {

				const lowerCaseFilter = this.globalfilter.toLowerCase();
				const nameIncludes = data.displayName != null && data.displayName.toLowerCase().includes(lowerCaseFilter);
				const aimIncludes = data.aim != null && data.aim.toLowerCase().includes(lowerCaseFilter);
				const captionIncludes = data.caption != null && data.caption.toLowerCase().includes(lowerCaseFilter);
				const vocabularyIncludes = data.vocabulary != null && data.vocabulary.toLowerCase().includes(lowerCaseFilter);
				const grammarIncludes = data.grammar != null && data.grammar.toLowerCase().includes(lowerCaseFilter);
				globalMatch = nameIncludes || aimIncludes || captionIncludes || vocabularyIncludes || grammarIncludes;
			}

			return globalMatch && selectMatch;
		}
		return lessonFilter;
	}

	applyFilter(filter: string) {
		const mode = this.isDraft ? 'draft' : 'live'
		sessionStorage.setItem(`${mode}-lessonFilter`, filter);
		this.globalfilter = filter;
		this.dataSource.filter = JSON.stringify(this.filteredValues);

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	createImage(id: string) {
		if (!id) {
			return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_default.jpg`;
		}
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.jpg`;
	}

	selectAll(event: MatCheckboxChange) {
		for (let lessonId in this.lessonsSelected) {
			this.lessonsSelected[lessonId] = event.checked;
		}
	}

	select(event: MatCheckboxChange, checkedId: string) {
		this.lessonsSelected[checkedId] = event.checked;
	}

	editLesson(lessonId: string) {
		this.router.navigate(['/navbar/lessons', lessonId]);
	}

	removeSelectedLessons() {
		const selectedIds = this.getSelected();
		if (selectedIds.length === 0) {
			this.toastService.error(`You must select at least a single lesson to delete!`);
			return;
		}

		if (selectedIds.length > 5) {
			this.toastService.error(`You can only delete 5 lessons at a time!`);
			return;
		}

		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			const lessonInfoRequests: Observable<boolean | void>[] = [];
			selectedIds.forEach((id: string) => {
				const infoRequest = this.lessonService.removeLessonInfo(id)
					.pipe(
						catchError((err) => {
							this.toastService.error(`Failed to delete lesson with id ${id}`);
							return of(false);
						})
					);

				lessonInfoRequests.push(infoRequest);
			});

			forkJoin([...lessonInfoRequests])
				.subscribe(
					{
						next: (res) => {
							if (!res.includes(false)) {
								this.toastService.success(`Deleted lessons successfully`);
								this.lessonUpdated.emit(true);
							}
						}
					}
				)
		});
	}

	publishLessons(release: boolean) {

		const selectedIds = this.getSelected();
		if (selectedIds.length === 0) {
			this.toastService.error(`You must select at least a single lesson to publish/un-publish!`);
			return;
		}

		const requests: Observable<boolean>[] = [];

		selectedIds.forEach(lessonId => {
			const request = this.lessonService.publishLesson(lessonId, release)
				.pipe(
					catchError(error => {
						this.toastService.error(`Failed to publish lesson ${lessonId}`)
						console.log(`Failed to publish lesson ${lessonId}`, error);
						return of(false);
					})
				);
			requests.push(request);
		});

		forkJoin(requests).subscribe(
			{
				next: (res) => {
					if (!res.includes(false)) {
						this.toastService.success('Lessons updated successfully!')
						this.lessonUpdated.emit(true);
					}
				}
			}
		)
	}

	openUploadState(uid: string) {
		this.dialog.open(UploadStateDialog, {
			width: '650px',
			data: uid,
		})
	}

	openContentValidation(lesson: ILesson) {
		this.dialog.open(ContentValidationDialog, {
			width: '650px',
			data: lesson,
		})
	}

	openAddDialog() {
		const dialogRef = this.dialog.open(AddLessonDialog, {
			width: '650px',
			data: {
				displayName: '',
				cefr: '',
				vocabulary: '',
				grammar: '',
				owner: '',
				age: '',
				caption: '',
				aim: '',
				image: '',
				version: 0,
				releasePhase: 'Published',
			} as ICreateLesson
		})

		dialogRef.afterClosed().subscribe((result: ILesson) => {
			if (!result) { return; }

			this.toastService.success(`Lesson ${result.uid} created successfully`);

			// let tempArray = this.dataSource.data;
			// this.lessons.push(result);
			// this.dataSource.data = tempArray;

			// if (this.dataSource.paginator) {
			// 	this.dataSource.paginator.lastPage();
			// }

			// this.lessonService.lessons.push(result);

			// this.openUploadState(result.uid);
			this.lessonUpdated.emit(true);
		})
	}

	copySelectedLesson() {

		const selectedIds = this.getSelected();

		if (selectedIds.length !== 1) {
			this.toastService.error(`You must select a single lesson to copy!`);
			return;
		}

		const lessonId = selectedIds[0];
		const lesson = this.lessons.find(value => value.uid === lessonId);

		const dialogRef = this.dialog.open(CopyLessonDialog, {
			width: '650px',
			data: {
				uid: lessonId,
				displayName: `${lesson!.displayName} - Copy`,
				cefr: lesson!.cefr,
				vocabulary: lesson!.vocabulary,
				grammar: lesson!.grammar,
				owner: lesson!.owner,
				age: lesson!.age,
				caption: lesson!.caption,
				aim: lesson!.aim,
				image: lesson!.image,
				version: 0,
				releasePhase: 'Not Published',
			} as ICreateLesson
		})

		dialogRef.afterClosed().subscribe((result: ILesson) => {
			if (!result) { return; }

			this.toastService.success(`Lesson ${result.uid} created successfully`);

			// let tempArray = this.dataSource.data;
			// this.lessons.push(result);
			// this.dataSource.data = tempArray;

			// if (this.dataSource.paginator) {
			// 	this.dataSource.paginator.lastPage();
			// }

			// this.lessonService.lessons.push(result);

			// this.openUploadState(result.uid);
			this.lessonUpdated.emit(true);
		})
	}

	private getSelected() {
		const selectedIds = [];

		for (let id in this.lessonsSelected) {
			const isFiltered = this.dataSource.filteredData.find(lesson => lesson.uid === id);
			if (this.lessonsSelected[id] && isFiltered) {
				selectedIds.push(id);
			}
		}

		return selectedIds;
	}

	pushSelectedLessonsToLive() {
		const selectedIds = this.getSelected();

		if (selectedIds.length === 0) {
			this.toastService.error(`You must select at least one lesson to push!`);
			return;
		}

		this.lessonService.pushLessonsToLive(selectedIds)
			.subscribe({
				next: () => {
					this.toastService.success(`Lessons pushed to live correctly`);
					this.lessonUpdated.emit(true);
				},
				error: () => {
					this.toastService.error(`Failed to push lessons live!`);
				}
			})

	}

	regenerateSelectedLessons() {
		const selectedIds = this.getSelected();

		if (selectedIds.length === 0) {
			this.toastService.error(`You must select at least one lesson to regenerate!`);
			return;
		}

		this.lessonService.regenerateLessons(selectedIds)
			.subscribe({
				next: () => {
					this.toastService.success('Lesson regeneration started successfully!')
				},
				error: () => {
					this.toastService.error(`Failed to regenerate lessons!`);
				}
			})

	}

	getOwner(ownerId: string) {
		let owner = this.owners.find(obj => obj.id === ownerId);
		return owner ? owner.displayName : ownerId;
	}

	testLesson(uid: string) {
		this.metagameLinkService.testLesson(uid, this.isDraft);
	}

	openClassroomGames(uid: string) {
		const mode = this.isDraft ? 'draft' : 'live';
		this.router.navigate([`navbar/lessons/${uid}/${mode}/games`]);
	}

	paginatorChanged(event: any) {
		const mode = this.isDraft ? 'draft' : 'live'
		sessionStorage.setItem(`${mode}-lessonPageIndex`, event.pageIndex);
		sessionStorage.setItem(`${mode}-lessonsPageSize`, event.pageSize);
	}
}
