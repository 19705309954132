export const PROFILE_PHOTOS_REF: IProfilePhotosRef[] = [
	{
		name: "Astronaut",
		ref: "icon_character_astronaut",
		path: "../../../../../assets/profile-photos/icon_character_astronaut.png"
	},
	{
		name: "Bearhead",
		ref: "icon_character_bearhead",
		path: "../../../../../assets/profile-photos/icon_character_bearhead.png"
	},
	{
		name: "Big Foot",
		ref: "icon_character_big-foot",
		path: "../../../../../assets/profile-photos/icon_character_big-foot.png"
	},
	{
		name: "Cupcake",
		ref: "icon_character_cupcake",
		path: "../../../../../assets/profile-photos/icon_character_cupcake.png"
	},
	{
		name: "Gamergirl Pop",
		ref: "icon_character_gamergirl-pop",
		path: "../../../../../assets/profile-photos/icon_character_gamergirl-pop.png"
	},
	{
		name: "Gamergirl",
		ref: "icon_character_gamergirl",
		path: "../../../../../assets/profile-photos/icon_character_gamergirl.png"
	},
	{
		name: "Guitar Boy",
		ref: "icon_character_guitar-boy",
		path: "../../../../../assets/profile-photos/icon_character_guitar-boy.png"
	},
	{
		name: "Hero Girl",
		ref: "icon_character_hero-girl",
		path: "../../../../../assets/profile-photos/icon_character_hero-girl.png"
	},
	{
		name: "Iara",
		ref: "icon_character_iara",
		path: "../../../../../assets/profile-photos/icon_character_iara.png"
	},
	{
		name: "Jessica Lolipop",
		ref: "icon_character_jessica_lolipop",
		path: "../../../../../assets/profile-photos/icon_character_jessica_lolipop.png"
	},
	{
		name: "Jessica",
		ref: "icon_character_jessica",
		path: "../../../../../assets/profile-photos/icon_character_jessica.png"
	},
	{
		name: "Matt Aranha",
		ref: "icon_character_matt-aranha",
		path: "../../../../../assets/profile-photos/icon_character_matt-aranha.png"
	},
	{
		name: "Matt",
		ref: "icon_character_matt",
		path: "../../../../../assets/profile-photos/icon_character_matt.png"
	},
	{
		name: "Science Robot",
		ref: "icon_character_science-robot",
		path: "../../../../../assets/profile-photos/icon_character_science-robot.png"
	},
	{
		name: "Pet Bear",
		ref: "icon_pet_bear",
		path: "../../../../../assets/profile-photos/icon_pet_bear.png"
	},
	{
		name: "Pet Boar",
		ref: "icon_pet_boar",
		path: "../../../../../assets/profile-photos/icon_pet_boar.png"
	},
	{
		name: "Pet Cat",
		ref: "icon_pet_cat",
		path: "../../../../../assets/profile-photos/icon_pet_cat.png"
	},
	{
		name: "Pet Chicken",
		ref: "icon_pet_chicken",
		path: "../../../../../assets/profile-photos/icon_pet_chicken.png"
	},
	{
		name: "Pet Deer",
		ref: "icon_pet_deer",
		path: "../../../../../assets/profile-photos/icon_pet_deer.png"
	},
	{
		name: "Pet Dino",
		ref: "icon_pet_dino",
		path: "../../../../../assets/profile-photos/icon_pet_dino.png"
	},
	{
		name: "Pet Dragon",
		ref: "icon_pet_dragon",
		path: "../../../../../assets/profile-photos/icon_pet_dragon.png"
	},
	{
		name: "Pet Fish",
		ref: "icon_pet_fish",
		path: "../../../../../assets/profile-photos/icon_pet_fish.png"
	},
	{
		name: "Pet Lizard Green",
		ref: "icon_pet_lizard_green",
		path: "../../../../../assets/profile-photos/icon_pet_lizard_green.png"
	},
	{
		name: "Pet Lizard Orange",
		ref: "icon_pet_lizard_orange",
		path: "../../../../../assets/profile-photos/icon_pet_lizard_orange.png"
	},
	{
		name: "Pet Penguin",
		ref: "icon_pet_penguin",
		path: "../../../../../assets/profile-photos/icon_pet_penguin.png"
	},
	{
		name: "Pet Candy Habbit",
		ref: "icon_pet-candy habbit",
		path: "../../../../../assets/profile-photos/icon_pet-candy habbit.png"
	},
	{
		name: "Pet Crab",
		ref: "icon_pet-crab",
		path: "../../../../../assets/profile-photos/icon_pet-crab.png"
	},
	{
		name: "Pet Pig Punk",
		ref: "icon_pet-pig-punk",
		path: "../../../../../assets/profile-photos/icon_pet-pig-punk.png"
	},
	{
		name: "Pet Pink Penguin Pink",
		ref: "icon_pet-pink-penguin-pink",
		path: "../../../../../assets/profile-photos/icon_pet-pink-penguin-pink.png"
	},
	{
		name: "Pet Robot",
		ref: "icon_pet-robot",
		path: "../../../../../assets/profile-photos/icon_pet-robot.png"
	},
	{
		name: "Pet Seal Sax",
		ref: "icon_pet-seal-sax",
		path: "../../../../../assets/profile-photos/icon_pet-seal-sax.png"
	},
	{
		name: "Pet Unicorn",
		ref: "icon_pet-unicorn",
		path: "../../../../../assets/profile-photos/icon_pet-unicorn.png"
	},
	{
		name: "Pig",
		ref: "icon_pig",
		path: "../../../../../assets/profile-photos/icon_pig.png"
	},
	{
		name: "Rabbit",
		ref: "icon_rabbit",
		path: "../../../../../assets/profile-photos/icon_rabbit.png"
	},
	{
		name: "Seal",
		ref: "icon_seal",
		path: "../../../../../assets/profile-photos/icon_seal.png"
	},
	{
		name: "Sheep",
		ref: "icon_sheep",
		path: "../../../../../assets/profile-photos/icon_sheep.png"
	},
	{
		name: "Troll",
		ref: "icon_troll",
		path: "../../../../../assets/profile-photos/icon_troll.png"
	},
	{
		name: "Default",
		ref: "icon_z_default",
		path: "../../../../../assets/profile-photos/icon_z_default.png"
	},
];

export class IProfilePhotosRef {
	name!: string;
	ref!: string;
	path!: string;
}
