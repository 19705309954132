import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { MatDialog } from "@angular/material/dialog";
import { DeleteConfirmationDialog } from "src/app/shared/components/delete-confirmation/delete-confirmation.component";
import {
	IDragAndDropRow,
	IDragDropElement,
	IDragDropTarget,
	ICreateDragDropElement,
	ICreateDragDrop,
} from "src/app/shared/models/lesson-content.model";
import { environment } from "src/environments/environment";
import { AddDragDropDialog } from "./add-drag-drop/add-drag-drop.component";

import { v4 as uuidv4 } from "uuid";

@Component({
	selector: "app-drag-drop",
	templateUrl: "./drag-drop.component.html",
	styleUrls: ["./drag-drop.component.scss"],
})
export class DragDropComponent implements OnInit {
	dragDrop!: IDragAndDropRow[];

	@Input() set data(value: IDragAndDropRow[]) {
		this.dragDrop = value;
	}

	@Output() newData = new EventEmitter<IDragAndDropRow[]>();

	element!: IDragDropElement[];
	target!: IDragDropTarget[];
	pagedDragDrop!: IDragAndDropRow[];
	panelOpenState = false;

	cols: number = 0;

	gridByBreakpoint = {
		xl: 6,
		lg: 5,
		md: 4,
		sm: 2,
		xs: 1,
	};

	constructor(
		public dialog: MatDialog,
		private observableMedia: MediaObserver
	) {
		this.cols = 5;
	}

	ngAfterContentInit() {
		this.observableMedia
			.asObservable()
			.subscribe((change: MediaChange[]) => {
				for (let [key, value] of Object.entries(
					this.gridByBreakpoint
				)) {
					if (key === change[0].mqAlias) {
						this.cols = value;
					}
				}
			});
	}

	ngOnInit(): void {}

	createImage(id: string) {
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.png`;
	}

	edit(index: number, drag: IDragAndDropRow) {
		const elements = drag.elements.map((element) => {
			return {
				name: element.name,
				image: element.image ?? "",
				imageExists: drag.elementType === "image" ? true : false,
				verifyImage: drag.elementType === "image" ? true : false,
				targets: drag.targets.map((target, index) =>
					element.targets.includes(index)
				),
			} as ICreateDragDropElement;
		});
		const dialogRef = this.dialog.open(AddDragDropDialog, {
			maxWidth: "100vw",
			maxHeight: "100vh",
			height: "100%",
			width: "85%",
			data: {
				uuid: drag.uuid,
				backgroundId: drag.backgroundId,
				statement: drag.statement,
				elementType: drag.elementType,
				elements,
				targets: drag.targets,
			} as ICreateDragDrop,
		});

		dialogRef.afterClosed().subscribe((result: IDragAndDropRow) => {
			if (!result) {
				return;
			}

			this.dragDrop[index] = result;
			this.sendData();
		});
	}

	remove(index: number) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
			data: index,
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			this.dragDrop.splice(index, 1);
			this.sendData();
		});
	}

	addDragDrop() {
		const dialogRef = this.dialog.open(AddDragDropDialog, {
			maxWidth: "100vw",
			maxHeight: "100vh",
			height: "100%",
			width: "85%",
			data: {
				uuid: uuidv4(),
				backgroundId: "",
				statement: "",
				elementType: "image",
				elements: [],
				targets: [],
			} as ICreateDragDrop,
		});

		dialogRef.afterClosed().subscribe((result: IDragAndDropRow) => {
			if (!result) {
				return;
			}

			this.dragDrop.push(result);
			this.sendData();
		});
	}

	sendData() {
		this.newData.emit(this.dragDrop);
	}
}
