<div class="component-container">
	
	<div class="table header">
		<mat-toolbar class="header" color="primary" style="
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 10px;
        margin-right: 10px;
      ">
			<!-- <button [disabled]="true" mat-mini-fab color="white" (click)="addClassroomGame()">
				<mat-icon>add</mat-icon>
			</button> -->
		</mat-toolbar>
	</div>

	<div class="lesson-table">
		<mat-table [dataSource]="dataSource" matSort>

			<ng-container matColumnDef="image">
				<mat-header-cell *matHeaderCellDef>IMAGE</mat-header-cell>
				<mat-cell *matCellDef="let row" style="width: 170px; padding-top: 5px">
					<img [src]="createImage(row.imageId)" style="
					max-width: 100%;
					margin-right: 5px;
					align-self: flex-start;
					overflow-y: none;"/>
				</mat-cell>
			</ng-container>
	
			<ng-container matColumnDef="name">
				<mat-header-cell *matHeaderCellDef mat-sort-header>NAME</mat-header-cell>
				<mat-cell *matCellDef="let row" style="text-overflow: ellipsis">
					<div class="name-cell" [style.background-color]="row.valid ? '#36B37E' : '#DE350B'"> 
						{{ row.name }}
					</div>
				</mat-cell>
			</ng-container>
	
			<ng-container matColumnDef="objective">
				<mat-header-cell *matHeaderCellDef mat-sort-header>OBJECTIVE</mat-header-cell>
				<mat-cell *matCellDef="let row" class="text-cell">
					<div>
                        {{row.objective}}
                    </div>
				</mat-cell>
			</ng-container>
	
			<ng-container matColumnDef="instructions">
				<mat-header-cell *matHeaderCellDef mat-sort-header>INSTRUCTIONS</mat-header-cell>
				<mat-cell *matCellDef="let row" class="text-cell">
					<div>
                        {{ row.instruction }}
                    </div>
				</mat-cell>
			</ng-container>
	
			<ng-container matColumnDef="tags">
				<mat-header-cell *matHeaderCellDef mat-sort-header>TAGS</mat-header-cell>
				<mat-cell *matCellDef="let row" class="text-cell">
					<div>
                        <h3>TAGS</h3>
                        {{row.tags}}
                    </div>
				</mat-cell>
			</ng-container>
	
			<ng-container matColumnDef="skills">
				<mat-header-cell *matHeaderCellDef mat-sort-header>SKILLS</mat-header-cell>
				<mat-cell *matCellDef="let row" class="text-cell">
					<div>
                        <h3>SKILLS</h3>
                        {{row.skills}}
                    </div>
				</mat-cell>
			</ng-container>
	
			<ng-container matColumnDef="content">
				<mat-header-cell *matHeaderCellDef mat-sort-header>CONTENT</mat-header-cell>
				<mat-cell *matCellDef="let row" class="text-cell">
					<div>
                        <h3>CONTENT</h3>
                        {{row.content}}
                    </div>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="version">
				<mat-header-cell *matHeaderCellDef mat-sort-header>VERSION</mat-header-cell>
				<mat-cell *matCellDef="let row" class="text-cell">
					<div>
                        <h3>VERSION</h3>
                        {{row.version}}
                    </div>
				</mat-cell>
			</ng-container>
	
			<ng-container matColumnDef="status">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let row; let i = index">
					<!-- <button [disabled]="true" mat-icon-button color="primary" (click)="edit(i, row)">
						<mat-icon>edit</mat-icon>
					</button> -->

					<button class="play-button" mat-icon-button color="primary" (click)="playClassroomGame(row)">
						<mat-icon>play_circle_filled</mat-icon>
					</button>

					<button [disabled]="!isDraft" mat-icon-button color="warn" (click)="remove(i, row.id)">
						<mat-icon>delete</mat-icon>
					</button>
				</mat-cell>
			</ng-container>
	
			<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
		</mat-table>
	</div>

	
</div>