import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ResizeEvent } from 'angular-resizable-element';
import { ToastService } from 'angular-toastify';
import { Subscription, forkJoin } from 'rxjs';
import { AddDragDropDialog } from 'src/app/navbar/lesson/tabs/drag-drop/add-drag-drop/add-drag-drop.component';
import { AudioFiles } from 'src/app/shared/models/audio-upload.model';
import { IUploadImage, ImageFiles } from 'src/app/shared/models/image-upload.model';
import { IDragDropTarget } from 'src/app/shared/models/lesson-content.model';
import { CreateQuestionDTO, UpdateQuestionDTO, IDragDropElementEditor, AnyGame, IDragDropGame, IColoringGame, IColoringGameTarget } from 'src/app/shared/models/placement-question.model';
import { AudioService } from 'src/app/shared/services/audio.service';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';
import { PlacementQuestionsService } from 'src/app/shared/services/placement-questions.service';
import { round } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { colors } from './colors';

interface TargetGroup {
	colorId: number;
	color: string;
	points: { x: number; y: number; }[];
}

@Component({
	selector: 'app-coloring-media',
	templateUrl: './coloring-media.component.html',
	styleUrls: ['./coloring-media.component.scss']
})
export class ColoringMediaComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input() data!: CreateQuestionDTO | UpdateQuestionDTO;
	@Input() isUpdate!: boolean;

	@Output() questionUploaded = new EventEmitter<boolean>();

	@ViewChild("boundBox", { static: false }) boundBox!: ElementRef;
	@ViewChildren('pointElement', { read: ElementRef }) pointElementRefs!: QueryList<ElementRef>;

	completed1: boolean = false;
	availableColors = colors;
	selectedColorId!: number;

	public files: ImageFiles[] = [];

	// files to audio here
	public audioFiles: AudioFiles[] = [];

	image = '';
	imageExists: boolean = false;
	imageToVerify: boolean = false;
	imageVerify!: string;

	hasAudio!: boolean;
	audioUrl!: string | null;
	audioTutorialAUrl!: string | null;
	audioTutorialBUrl!: string | null;

	colorSelector = false;

	positionX = 0;
	positionY = 0;

	uploading: boolean = false;
	addSubscription!: Subscription;

	targetGroups: TargetGroup[] = [];
	selectedGroupId: number | null = null;

	constructor(
		public dialogRef: MatDialogRef<AddDragDropDialog>,
		private placementQuestionsService: PlacementQuestionsService,
		private imageService: ImageUploaderService,
		private audioService: AudioService,
		private toastService: ToastService,
		private cdr: ChangeDetectorRef
	) {
		this.addSubscription = new Subscription();
	}

	ngOnInit(): void {

		if (this.data.game && this.isColoringGame(this.data.game.content)) {
			this.image = this.data.game.content.imageId;
			this.imageExists = true;
			this.audioUrl = this.data.game.content.audioId;
			this.audioTutorialAUrl = this.data.game.content.audioTutorialA;
			this.audioTutorialBUrl = this.data.game.content.audioTutorialB;

			// add targets to groups
			this.targetGroups = this.data.game.content.targetPoints.reduce((acc, item) => {
				let group = acc.find(g => g.colorId === item.colorId && acc.indexOf(g) === item.groupId);
				if (!group) {
					const colorInfo = this.availableColors.find(c => c.id === item.colorId);
					const colorHex = colorInfo ? colorInfo.color : "unknown"; // Define 'unknown' se a cor não for encontrada
					group = { colorId: item.colorId, color: colorHex, points: [] };
					acc[item.groupId] = group; // Inserir na posição correta do grupo
				}
				group.points.push({ x: item.x, y: item.y });
				return acc;
			}, [] as TargetGroup[]);
		}

		if (this.image !== "") {
			this.imageExists = true;
			this.completed1 = true;
		}
	}

	ngOnDestroy(): void {
		if (this.addSubscription) {
			this.addSubscription.unsubscribe();
		}
	}

    ngAfterViewInit() {
        this.pointElementRefs.changes.subscribe(() => {
            this.cdr.detectChanges();
        });
    }

	isColoringGame(game: AnyGame): game is IColoringGame {
		return (
			(game as IColoringGame).imageId !== undefined &&
			(game as IColoringGame).audioId !== undefined &&
			(game as IColoringGame).targetPoints !== undefined
		);
	}

	close() {
		this.dialogRef.close();
	}

	changeImage(newValue: string) {
		this.image = newValue;
		this.imageToVerify = false;
		this.files = [];
		this.imageExists = false;
		this.completed1 = false;
	}

	verifyImage() {
		this.imageVerify = this.image;
		this.imageToVerify = true;
	}

	setFile(file: ImageFiles | null) {
		if (file === null) {
			this.completed1 = false;
			this.image = "";
			this.files = [];
			return;
		}
		this.completed1 = true;
		this.image = file.name;
		this.files.push(file);
	}

	getImage() {
		if (this.imageExists) {
			return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images-placement/${this.image}.png`;
		} else {
			return this.files[0].path;
		}
	}

	selectTargetGroup(index: number): void {
		this.selectedGroupId = index;
	}

    addPointToGroup(event: MouseEvent): void {
        if (this.selectedGroupId === null) {
            return;
        }

		console.log("Evento de clique capturado", event);

        const boundBox = event.currentTarget as HTMLElement;
		const boundBoxRect = boundBox.getBoundingClientRect();

		// Calculate the click position relative to the image
		const clickX = event.clientX - boundBoxRect.left;
		const clickY = event.clientY - boundBoxRect.top;

		// Normalize coordinates to a range of -1 to 1
		const normalizedX = (clickX / boundBox.clientWidth) * 2 - 1;
		const normalizedY = 1 - (clickY / boundBox.clientHeight) * 2;

		console.log("Got points ", {normalizedX, normalizedY})

		const group = this.targetGroups[this.selectedGroupId];
        group.points.push({ x: normalizedX, y: normalizedY });

        // Forçar atualização da view após mudanças no modelo de dados
        this.cdr.detectChanges();
    }

	addTargetGroup(colorId: number): void {
		const color = this.availableColors.find(color => color.id === colorId)?.color || '#000000';

		this.targetGroups.push({
			colorId: colorId,
			points: [],
			color: color
		});

		this.selectedGroupId = this.targetGroups.length - 1;
		this.colorSelector = false;
	}

	removeTargetGroup(index: number): void {
		if (index >= 0 && index < this.targetGroups.length) {
			this.targetGroups.splice(index, 1);

			if (this.selectedGroupId != null) {
				if (this.selectedGroupId === index) {
					this.selectedGroupId = null;
				} else if (this.selectedGroupId > index) {
					this.selectedGroupId -= 1;
				}
			}
		}
	}

	audioUploaded(url: string | null) {
		this.hasAudio = url ? true : false;
		this.audioUrl = url;
	}

	audioTutorialAUploaded(url: string | null) {
		this.audioTutorialAUrl = url;
	}

	audioTutorialBUploaded(url: string | null) {
		this.audioTutorialBUrl = url;
	}

	getPosition(element: IDragDropTarget) {
		const parentPos = document
			.getElementById("bound-box")
			?.getBoundingClientRect() as DOMRect;
		const offsetX = 22;
		const x = round(element.upperLeft.x * parentPos.width) + offsetX;
		const y = round(element.upperLeft.y * parentPos.height);

		return {
			x,
			y,
		};
	}

	addTargetStart() {
		this.colorSelector = true;
	}

	addTarget(colorId: number) {
		this.selectedColorId = colorId;
	}

	uploadQuestion() {
		this.uploading = true;
		let validationErrors = false;

		if (!this.imageExists && this.files.length === 0) {
			this.toastService.error("No image has been selected.");
			validationErrors = true;
		}

		if (!this.audioUrl || !this.audioTutorialAUrl || !this.audioTutorialBUrl) {
			this.toastService.error("Audio or Audio Tutorials are empty.");
			validationErrors = true;
		}

		if (this.targetGroups.length === 0) {
			this.toastService.error("No targets have been selected.");
			validationErrors = true;
		}

		const targetPoints: IColoringGameTarget[] = this.targetGroups.reduce((acc, group, index) => {
			const groupPoints = group.points.map(point => ({
				groupId: index,
				colorId: group.colorId,
				x: point.x,
				y: point.y
			}));
			return acc.concat(groupPoints);
		}, [] as IColoringGameTarget[]);

		if (validationErrors) {
			this.uploading = false;
			this.questionUploaded.emit(false);
			return;
		}

		this.data = {
			...this.data,
			game: {
				activity: 'coloring-placement',
				content: {
					imageId: this.image,
					audioId: this.audioUrl,
					audioTutorialA: this.audioTutorialAUrl,
					audioTutorialB: this.audioTutorialBUrl,
					targetPoints
				} as IColoringGame
			}
		};

		const fileBlobs: Blob[] = this.files.map(file => new Blob([file.file], { type: "image/png" }));
		const fileIds: IUploadImage[] = this.files.map(file => ({ id: file.name, extension: "png" }));

		const audioBlobs: Blob[] = this.audioFiles.map(file => new Blob([file.file], { type: "audio/mp3" }));
		const audioIds: IUploadImage[] = this.audioFiles.map(file => ({ id: file.name, extension: "mp3" }));

		const uploads = [];
		if (fileBlobs.length > 0) {
			uploads.push(this.imageService.uploadPlacementImage(fileBlobs, fileIds));
		}
		if (audioBlobs.length > 0) {
			uploads.push(this.audioService.uploadPlacementAudio(audioBlobs, audioIds));
		}

		if (uploads.length > 0) {
			forkJoin(uploads).subscribe({
				next: (results) => {
					this.uploading = false;
					const allUploadsSuccessful = results.every(result => result === true);
					if (!allUploadsSuccessful) {
						this.toastService.error("Failed to upload files");
						this.questionUploaded.emit(false);
						return;
					}
					this.toastService.success("Files were uploaded successfully");
				},
				error: (error) => {
					this.uploading = false;
					this.toastService.error("An error occurred during file upload");
					console.error('Upload error:', error);
					this.questionUploaded.emit(false);
				},
				complete: () => {
					this.handleQuestionCreationOrUpdate();
				}
			});
		} else {
			// If no files need to be uploaded, proceed directly.
			this.handleQuestionCreationOrUpdate();
		}
	}

	private handleQuestionCreationOrUpdate() {
		if (!this.isUpdate) {
			const createOperation = this.placementQuestionsService.createQuestion(this.data);
			this.addSubscription = createOperation.subscribe({
				next: (res: { uid: string }) => {
					if (!res || !res.uid) {
						console.error('Error in question creation: No UID returned');
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error: any) => {
					console.error('Question creation error', error);
					this.questionUploaded.emit(false);
				}
			});
		} else {
			const updateData = this.data as UpdateQuestionDTO;
			const updateOperation = this.placementQuestionsService.updateQuestion(updateData, updateData.questionId);
			this.addSubscription = updateOperation.subscribe({
				next: (res: boolean) => {
					if (!res) {
						console.error('Error in question update: Update failed');
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error: any) => {
					console.error('Question update error', error);
					this.questionUploaded.emit(false);
				}
			});
		}
	}
}
