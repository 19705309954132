<div class="component-body">
  <mat-toolbar *ngIf="!data"> Adicionar conteúdo </mat-toolbar>
  <mat-toolbar *ngIf="data"> Editar conteúdo </mat-toolbar>

  <div class="media-selector">
    <mat-tab-group *ngIf="!data">
      <mat-tab label="Imagens">
        <app-image-selector
          (mediaSubmitted)="close($event)"
        ></app-image-selector>
      </mat-tab>

      <mat-tab label="Youtube">
        <app-youtube-selector
          (mediaSubmitted)="close($event)"
        ></app-youtube-selector>
      </mat-tab>

      <mat-tab label="Games">
        <app-classroom-game-selector
          (mediaSubmitted)="close($event)"
        ></app-classroom-game-selector>
      </mat-tab>

      <mat-tab label="Atividades">
        <app-activity-selector
          (mediaSubmitted)="close($event)"
        ></app-activity-selector>
      </mat-tab>
    </mat-tab-group>

    <app-activity-selector
      *ngIf="data?.type == 'activity'"
      [data]="data"
      (mediaSubmitted)="close($event)"
    ></app-activity-selector>
  </div>
</div>
