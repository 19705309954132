<div class="component-body">
  <lib-toastify-toast-container
    position="bottom-right"
  ></lib-toastify-toast-container>
  <mat-toolbar class="header" color="primary">
    <button mat-icon-button [routerLink]="['/navbar/steps']">
      <mat-icon>backspace icon</mat-icon>
    </button>
    <div class="start-header">
      <h1>
        Steps for Lesson Plans
        <span *ngIf="!stepId"> - CREATE</span>
        <span *ngIf="stepId"> - EDIT</span>
      </h1>
    </div>
    <div class="end-header">
      <button
        mat-raised-button
        class="save-button"
        color="white"
        (click)="uploadStep()"
        *ngIf="stepId"
      >
        SAVE STEP
      </button>
      <button
        mat-raised-button
        class="save-button"
        color="white"
        (click)="uploadStep()"
        *ngIf="!stepId"
      >
        CREATE STEP
      </button>
    </div>
  </mat-toolbar>

  <div class="content" *ngIf="stepLoading || !selectedLessons">
    <div class="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div class="content" *ngIf="!stepLoading && selectedLessons">
    <div class="title">
      <mat-form-field appearance="standard" [formGroup]="stepGroup">
        <mat-label>Step Name</mat-label>
        <input
          matInput
          formControlName="name"
          placeholder="Ex. Lesson Plan 1"
        />
        <button
          *ngIf="this.stepGroup.get('name')"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="this.stepGroup.get('name')?.setValue('')"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="container">
      <div class="image-actions">
        <div class="image" *ngIf="resourceUrl">
          <div *ngIf="selectedMedia?.type === 'youtube'">
            <iframe
              width="640"
              height="360"
              [src]="resourceUrl | sanitize"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div
            *ngIf="selectedMedia?.type == 'activity'"
            class="content-view"
            (click)="openMediaSelector(selectedMedia)"
          >
            <mat-icon *ngIf="!selectedMedia">add_circle</mat-icon>
            <img
              class="content-image"
              *ngIf="selectedMedia"
              [src]="resourceUrl"
            />
            <div class="image-text" style="text-align: center">
              Edit Activity
            </div>
            <div class="background"></div>
          </div>

          <div
            *ngIf="
              selectedMedia?.type !== 'youtube' &&
              selectedMedia?.type !== 'activity'
            "
            class="content-view"
            (click)="openMediaSelector()"
          >
            <mat-icon *ngIf="!selectedMedia">add_circle</mat-icon>
            <img
              class="content-image"
              *ngIf="selectedMedia"
              [src]="resourceUrl"
            />
          </div>
        </div>

        <div class="image" *ngIf="!resourceUrl">
          <div class="content-view" (click)="openMediaSelector()">
            <mat-icon>add_circle</mat-icon>
          </div>
        </div>

        <div class="content-add">
          <button
            mat-raised-button
            class="add-button"
            *ngIf="selectedMedia?.type == 'activity'"
            (click)="openMediaSelector(selectedMedia)"
          >
            <span class="counter-activity">{{ getQtdActivities() }}</span>
            {{ getContentName() }}
          </button>

          <button
            mat-raised-button
            class="add-button"
            *ngIf="selectedMedia?.type !== 'activity'"
            (click)="openMediaSelector()"
          >
            {{ getContentName() }}
          </button>

          <button
            mat-raised-button
            class="delete-button"
            color="primary"
            (click)="removeMedia()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>

        <div class="select-skills">
          <mat-label>Habilidades trabalhadas</mat-label>
          <div class="skills-container">
            <button
              class="skill-button"
              [ngClass]="{
                active: stepGroup.value.skills?.includes('Listening')
              }"
              (click)="setSkill('Listening')"
            >
              <mat-icon>hearing</mat-icon>
              Listening
            </button>

            <button
              class="skill-button"
              [ngClass]="{
                active: stepGroup.value.skills?.includes('Reading')
              }"
              (click)="setSkill('Reading')"
            >
              <mat-icon>chrome_reader_mode</mat-icon>
              Reading
            </button>

            <button
              class="skill-button"
              [ngClass]="{
                active: stepGroup.value.skills?.includes('Writing')
              }"
              (click)="setSkill('Writing')"
            >
              <mat-icon>create</mat-icon>
              Writing
            </button>

            <button
              class="skill-button"
              [ngClass]="{
                active: stepGroup.value.skills?.includes('Speaking')
              }"
              (click)="setSkill('Speaking')"
            >
              <mat-icon>mic</mat-icon>
              Speaking
            </button>
          </div>
        </div>

        <div class="attachments">
          <mat-label>Atividades para imprimir</mat-label>
          <div class="list-action">
            <div class="attachments-list" *ngIf="attachmentLoading">
              <mat-spinner></mat-spinner>
            </div>
            <div class="attachments-list" *ngIf="!attachmentLoading">
              <div
                class="item"
                *ngFor="let file of stepGroup.value.attachments; let i = index"
              >
                <a class="file-name" href="{{ file.url }}" target="_blank">{{
                  file.fileName
                }}</a>
                <mat-icon (click)="deleteFile(i)">close</mat-icon>
              </div>
            </div>
            <button
              mat-raised-button
              color="primary"
              [disabled]="attachmentLoading"
              (click)="fileInput.click()"
            >
              <mat-icon>attach_file</mat-icon>
              <span class="attach-button">Anexar PDF</span>
              <input #fileInput type="file" (change)="uploadFile($event)" />
            </button>
          </div>
        </div>
      </div>
      <div class="info">
        <div class="lessons">
          <mat-form-field appearance="outline" [formGroup]="stepGroup">
            <mat-label>{{ getSelectedLessons() }}</mat-label>
            <mat-select class="lesson-select" formControlName="lessons">
              <mat-option
                disabled
                *ngFor="let lesson of selectedLessons"
                [value]="lesson.uid"
                >{{ lesson.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button
            mat-raised-button
            color="primary"
            (click)="openLessonSelector()"
          >
            Adicionar lição
          </button>
        </div>
        <div class="moment-time">
          <mat-form-field
            class="moment-select"
            appearance="outline"
            [formGroup]="stepGroup"
          >
            <mat-label>Momento</mat-label>
            <mat-select formControlName="moment">
              <mat-option *ngFor="let moment of validMoments" [value]="moment">
                <div class="moment" [ngClass]="setMomentClass(moment)">
                  <div class="tag-border"></div>
                  <div class="tag">
                    <div class="tag-circle"></div>
                    <p>{{ moment }}</p>
                  </div>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="time">
            <mat-form-field appearance="outline" [formGroup]="stepGroup">
              <mat-label>Tempo</mat-label>
              <input matInput type="number" formControlName="time" />
            </mat-form-field>
            <span>minutos</span>
          </div>
        </div>

        <div class="moment-time">
          <mat-form-field
            class="moment-select"
            appearance="outline"
            [formGroup]="stepGroup"
          >
            <mat-label>CEFR</mat-label>
            <mat-select
              [disabled]="selectedLessons.length > 0"
              formControlName="cefr"
              multiple
            >
              <mat-option *ngFor="let cefr of validCefrs" [value]="cefr">
                {{ cefr }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="moment-select"
            appearance="outline"
            [formGroup]="stepGroup"
          >
            <mat-label>Idade</mat-label>
            <mat-select
              [disabled]="selectedLessons.length > 0"
              formControlName="age"
              multiple
            >
              <mat-option *ngFor="let age of validAges" [value]="age">
                {{ age }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="objective-material">
          <mat-form-field
            class="objective"
            [formGroup]="stepGroup"
            appearance="outline"
          >
            <mat-label>Objetivo</mat-label>
            <textarea formControlName="objective" matInput> </textarea>
          </mat-form-field>

          <mat-form-field [formGroup]="stepGroup" appearance="outline">
            <mat-label>Material de aula</mat-label>
            <textarea formControlName="material" matInput> </textarea>
          </mat-form-field>
        </div>
        <div class="instructions">
          <mat-form-field [formGroup]="stepGroup" appearance="outline">
            <mat-label>Instruções do professor</mat-label>
            <textarea formControlName="instructions" matInput> </textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
