import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface YTvideoContentDetails {
	items: {
		id: string;
		contentDetails: {
			duration: string;
		}
	}[]
}

@Injectable({
	providedIn: 'root'
})
export class YoutubeService {

	constructor(
		private http: HttpClient
	) { }

	public getVideoContentDetails(videoId: string) {
		const params = new HttpParams()
			.set('part', 'contentDetails')
			.set('id', videoId)
			.set('key', environment.YOUTUBE_API_KEY);

		return this.http.get<YTvideoContentDetails>(`${environment.YOUTUBE_DATA_API}/videos`, { params: params });
	}
}
