<div class="content-view">
  <lib-toastify-toast-container position="bottom-right"></lib-toastify-toast-container>
  <div class="header">
    <mat-toolbar class="header" color="primary">
      <button mat-icon-button color="white" (click)="home()">
        <mat-icon>home</mat-icon>
      </button>
      <div class="start-header">
        <h1 class="title">Placement Questions</h1>
      </div>
      <div class="end-header">
        <button mat-mini-fab color="white" (click)="logout()">
          <mat-icon>logout</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  </div>
</div>

<div class="component-container">
  <div class="actions-container">
    <div class="select-all-container">
      <mat-label>select all</mat-label>
      <mat-checkbox (change)="selectAll($event)"></mat-checkbox>
    </div>

    <form class="actions-filter">
      <mat-form-field class="keyword-field" appearance="outline">
        <mat-label>Palavra chave</mat-label>
        <input matInput [ngModel]="globalfilter" (ngModelChange)="applyFilter($event)"
          [ngModelOptions]="{ standalone: true }" />
      </mat-form-field>

      <mat-form-field class="filter-field" appearance="outline">
        <mat-label>Game</mat-label>
        <mat-select [formControl]="gameControl">
          <mat-option>nenhum</mat-option>
          <mat-option *ngFor="let g of games" [value]="g">
            {{ g.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="filter-field" appearance="outline">
        <mat-label>Habilidades</mat-label>
        <mat-select [formControl]="skillControl">
          <mat-option>nenhum</mat-option>
          <mat-option *ngFor="let skill of skills" [value]="skill">
            {{ skill }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="filter-field" appearance="outline">
        <mat-label>CEFR</mat-label>
        <mat-select [formControl]="cefrControl">
          <mat-option>nenhum</mat-option>
          <mat-option *ngFor="let cefr of cefrs" [value]="cefr">
            {{ cefr }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="filter-field" appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select [formControl]="statusControl">
          <mat-option [value]="null">nenhum</mat-option>
          <mat-option [value]="true">
            Published
          </mat-option>
          <mat-option [value]="false">
            Not Published
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div class="action-buttons-container">
      <div class="top-actions">
        <button mat-mini-fab color="primary" (click)="createQuestion()">
          <mat-icon>add</mat-icon>
        </button>

        <button mat-mini-fab color="white" (click)="removeSelectedQuestions()">
          <mat-icon color="primary">delete_outline</mat-icon>
        </button>

        <button mat-raised-button color="warn" (click)="publishQuestions(false)">Un-publish</button>

        <button mat-raised-button color="primary" (click)="publishQuestions(true)">Publish</button>
      </div>
    </div>
  </div>

  <div class="loading" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

  <div class="questions-table" *ngIf="!loading">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> NAME </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="name-cell" [style.background-color]="row.uploadStatus?.status === 'done' ? '#36B37E' :
                               row.uploadStatus?.status === 'error' ? '#DE350B' :
                               row.uploadStatus?.status === 'waiting' ? '#FFA500' : '#FFFFFF'">
            {{ row.name }}
          </div>

        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> ACTIONS </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <div class="buttons-action">
            <mat-checkbox class="checkbox" [checked]="questionsSelected[row.uid]" (change)="select($event, row.uid)">
            </mat-checkbox>

            <button class="edit-button" mat-icon-button color="primary" (click)="editQuestion(row)">
              <mat-icon>edit</mat-icon>
            </button>

            <mat-icon class="info-icon" [matTooltip]="row.questionId" (click)="copyToClipboard(row.questionId)">info_outline</mat-icon>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="game">
        <mat-header-cell mat-header-cell *matHeaderCellDef> GAME </mat-header-cell>
        <mat-cell *matCellDef="let row" class="text-cell">
          <div>
            <h3>Game</h3>
            <div class="media" *ngIf="row.game">
              {{row.game.name}}
            </div>
            <div class="media" *ngIf="!row.game">
              <mat-icon class="type-icon">
                cancel
              </mat-icon>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="skill">
        <mat-header-cell *matHeaderCellDef mat-sort-header> SKILLS </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row" class="text-cell">
          <div>
            <h3>Habilidades</h3>
            <div class="skills-container">
              <button class="skill-button" [ngClass]="{ active: row.skill?.includes('Listening') }" translate>
                <mat-icon>hearing</mat-icon>
                Listening
              </button>

              <button class="skill-button" [ngClass]="{ active: row.skill?.includes('Reading') }" translate>
                <mat-icon>chrome_reader_mode</mat-icon>
                Reading
              </button>

              <button class="skill-button" [ngClass]="{ active: row.skill?.includes('Writing') }" translate>
                <mat-icon>create</mat-icon>
                Writing
              </button>

              <button class="skill-button" [ngClass]="{ active: row.skill?.includes('Speaking') }" translate>
                <mat-icon>mic</mat-icon>
                Speaking
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="cefr">
        <mat-header-cell mat-header-cell *matHeaderCellDef> CEFR </mat-header-cell>
        <mat-cell *matCellDef="let row" class="text-cell">
          <div>
            <h3>CEFR</h3>
            <div class="cefr">
              <span>{{row.cefr}}</span>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell mat-header-cell *matHeaderCellDef> STATUS </mat-header-cell>
        <mat-cell *matCellDef="let row" class="text-cell">
          <div>
            <h3>Status</h3>
            <div class="status">
              <span>{{ row.published != null ? (row.published ? 'Published' : 'Not Published') : 'Error Status'
                }}</span>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>

  <mat-paginator or [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="defaultPageSize" (page)="paginatorChanged($event)"
    aria-label="Select page of questions" [showFirstLastButtons]="true">
  </mat-paginator>
</div>