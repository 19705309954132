import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'angular-toastify';
import { Subscription } from 'rxjs';
import { ImageFiles, IUploadImage } from 'src/app/shared/models/image-upload.model';
import { CreateQuestionDTO, UpdateQuestionDTO, ISpeakingDialogueGame, VALID_VOICE_ACTORS } from 'src/app/shared/models/placement-question.model';
import { PlacementQuestionsService } from 'src/app/shared/services/placement-questions.service';
import { CreateQuestionDialog } from '../create-question.component';
import { PROFILE_PHOTOS_REF } from '../dialogue-media/profile-photos';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';

@Component({
	selector: 'app-speaking-dialogue-media',
	templateUrl: './speaking-dialogue-media.component.html',
	styleUrls: ['./speaking-dialogue-media.component.scss']
})
export class SpeakingDialogueMediaComponent implements OnInit {
	@Input() data!: CreateQuestionDTO | UpdateQuestionDTO;
	@Input() isUpdate!: boolean;

	@Output() questionUploaded = new EventEmitter<boolean>();

	private addSubscription!: Subscription;

	public files: ImageFiles[] = [];

	image = "";
	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	titleControl = new FormControl(null, Validators.required);
	titleVoiceControl = new FormControl(null, Validators.required);
	voiceActorControl = new FormControl(null, Validators.required);
	profilePhotoAControl = new FormControl(null, Validators.required);
	profilePhotoBControl = new FormControl(null, Validators.required);
	profilePhotosRef = PROFILE_PHOTOS_REF;
	dialogueForm!: FormGroup;

	validVoiceActors = VALID_VOICE_ACTORS;

	linesQtd = 1;

	constructor(
		public dialogRef: MatDialogRef<CreateQuestionDialog>,
		private placementQuestionsService: PlacementQuestionsService,
		private uploadService: ImageUploaderService,
		private toastService: ToastService,
		private fb: FormBuilder
	) {
		this.addSubscription = new Subscription();

		this.dialogueForm = this.fb.group({
			lines: this.fb.array([]),
		});
		this.addLine();
	}

	ngOnInit(): void {
		if (this.data.game && this.isSpeakingDialogueGame(this.data.game.content)) {
			const content = this.data.game.content as ISpeakingDialogueGame;
			this.titleControl.setValue(content.titleText);
			this.titleVoiceControl.setValue(content.titleVoiceActor);
			this.voiceActorControl.setValue(content.charVoiceActor);
			this.profilePhotoAControl.setValue(
				this.profilePhotosRef.find(
					(profile) => profile.name === content.charA.name
				) ?? null
			);
			this.profilePhotoBControl.setValue(
				this.profilePhotosRef.find(
					(profile) => profile.name === content.charB.name
				) ?? null
			);

			this.lines.clear();
			content.entries.forEach((line) => {
				this.lines.push(
					this.fb.group({
						lineA: new FormControl(line.lineA, Validators.required),
						lineB: new FormControl(line.lineB, Validators.required),
					})
				);
			});
		}
	}

	ngOnDestroy(): void {
		if (this.addSubscription) {
			this.addSubscription.unsubscribe();
		}
	}

	changeImage(newValue: string) {
		this.image = newValue;
		this.toVerify = false;
		this.files = [];
		this.imageExists = false;
	}

	verifyImage() {
		this.imageVerify = this.image;
		this.toVerify = true;
	}

	setFile(file: ImageFiles | null) {
		if (file === null) {
			this.image = "";
			this.files = [];
			return;
		}
		this.image = file.name;
		this.files.push(file);
	}

	isSpeakingDialogueGame(game: any): game is ISpeakingDialogueGame {
		const hasBasicProps =
			typeof (game as ISpeakingDialogueGame).titleText === "string" &&
			typeof (game as ISpeakingDialogueGame).titleVoiceActor === "string" &&
			typeof (game as ISpeakingDialogueGame).charVoiceActor === "string" &&
			Array.isArray((game as ISpeakingDialogueGame).entries) &&
			(game as ISpeakingDialogueGame).charA &&
			(game as ISpeakingDialogueGame).charB &&
			typeof (game as ISpeakingDialogueGame).charA.imageId === "string" &&
			typeof (game as ISpeakingDialogueGame).charA.name === "string" &&
			typeof (game as ISpeakingDialogueGame).charB.imageId === "string" &&
			typeof (game as ISpeakingDialogueGame).charB.name === "string";

		if (hasBasicProps) {
			return (game as ISpeakingDialogueGame).entries.every(
				(line) =>
					typeof line.lineA === "string" &&
					typeof line.lineB === "string"
			);
		}

		return false;
	}

	uploadQuestion() {
		let canUpload = true;

		if (!this.titleControl || !this.titleControl.value) {
			this.titleControl.markAsTouched();
			canUpload = false;
		}

		if (!this.titleVoiceControl || !this.titleVoiceControl.value) {
			this.titleVoiceControl.markAsTouched();
			canUpload = false;
		}

		if (!this.imageExists && this.files.length === 0) {
			this.toastService.error("No image has been selected.");
			canUpload = false;
		}

		if (!this.voiceActorControl || !this.voiceActorControl.value) {
			this.voiceActorControl.markAsTouched();
			canUpload = false;
		}

		const charA = this.profilePhotoAControl.value;
		const charB = this.profilePhotoBControl.value;

		if (
			charA === null ||
			charA.ref.trim() === "" ||
			charA.name.trim() === ""
		) {
			this.profilePhotoAControl.markAsTouched();
			canUpload = false;
		}

		if (
			charB === null ||
			charB.ref.trim() === "" ||
			charB.name.trim() === ""
		) {
			this.profilePhotoBControl.markAsTouched();
			canUpload = false;
		}

		if (this.lines.controls.length <= 0) {
			this.toastService.error("No lines have been created.");
			canUpload = false;
		}

		this.lines.controls.forEach((line) => {
			const lineA = line.get("lineA");
			const lineB = line.get("lineB");

			if (!lineA || !lineA.value || lineA.value.trim() === "") {
				lineA?.markAsTouched();
				canUpload = false;
			}

			if (!lineB || !lineB.value || lineB.value.trim() === "") {
				lineB?.markAsTouched();
				canUpload = false;
			}
		});

		if (!canUpload) {
			this.toastService.error(
				"There are one or more errors in upload form. Please fix them and try again."
			);
			this.questionUploaded.emit(false);
			return;
		}

		this.data = {
			...this.data,
			game: {
				activity: "speaking-dialogue",
				content: {
					titleText: this.titleControl.value,
					titleVoiceActor: this.titleVoiceControl.value,
					titleImage: this.image,
					charVoiceActor: this.voiceActorControl.value,
					charA: {
						imageId: this.profilePhotoAControl.value.ref,
						name: this.profilePhotoAControl.value.name,
					},
					charB: {
						imageId: this.profilePhotoBControl.value.ref,
						name: this.profilePhotoBControl.value.name,
					},
					entries: this.lines.value,
				} as ISpeakingDialogueGame,
			},
		};

		if (this.files.length > 0) {
			const uploadFileIds = this.files.map((file) => {
				return {
					id: file.name,
					extension: "jpg",
				} as IUploadImage;
			});

			const fileBlobs = this.files.map((fileInfo) => {
				const imageBlob = new Blob([fileInfo.file], {
					type: `image/jpeg`,
				});
				return imageBlob;
			});

			this.addSubscription = this.uploadService
				.uploadPlacementImage(fileBlobs, uploadFileIds)
				.subscribe((res) => {
					if (!res) {
						this.toastService.error("Failed to upload image");
						this.questionUploaded.emit(false);
						return;
					} else {
						this.toastService.success(
							"Image was uploaded successfully"
						);
						if (!this.isUpdate) {
							this.createQuestion(this.data as CreateQuestionDTO);
						} else {
							this.updateQuestion(this.data as UpdateQuestionDTO);
						}
					}
				});
		} else {
			if (!this.isUpdate) {
				this.createQuestion(this.data as CreateQuestionDTO);
			} else {
				this.updateQuestion(this.data as UpdateQuestionDTO);
			}
		}
	}

	createQuestion(createData: CreateQuestionDTO) {
		this.addSubscription = this.placementQuestionsService
			.createQuestion(createData)
			.pipe()
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to create question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to create question.");
					this.questionUploaded.emit(false);
				},
			});
	}

	updateQuestion(updateData: UpdateQuestionDTO) {
		this.addSubscription = this.placementQuestionsService
			.updateQuestion(updateData, updateData.questionId)
			.pipe()
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to update question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to update question.");
					this.questionUploaded.emit(false);
				},
			});
	}

	get lines(): FormArray {
		return this.dialogueForm.get("lines") as FormArray;
	}

	createDialogueLine(): FormGroup {
		return this.fb.group({
			lineA: new FormControl(null, Validators.required),
			lineB: new FormControl(null, Validators.required),
		});
	}

	addLine(): void {
		this.lines.push(this.createDialogueLine());
	}

	removeLine(index: number): void {
		this.lines.removeAt(index);
	}
}
