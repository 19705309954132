import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, FormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'angular-toastify';
import { Subscription } from 'rxjs';
import { ImageFiles, IUploadImage } from 'src/app/shared/models/image-upload.model';
import { CreateQuestionDTO, UpdateQuestionDTO, AnyGame, IOrderingLettersGame, IOrderingLettersGameQuestion } from 'src/app/shared/models/placement-question.model';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';
import { PlacementQuestionsService } from 'src/app/shared/services/placement-questions.service';
import { CreateQuestionDialog } from '../create-question.component';

@Component({
	selector: 'app-ordering-letters-media',
	templateUrl: './ordering-letters-media.component.html',
	styleUrls: ['./ordering-letters-media.component.scss']
})
export class OrderingLettersMediaComponent implements OnInit, OnDestroy {
	@Input() data!: CreateQuestionDTO | UpdateQuestionDTO;
	@Input() isUpdate!: boolean;

	@Output() questionUploaded = new EventEmitter<boolean>();

	questionsForm!: FormGroup;

	private uploadSubscription!: Subscription;
	private addSubscription!: Subscription;

	constructor(
		public dialogRef: MatDialogRef<CreateQuestionDialog>,
		private placementService: PlacementQuestionsService,
		private uploadService: ImageUploaderService,
		private readonly toastService: ToastService,
		private fb: FormBuilder
	) {
		this.addSubscription = new Subscription();
		this.uploadSubscription = new Subscription();

		this.questionsForm = this.fb.group({
			questions: this.fb.array([]),
		});
		this.addQuestion();
	}

	ngOnInit() {
		if (this.data.game && this.isOrderingLettersGame(this.data.game.content)) {
			this.questions.clear();

			this.data.game.content.questions.forEach((data) => {
				this.questions.push(
					this.fb.group({
						keyword: new FormControl(
							data.text,
							Validators.required
						),
						imageId: new FormControl(
							data.imageId,
							Validators.required
						),
						imageExists: new FormControl(
							true,
							Validators.required
						),
						verifyImage: new FormControl(
							true,
							Validators.required
						),
						file: new FormControl(null, Validators.required),
					})
				);
			});
		}
	}

	ngOnDestroy(): void {
		if (this.addSubscription) {
			this.addSubscription.unsubscribe();
		}

		if (this.uploadSubscription) {
			this.uploadSubscription.unsubscribe();
		}
	}

	isOrderingLettersGame(game: AnyGame): game is IOrderingLettersGame {
		const hasQuestions = Array.isArray((game as IOrderingLettersGame).questions);

		if (hasQuestions) {
			return (game as IOrderingLettersGame).questions.every(
				(question: IOrderingLettersGameQuestion) => {
					// Verifica se tanto imageId quanto text são strings não vazias
					return typeof question.imageId === "string" && question.imageId.trim() !== "" &&
						typeof question.text === "string" && question.text.trim() !== "";
				}
			);
		}

		return false;
	}

	clearQuestionImage(newValue: string, form: AbstractControl | null) {
		if (form !== null) {
			form.get("imageId")?.setValue(newValue, { emitEvent: true });
			form.get("verifyImage")?.setValue(false, { emitEvent: true });
			form.get("imageExists")?.setValue(false, { emitEvent: true });
			form.get("file")?.setValue(null, { emitEvent: true });
		}
	}

	verifyQuestionImage(form: AbstractControl | null) {
		if (form !== null) {
			(form as FormControl)
				.get("verifyImage")
				?.setValue(true, { emitEvent: true });
		}
	}

	setQuestionFile(file: ImageFiles | null, form: AbstractControl | null) {
		if (form !== null) {
			const formControl = form as FormControl;
			formControl.get("imageId")?.setValue(file?.name ?? "", { emitEvent: true });
			formControl.get("file")?.setValue(file, { emitEvent: true });

			if (file === null) {
				formControl.get("verifyImage")?.setValue(false, { emitEvent: true });
				formControl.get("imageExists")?.setValue(false, { emitEvent: true });
			}
		}
	}


	existsFile(form: AbstractControl | null, imageExists: boolean, imageId: string) {
		if (form !== null) {
			form.get("imageExists")?.setValue(imageExists, { emitEvent: true });
			form.get("imageId")?.setValue(imageId, { emitEvent: true });
		}
	}

	get questions(): FormArray {
		return this.questionsForm.get("questions") as FormArray;
	}

	getFormGroup(form: AbstractControl | null): FormGroup {
		return form as FormGroup;
	}

	createQuestionGroup(): FormGroup {
		return this.fb.group({
			keyword: new FormControl('', Validators.required),
			imageId: new FormControl('', Validators.required),
			imageExists: new FormControl(false, Validators.required),
			verifyImage: new FormControl(false, Validators.required),
		});
	}


	addQuestion(): void {
		this.questions.push(this.createQuestionGroup());
	}

	removeQuestion(index: number): void {
		this.questions.removeAt(index);
	}

	uploadQuestion() {
		let canUpload: boolean = true;

		if (this.questions.controls.length <= 0) {
			this.toastService.error("No questions have been created.");
			canUpload = false;
		}

		const fileBlobs: Blob[] = [];
		const fileIds: IUploadImage[] = [];

		this.questions.controls.forEach((element) => {
			const keywordForm = element.get("keyword");
			const imageIdForm = element.get("imageId");
			const file = element.get("file");

			if (!keywordForm || !keywordForm.value || keywordForm.value.trim() === "") {
				keywordForm?.markAsTouched();
				canUpload = false;
			}

			if (!imageIdForm || !imageIdForm.value || imageIdForm.value.trim() === "") {
				imageIdForm?.markAsTouched();
				canUpload = false;
			}

			if (file && file.value) {
				fileBlobs.push(
					new Blob([file.value.file], { type: "image/jpeg" })
				);
				fileIds.push({
					id: file.value.name,
					extension: "jpg",
				} as IUploadImage);
			}
		});

		if (!canUpload) {
			this.toastService.error("There are one or more errors in upload form. Please fix them and try again.");
			this.questionUploaded.emit(false);
			return;
		}

		this.data = {
			...this.data,
			game: {
				activity: "ordering-letters",
				content: {
					questions: this.questions.controls.map(ctrl => ({
						text: ctrl.get('keyword')?.value,
						imageId: ctrl.get('imageId')?.value
					})) as IOrderingLettersGameQuestion[]
				}
			}
		};

		if (fileBlobs.length > 0) {
			this.uploadService.uploadPlacementImage(fileBlobs, fileIds)
				.subscribe((res) => {
					if (!res) {
						this.toastService.error("Failed to upload images");
						this.questionUploaded.emit(false);
						return;
					}
					this.toastService.success("Images were uploaded successfully");
					this.finalizeUpload();
				});
		} else {
			this.finalizeUpload();
		}
	}

	finalizeUpload() {
		if (!this.isUpdate) {
			this.createQuestion(this.data as CreateQuestionDTO);
		} else {
			this.updateQuestion(this.data as UpdateQuestionDTO);
		}
	}


	createQuestion(createData: CreateQuestionDTO) {
		this.addSubscription = this.placementService
			.createQuestion(createData)
			.pipe()
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to create question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to create question.");
					this.questionUploaded.emit(false);
				},
			});
	}

	updateQuestion(updateData: UpdateQuestionDTO) {
		this.addSubscription = this.placementService
			.updateQuestion(updateData, updateData.questionId)
			.pipe()
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to update question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to update question.");
					this.questionUploaded.emit(false);
				},
			});
	}
}
