<div class="login-container">
	<div class="header">
		<h1>Login</h1>
	</div>
	<div class="content">
		<ng-container class="login-content">
			<mat-form-field appearance="standard">
				<mat-label>Enter your email</mat-label>
				<input matInput type="email" placeholder="example@manifestohut.com.br" [formControl]="email" required>
				<mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
			</mat-form-field>

			<mat-form-field appearance="standard">
				<mat-label>Enter your password</mat-label>
				<input matInput [type]="hide ? 'password' : 'text'" [formControl]="password" required>
				<button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
				  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
				</button>
			  </mat-form-field>
		</ng-container>
	</div>
	<div class="footer">
		<button mat-raised-button color="primary" (click)="login()" style="width: 100px;">Login</button>
	</div>
</div>