import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILesson } from 'src/app/shared/models/lesson.model';
import { UploadStateDialog } from '../upload-state/upload-state.component';

@Component({
  selector: 'app-content-validation',
  templateUrl: './content-validation.component.html',
  styleUrls: ['./content-validation.component.scss']
})
export class ContentValidationDialog implements OnInit {

  constructor(
	public dialogRef: MatDialogRef<UploadStateDialog>,
	@Inject(MAT_DIALOG_DATA) public data: ILesson,
  ) {
  }

  ngOnInit(): void {

  }

  closeDialog() {
	this.dialogRef.close();
}
}
