import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { EditImageDialog } from "src/app/shared/components/edit-image/edit-image.component";
import { environment } from "src/environments/environment";
import { AddTextDialog } from './add-text/add-text.component';
import { DeleteConfirmationDialog } from "src/app/shared/components/delete-confirmation/delete-confirmation.component";
import { ITextsRow } from "src/app/shared/models/lesson-content.model";

import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: "app-texts",
	templateUrl: "./texts.component.html",
	styleUrls: ["./texts.component.scss"],
})
export class TextsComponent implements OnInit {


	dataSource!: MatTableDataSource<ITextsRow>;

	@Input() set data(value: ITextsRow[]) {
		this.dataSource.data = value;
	}

	@Output() newData = new EventEmitter<ITextsRow[]>();

	displayedColumns: string[] = ['image','title', 'text', 'delete'];
	length: number = 0;
	selected: string[] = [];

	cols: number = 0;

	constructor(
		public dialog: MatDialog,
	) {
		this.dataSource = new MatTableDataSource();
		this.cols = 5;
	}

	ngOnInit(): void {}

	addText() {
		const dialogRef = this.dialog.open(AddTextDialog, {
			width: "650px",
			data: {
				uuid: uuidv4(),
				title: "",
				text: "",
				imageId: "",
			} as ITextsRow,
		});

		dialogRef.afterClosed().subscribe((result: ITextsRow) => {
			if (!result) {
				return;
			}

			let tempArray = this.dataSource.data;
			tempArray.push(result);
			this.dataSource.data = tempArray;
			this.sendData();
		});
	}

	remove(index: number) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
			data: index,
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			let tempArray = this.dataSource.data;
			tempArray.splice(index, 1);
			this.dataSource.data = tempArray;
			this.sendData();
		});
	}

	createImage(id: string) {
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.jpg`;
	}

	editImage(index: number, text: ITextsRow) {
		const dialogRef = this.dialog.open(EditImageDialog, {
			width: "350px",
			data: text.imageId,
		});

		dialogRef.afterClosed().subscribe((result: string) => {
			if (!result) {
				return;
			}

			let tempArray = this.dataSource.data;
			text.imageId = result;
			tempArray[index] = text;
			this.dataSource.data = tempArray;
			this.sendData();
		});
	}

	copyImageName(index: number) {
		let text = this.dataSource.data[index];
		navigator.clipboard.writeText(text.imageId);
	}

	sendData() {
		this.newData.emit(this.dataSource.data);
	}
}
