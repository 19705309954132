import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityDescriptionComponent } from './activity-description.component';
import { AngularToastifyModule, ToastService } from 'angular-toastify';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarRoutingModule } from '../navbar-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { MatStepperModule } from '@angular/material/stepper';
import { ResizableModule } from 'angular-resizable-element';
import { AddActivityDescriptionDialog } from './add-activity-description/add-activity-description.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
	declarations: [
		ActivityDescriptionComponent,
		AddActivityDescriptionDialog
	],
	imports: [
		NavbarRoutingModule,
		AngularToastifyModule,
		CommonModule,
		FormsModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatDialogModule,
		MatFormFieldModule,
		MatGridListModule,
		MatExpansionModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatPaginatorModule,
		MatTabsModule,
		MatToolbarModule,
		MatSelectModule,
		MatStepperModule,
		MatTableModule,
		MatExpansionModule,
		MatProgressSpinnerModule,
		ReactiveFormsModule,
		SharedModule,
		ResizableModule,
	],
	providers: [
		ToastService
	]
})
export class ActivityDescriptionModule { }
