<ng-container>
  <h3>FILL THE GAPS IMAGE</h3>

  <form [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Text</mat-label>
      <textarea matInput formControlName="text"></textarea>
      <mat-error *ngIf="form.get('text')?.errors?.required">
        Text is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <div class="lesson-image-content">
      <mat-form-field appearance="outline">
        <mat-label>Image</mat-label>
        <input matInput formControlName="imageId">
        <button *ngIf="form.get('imageId')?.value" matSuffix mat-icon-button aria-label="Clear"
          (click)="clearQuestionImage('', form)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button mat-button [disabled]="!form.get('imageId')?.value" class="verify-button"
        (click)="verifyQuestionImage(form)">Verify Image</button>
    </div>

    <app-image-uploader *ngIf="form.get('verifyImage')?.value" [isPlacement]="true"
      [imageId]="form.get('imageId')?.value" (uploadFiles)="setQuestionFile($event, form)"
      (exists)="existsFile(form, true, $event)">
    </app-image-uploader>

    <div class="header">
      <mat-toolbar class="header" color="primary">
        <div class="start-header">
          <h2 class="title">Choices</h2>
        </div>
        <div class="end-header">
          <button mat-mini-fab color="white" (click)="addChoice()" [disabled]="choices.length >= 7">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-toolbar>
    </div>

    <div formArrayName="choices">
      <mat-accordion>
        <mat-expansion-panel hideToggle *ngFor="let choiceGroup of choices.controls; let i = index" [formGroupName]="i"
          class="choice-container">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Choice {{i + 1}}
            </mat-panel-title>
            <mat-panel-description>
              <div class="description-container"> {{ choiceGroup.get('image')?.value }} </div>
              <div class="delete-div">
                <button mat-icon-button class="delete-button" (click)="removeChoice(i)">
                  <mat-icon color="warn">delete</mat-icon>
                </button>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="choice-image-content">
            <mat-form-field appearance="outline">
              <mat-label>Image</mat-label>
              <input matInput formControlName="image">
              <button *ngIf="choiceGroup.get('image')?.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearChoiceImage(i, 'choices')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <button mat-button [disabled]="!choiceGroup.get('image')?.value" class="verify-button"
              (click)="verifyChoiceImage(i, 'choices')">Verify Image</button>
          </div>

          <app-image-uploader *ngIf="choiceGroup.get('verifyImage')?.value" [isPlacement]="true"
            [imageId]="choiceGroup.get('image')?.value" (uploadFiles)="setChoiceFile($event, i, 'choices')"
            (exists)="existsChoiceFile(i, true, $event, 'choices')">
          </app-image-uploader>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="header">
      <mat-toolbar class="header" color="primary">
        <div class="start-header">
          <h2 class="title">Wrong Choices</h2>
        </div>
      </mat-toolbar>
    </div>

    <div formArrayName="wrongChoices">
      <mat-accordion>
        <mat-expansion-panel hideToggle *ngFor="let wrongChoiceGroup of wrongChoices.controls; let i = index"
          [formGroupName]="i" class="wrong-choice-container">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Wrong Choice {{i + 1}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-form-field appearance="outline">
            <mat-label>Text</mat-label>
            <input matInput formControlName="text">
            <mat-error *ngIf="wrongChoiceGroup.get('text')?.errors?.required">
              Text is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <div class="wrong-choice-image-content">
            <mat-form-field appearance="outline">
              <mat-label>Image</mat-label>
              <input matInput formControlName="image">
              <button *ngIf="wrongChoiceGroup.get('image')?.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearChoiceImage(i, 'wrongChoices')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <button mat-button [disabled]="!wrongChoiceGroup.get('image')?.value" class="verify-button"
              (click)="verifyChoiceImage(i, 'wrongChoices')">Verify Image</button>
          </div>

          <app-image-uploader *ngIf="wrongChoiceGroup.get('verifyImage')?.value" [isPlacement]="true"
            [imageId]="wrongChoiceGroup.get('image')?.value" (uploadFiles)="setChoiceFile($event, i, 'wrongChoices')"
            (exists)="existsChoiceFile(i, true, $event, 'wrongChoices')">
          </app-image-uploader>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </form>
</ng-container>