import { IEnvironment } from "./environment.model";

export const environment : IEnvironment = {
	YOUTUBE_DATA_API: "https://www.googleapis.com/youtube/v3",
	YOUTUBE_API_KEY : "AIzaSyDNyKo1B6kkdCzJiN4MPwWawxIuJY9Cnss",
	SLIDE_PLAYER_URL : 'https://slide-player-staging.geppetto.com.br',
	METAGAME_URL: 'https://game-staging.geppetto.com.br',
	production: false,

	PRODUCTION_EDITOR: "https://lesson-editor-hi5-prod.firebaseapp.com/",

	KNOWN_OWNERS: [
		{
			id: "dev/dev",
			displayName: "DEV",
		},
		{
			id: "system/system",
			displayName: "SYSTEM",
		},
		{
			id: "placement/placement",
			displayName: "PLACEMENT",
		},
		{
			id: "accounts/ECFVyaFm3wyRVeBFooUx",
			displayName: "SOMOS",
		}
	],

	BUCKETS: {
		CONTENT_BUCKET: "geppetto-hi5-content-staging",
		ALTERNATE_CONTENT_BUCKET: "geppetto-hi5-content-staging",
	},

	firebaseConfig: {
		apiKey: "AIzaSyBtQgDFZsl6eV45Afl-wPHbg29iWX47Yws",
		authDomain: "geppetto-hi5-staging.firebaseapp.com",
		databaseURL: "https://geppetto-hi5-staging.firebaseio.com",
		projectId: "geppetto-hi5-staging",
		storageBucket: "geppetto-hi5-staging.appspot.com",
		messagingSenderId: "244390872023",
		appId: "1:244390872023:web:e5d94a5fcf1064c3994192"
	},

	SERVICES: {
		CLASS_SESSION_LOCAL: "http://localhost:9000/",
		CLASS_SESSION: "https://api-staging.geppetto.com.br/class-session/",
		ALTERNATE_CLASS_SESSION: "https://api-staging.geppetto.com.br/class-session/",
		LESSON_LOCAL: "http://localhost:8080/",
		LESSON: "https://api-staging.geppetto.com.br/lesson/",
		ALTERNATE_LESSON: "https://api-staging.geppetto.com.br/lesson/",
		// ALTERNATE_LESSON: "https://api-staging.geppetto.com.br/lesson/",
		ASSET_DELIVERY: "https://api-staging.geppetto.com.br/asset-delivery/",
		ASSET_DELIVERY_LOCAL: "http://localhost:9000/",
		MANAGEMENT: "https://api-staging.geppetto.com.br/management/v2/",
		MANAGEMENT_LOCAL: "http://localhost:9000/v2/"
	}
};
