<div mat-dialog-title color="primary">CREATE QUESTION</div>
<lib-toastify-toast-container position="bottom-right"></lib-toastify-toast-container>
<div mat-dialog-content class="dialog-content" style="align-items: center">
  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Question</mat-label>
      <input matInput [formControl]="questionControl" />
      <button *ngIf="questionControl.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="questionControl.reset()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="questionControl.hasError('required')">
        Question is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Keywords</mat-label>
      <input matInput [formControl]="keysControl" />
      <button *ngIf="keysControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="keysControl.reset()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="keysControl.hasError('required')">
        Keyswords is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Difficulty</mat-label>
      <mat-select [(ngModel)]="data.difficulty">
        <ng-container *ngFor="let difficulty of difficulties">
          <mat-option [value]="difficulty">{{difficulty}}</mat-option>
        </ng-container>
      </mat-select>
      <button *ngIf="data.difficulty" matSuffix mat-icon-button aria-label="Clear"
        (click)="data.difficulty = 1">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Min. Words</mat-label>
      <input matInput type="number" [formControl]="minWordsControl" />
      <button *ngIf="minWordsControl.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="minWordsControl.reset()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="minWordsControl.hasError('required')">
        Min. Words is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="lesson-image-content">
    <mat-form-field appearance="outline">
      <mat-label>Image</mat-label>
      <input matInput [ngModel]="data.imageId" (ngModelChange)="changeImage($event)" />
      <button *ngIf="data.imageId" matSuffix mat-icon-button aria-label="Clear" (click)="changeImage('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button mat-button [disabled]="data.imageId === ''" class="verify-button" (click)="verifyImage()">
      Verify Image
    </button>
  </ng-container>

  <ng-container>
    <app-image-uploader *ngIf="toVerify" [imageId]="imageVerify" (uploadFiles)="setFile($event)"
      (exists)="imageExists = true; data.imageId = $event"></app-image-uploader>
  </ng-container>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Discard</button>
  <button mat-button (click)="createQuestion()" cdkFocusInitial>Save</button>
</div>