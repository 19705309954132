<ng-container>
  <h3>MATCH GAME</h3>

  <!-- Campo de Questão -->
  <ng-container class="lesson-title-content">
    <mat-form-field appearance="outline">
      <mat-label>Question</mat-label>
      <input matInput [formControl]="questionControl">
      <button *ngIf="questionControl.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="questionControl.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="questionControl.hasError('required')">
        Question is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <!-- Upload de AudioTutorialA -->
  <h4>Audio Tutorial A</h4>
  <ng-container class="lesson-image-content">
    <app-audio-uploader [audioId]="audioTutorialAUrl" (audioUploaded)="audioTutorialAUploaded($event)"></app-audio-uploader>
  </ng-container>

  <!-- Upload de AudioTutorialB -->
  <h4>Audio Tutorial B</h4>
  <ng-container class="lesson-image-content">
    <app-audio-uploader [audioId]="audioTutorialBUrl" (audioUploaded)="audioTutorialBUploaded($event)"></app-audio-uploader>
  </ng-container>

  <!-- Upload de Audio -->
  <h4>Audio Content</h4>
  <ng-container class="lesson-image-content">
    <app-audio-uploader [audioId]="audioUrl" (audioUploaded)="audioUploaded($event)"></app-audio-uploader>
  </ng-container>

  <!-- Pares de Imagens -->
  <ng-container>
    <div class="header">
      <mat-toolbar class="header" color="primary">
        <div class="start-header" style="width: 50%">
          <h1 class="title">Image Pairs</h1>
        </div>
        <div class="end-header" style="display: flex; flex-direction: row-reverse; width: 50%">
          <button mat-mini-fab color="white" (click)="addLine()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-toolbar>
    </div>

    <!-- Form para Pares de Imagens -->
    <form [formGroup]="imagesForm">
      <div formArrayName="imagePairs">
        <mat-accordion>
          <mat-expansion-panel hideToggle *ngFor="let pairGroup of imagePairs.controls; let i = index" class="line-container">
            <mat-expansion-panel-header>
              <mat-panel-title style="align-items: center;">
                Pair {{i + 1}}
              </mat-panel-title>
              <mat-panel-description style="align-items: center">
                <div style="display: flex; width: 95%"> - </div>
                <div class="delete-div">
                  <button mat-icon-button class="delete-button" (click)="removeLine(i)">
                    <mat-icon color="warn">delete</mat-icon>
                  </button>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <!-- Form para Imagens -->
            <ng-container [formGroupName]="i" *ngFor="let imageString of ['imageA', 'imageB']; let j = index">
              <form [formGroup]="getGroup(pairGroup.get(imageString))">
                <mat-accordion>
                  <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title style="align-items: center;">
                        {{imageString === 'imageA' ? 'Image A' : 'Image B'}}
                      </mat-panel-title>
                      <mat-panel-description style="align-items: center">
                        <div style="display: flex; width: 95%"> 
                          {{ getValue(pairGroup, imageString, 'imageId') ?? 'No Image Selected'}}
                        </div>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
  
                    <ng-container>
                      <ng-container class="lesson-image-content">
                        <mat-form-field appearance="outline">
                          <mat-label>Image</mat-label>
                          <input matInput formControlName="imageId">
                          <button *ngIf="getValue(pairGroup, imageString, 'imageId')" matSuffix mat-icon-button
                            aria-label="Clear" (click)="clearPairImage('', pairGroup, imageString)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>
                        <button mat-button [disabled]="!getValue(pairGroup, imageString, 'imageId')" class="verify-button"
                          (click)="verifyPairImage(getControl(pairGroup, imageString, 'verifyImage'))">Verify Image</button>
                      </ng-container>
  
                      <ng-container>
                        <app-image-uploader *ngIf="getValue(pairGroup, imageString, 'verifyImage')"
                          [imageId]="getValue(pairGroup, imageString, 'imageId')" [isPlacement]="true"
                          (uploadFiles)="setPairFile($event, pairGroup, imageString)"
                          (exists)="existsFile(pairGroup, imageString, true, $event)"></app-image-uploader>
                      </ng-container>
                    </ng-container>
                  </mat-expansion-panel>
                </mat-accordion>
              </form>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </form>
  </ng-container>
</ng-container>
