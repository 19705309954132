import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastService } from "angular-toastify";
import { VALID_AGES, VALID_SKILLS } from "src/app/shared/constants";
import { IActivityDescription } from "src/app/shared/models/activity-description.model";
import { ImageFiles } from "src/app/shared/models/image-upload.model";

@Component({
	selector: "app-add-activity-description",
	templateUrl: "./add-activity-description.component.html",
	styleUrls: ["./add-activity-description.component.scss"],
})
export class AddActivityDescriptionDialog implements OnInit {
	public files: ImageFiles[] = [];
	ages: number = this.data.ages.length ? this.data.ages.length : 1;

	nameControl = new FormControl(this.data.name, Validators.required);
	cognitionControl = new FormControl(this.data.cognition, Validators.required);
	descriptionControl = new FormControl(this.data.description, Validators.required);

	validSkills = VALID_SKILLS;
	validAges = VALID_AGES;
	validCognitions = ["0", "1", "2", "3", "4"];

	isEdit = false;


	constructor(
		public dialogRef: MatDialogRef<AddActivityDescriptionDialog>,
		@Inject(MAT_DIALOG_DATA) public data: IActivityDescription,
		private toastService: ToastService
	) { }

	ngOnInit(): void {
		if(this.data.name !== "") {
			this.isEdit = true;
		}
	}

	closeDialog() {
		this.dialogRef.close();
	}

	trackByIdx(index: number, obj: any): any {
		return index;
	}

	createContentGroup() {
		let canUpload = true;

		this.data.name = this.nameControl.value;
		this.data.cognition = this.cognitionControl.value;
		this.data.description = this.descriptionControl.value;

		if (this.data.name === "") {
			this.nameControl.markAsTouched();
			canUpload = false;
		}

		if (this.data.ages.length === 0) {
			this.toastService.error("Please select at least one age group.");
			canUpload = false;
		}

		if (this.data.cognition === "") {
			this.cognitionControl.markAsTouched();
			canUpload = false;
		}

		if (this.data.description === "") {
			this.descriptionControl.markAsTouched();
			canUpload = false;
		}

		if (!canUpload) {
			this.toastService.error("Failed to save new Activity Description.");
			return;
		}

		this.dialogRef.close(this.data);
	}
}
