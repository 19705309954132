<div class="info-tab-content">
	<div class="lesson-info" >
		<mat-card style="text-align: center; width: 100%; height: 100%;">
			<div class="image-container" style="width: 100%; margin-bottom: 20px">
				<button mat-icon-button matTooltip="Copy image name" class="copy-button" (click)="copyImageName()">
					<mat-icon color="primary">collections</mat-icon>
				</button>
				<img mat-card-image (click)="editImage()" [src]="createImage(info.image)" (error)="img.src=getDefault()" #img style="width: 500px; height: 100%; justify-content: center; align-items: center;">
				<div class="image-text" style="text-align: center; ">Change Image</div>
				<div class="background"></div>
			</div>
			<mat-card-content>
				<mat-list role="list" class="info-list">
					<mat-list-item role="listitem">
						<mat-form-field appearance="outline">
							<mat-label>Display Name</mat-label>
							<input matInput [(ngModel)]="info.displayName" [formControl]="displayNameControl">
							<button *ngIf="info.displayName" matSuffix mat-icon-button aria-label="Clear" (click)="info.displayName=''">
								<mat-icon>close</mat-icon>
							</button>
							<mat-error *ngIf="displayNameControl.hasError('required')">
								Lesson display name is <strong>required</strong>
							</mat-error>
						</mat-form-field>
					</mat-list-item>
			
					<mat-list-item role="listitem">
						<mat-form-field appearance="outline">
							<mat-label>CEFR</mat-label>
							<mat-select [(ngModel)]="info.cefr">
								<ng-container *ngFor="let cefr of cefrs">
									<mat-option [value]="cefr">{{cefr}}</mat-option>
								</ng-container>
							</mat-select>
							<mat-error *ngIf="cefrControl.hasError('required')">
								Lesson cefr is <strong>required</strong>
							</mat-error>
						</mat-form-field>
					</mat-list-item>
			
					<mat-list-item role="listitem">
						<mat-form-field appearance="outline">
							<mat-label>Age</mat-label>
							<mat-select [(ngModel)]="info.age" [formControl]="ageControl">
								<ng-container *ngFor="let age of ages">
									<mat-option [value]="age">{{age}}</mat-option>
								</ng-container>
							</mat-select>
							<mat-error *ngIf="ageControl.hasError('required')">
								Lesson age is <strong>required</strong>
							</mat-error>
						</mat-form-field>
					</mat-list-item>
			
					<mat-list-item role="listitem">
						<mat-form-field appearance="outline">
							<mat-label>Caption</mat-label>
							<textarea matInput [(ngModel)]="info.caption" [formControl]="captionControl"></textarea>
							<button *ngIf="info.caption" matSuffix mat-icon-button aria-label="Clear" (click)="info.caption=''">
								<mat-icon>close</mat-icon>
							</button>
							<mat-error *ngIf="captionControl.hasError('required')">
								Lesson caption is <strong>required</strong>
							</mat-error>
						</mat-form-field>
					</mat-list-item>
			
					<mat-list-item role="listitem">
						<mat-form-field appearance="outline">
							<mat-label>Vocabulary</mat-label>
							<textarea matInput [(ngModel)]="info.vocabulary" [formControl]="vocabularyControl"></textarea>
							<button *ngIf="info.vocabulary" matSuffix mat-icon-button aria-label="Clear" (click)="info.vocabulary=''">
								<mat-icon>close</mat-icon>
							</button>
							<mat-error *ngIf="vocabularyControl.hasError('required')">
								Lesson vocabulary is <strong>required</strong>
							</mat-error>
						</mat-form-field>
					</mat-list-item>
		
					<mat-list-item role="listitem">
						<mat-form-field appearance="outline">
							<mat-label>Grammar</mat-label>
							<textarea matInput [(ngModel)]="info.grammar" [formControl]="grammarControl"></textarea>
							<button *ngIf="info.grammar" matSuffix mat-icon-button aria-label="Clear" (click)="info.grammar=''">
								<mat-icon>close</mat-icon>
							</button>
							<mat-error *ngIf="grammarControl.hasError('required')">
								Lesson grammar is <strong>required</strong>
							</mat-error>
						</mat-form-field>
					</mat-list-item>
		
					<mat-list-item role="listitem">
							<mat-form-field appearance="outline">
								<mat-label>Aim</mat-label>
								<textarea matInput [(ngModel)]="info.aim" [formControl]="aimControl"></textarea>
								<button *ngIf="info.aim" matSuffix mat-icon-button aria-label="Clear"
									(click)="info.aim=''">
									<mat-icon>close</mat-icon>
								</button>
								<mat-error *ngIf="aimControl.hasError('required')">
									Lesson can statement is <strong>required</strong>
								</mat-error>
							</mat-form-field>
					</mat-list-item>
			
					<mat-list-item role="listitem">
							<mat-form-field appearance="outline">
								<mat-label>Owner</mat-label>
								<mat-select [(ngModel)]="info.owner" [formControl]="ownerControl">
									<ng-container *ngFor="let owner of owners">
										<mat-option [value]="owner.id">{{owner.displayName}}</mat-option>
									</ng-container>
								</mat-select>
								<button *ngIf="info.owner" matSuffix mat-icon-button aria-label="Clear" (click)="info.owner='system/system'">
									<mat-icon>close</mat-icon>
								</button>
								<mat-error *ngIf="ownerControl.hasError('required')">
									Lesson owner is <strong>required</strong>
								</mat-error>
							</mat-form-field>
					</mat-list-item>
				</mat-list>
			</mat-card-content>
		</mat-card>
	</div>
</div>