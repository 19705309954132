import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularToastifyModule, ToastService } from "angular-toastify";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NavbarRoutingModule } from "../navbar-routing.module";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatExpansionModule } from "@angular/material/expansion";
import { ClassroomGamesComponent } from "./classroom-games.component";
import { ClassroomGameComponent } from "./tabs/classroom-game/classroom-game.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatStepperModule } from "@angular/material/stepper";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";
import { AddClassroomGameDialog } from "./tabs/classroom-game/add-classroom-game/add-classroom-game.component";
import { SharedModule } from "src/app/shared/components/shared.module";
import { AddCardDialog } from "./tabs/classroom-game/add-card/add-card.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
	declarations: [
		ClassroomGamesComponent,
		ClassroomGameComponent,
		AddClassroomGameDialog,
		AddCardDialog
	],
	imports: [
		NavbarRoutingModule,
		AngularToastifyModule,
		CommonModule,
		FormsModule,
		SharedModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatDialogModule,
		MatFormFieldModule,
		MatGridListModule,
		MatExpansionModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatPaginatorModule,
		MatTabsModule,
		MatToolbarModule,
		MatSelectModule,
		MatStepperModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatExpansionModule,
		ReactiveFormsModule,
	],
	providers: [
		ToastService,
		ImageUploaderService
	]
})
export class ClassroomGamesModule {}
