<div class="navigation-page">
	<div class="header">
		<mat-toolbar class="header" color="primary">
			<div class="start-header">
				<h1 class="title">Content Editor</h1>
			</div>
			<div class="end-header">
				<button mat-mini-fab color="white" (click)="logout()">
					<mat-icon>logout</mat-icon>
				</button>
			</div>
		</mat-toolbar>
	</div>
	<div class="content">
		<div class="navigation">
			<h1>Welcome to the Content Editor!</h1>
			<mat-list>
				<mat-card mat-list-item color="primary" *ngFor="let button of navButtons" [routerLink]="button.href" style="cursor: pointer;">
					<mat-card-content>
						<span> {{button.title}} </span>
					</mat-card-content>
				</mat-card>
			</mat-list>
		</div>
	</div>
</div>
