import { CdkDragEnd } from "@angular/cdk/drag-drop";
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from "@angular/core";
import { MatOptionSelectionChange } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ResizeEvent } from "angular-resizable-element";
import { ToastService } from "angular-toastify";
import {
	ImageFiles,
	IUploadImage,
} from "src/app/shared/models/image-upload.model";
import {
	IDragDropTarget,
	ICreateDragDrop,
	ICreateDragDropElement,
	IDragDropElement,
	IDragAndDropRow,
} from "src/app/shared/models/lesson-content.model";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";
import { round } from "src/app/shared/utils";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-add-drag-drop",
	templateUrl: "./add-drag-drop.component.html",
	styleUrls: ["./add-drag-drop.component.scss"],
})
export class AddDragDropDialog implements OnInit, AfterViewInit {
	dragElements: IDragDropTarget[] = [];
	@ViewChild("boundBox", { static: false }) boundBox!: ElementRef;
	@ViewChildren("dragElement") dragElementRefs!: QueryList<ElementRef>;

	completed1: boolean = false;

	public files: ImageFiles[] = [];
	public elementFiles: Map<number, ImageFiles> = new Map<
		number,
		ImageFiles
	>();

	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	positionX = 0;
	positionY = 0;

	uploading: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<AddDragDropDialog>,
		@Inject(MAT_DIALOG_DATA) public data: ICreateDragDrop,
		private toastService: ToastService,
		private uploadService: ImageUploaderService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		if (this.data.backgroundId !== "") {
			this.imageExists = true;
			this.completed1 = true;
		}
	}

	ngAfterViewInit() {
		this.dragElementRefs.changes.subscribe(() => {
			this.initializeTargets();
		});
		this.initializeTargets();
	}

	close() {
		this.dialogRef.close();
	}

	changeImage(newValue: string) {
		this.data.backgroundId = newValue;
		this.toVerify = false;
		this.files = [];
		this.imageExists = false;
		this.completed1 = false;
	}

	verifyImage() {
		this.imageVerify = this.data.backgroundId;
		this.toVerify = true;
	}

	setFile(file: ImageFiles | null) {
		if (file === null) {
			this.completed1 = false;
			this.data.backgroundId = "";
			this.files = [];
			return;
		}
		this.completed1 = true;
		this.data.backgroundId = file.name;
		this.files.push(file);
		console.log(this.files);
	}

	changeElementImage(newValue: string, index: number) {
		this.data.elements[index].image = newValue;
		this.data.elements[index].verifyImage = false;
		this.elementFiles.delete(index);
		this.data.elements[index].imageExists = false;
	}

	verifyElementImage(index: number) {
		this.data.elements[index].verifyImage = true;
	}

	setElementFile(file: ImageFiles | null, index: number) {
		if (file === null) {
			this.data.elements[index].image = "";
			this.elementFiles.delete(index);
			return;
		}
		this.data.elements[index].image = file.name;
		this.elementFiles.set(index, file);
	}

	getImage() {
		if (this.imageExists) {
			return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${this.data.backgroundId}.png`;
		} else {
			return this.files[0].path;
		}
	}

	getTargetImage(index: number) {
		if (
			this.data.elements[index] &&
			this.data.elements[index].imageExists
		) {
			return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${this.data.elements[index].image}.png`;
		} else if (
			this.data.elements[index] &&
			!this.data.elements[index].imageExists
		) {
			return this.elementFiles.get(index)?.path as string;
		} else {
			return "";
		}
	}

	getTargetInfo(element: IDragDropTarget) {
		const { x, y, width, height } = this.getPosition(element);
		return `x: ${x} | y: ${y} | width: ${width} | height: ${height}`;
	}

	initializeTargets() {
		this.dragElementRefs.forEach((dragElement, index) => {
			const target = this.data.targets[index];
			const style = this.getPosition(target);
			dragElement.nativeElement.style.left = style.x + "px";
			dragElement.nativeElement.style.top = style.y + "px";
			dragElement.nativeElement.style.width = style.width + "px";
			dragElement.nativeElement.style.height = style.height + "px";
		});
		this.cdr.detectChanges();
	}

	calculateTargetPos(element: IDragDropTarget) {
		const { x, y, width, height } = this.getPosition(element);
		return {
			left: x + "px",
			top: y + "px",
			width: width + "px",
			height: height + "px",
		};
	}

	getTargetImagePosition(element: IDragDropTarget) {
		const parentPos = document
			.getElementById("bound-box")
			?.getBoundingClientRect() as DOMRect;
		const x = round(element.upperLeft.x * parentPos.width);
		const y = round(element.upperLeft.y * parentPos.height);
		return {
			positionX: x,
			positionY: y,
		};
	}

	getPosition(element: IDragDropTarget) {
		const parentPos = document
			.getElementById("bound-box")
			?.getBoundingClientRect() as DOMRect;
		const offsetX = 22; // Deslocamento de 22px no eixo x
		const x = round(element.upperLeft.x * parentPos.width) + offsetX;
		const y = round(element.upperLeft.y * parentPos.height);
		const width = round(
			Math.max(
				80,
				(element.lowerRight.x - element.upperLeft.x) * parentPos.width
			)
		);
		const height = round(
			Math.max(
				50,
				(element.lowerRight.y - element.upperLeft.y) * parentPos.height
			)
		);
		return {
			x,
			y,
			width,
			height,
		};
	}

	addTarget() {
		this.data.targets.push({
			lowerRight: {
				x: 0.13,
				y: 0.1,
			},
			upperLeft: {
				x: 0,
				y: 0,
			},
		});

		if (this.data.elements.length > 0) {
			this.data.elements = this.data.elements.map((element) => {
				element.targets.push(false);
				return element;
			});
		}
	}

	removeTarget(index: number) {
		this.data.targets.splice(index, 1);
		if (this.data.elements.length > 0) {
			this.data.elements = this.data.elements.map((element) => {
				element.targets.splice(index, 1);
				return element;
			});
		}
	}

	onDragEnded(event: CdkDragEnd, target: IDragDropTarget, index: number) {
		const parentPos = document
			.getElementById("bound-box")
			?.getBoundingClientRect() as DOMRect;
		const childPos =
			event.source.element.nativeElement.getBoundingClientRect() as DOMRect;
		const xRatio = parentPos.width;
		const yRatio = parentPos.height;
		target.upperLeft.x = Math.max(
			(childPos.left - parentPos.left) / xRatio,
			0
		);
		target.upperLeft.y = Math.max(
			(childPos.top - parentPos.top) / yRatio,
			0
		);
		target.lowerRight.x = Math.min(
			(childPos.left - parentPos.left + childPos.width) / xRatio,
			1
		);
		target.lowerRight.y = Math.min(
			(childPos.top - parentPos.top + childPos.height) / yRatio,
			1
		);
		this.data.targets[index] = target;
	}

	onResizeEnd(
		event: ResizeEvent,
		target: IDragDropTarget,
		index: number
	): void {
		const parentPos = document
			.getElementById("bound-box")
			?.getBoundingClientRect() as DOMRect;
		const xRatio = parentPos.width;
		const yRatio = parentPos.height;
		target.lowerRight.x =
			target.upperLeft.x + (event.rectangle.width as number) / xRatio;
		target.lowerRight.y =
			target.upperLeft.y + (event.rectangle.height as number) / yRatio;
		this.data.targets[index] = target;

		const style = this.getPosition(target);
		const dragElement = this.dragElementRefs.toArray()[index];
		dragElement.nativeElement.style.width = style.width + "px";
		dragElement.nativeElement.style.height = style.height + "px";
	}

	addElement() {
		this.data.elements.push({
			name: "",
			image: "",
			targets: this.data.targets.map((target) => false),
			verifyImage: false,
			imageExists: false,
		});
	}

	removeElement(index: number) {
		this.data.elements.splice(index, 1);
	}

	getSelected(element: ICreateDragDropElement) {
		const values: string[] = [];
		element.targets.map((target, index) => {
			if (target) {
				values.push(this.getSelectionValue(index));
			}
		});
		return values;
	}

	getSelectionValue(targetIndex: number) {
		return `Target ${targetIndex}`;
	}

	toggleTarget(
		event: MatOptionSelectionChange,
		targetIndex: number,
		elementIndex: number
	) {
		this.data.elements[elementIndex].targets[targetIndex] =
			event.source.selected;
	}

	createDragDrop() {
		this.uploading = true;
		let failed = false;
		if (!this.data.statement || this.data.statement === "") {
			this.toastService.error("Statement is empty.");
			failed = true;
		}

		if (this.data.targets.length === 0) {
			this.toastService.error(
				"There are no targets for the background image."
			);
			failed = true;
		}

		if (this.data.elements.length === 0) {
			this.toastService.error("There are no drag elements.");
			failed = true;
		}

		const dragDropElements: IDragDropElement[] = [];

		let elementErrors = false;
		for (let [key, value] of this.data.elements.entries()) {
			if (value.name === "") {
				this.toastService.error(`Element ${key} has no name.`);
				elementErrors = true;
			}

			const targets: number[] = [];

			value.targets.map((target, index) => {
				if (target) {
					targets.push(index);
				}
			});

			if (targets.length === 0) {
				this.toastService.error(`Element ${key} has no targets.`);
				elementErrors = true;
			}

			const element = {
				name: value.name,
				targets,
			} as IDragDropElement;

			if (this.data.elementType === "image") {
				element.image = value.image;
				if (value.image === "") {
					this.toastService.error(`Element ${key} has no image.`);
					elementErrors = true;
				}
			}

			dragDropElements.push(element);
		}

		if (elementErrors) {
			this.toastService.error(
				"One or more elements have errors in them."
			);
			failed = true;
		}

		if (failed) {
			this.uploading = false;
			return;
		}

		const fileBlobs: Blob[] = [];
		const fileIds: IUploadImage[] = [];

		if (this.data.elementType === "image") {
			for (let [key, value] of this.elementFiles.entries()) {
				fileBlobs.push(
					new Blob([value.file], {
						type: "image/png",
					})
				);

				fileIds.push({
					id: value.name,
					extension: "png",
				});
			}
		}

		for (let file of this.files) {
			fileBlobs.push(
				new Blob([file.file], {
					type: "image/png",
				})
			);

			fileIds.push({
				id: file.name,
				extension: "png",
			});
		}

		if (fileIds.length > 0) {
			this.uploadService
				.uploadImages(fileBlobs, fileIds)
				.subscribe((res) => {
					if (!res) {
						this.uploading = false;
						this.toastService.error("Failed to upload images");
						return;
					} else {
						this.uploading = false;
						this.toastService.success(
							"Images were uploaded successfully"
						);
						this.dialogRef.close({
							uuid: this.data.uuid,
							backgroundId: this.data.backgroundId,
							elementType: this.data.elementType,
							elements: dragDropElements,
							statement: this.data.statement,
							targets: this.data.targets,
						} as IDragAndDropRow);
					}
				});
		} else {
			this.uploading = false;
			this.dialogRef.close({
				uuid: this.data.uuid,
				backgroundId: this.data.backgroundId,
				elementType: this.data.elementType,
				elements: dragDropElements,
				statement: this.data.statement,
				targets: this.data.targets,
			} as IDragAndDropRow);
		}
	}
}
