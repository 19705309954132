import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { MatDialog } from "@angular/material/dialog";
import { AddQuizQuestionDialog } from "./add-quiz-question/add-quiz-question.component";
import { DeleteConfirmationDialog } from "src/app/shared/components/delete-confirmation/delete-confirmation.component";
import { DIFFICULTY, IQuizQuestionRow } from "src/app/shared/models/lesson-content.model";

import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: "app-quiz-questions",
	templateUrl: "./quiz-questions.component.html",
	styleUrls: ["./quiz-questions.component.scss"],
})
export class QuizQuestionsComponent implements OnInit {

	readonly difficulties = DIFFICULTY;

	questions!: IQuizQuestionRow[];

	@Input() set data(value: IQuizQuestionRow[]) {
		this.questions = value;
		this.length = this.questions.length;
	}

	@Output() newData = new EventEmitter<IQuizQuestionRow[]>();

	pagedSentences!: IQuizQuestionRow[];
	length: number = 0;
	selected: string[] = [];

	cols: number = 0;

	gridByBreakpoint = {
		xl: 5,
		lg: 4,
		md: 3,
		sm: 2,
		xs: 1,
	};

	constructor(
		public dialog: MatDialog,
		private observableMedia: MediaObserver
	) {
		this.cols = 5;
	}

	ngAfterContentInit() {
		this.observableMedia
			.asObservable()
			.subscribe((change: MediaChange[]) => {
				for (let [key, value] of Object.entries(
					this.gridByBreakpoint
				)) {
					if (key === change[0].mqAlias) {
						this.cols = value;
					}
				}
			});
	}

	ngOnInit(): void {}

	remove(index: number) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
			data: index,
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			this.questions.splice(index, 1);
			this.sendData();
		});
	}

	addQuizQuestion() {
		const dialogRef = this.dialog.open(AddQuizQuestionDialog, {
			width: "550px",
			data: {
				uuid: uuidv4(),
				statement: "",
				rightAnswer: "",
				wrongAnswers: [],
				difficulty: 1,
				isGrammar: false,
			} as IQuizQuestionRow,
		});

		dialogRef.afterClosed().subscribe((result: IQuizQuestionRow) => {
			if (!result) {
				return;
			}

			this.questions.push(result);
			this.sendData();
		});
	}

	sendData() {
		this.newData.emit(this.questions);
	}
}

