import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PlacementQuestionsComponent } from "./placement-questions.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { AngularToastifyModule } from "angular-toastify";
import { NgxFileDropModule } from "ngx-file-drop";
import { SharedModule } from "src/app/shared/components/shared.module";
import { CustomPipesModule } from "src/app/shared/pipes/custom-pipes.module";
import { MatPaginatorModule } from "@angular/material/paginator";
import { CreateQuestionDialog } from "./create-question/create-question.component";
import { TextMediaComponent } from "./create-question/text-media/text-media.component";
import { DialogueMediaComponent } from "./create-question/dialogue-media/dialogue-media.component";
import { MultichoicePlacementMediaComponent } from "./create-question/multichoice-placement-media/multichoice-placement-media.component";
import { MatchGameMediaComponent } from "./create-question/match-game-media/match-game-media.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ResizableModule } from "angular-resizable-element";
import { DragDropMediaComponent } from "./create-question/drag-drop-media/drag-drop-media.component";
import { MatStepperModule } from "@angular/material/stepper";
import { ColoringMediaComponent } from "./create-question/coloring-media/coloring-media.component";
import { OrderingLettersMediaComponent } from "./create-question/ordering-letters-media/ordering-letters-media.component";
import { MultichoiceFillthegapsMediaComponent } from "./create-question/multichoice-fillthegaps-media/multichoice-fillthegaps-media.component";
import { FillTheGapsImageMediaComponent } from "./create-question/fill-the-gaps-image-media/fill-the-gaps-image-media.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { SpeakingDialogueMediaComponent } from "./create-question/speaking-dialogue-media/speaking-dialogue-media.component";

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		AngularToastifyModule,
		FormsModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatExpansionModule,
		MatGridListModule,
		MatTabsModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatToolbarModule,
		MatTooltipModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		MatSortModule,
		MatTableModule,
		MatPaginatorModule,
		MatStepperModule,
		ReactiveFormsModule,
		NgxFileDropModule,
		CustomPipesModule,
		ResizableModule,
		DragDropModule,
		SharedModule,
		ClipboardModule,
	],
	declarations: [
		PlacementQuestionsComponent,
		CreateQuestionDialog,
		TextMediaComponent,
		DialogueMediaComponent,
		SpeakingDialogueMediaComponent,
		MultichoicePlacementMediaComponent,
		MatchGameMediaComponent,
		DragDropMediaComponent,
		ColoringMediaComponent,
		OrderingLettersMediaComponent,
		MultichoiceFillthegapsMediaComponent,
		FillTheGapsImageMediaComponent,
	],
})
export class PlacementQuestionsModule {}
