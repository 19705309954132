<div mat-dialog-title color="primary">CREATE QUIZ TEXT</div>
<lib-toastify-toast-container position="bottom-right"></lib-toastify-toast-container>
<div mat-dialog-content class="dialog-content" style="align-items: center">
  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Statement</mat-label>
      <input matInput [formControl]="statementControl" />
      <button *ngIf="statementControl.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="statementControl.reset()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="statementControl.hasError('required')">
        Statement is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Right Answer</mat-label>
      <input matInput [formControl]="rightAnswerControl" />
      <button *ngIf="rightAnswerControl.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="rightAnswerControl.reset()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="rightAnswerControl.hasError('required')">
        Right Answer is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Wrong Answer 1</mat-label>
      <input matInput [formControl]="wrongAnswerControl" />
      <button *ngIf="wrongAnswerControl.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="wrongAnswerControl.reset()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="wrongAnswerControl.hasError('required')">
        Wrong Answer 1 is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Wrong Answer 2</mat-label>
      <input matInput [(ngModel)]="data.wrongAnswers[1]" />
      <button *ngIf="data.wrongAnswers[1]" matSuffix mat-icon-button aria-label="Clear"
        (click)="data.wrongAnswers[1] = ''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Wrong Answer 3</mat-label>
      <input matInput [(ngModel)]="data.wrongAnswers[2]" />
      <button *ngIf="data.wrongAnswers[2]" matSuffix mat-icon-button aria-label="Clear"
        (click)="data.wrongAnswers[2] = ''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Difficulty</mat-label>
      <mat-select [(ngModel)]="data.difficulty">
        <ng-container *ngFor="let difficulty of difficulties">
          <mat-option [value]="difficulty">{{difficulty}}</mat-option>
        </ng-container>
      </mat-select>
      <button *ngIf="data.difficulty" matSuffix mat-icon-button aria-label="Clear"
        (click)="data.difficulty = 1">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Is Grammar?</mat-label>
      <mat-select [formControl]="grammarControl">
        <mat-option [value]="false">No</mat-option>
        <mat-option [value]="true">Yes</mat-option>
      </mat-select>
      <mat-error *ngIf="grammarControl.hasError('required')">
        Grammar is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="outline">
      <mat-label>Text</mat-label>
      <textarea matInput [formControl]="textControl"></textarea>
      <button *ngIf="textControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="textControl.reset()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="textControl.hasError('required')">
        Text is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Discard</button>
  <button mat-button (click)="createQuizText()" cdkFocusInitial>Save</button>
</div>