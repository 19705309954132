import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, of, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { ILessonClassroomData } from "../models/classroom-games.model";
import { IContentGroup } from "../models/content-group.model";
import { ILessonContent, IUpdateLessonContent } from "../models/lesson-content.model";
import { ILesson, IUpdateLesson } from "../models/lesson.model";
import { LessonService } from "./lesson.service";

@Injectable({
	providedIn: 'root',
})
export class ImportService {

	constructor(
		private http: HttpClient,
		private lessonService: LessonService,
	) {}

	public getExportedClassroomGames(lessonId: string) : Observable<ILessonClassroomData | null> {
		return this.http.get<ILessonClassroomData>(`${environment.SERVICES.ALTERNATE_LESSON}classroom-games/${lessonId}`)
		.pipe(
			catchError(error => {
				console.log('EXPORT CLASSROOM GAMES', error);
				return of(null);
			})
		);
	}

	public getExportedContentGroups() : Observable<IContentGroup[]> {
		return this.http.get<IContentGroup[]>(`${environment.SERVICES.ALTERNATE_CLASS_SESSION}api/content-groups`)
		.pipe(
			catchError(error => {
				console.log('EXPORT CONTENT GROUPS', error);
				return of([]);
			})
		)
	}

	public getExportedLessons(ids: string[]): Observable<(ILesson| undefined)[]> {
		return this.http.get<ILesson[]>(`${environment.SERVICES.ALTERNATE_LESSON}lessons`)
		.pipe(
			switchMap((lessons: ILesson[]) => {
				return of(lessons.filter(lesson => ids.includes(lesson.uid)));
			}),
			catchError(error => {
				console.log('EXPORT LESSONS', error);
				return of([]);
			})
		)
	}

	public getExportedContent(id: string): Observable<ILessonContent | null> {
		return this.http.get<ILessonContent>(`${environment.SERVICES.ALTERNATE_LESSON}lessons/${id}/content`)
		.pipe(
			catchError(error => {
				console.log('EXPORT CONTENT', error);
				return of(null);
			})
		)
	}

	public updateImportedLesson(uid: string, info: ILesson, content: ILessonContent) {
		const updateInfo : IUpdateLesson = Object.assign({}, info);
		const updateContent: IUpdateLessonContent = Object.assign({}, content);

		updateContent.version = updateInfo.version;

		delete updateInfo.uid;
		delete updateInfo.version;
		delete updateInfo.isUploading;

		delete updateContent.uploadId;
		delete updateContent.lessonId;

		return this.lessonService.saveLessonInfo(uid, updateInfo)
		.pipe(
			switchMap(() => {
				return this.lessonService.saveLessonContent(uid, updateContent);
			}),
			switchMap(() => {
				return of(true);
			}),
			catchError(error => {
				console.log('IMPORT LESSON', error);
				return of(false);
			})
		)
	}


	public createImportedLesson(lesson: IUpdateLesson, content: IUpdateLessonContent): Observable<string | null> {
		delete lesson.isUploading;

		content.version = lesson.version;

		delete content.uploadId;
		delete content.lessonId;

		return this.http.post<{uid: string}>(`${environment.SERVICES.LESSON}lessons`, lesson)
		.pipe(
			switchMap(res => this.http.post<{uid: string}>(`${environment.SERVICES.LESSON}lessons/${res.uid}/content`, content)),
			switchMap(res => {
				return of(res.uid);
			}),
			catchError(error => {
				console.log('CREATE IMPORTED CONTENT', error);
				return of(null);
			}),
		)
	}

}
