import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreateQuestionDTO, Question, UpdateQuestionDTO } from '../models/placement-question.model';

@Injectable({
	providedIn: 'root'
})
export class PlacementQuestionsService {

	constructor(
		private http: HttpClient,
	) { }

	public createQuestion(question: CreateQuestionDTO): Observable<{ uid: string }> {
		return this.http.post<{ uid: string }>(`${environment.SERVICES.LESSON}placement-question`, question);
	}

	public updateQuestion(question: UpdateQuestionDTO, questionId: string): Observable<boolean> {
		return this.http.put<boolean>(`${environment.SERVICES.LESSON}placement-question/${questionId}`, question);
	}

	public getAllQuestions(): Observable<Question[]> {
		return this.http.get<Question[]>(`${environment.SERVICES.LESSON}placement-question`)
			.pipe(
				switchMap(res => {
					return of(res);
				}),
			);
	}

	public getQuestion(questionId: string): Observable<Question> {
		return this.http.get<Question>(`${environment.SERVICES.LESSON}placement-question/${questionId}`)
			.pipe(
				switchMap(res => {
					return of(res);
				}),
			);
	}

	public deleteQuestion(questionId: string): Observable<void> {
		return this.http.delete<void>(`${environment.SERVICES.LESSON}placement-question/${questionId}`);
	}

	public publishQuestion(questionId: string, publishStatus: boolean): Observable<boolean> {
		return this.http.get<Question>(`${environment.SERVICES.LESSON}placement-question/${questionId}`)
			.pipe(
				switchMap(res => {
					return this.http.patch<boolean>(`${environment.SERVICES.LESSON}placement-question/publish/${questionId}`, { status: publishStatus });
				}),
			);
	}
}
