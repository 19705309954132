import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, of, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { ICreateLessonContent, ILessonContent, IUpdateLessonContent } from "../models/lesson-content.model";
import { IActiveLesson, ICreateLesson, ILesson, IUpdateLesson } from "../models/lesson.model";

@Injectable({
	providedIn: 'root',
})
export class LessonService {

	lessons: ILesson[] = [];

	constructor(
		private http: HttpClient,
	) { }

	public createLessonInfo(lesson: ICreateLesson): Observable<{ uid: string }> {
		return this.http.post<{ uid: string }>(`${environment.SERVICES.LESSON}lessons-draft`, lesson);
	}

	public createLessonContent(uid: string, content: ICreateLessonContent): Observable<{ uid: string }> {
		return this.http.post<{ uid: string }>(`${environment.SERVICES.LESSON}lessons/${uid}/content`, content);
	}

	public removeLessonInfo(uid: string): Observable<void> {
		return this.http.delete<void>(`${environment.SERVICES.LESSON}lessons-draft/${uid}`);
	}

	public removeLessonContent(uid: string): Observable<void> {
		return this.http.delete<void>(`${environment.SERVICES.LESSON}lessons/${uid}/content`);
	}

	public pushLessonsToLive(uids: string[]) {
		return this.http.post<void>(`${environment.SERVICES.LESSON}lessons`, { lessonIds: uids });
	}

	public regenerateLessons(uids: string[]) {
		return this.http.put<void>(`${environment.SERVICES.LESSON}lessons-draft`, { lessonIds: uids })
	}

	public saveLessonInfo(uid: string, info: IUpdateLesson) {
		return this.http.put<void>(`${environment.SERVICES.LESSON}lessons-draft/${uid}`, info);
	}

	public saveLessonContent(uid: string, content: IUpdateLessonContent) {
		return this.http.put<void>(`${environment.SERVICES.LESSON}lessons/${uid}/content`, content);
	}

	public saveLesson(uid: string, info: ILesson, content: ILessonContent) {
		const updateInfo: IUpdateLesson = Object.assign({}, info);

		delete updateInfo.uid;
		delete updateInfo.version;
		delete updateInfo.isUploading;

		const updateContent: IUpdateLessonContent = Object.assign({}, content);

		delete updateContent.uploadId;
		delete updateContent.lessonId;

		return this.saveLessonInfo(uid, updateInfo)
			.pipe(
				switchMap(() => {
					return this.saveLessonContent(uid, updateContent);
				}),
				switchMap(() => {
					return of(true);
				}),
				catchError(error => {
					console.log('SAVE LESSON', error);
					return of(false);
				})
			)
	}

	public publishLesson(uid: string, phase: boolean): Observable<boolean> {
		const updateInfo: IUpdateLesson = {
			releasePhase: phase ? 'Published' : 'Not Published'
		};

		return this.http.put<boolean>(`${environment.SERVICES.LESSON}lessons/${uid}`, updateInfo);
	}

	public getLessons(): Observable<ILesson[]> {
		return this.http.get<ILesson[]>(`${environment.SERVICES.LESSON}lessons`)
			.pipe(
				switchMap(res => {
					this.lessons = res;
					return of(res);
				}),
			);
	}

	public getDraftLessons(): Observable<ILesson[]> {
		return this.http.get<ILesson[]>(`${environment.SERVICES.LESSON}lessons-draft`)
			.pipe(
				switchMap(res => {
					this.lessons = res;
					return of(res);
				}),
			);
	}

	public getLessonContent(id: string): Observable<IActiveLesson | null> {
		return this.http.get<ILessonContent>(`${environment.SERVICES.LESSON}lessons/${id}/content`)
			.pipe(
				switchMap(res => {
					return of(this.setActiveLesson(id, res))
				}),
				catchError(error => {
					console.log('LESSON CONTENT', error);
					return of(null);
				})
			)
	}

	public setActiveLesson(id: string, json: ILessonContent): IActiveLesson | null {
		const lesson = this.lessons.find(lesson => lesson.uid === id);
		if (!lesson) { return null }

		return {
			info: lesson,
			content: json,
		};
	}

}
