import { Component, Inject, OnInit, Optional, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastService } from "angular-toastify";
import { Subscription, switchMap, catchError, of } from "rxjs";
import { VALID_CEFRS } from "src/app/shared/constants";
import { IAccount } from "src/app/shared/models/account.model";
import {
	ImageFiles,
	IUploadImage,
} from "src/app/shared/models/image-upload.model";
import { ILesson } from "src/app/shared/models/lesson.model";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";
import { LessonService } from "src/app/shared/services/lesson.service";
import { AccountStore } from "src/app/shared/services/stores/account.store.service";
import { Owner } from "src/environments/environment.model";
import { AddLessonDialog } from "../../lessons/add-lesson/add-lesson.component";
import {
	CreateQuestionDTO,
	UpdateQuestionDTO,
	VALID_SKILLS,
} from "src/app/shared/models/placement-question.model";
import { PlacementQuestionsService } from "src/app/shared/services/placement-questions.service";
import { TextMediaComponent } from "./text-media/text-media.component";
import { IGameTypes, VALID_GAME_TYPES } from "./game-type";
import { DialogueMediaComponent } from "./dialogue-media/dialogue-media.component";
import { MultichoicePlacementMediaComponent } from "./multichoice-placement-media/multichoice-placement-media.component";
import { MatchGameMediaComponent } from "./match-game-media/match-game-media.component";
import { DragDropMediaComponent } from "./drag-drop-media/drag-drop-media.component";
import { ColoringMediaComponent } from "./coloring-media/coloring-media.component";
import { OrderingLettersMediaComponent } from "./ordering-letters-media/ordering-letters-media.component";
import { MultichoiceFillthegapsMediaComponent } from "./multichoice-fillthegaps-media/multichoice-fillthegaps-media.component";
import { FillTheGapsImageMediaComponent } from "./fill-the-gaps-image-media/fill-the-gaps-image-media.component";
import { SpeakingDialogueMediaComponent } from "./speaking-dialogue-media/speaking-dialogue-media.component";

@Component({
	selector: "app-create-question",
	templateUrl: "./create-question.component.html",
	styleUrls: ["./create-question.component.scss"],
})
export class CreateQuestionDialog implements OnInit {
	@ViewChild(TextMediaComponent) textMediaComponent!: TextMediaComponent;
	@ViewChild(DialogueMediaComponent)
	dialogueMediaComponent!: DialogueMediaComponent;
	@ViewChild(SpeakingDialogueMediaComponent)
	speakingDialogueMediaComponent!: SpeakingDialogueMediaComponent;
	@ViewChild(MultichoicePlacementMediaComponent)
	multichoicePlacementMediaComponent!: MultichoicePlacementMediaComponent;
	@ViewChild(MatchGameMediaComponent)
	matchMediaComponent!: MatchGameMediaComponent;
	@ViewChild(DragDropMediaComponent) dragDropMediaComponent!: DragDropMediaComponent;
	@ViewChild(ColoringMediaComponent) coloringMediaComponent!: ColoringMediaComponent;
	@ViewChild(OrderingLettersMediaComponent) orderingLettersMediaComponent!: OrderingLettersMediaComponent;
	@ViewChild(MultichoiceFillthegapsMediaComponent) multichoiceFillthegapsMediaComponent!: MultichoiceFillthegapsMediaComponent;
	@ViewChild(FillTheGapsImageMediaComponent) fillTheGapsImageMediaComponent!: FillTheGapsImageMediaComponent;

	private addSubscription!: Subscription;

	isUploading = false;
	step = 1;

	public files: ImageFiles[] = [];

	readonly skills = VALID_SKILLS;
	readonly cefrs = VALID_CEFRS;
	readonly gameTypes = VALID_GAME_TYPES;

	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	nameControl = new FormControl(null, Validators.required);
	skillControl = new FormControl(null, Validators.required);
	cefrControl = new FormControl(null, Validators.required);

	data = {
		game: undefined,
		name: "",
		skill: undefined,
		cefr: undefined,
		published: false,
	} as CreateQuestionDTO | UpdateQuestionDTO;
	gameType!: IGameTypes | undefined;
	isUpdate = false;
	isLargeScreen = false;

	constructor(
		public dialogRef: MatDialogRef<AddLessonDialog>,
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public updateData: UpdateQuestionDTO,
		private toastService: ToastService,
		private placementQuestionsService: PlacementQuestionsService,
		private uploadService: ImageUploaderService
	) {
		this.addSubscription = new Subscription();
	}

	ngOnDestroy(): void {
		if (this.addSubscription) {
			this.addSubscription.unsubscribe();
		}
	}

	ngOnInit(): void {
		if (this.updateData) {
			this.isUpdate = true;
			this.data = this.updateData;
			this.gameType = this.gameTypes.find(
				(game) => game.activity === this.data.game?.activity
			) as IGameTypes;
			this.step = 2;
		}

		this.cefrControl.valueChanges.subscribe((cefr) => {
			if (this.gameType?.cefr !== cefr) {
				this.gameType = undefined;
			}
		});
	}

	closeDialog() {
		this.dialogRef.close(false);
	}

	verifyAndUpload() {
		let canUpload = true;
		this.isUploading = true;

		if (this.data.name === "") {
			this.nameControl.markAsTouched();
			canUpload = false;
		}
		if ((this.data.skill as any) === "") {
			this.skillControl.markAsTouched();
			canUpload = false;
		}
		if ((this.data.cefr as any) === "") {
			this.cefrControl.markAsTouched();
			canUpload = false;
		}

		if (!canUpload) {
			this.toastService.error("Failed to save new question.");
			this.isUploading = false;
			return;
		}

		if (this.gameType) {
			if (this.gameType.activity === "text-fill-the-gaps") {
				this.textMediaComponent.uploadQuestion();
			} else if (this.gameType.activity === "ordering-dialogue") {
				this.dialogueMediaComponent.uploadQuestion();
			} else if (this.gameType.activity === "speaking-dialogue") {
				this.speakingDialogueMediaComponent.uploadQuestion();
			} else if (this.gameType.activity === "multichoice-placement") {
				this.multichoicePlacementMediaComponent.uploadQuestion();
			} else if (this.gameType.activity === "bubble-quiz-match") {
				this.matchMediaComponent.uploadQuestion();
			} else if (this.gameType.activity === 'drag-drop-placement') {
				this.dragDropMediaComponent.uploadQuestion();
			} else if (this.gameType.activity === 'coloring-placement') {
				this.coloringMediaComponent.uploadQuestion();
			} else if (this.gameType.activity === 'ordering-letters') {
				this.orderingLettersMediaComponent.uploadQuestion();
			} else if (this.gameType.activity === 'multichoice-fill-the-gaps') {
				this.multichoiceFillthegapsMediaComponent.uploadQuestion();
			} else if (this.gameType.activity === 'fill-the-gaps-image') {
				this.fillTheGapsImageMediaComponent.uploadQuestion();
			} else {
				this.toastService.error("Invalid game type.");
				this.isUploading = false;
			}
		}
	}

	questionUploaded(result: boolean) {
		if (result) {
			this.dialogRef.close(true);
			return;
		}

		this.isUploading = false;
	}

	nextStep() {
		this.data = {
			...this.data,
			skill: this.gameType?.skill,
		};
		this.step = 2;
	}

	backStep() {
		this.step = 1;
	}
}
