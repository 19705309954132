import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastService } from "angular-toastify";
import { catchError, Observable, of, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { IUploadAudio } from "../models/audio-upload.model";

@Injectable({
	providedIn: 'root',
})
export class AudioService {

	constructor(
		private http: HttpClient,
		private toastService: ToastService,
	) { }

	verifyAudioExists(audioLink: string): Observable<boolean> {
		return this.http.get(audioLink, { responseType: 'blob' })
			.pipe(
				switchMap(res => {
					return of(true);
				}),
				catchError(error => {
					console.log('VERIFY AUDIO', error);
					return of(false);
				}),
			)
	}

	uploadPlacementAudio(files: Blob[], uploadAudios: IUploadAudio[]) {
		const formData = new FormData();

		console.log(files);
		console.log(uploadAudios);

		files.forEach((value, index) => {
			const mimeType = uploadAudios[index].extension === 'mp3' ? 'audio/mpeg' : 'audio/unknown';
			console.log(`File ${index}: MIME type is set to ${mimeType}`); // Ensuring MIME type is correct

			const audioBlob = new Blob([value], { type: mimeType });
			formData.append('audioFile', audioBlob, uploadAudios[index].id);
		});

		return this.http.post(`${environment.SERVICES.ASSET_DELIVERY}audios/placement-question`, formData)
			.pipe(
				switchMap(() => of(true)),
				catchError(error => {
					console.error('UPLOAD AUDIOS', error);
					return of(false);
				}),
			);
	}
}
