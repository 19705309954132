import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { MatDialog } from "@angular/material/dialog";
import { DeleteConfirmationDialog } from "src/app/shared/components/delete-confirmation/delete-confirmation.component";
import { EditImageDialog } from "src/app/shared/components/edit-image/edit-image.component";
import { DIFFICULTY, IQuestionsRow } from "src/app/shared/models/lesson-content.model";
import { environment } from "src/environments/environment";
import { AddQuestionDialog } from "./add-question/add-question.component";

import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

	readonly difficulties = DIFFICULTY;

	questions!: IQuestionsRow[];

	@Input() set data(value: IQuestionsRow[]) {
		this.questions = value;
	}

	@Output() newData = new EventEmitter<IQuestionsRow[]>();

	pagedQuestions!: IQuestionsRow[];
	keys: string = '';
	cols: number = 0;
	gridByBreakpoint = {
		xl: 6,
		lg: 5,
		md: 4,
		sm: 2,
		xs: 1,
	};

	constructor(
		public dialog: MatDialog,
		private observableMedia: MediaObserver
	) {
		this.cols = 5;
	}

	ngAfterContentInit() {
		this.observableMedia
			.asObservable()
			.subscribe((change: MediaChange[]) => {
				for (let [key, value] of Object.entries(
					this.gridByBreakpoint
				)) {
					if (key === change[0].mqAlias) {
						this.cols = value;
					}
				}
			});
	}

	ngOnInit(): void {}

	createImage(id: string) {
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.jpg`;
	}

	editImage(index: number, quest: IQuestionsRow) {
		const dialogRef = this.dialog.open(EditImageDialog, {
			width: "350px",
			data: quest.imageId,
		});

		dialogRef.afterClosed().subscribe((result: string) => {
			if (!result) {
				return;
			}
			quest.imageId = result;
			this.questions[index] = quest;
			this.sendData();
		});
	}

	changeKeys(newKeys: string, question: IQuestionsRow) {
		// what will be removed from vector
		let remove = /\s*,\s*/;

		// Create vector of keywords with separator ',' and ignore spaces between the separator
		question.keywords = newKeys.split(remove);
	}

	remove(index: number) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
			data: index,
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			this.questions.splice(index, 1);
			this.sendData();
		});
	}

	copyImageName(index: number) {
		const question = this.questions[index];
		navigator.clipboard.writeText(question.imageId);
	}

	addQuestion() {
		const dialogRef = this.dialog.open(AddQuestionDialog, {
			width: "450px",
			data: {
				uuid: uuidv4(),
				question: '',
				keywords: [
					'',
					'',
					'',
				],
				difficulty: 1,
				minWords: 0,
				imageId: '',
			} as IQuestionsRow,
		});

		dialogRef.afterClosed().subscribe((result: IQuestionsRow) => {
			if (!result) {
				return;
			}

			this.questions.push(result);
			this.sendData();
		});
	}

	sendData() {
		this.newData.emit(this.questions);
	}
}
