import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ILesson } from 'src/app/shared/models/lesson.model';
import { LessonService } from 'src/app/shared/services/lesson.service';
import { IContentTag, Language } from 'src/app/shared/models/content-tags.model'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAccount } from 'src/app/shared/models/account.model';
import { ICreateLessonProgramBody, ILessonProgram } from 'src/app/shared/models/programs.model';
import { ImageFiles, IUploadImage } from 'src/app/shared/models/image-upload.model';
import { AccountStore } from 'src/app/shared/services/stores/account.store.service';
import { ProgramStore } from 'src/app/shared/services/stores/program.store.service';
import { ContentTagStore } from 'src/app/shared/services/stores/content.store.service';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';
import { ToastService } from 'angular-toastify';
import { VALID_AGES, VALID_CEFRS } from 'src/app/shared/constants';

@Component({
	selector: 'app-add-programs',
	templateUrl: './add-programs.component.html',
	styleUrls: ['./add-programs.component.scss']
})
export class AddProgramsDialog implements OnInit, AfterViewInit {

	@ViewChild('lessonpaginator') lessonPaginator !: MatPaginator;

	editingMode: boolean = false;

	informationGroup !: FormGroup;
	availableAges = VALID_AGES;
	availableCefr = VALID_CEFRS;

	public files: ImageFiles[] = [];

	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;
	imageControl !: FormControl;

	availableTags !: IContentTag[];
	tagIds: string[] = [];
	selectedTags: string[] = [];
	tagsLoading = true;
	tagGroup!: FormGroup;

	availableOwners !: IAccount[];
	isPrivateProgram = false
	ownerControl!: FormControl;

	lessonColumns = ['name', 'cefr', 'age', 'caption', 'pick'];
	lessonDataSource !: MatTableDataSource<ILesson>;
	selectedLessons: string[] = [];

	publishStatus!: boolean;

	isRegistering = false;

	constructor(
		private dialogRef: MatDialogRef<AddProgramsDialog>,
		private lessonService: LessonService,
		private tagStore: ContentTagStore,
		private accountStore: AccountStore,
		private programStore: ProgramStore,
		private uploadService: ImageUploaderService,
		private toastService: ToastService,
		@Inject(MAT_DIALOG_DATA) public data: ILessonProgram
	) {
		this.informationGroup = new FormGroup({
			name: new FormControl(data?.name ?? '', [Validators.required]),
			description: new FormControl(data?.description ?? '', [Validators.required]),
			targetCefr: new FormControl(data?.targetCefr ?? '', [Validators.required]),
			targetAgeRange: new FormControl(data?.targetAgeRange ?? '', [Validators.required]),
		});

		this.imageControl = new FormControl(data?.image ?? '', [Validators.required]);

		this.ownerControl = new FormControl('', [Validators.required]);

		this.tagGroup = new FormGroup({
			ptControl: new FormControl('', [Validators.required]),
			enControl: new FormControl('', [Validators.required]),
			esControl: new FormControl('', [Validators.required])
		});

		if (data) {

			if (data?.ownerType !== 'system') {
				this.isPrivateProgram = true;
				this.ownerControl.setValue(data.ownerId);
			}

			if (data?.tags) {
				this.selectedTags = Array.from(new Set(data.tags.map(tag => tag.tagId)));
			}

			if (data?.lessons) {
				this.selectedLessons = data.lessons
			}

			if (data?.image) {
				this.setImage(data.image);
				this.verifyImage();
			}

			if (data?.isPublished) {
				this.publishStatus = data.isPublished;
			}

			this.editingMode = true;
		}

	}

	ngOnInit(): void {
		this.loadLessons();
		this.loadProgramTags();
		this.loadAccounts();
	}

	ngAfterViewInit(): void {

	}

	private loadLessons() {
		this.lessonService.getLessons()
			.subscribe(res => {
				this.lessonDataSource = new MatTableDataSource(res);
				this.lessonDataSource.paginator = this.lessonPaginator;
			});
	}

	private loadAccounts() {
		this.accountStore.listAccounts()
			.subscribe((res) => {
				this.availableOwners = (res as IAccount[]).sort((a, b) => a.displayName.localeCompare(b.displayName));
			})
	}

	private loadProgramTags() {
		this.tagsLoading = true;
		this.tagStore.getProgramTags()
			.subscribe(res => {
				this.availableTags = res as IContentTag[];
				this.availableTags.forEach(tag => {
					if (!this.tagIds.includes(tag.tagId)) {
						this.tagIds.push(tag.tagId);
					}
				})
				this.tagsLoading = false;
			})
	}

	close(res?: { reloading: boolean }) {
		this.dialogRef.close(res);
	}

	setFile(file: ImageFiles | null) {
		if (file === null) {
			this.imageControl.setValue('');
			this.files = [];
			return;
		}
		this.imageControl.setValue(file.name);
		this.files.push(file);
	}

	verifyImage() {
		this.imageVerify = this.imageControl.value;
		this.toVerify = true;
	}

	resetImage() {
		console.log('reseting image')
		this.files = []
		this.imageVerify = '';
		this.toVerify = false;
	}

	setImage(imgId: string) {
		this.imageControl.setValue(imgId);
	}

	privateCheckChanged(event: boolean) {
		if (event === false) {
			this.ownerControl.reset('');
		}
	}

	createTags() {
		const controls = this.tagGroup.value;
		const tagId = `program_${controls.enControl.toLowerCase()}`
		const tags: IContentTag[] = Object.keys(controls)
			.map(key => (
				{
					language: key.slice(0, 2) as Language,
					tagId,
					value: controls[key].charAt(0).toUpperCase() + controls[key].slice(1).toLowerCase(),
					tagType: 'program'
				}
			))
		this.tagGroup.reset({ enControl: '', ptControl: '', esControl: '' })
		this.tagStore.createTags(tags)
	}

	addTag(tagId: string) {
		this.selectedTags.push(tagId);
	}

	removeTag(index: number) {
		this.selectedTags.splice(index, 1);
	}

	getTagValue(tagId: string, language: Language) {
		if (!this.availableTags) {
			return '';
		}
		return this.availableTags.find(tag => tag.tagId === tagId && tag.language === language)?.value ?? '';
	}

	addLesson(lesson: ILesson) {
		this.selectedLessons.push(lesson.uid);
	}

	removeLesson(index: number) {
		this.selectedLessons.splice(index, 1);
	}

	getLessonName(lessonId: string) {
		if (!this.lessonDataSource) {
			return '';
		}
		return this.lessonDataSource.data.find(lesson => lesson.uid === lessonId)?.displayName ?? '';
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.lessonDataSource.filter = filterValue.trim().toLowerCase();

		if (this.lessonDataSource.paginator) {
			this.lessonDataSource.paginator.firstPage();
		}
	}

	drop(event: CdkDragDrop<ILesson[]>) {
		moveItemInArray(this.selectedLessons, event.previousIndex, event.currentIndex);
	}



	registerProgram() {

		const program: ICreateLessonProgramBody = {
			name: this.informationGroup.value.name,
			description: this.informationGroup.value.description,
			targetAgeRange: this.informationGroup.value.targetAgeRange,
			targetCefr: this.informationGroup.value.targetCefr,
			image: this.imageControl.value,
			isPublished: this.publishStatus ?? false,
			ownerType: this.isPrivateProgram ? 'accounts' : 'system',
			ownerId: this.isPrivateProgram ? this.ownerControl.value : 'system',
			tagIds: this.selectedTags,
			tags: this.availableTags.filter(tag => this.selectedTags.includes(tag.tagId)),
			lessons: this.selectedLessons,
		}

		// upload files if necessary
		if (this.files.length > 0) {
			const uploadFileIds = this.files.map(file => {
				return {
					id: file.name,
					extension: 'jpg',
				} as IUploadImage;
			})

			const fileBlobs = this.files.map(fileInfo => {
				const imageBlob = new Blob([fileInfo.file], { type: `image/jpeg` })
				return imageBlob;
			});

			this.isRegistering = true;
			this.uploadService.uploadImages(fileBlobs, uploadFileIds).subscribe(res => {
				if (!res) {
					this.isRegistering=false;
					this.toastService.error('Failed to upload image');
					return;
				} else {
					this.toastService.success('Image was uploaded successfully');
					if (this.data) {
						this.editProgram(this.data.uid, program);
					} else {
						this.addProgram(program);
					}
				}
			});
		}else {
			if (this.data) {
				this.editProgram(this.data.uid, program);
			} else {
				this.addProgram(program);
			}
		}
	}

	editProgram(programId: string, program: ICreateLessonProgramBody) {
		this.programStore.updateProgram(programId, program)
		this.close({ reloading: true })
	}

	addProgram(program: ICreateLessonProgramBody) {
		this.programStore.registerProgram(program)
		this.close({ reloading: true })
	}

}

