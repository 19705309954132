<div mat-dialog-title color="primary">
	Upload History {{data}}
</div>
<lib-toastify-toast-container
	position="bottom-right"
></lib-toastify-toast-container>
<div mat-dialog-content class='dialog-content'>
	<table mat-table [dataSource]="(uploadStatus)" multiTemplateDataRows>
		<ng-container matColumnDef="version">
			<th mat-header-cell *matHeaderCellDef>Version</th>
			<td mat-cell *matCellDef="let row">
				{{row.version}} 
			</td>
		</ng-container>

		<ng-container matColumnDef="creation-time">
			<th mat-header-cell *matHeaderCellDef>Creation</th>
			<td mat-cell *matCellDef="let row">
				{{ row.creationTime }}
			</td>
		</ng-container>

		<ng-container matColumnDef="start-time">
			<th mat-header-cell *matHeaderCellDef>Start</th>
			<td mat-cell *matCellDef="let row">
				{{ row.startTime }}
			</td>
		</ng-container>

		<ng-container matColumnDef="end-time">
			<th mat-header-cell *matHeaderCellDef>End</th>
			<td mat-cell *matCellDef="let row">
				{{ row.endTime}}
			</td>
		</ng-container>

		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef>Status</th>
			<td mat-cell *matCellDef="let row">
				<button mat-flat-button [style]="row.color">{{ row.status }}</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="re-run">
			<th mat-header-cell *matHeaderCellDef>Re-Run</th>
			<td mat-cell *matCellDef="let row; let i=dataIndex" style="align-items: center;">
				<button mat-icon-button color="primary" *ngIf="i === 0" [disabled]="!errorStates.has(row.status)" (click)="forceUploadLesson()">
					<mat-icon>refresh</mat-icon>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let row; let index=dataIndex" [attr.colspan]="columns.length">
				<div class="row-detail" [@detailExpand]="index == expandedElementIndex ? 'expanded' : 'collapsed'">
					<div class="row-content">
						
						<div *ngFor="let step of row.steps" class="content">
							<div class="key">
								<h3>{{step.name}}</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="step.color">{{ step.status }}</button>
							</div>
						</div>

					</div>
				</div>
		</ng-container>

		<tr class="table-header" mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
		<tr mat-row *matRowDef="let elementIndex=dataIndex; columns: columns;"
			class="element-row"
			(click)="expandedElementIndex = expandedElementIndex === elementIndex ? null : elementIndex">
		</tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
	</table>
</div>

<div mat-dialog-actions>
	<button mat-button (click)="closeDialog()">Close</button>
</div>