/* eslint-disable @typescript-eslint/naming-convention */
import {
	HTTP_INTERCEPTORS,
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { from, Observable } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';



@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
	public constructor(
		private readonly auth: AngularFireAuth,
	) { }

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.url.startsWith( 'https://www.googleapis.com/youtube/v3/') || req.url.startsWith('https://storage.googleapis.com') || (environment.production && req.url.startsWith('https://api-staging'))) {
			return next.handle(req);
		}

		return from(this.auth.idToken).pipe(
			first(),
			switchMap(token => {
				if (token) {
					const authReq = req.clone({
						setHeaders: {
							'Authorization': `Bearer ${token}`,
						}
					});
					return next.handle(authReq);
				}

				return next.handle(req);
			}),
		)

	}
}

export const INTERCEPTORS: Provider[] = [
	{ provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
];
