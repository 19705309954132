import { AfterContentInit, Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';

@Component({
	selector: 'app-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements AfterContentInit {

	constructor(
		private readonly router: Router,
	) {
		this.router.navigate(['auth/login']);
	}

	ngAfterContentInit(): void {
	}

}
