import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, combineLatest, Observable, of, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { IClassroomContentData, IClassroomGameData } from "../models/classroom-games.model";
import { IUploadImage } from "../models/image-upload.model";
import { ILessonContent } from "../models/lesson-content.model";
import { ILesson } from "../models/lesson.model";

@Injectable({
	providedIn: 'root',
})
export class ImageUploaderService {

	constructor(
		private http: HttpClient,
	) {
	}

	getImagesToReUpload(lessonContent: ILessonContent, info: ILesson): Observable<boolean> {
		const reUploadImages: IUploadImage[] = [];

		if (info.image) {
			reUploadImages.push({
				id: info.image,
				extension: 'jpg',
			} as IUploadImage);
		}

		if (lessonContent.dialogues) {
			for (let dialogue of lessonContent.dialogues) {
				reUploadImages.push({
					id: dialogue.imageId,
					extension: 'jpg',
				} as IUploadImage)
			}
		}

		if (lessonContent.dragDrop) {
			for (let dragDrop of lessonContent.dragDrop) {
				reUploadImages.push({
					id: dragDrop.backgroundId,
					extension: 'png',
				} as IUploadImage)
				if (dragDrop.elementType === 'image') {
					for (let elements of dragDrop.elements) {
						reUploadImages.push({
							id: elements.image,
							extension: 'png',
						} as IUploadImage)
					}
				}
			}
		}

		if (lessonContent.questions) {
			for (let questions of lessonContent.questions) {
				reUploadImages.push({
					id: questions.imageId,
					extension: 'jpg',
				} as IUploadImage)
			}
		}

		if (lessonContent.quizImage) {
			for (let quizImage of lessonContent.quizImage) {
				reUploadImages.push({
					id: quizImage.imageId,
					extension: 'jpg',
				} as IUploadImage)
			}
		}

		if (lessonContent.sentences) {
			for (let sentences of lessonContent.sentences) {
				reUploadImages.push({
					id: sentences.imageId,
					extension: 'jpg',
				} as IUploadImage)
			}
		}

		if (lessonContent.texts) {
			for (let texts of lessonContent.texts) {
				reUploadImages.push({
					id: texts.imageId,
					extension: 'jpg',
				} as IUploadImage)
			}
		}

		if (lessonContent.vocabulary) {
			for (let vocabulary of lessonContent.vocabulary) {
				reUploadImages.push({
					id: vocabulary.imageId,
					extension: 'jpg',
				} as IUploadImage)
			}
		}

		if (reUploadImages.length > 0) {
			return this.reUploadImages(reUploadImages, environment.BUCKETS.ALTERNATE_CONTENT_BUCKET)
		} else {
			return of(true);
		}
	}

	getClassroomGameImagesToReUpload(contents: IClassroomContentData[], games: IClassroomGameData[]): Observable<boolean> {
		const reUploadImages: IUploadImage[] = [];
		const imageSet: Set<string> = new Set<string>();
		for (let content of contents) {
			for (let card of content.cards) {
				if (!imageSet.has(card.imageId)) {
					reUploadImages.push({
						id: card.imageId,
						extension: 'jpg',
					} as IUploadImage)
					imageSet.add(card.imageId);
				}
			}
		}

		for (let game of games) {
			reUploadImages.push({
				id: game.imageId,
				extension: 'jpg',
			} as IUploadImage)
		}


		if (reUploadImages.length > 0) {
			return this.reUploadImages(reUploadImages, environment.BUCKETS.ALTERNATE_CONTENT_BUCKET)
		} else {
			return of(true);
		}
	}

	reUploadImages(reUploadImages: IUploadImage[], sourceBucket: string): Observable<boolean>{
		let requests = [];
		for (const image of reUploadImages) {
			requests.push(this.http.get(`https://storage.googleapis.com/${sourceBucket}/images/${image.id}.${image.extension}`, { responseType: 'blob' }));
		}

		return combineLatest(requests)
		.pipe(
			switchMap((blobs: Blob[]) => {
				return this.uploadImages(blobs, reUploadImages);
			}),
			catchError(error => {
				console.log('RE UPLOAD IMAGES', error);
				return of(false);
			}),
		)
	}

	uploadImages(files: Blob[], uploadImages: IUploadImage[]): Observable<boolean> {
		const formData = new FormData();

		files.forEach((value, index) => {
			const mymeType = uploadImages[index].extension === 'png' ? 'png' : 'jpeg';
			const imageBlob = new Blob([value], {type: `image/${mymeType}`})
			formData.append('imageFile', imageBlob, uploadImages[index].id);
		})

		return this.http.post(`${environment.SERVICES.ASSET_DELIVERY}images`, formData)
		.pipe(
			switchMap(() => {
				return of(true);
			}),
			catchError(error => {
				console.log('UPLOAD IMAGES', error);
				return of(false);
			}),
		)
	}

	uploadLessonPlanImage(file : Blob, planId: string) {
		const formData = new FormData();
		formData.append('imageFile', file)

		return this.http.post<{url : string}>(`${environment.SERVICES.ASSET_DELIVERY}images/lesson-plan/${planId}`, formData)
	}

	uploadStepImage(file : Blob) {
		const formData = new FormData();
		formData.append('imageFile', file);

		return this.http.post<{url : string}>(`${environment.SERVICES.ASSET_DELIVERY}images/steps`, formData)
	}

	uploadPlacementImage(files: Blob[], uploadImages: IUploadImage[]) {
		const formData = new FormData();

		files.forEach((value, index) => {
			const mymeType = uploadImages[index].extension === 'png' ? 'png' : 'jpeg';
			const imageBlob = new Blob([value], {type: `image/${mymeType}`})
			formData.append('imageFile', imageBlob, uploadImages[index].id);
		})

		return this.http.post(`${environment.SERVICES.ASSET_DELIVERY}images/placement-question`, formData)
		.pipe(
			switchMap(() => {
				return of(true);
			}),
			catchError(error => {
				console.log('UPLOAD IMAGES', error);
				return of(false);
			}),
		)
	}
}
