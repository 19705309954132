export interface INavigationItem {
	href: string;
	title: string;
	icon?: string;
	home?: boolean;
}

export const NAVIGATION_BUTTONS : INavigationItem[] = [
	{
		href: '/navbar/lessons',
		title: 'LESSONS',
	},
	{
		href: '/navbar/programs',
		title: 'PROGRAMS',
	},
	{
		href: '/navbar/activity-description',
		title: 'ACTIVITY DESCRIPTIONS',
	},
	{
		href: '/navbar/steps',
		title: 'STEPS FOR LESSON PLANS',
	},
	{
		href: '/navbar/placement-questions',
		title: 'PLACEMENT QUESTIONS',
	},
]
