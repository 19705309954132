<div class="body">
  <div class="loading" *ngIf="uploadingImg">
    <mat-spinner></mat-spinner>
  </div>
  <div class="selector" *ngIf="!uploadingImg">
    <div class="game-card" *ngFor="let game of games" (click)="selectGame(game)" [ngClass]="{ active: game.name === selectedGame?.name }">
      <img mat-card-image [src]="game.imageUrl">
      <span>{{game.name}}</span>
    </div>
  </div>
  <div class="action-buttons">
    <div class="image-upload">
      <div class="file-upload-wrapper" data-text="Selecione uma imagem">
        <input class="file-upload-field"
           type="file"
           id="file"
           (change)="handleImageInput($event)">
      </div>
    </div>

    <button mat-raised-button color="primary" class="finish-button" [disabled]="selectedGame === undefined" (click)="submitClassroomGameStep()">Finalizar</button>
  </div>
</div>
