import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from 'angular-toastify';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { GenericStepMedia } from 'src/app/shared/models/steps.model';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';

@Component({
	selector: 'app-image-selector',
	templateUrl: './image-selector.component.html',
	styleUrls: ['./image-selector.component.scss']
})
export class ImageSelectorComponent implements OnInit {

	@Output() mediaSubmitted: EventEmitter<GenericStepMedia> = new EventEmitter();

	imageUrl!: string | undefined;
	imageUploading = false;
	files: NgxFileDropEntry[] = [];

	constructor(
		private imageUploadServer: ImageUploaderService,
		private toastrService : ToastService,
	) { }

	ngOnInit(): void {

	}

	dropped(files: NgxFileDropEntry[]) {
		this.files = files;

		const droppedFile = this.files[0];

		if (droppedFile.fileEntry.isFile) {
			this.imageUploading = true;
			const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
			fileEntry.file((file: File) => {
				const imageBlob = new Blob([file], { type: file.type });

				if (!(imageBlob.type === 'image/jpeg' || imageBlob.type === 'image/png')){
					this.toastrService.error('File should be JPEG or PNG image');
					this.imageUploading = false;
					return;
				}

				this.imageUploadServer.uploadStepImage(imageBlob).subscribe(res => {
					this.imageUploading = false;
					this.imageUrl = res.url;
					this.mediaSubmitted.emit({ type: 'image', thumbnailUrl: res.url, content: { url: res.url } });
				})
			})
		} else {
			this.toastrService.error('This is not a file');
		}
	}
}
