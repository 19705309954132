import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AngularToastifyModule, ToastService } from 'angular-toastify';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UnsavedConfirmationDialog } from './confirmation/unsaved-confirmation.component';
import { EditImageDialog } from './edit-image/edit-image.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { WatchVideoDialog } from './watch-video/watch-video.component';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AudioUploaderComponent } from './audio-uploader/audio-uploader.component';


@NgModule({
	declarations: [
		ImageUploaderComponent,
		AudioUploaderComponent,
		EditImageDialog,
		WatchVideoDialog,
		UnsavedConfirmationDialog,
  		HeaderComponent,
	],
	imports: [
		AngularToastifyModule,
		CommonModule,
		MatCheckboxModule,
		MatButtonModule,
		MatCardModule,
		MatDialogModule,
		MatIconModule,
		MatInputModule,
		MatToolbarModule,
		NgxFileDropModule,
		MatProgressSpinnerModule,
		ReactiveFormsModule,
		FormsModule,
	],
	exports: [
		ImageUploaderComponent,
		AudioUploaderComponent,
		EditImageDialog,
		UnsavedConfirmationDialog,
		HeaderComponent,
	],
	providers: [
		ToastService
	],
})

export class SharedModule { }
