<div class="quiz-tab-content">
  <div class="table header">
    <mat-toolbar class="header-base" color="primary">
      <button mat-mini-fab color="white" (click)="addQuizImage()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div class="grid-container">
    <ng-container *ngFor="let img of images; let indexOfelement = index">
      <div class="grid-item">
        <mat-card style="text-align: center">
          <button mat-icon-button matTooltip="Delete" class="delete-button" (click)="remove(indexOfelement)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Copy image name" class="copy-button" (click)="copyImageName(indexOfelement)">
						<mat-icon color="primary">collections</mat-icon>
					</button>
          <div class="image-container" (click)="editImage(indexOfelement, img)">
            <img mat-card-image [src]="createImage(img.imageId)" style="width: 100%" />
            <div class="image-text" style="text-align: center">
              Change Image
            </div>
            <div class="background"></div>
          </div>
          <mat-card-content style="text-align: center">
            <ng-container class="lesson-name-content">
              <mat-form-field appearance="outline">
                <mat-label>Statement</mat-label>
                <input matInput [(ngModel)]="img.statement" />
                <button *ngIf="img.statement" matSuffix mat-icon-button aria-label="Clear" (click)="img.statement = ''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Right Answer</mat-label>
                <input matInput [(ngModel)]="img.rightAnswer" />
                <button *ngIf="img.rightAnswer" matSuffix mat-icon-button aria-label="Clear"
                  (click)="img.rightAnswer = ''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Wrong Answer 1</mat-label>
                <input matInput [(ngModel)]="img.wrongAnswers[0]" />
                <button *ngIf="img.wrongAnswers[0]" matSuffix mat-icon-button aria-label="Clear"
                  (click)="img.wrongAnswers[0] = ''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Wrong Answer 2</mat-label>
                <input matInput [(ngModel)]="img.wrongAnswers[1]" />
                <button *ngIf="img.wrongAnswers[1]" matSuffix mat-icon-button aria-label="Clear"
                  (click)="img.wrongAnswers[1] = ''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Wrong Answer 3</mat-label>
                <input matInput [(ngModel)]="img.wrongAnswers[2]" />
                <button *ngIf="img.wrongAnswers[2]" matSuffix mat-icon-button aria-label="Clear"
                  (click)="img.wrongAnswers[2] = ''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Difficulty</mat-label>
                <mat-select [(ngModel)]="img.difficulty">
                  <ng-container *ngFor="let difficulty of difficulties">
                    <mat-option [value]="difficulty">{{difficulty}}</mat-option>
                  </ng-container>
                </mat-select>
                <button *ngIf="img.difficulty" matSuffix mat-icon-button aria-label="Clear"
                  (click)="img.difficulty = 1">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Is Grammar?</mat-label>
                <mat-select [(ngModel)]="img.isGrammar">
                  <mat-option [value]="false">No</mat-option>
                  <mat-option [value]="true">Yes</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>