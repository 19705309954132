<div class="lesson-view">
	<lib-toastify-toast-container
		position="bottom-right"
	></lib-toastify-toast-container>
	<div class="lesson-header">
		<mat-toolbar class="header" color="primary">
			<button mat-icon-button (click)="returnToLessons()">
				<mat-icon>backspace icon</mat-icon>
			</button>
			<div class='start-header'>
				<h1 class="title">{{lessonId}}</h1>
			</div>
			<div class='end-header'>
				<button [disabled]="saving" class="save-button" mat-raised-button (click)="save()">SAVE</button>
			</div>
		</mat-toolbar>
	</div>

	<div class="lesson-content">
		<mat-tab-group mat-align-tabs="center">

			<mat-tab label="Lesson Info">
				<ng-template matTabContent>
					<app-lesson-info [data]="activeLesson.info"></app-lesson-info>
				</ng-template>
			</mat-tab>

			<!-- <mat-tab label="Lesson Parameters">
				<ng-template matTabContent>
					<app-lesson-parameters [data]="activeLesson.content.lessonParameters" (newData)="activeLesson.content.lessonParameters=$event"></app-lesson-parameters>
				</ng-template>
			</mat-tab> -->

			<mat-tab label="Vocabulary">
				<ng-template matTabContent>
					<app-vocabulary [data]="activeLesson.content.vocabulary" (newData)="activeLesson.content.vocabulary=$event"></app-vocabulary>
				</ng-template>
			</mat-tab>

			<mat-tab label="Sentences">
				<ng-template matTabContent>
					<app-sentences [data]="activeLesson.content.sentences" (newData)="activeLesson.content.sentences=$event"></app-sentences>
				</ng-template>
			</mat-tab>

			<mat-tab label="Texts">
				<ng-template matTabContent>
					<app-texts [data]="activeLesson.content.texts" (newData)="activeLesson.content.texts=$event"></app-texts>
				</ng-template>
			</mat-tab>

			<mat-tab label="Dialogues">
				<ng-template matTabContent>
					<app-dialogue [data]="activeLesson.content.dialogues" (newData)="activeLesson.content.dialogues=$event"></app-dialogue>
				</ng-template>
			</mat-tab>

			<mat-tab label="Questions">
				<ng-template matTabContent>
					<app-questions [data]="activeLesson.content.questions" (newData)="activeLesson.content.questions=$event"></app-questions>
				</ng-template>
			</mat-tab>

			<mat-tab label="Quiz Video">
				<ng-template matTabContent>
					<app-quiz-video [data]="activeLesson.content.quizVideo" (newData)="activeLesson.content.quizVideo=$event"></app-quiz-video>
				</ng-template>
			</mat-tab>

			<mat-tab label="Quiz Questions">
				<ng-template matTabContent>
					<app-quiz-questions [data]="activeLesson.content.quizQuestion" (newData)="activeLesson.content.quizQuestion=$event"></app-quiz-questions>
				</ng-template>
			</mat-tab>

			<mat-tab label="Quiz Text">
				<ng-template matTabContent>
					<app-quiz-text [data]="activeLesson.content.quizText" (newData)="activeLesson.content.quizText=$event"></app-quiz-text>
				</ng-template>
			</mat-tab>

			<mat-tab label="Quiz Image">
				<ng-template matTabContent>
					<app-quiz-image [data]="activeLesson.content.quizImage" (newData)="activeLesson.content.quizImage=$event"></app-quiz-image>
				</ng-template>
			</mat-tab>

			<mat-tab label="Drag Drop">
				<ng-template matTabContent>
					<app-drag-drop [data]="activeLesson.content.dragDrop" (newData)="activeLesson.content.dragDrop=$event"></app-drag-drop>
				</ng-template>
			</mat-tab>

			<mat-tab label="Video Session">
				<ng-template matTabContent>
					<app-video-session [data]="activeLesson.content.videoSession" (newData)="activeLesson.content.videoSession=$event"></app-video-session>
				</ng-template>
			</mat-tab>

		</mat-tab-group>
	</div>
</div>