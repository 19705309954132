import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);
const redirectLoggedInToNavbar = () => redirectLoggedInTo(['navbar']);

const routes: Routes = [
	{
		path: 'navbar',
		loadChildren: () => import('./navbar/navbar.module').then(m => m.NavbarModule),
		canActivate: [ AngularFireAuthGuard ],
		data: { authGuardPipe: redirectUnauthorizedToLogin}
	},
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
		data: { authGuardPipe: redirectLoggedInToNavbar }
	},
	{ path: '', redirectTo: 'navbar', pathMatch: 'full' },
	{ path: '', redirectTo: 'auth', pathMatch: 'full' },
	{ path: '**', redirectTo: 'navbar' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
