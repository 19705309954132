<app-header title="Programs"></app-header>

<div class="page-container">

    <mat-toolbar class="footer" color="primary" style="display: flex; flex-direction: row-reverse;">
        <button [disabled]="loading" mat-mini-fab color="white" (click)="openProgramDialog()">
            <mat-icon>add</mat-icon>
        </button>
        <button class="toolbar-btn" [disabled]="selectedPrograms.length === 0" mat-raised-button (click)="changePublishStatus(true)">PUBLISH</button>
        <button class="toolbar-btn" [disabled]="selectedPrograms.length === 0" mat-raised-button (click)="changePublishStatus(false)">UN-PUBLISH</button>
    </mat-toolbar>

    <div class="table-header">
        <mat-form-field appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput [value]="defaultFilter" (keyup)="applyFilter($event)" placeholder="Ex. program1" #input>
        </mat-form-field>
    </div>

    <table *ngIf="!loading && availableAccounts && availableTags" class="program-table" mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef> SELECT </th>
            <td mat-cell *matCellDef="let program" class="checkbox-container">
                <mat-checkbox class="checkbox" [checked]="isSelected(program)" (change)="select($event, program)"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef style="width: 170px;"> IMAGE </th>
            <td mat-cell *matCellDef="let program" class="img-container">
                <img [src]="createImage(program.image)" class="program-img">
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let program"> {{program.name}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let program"> {{program.description}} </td>
        </ng-container>

        <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef> Owner </th>
            <td mat-cell *matCellDef="let program"> {{program.ownerName}} </td>
        </ng-container>

        <ng-container matColumnDef="lessons">
            <th mat-header-cell *matHeaderCellDef> Lessons </th>
            <td mat-cell *matCellDef="let program"> {{getNumberOfLessons(program)}} </td>
        </ng-container>

        <ng-container matColumnDef="cefr">
            <th mat-header-cell *matHeaderCellDef> Cefr </th>
            <td mat-cell *matCellDef="let program"> {{program.targetCefr}} </td>
        </ng-container>

        <ng-container matColumnDef="age">
            <th mat-header-cell *matHeaderCellDef> Age </th>
            <td mat-cell *matCellDef="let program"> {{program.targetAgeRange}} </td>
        </ng-container>

        <ng-container matColumnDef="tags">
            <th mat-header-cell *matHeaderCellDef> Tags </th>
            <td mat-cell *matCellDef="let program">
                <div *ngFor="let tag of program.tagValues">
                    {{tag}}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="published">
            <th mat-header-cell *matHeaderCellDef> Published </th>
            <td mat-cell *matCellDef="let program"><b> {{getPublishedStatus(program)}} </b></td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let program">
                <button mat-icon-button color="primary" (click)="editProgram(program)">
                    <mat-icon>edit</mat-icon>
                </button> </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let program">
                <button mat-icon-button color="primary" (click)="deleteProgram(program)">
                    <mat-icon>delete</mat-icon>
                </button> </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

    </table>

    <div class="loading" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="defaultPageSize" (page)="paginatorChanged($event)"  showFirstLastButtons></mat-paginator>
</div>