<div mat-dialog-title color="primary">
	Update All
</div>
<lib-toastify-toast-container
	position="bottom-right"
></lib-toastify-toast-container>

<div mat-dialog-content class='dialog-content'>
	<table mat-table [dataSource]="(updateStatus)" multiTemplateDataRows>

        <ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef>Name</th>
			<td mat-cell *matCellDef="let row">
				{{row.info.displayName}} 
			</td>
		</ng-container>

        <ng-container matColumnDef="version">
			<th mat-header-cell *matHeaderCellDef>Version</th>
			<td mat-cell *matCellDef="let row">
				{{row.info.version}} 
			</td>
		</ng-container>

        <ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef>Status</th>
			<td mat-cell *matCellDef="let row; let i=index">
				<button mat-flat-button [style]="getStyle(getStatus(row.uploadStatus | async, i))">{{ getStatus(row.uploadStatus | async, i) }}</button>
			</td>
		</ng-container>

        <ng-container matColumnDef="re-run">
			<th mat-header-cell *matHeaderCellDef>Re-Run</th>
			<td mat-cell *matCellDef="let row; let i=dataIndex" style="align-items: center;">
				<button mat-icon-button color="primary" [disabled]="(!errorStates.has(getStatus(row.uploadStatus | async, i)) || row.reRun) && !row.reqError" (click)="forceUploadLesson(i)">
					<mat-icon>refresh</mat-icon>
				</button>
			</td>
		</ng-container>

        <ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let row; let i=index" [attr.colspan]="columns.length">
				<div class="row-detail" *ngIf="row.uploadStatus !== null" [@detailExpand]="(i == expandedIndex && row.uploadStatus == expandedElement) ? 'expanded' : 'collapsed'">
					<div class="row-content" *ngFor="let uploadStatus of row.uploadStatus | async">
										
						<div class="content">
							<div class="key">
								<h3>Vocabulary</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateVocabulary)">{{ uploadStatus.generateVocabulary }}</button>
							</div>
						</div>
					
						<div class="content">
							<div class="key">
								<h3>Sentences</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateSentences)">{{ uploadStatus.generateSentences }}</button>
							</div>
						</div>
					
						<div class="content">
							<div class="key">
								<h3>Dialogues</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateDialogues)">{{ uploadStatus.generateDialogues }}</button>
							</div>
						</div>
					
						<div class="content">
							<div class="key">
								<h3>Texts</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateTexts)">{{ uploadStatus.generateTexts }}</button>
							</div>
						</div>
					
						<div class="content">
							<div class="key">
								<h3>Quiz Text</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateQuizText)">{{ uploadStatus.generateQuizText }}</button>
							</div>
						</div>
					
						<div class="content">
							<div class="key">
								<h3>Quiz Video</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateQuizVideo)">{{ uploadStatus.generateQuizVideo }}</button>
							</div>
						</div>
					
						<div class="content">
							<div class="key">
								<h3>Quiz Image</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateQuizImage)">{{ uploadStatus.generateQuizImage }}</button>
							</div>
						</div>
					
						<div class="content">
							<div class="key">
								<h3>Quiz Question</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateQuizQuestion)">{{ uploadStatus.generateQuizQuestion }}</button>
							</div>
						</div>
					
						<div class="content">
							<div class="key">
								<h3>Questions</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateQuestions)">{{ uploadStatus.generateQuestions }}</button>
							</div>
						</div>
					
						<div class="content">
							<div class="key">
								<h3>Drag Drop</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateDragDrop)">{{ uploadStatus.generateDragDrop }}</button>
							</div>
						</div>
					
						<div class="content">
							<div class="key">
								<h3>Jenga</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateJenga)">{{ uploadStatus.generateJenga }}</button>
							</div>
						</div>
					
						<div class="content">
							<div class="key">
								<h3>Fill The Gaps</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateFillTheGaps)">{{ uploadStatus.generateFillTheGaps }}</button>
							</div>
						</div>
					
						<div class="content">
							<div class="key">
								<h3>Multichoice Fill The Gaps</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateFillMultichoice)">{{ uploadStatus.generateFillMultichoice }}</button>
							</div>
						</div>
					
						<div class="content">
							<div class="key">
								<h3>Ordering Dialogues</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateOrderingDialogues)">{{ uploadStatus.generateOrderingDialogues }}</button>
							</div>
						</div>

						<div class="content">
							<div class="key">
								<h3>Video Session</h3>
							</div>
							<div class="value">
								<button mat-flat-button [style]="getStyle(uploadStatus.generateVideoSession)">{{ uploadStatus.generateVideoSession }}</button>
							</div>
						</div>
					</div>
				</div>
		</ng-container>

        <tr class="table-header" mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
		<tr mat-row *matRowDef="let element; columns: columns; let indexOfelement = index"
			class="element-row"
			[class.example-expanded-row]="expandedElement === element"
			(click)="expandedElement = expandedElement === element.uploadStatus ? null : element.uploadStatus; expandedIndex=indexOfelement; ">
		</tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
</div>

<div mat-dialog-actions>
	<button mat-button (click)="closeDialog()">Close</button>
	<button mat-button matTooltip="If previous lesson is bugged, this will start the next lesson." (click)="forceResume()">FORCE NEXT</button>
</div>