import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import {
	AnyGame,
	CreateQuestionDTO,
	IDialogueGame,
	UpdateQuestionDTO,
} from "src/app/shared/models/placement-question.model";
import { PlacementQuestionsService } from "src/app/shared/services/placement-questions.service";
import { CreateQuestionDialog } from "../create-question.component";
import { MatDialogRef } from "@angular/material/dialog";
import {
	IUploadImage,
	ImageFiles,
} from "src/app/shared/models/image-upload.model";
import { ToastService } from "angular-toastify";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";
import { PROFILE_PHOTOS_REF } from "./profile-photos";

@Component({
	selector: "app-dialogue-media",
	templateUrl: "./dialogue-media.component.html",
	styleUrls: ["./dialogue-media.component.scss"],
})
export class DialogueMediaComponent implements OnInit, OnDestroy {
	@Input() data!: CreateQuestionDTO | UpdateQuestionDTO;
	@Input() isUpdate!: boolean;

	@Output() questionUploaded = new EventEmitter<boolean>();

	private addSubscription!: Subscription;

	public files: ImageFiles[] = [];

	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	titleControl = new FormControl(null, Validators.required);
	profilePhotoAControl = new FormControl(null, Validators.required);
	profilePhotoBControl = new FormControl(null, Validators.required);
	profilePhotosRef = PROFILE_PHOTOS_REF;
	dialogueForm!: FormGroup;

	linesQtd = 1;

	constructor(
		public dialogRef: MatDialogRef<CreateQuestionDialog>,
		private placementQuestionsService: PlacementQuestionsService,
		private toastService: ToastService,
		private uploadService: ImageUploaderService,
		private fb: FormBuilder
	) {
		this.addSubscription = new Subscription();

		this.dialogueForm = this.fb.group({
			lines: this.fb.array([]),
		});
		this.addLine();
	}

	ngOnInit(): void {
		if (this.data.game && this.isDialogueGame(this.data.game.content)) {
			const content = this.data.game.content as IDialogueGame;
			this.titleControl.setValue(content.title);
			this.profilePhotoAControl.setValue(
				this.profilePhotosRef.find(
					(profile) => profile.name === content.charA.name
				) ?? null
			);
			this.profilePhotoBControl.setValue(
				this.profilePhotosRef.find(
					(profile) => profile.name === content.charB.name
				) ?? null
			);

			this.lines.clear();
			content.lines.forEach((line) => {
				this.lines.push(
					this.fb.group({
						lineA: new FormControl(line.lineA, Validators.required),
						lineB: new FormControl(line.lineB, Validators.required),
					})
				);
			});
		}
	}

	ngOnDestroy(): void {
		if (this.addSubscription) {
			this.addSubscription.unsubscribe();
		}
	}

	isDialogueGame(game: AnyGame): game is IDialogueGame {
		const hasBasicProps =
			typeof (game as IDialogueGame).title === "string" &&
			Array.isArray((game as IDialogueGame).lines) &&
			(game as IDialogueGame).charA &&
			(game as IDialogueGame).charB &&
			typeof (game as IDialogueGame).charA.imageId === "string" &&
			typeof (game as IDialogueGame).charA.name === "string" &&
			typeof (game as IDialogueGame).charB.imageId === "string" &&
			typeof (game as IDialogueGame).charB.name === "string";

		if (hasBasicProps) {
			return (game as IDialogueGame).lines.every(
				(line) =>
					typeof line.lineA === "string" &&
					typeof line.lineB === "string"
			);
		}

		return false;
	}

	uploadQuestion() {
		let canUpload = true;

		if (!this.titleControl || !this.titleControl.value) {
			this.titleControl.markAsTouched();
			canUpload = false;
		}

		const charA = this.profilePhotoAControl.value;
		const charB = this.profilePhotoBControl.value;

		if (
			charA === null ||
			charA.ref.trim() === "" ||
			charA.name.trim() === ""
		) {
			this.profilePhotoAControl.markAsTouched();
			canUpload = false;
		}

		if (
			charB === null ||
			charB.ref.trim() === "" ||
			charB.name.trim() === ""
		) {
			this.profilePhotoBControl.markAsTouched();
			canUpload = false;
		}

		if (this.lines.controls.length <= 0) {
			this.toastService.error("No lines have been created.");
			canUpload = false;
		}

		this.lines.controls.forEach((line) => {
			const lineA = line.get("lineA");
			const lineB = line.get("lineB");

			if (!lineA || !lineA.value || lineA.value.trim() === "") {
				lineA?.markAsTouched();
				canUpload = false;
			}

			if (!lineB || !lineB.value || lineB.value.trim() === "") {
				lineB?.markAsTouched();
				canUpload = false;
			}
		});

		if (!canUpload) {
			this.toastService.error(
				"There are one or more errors in upload form. Please fix them and try again."
			);
			this.questionUploaded.emit(false);
			return;
		}

		this.data = {
			...this.data,
			game: {
				activity: "ordering-dialogue",
				content: {
					title: this.titleControl.value,
					charA: {
						imageId: this.profilePhotoAControl.value.ref,
						name: this.profilePhotoAControl.value.name,
					},
					charB: {
						imageId: this.profilePhotoBControl.value.ref,
						name: this.profilePhotoBControl.value.name,
					},
					lines: this.lines.value,
				} as IDialogueGame,
			},
		};

		if (!this.isUpdate) {
			this.createQuestion(this.data as CreateQuestionDTO);
		} else {
			this.updateQuestion(this.data as UpdateQuestionDTO);
		}
	}

	createQuestion(createData: CreateQuestionDTO) {
		this.addSubscription = this.placementQuestionsService
			.createQuestion(createData)
			.pipe()
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to create question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to create question.");
					this.questionUploaded.emit(false);
				},
			});
	}

	updateQuestion(updateData: UpdateQuestionDTO) {
		this.addSubscription = this.placementQuestionsService
			.updateQuestion(updateData, updateData.questionId)
			.pipe()
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to update question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to update question.");
					this.questionUploaded.emit(false);
				},
			});
	}

	get lines(): FormArray {
		return this.dialogueForm.get("lines") as FormArray;
	}

	createDialogueLine(): FormGroup {
		return this.fb.group({
			lineA: new FormControl(null, Validators.required),
			lineB: new FormControl(null, Validators.required),
		});
	}

	addLine(): void {
		this.lines.push(this.createDialogueLine());
	}

	removeLine(index: number): void {
		this.lines.removeAt(index);
	}
}
