import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IActiveLesson } from 'src/app/shared/models/lesson.model';
import { ToastService } from 'angular-toastify';
import { LessonService } from 'src/app/shared/services/lesson.service';
import { Subscription } from 'rxjs';
import { UnsavedConfirmationDialog } from 'src/app/shared/components/confirmation/unsaved-confirmation.component';

@Component({
	selector: 'app-lesson',
	templateUrl: './lesson.component.html',
	styleUrls: ['./lesson.component.scss']
})
export class LessonComponent implements OnInit, OnDestroy {
	lessonId: string = '';

	activeLesson!: IActiveLesson;

	saving: boolean = false;

	subscription!: Subscription;

	constructor(
		public dialog: MatDialog,
		private router: Router,
		private route: ActivatedRoute,
		private lessonService: LessonService,
		private toastService: ToastService,
	) {}

	ngOnInit(): void {
		this.route.data.subscribe((data) => {
			if (data.lessonData === null) {
				this.router.navigate(['/navbar/lessons']);
			} else {
				this.activeLesson = data.lessonData
				this.lessonId = this.activeLesson.info.uid;
			}
		})
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	save() {
		if (this.saving) {
			return;
		}
		this.saving = true;
		this.subscription = this.lessonService.saveLesson(this.lessonId, this.activeLesson.info, this.activeLesson.content)
		.subscribe(res => {
			if (!res) {
				this.toastService.error(`Failed to save lesson ${this.lessonId}`);
				this.saving = false;
				return;
			} else {
				this.toastService.success(`Lesson saved successfully`);
				this.router.navigate(['/navbar/lessons']);
			}
		})
	}

	returnToLessons() {
		const dialogRef = this.dialog.open(UnsavedConfirmationDialog, {
			width: "350px",
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}
			this.router.navigate(['navbar/lessons']);
		});
	}

}
