import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, of, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import {
	IClassroomContent,
	IClassroomContentData,
	IClassroomGame,
	IClassroomGameData,
	ICreateClassroomGameResult,
	IGetLessonClassroomResult,
	ILessonClassroomData,
} from "../models/classroom-games.model";

@Injectable({
	providedIn: 'root',
})
export class ClassroomGamesService {

	constructor(
		private http: HttpClient,
	) { }

	public getLessonClassroomGames(lessonId: string, isDraft: boolean = false): Observable<IGetLessonClassroomResult | null> {
		return this.http.get<ILessonClassroomData>(`${environment.SERVICES.LESSON}classroom-games/${lessonId}`, {
			params: {
				isDraft
			}
		})
		.pipe(
			switchMap(res => {
				return of({
					lessonId,
					classroomData: res,
				} as IGetLessonClassroomResult)
			}),
			catchError(error => {
				console.log('CLASSROOM GAMES', error);
				return of(null);
			}),
		);
	}

	public createClassroomContent(lessonId: string, content: IClassroomContent): Observable<{uid: string}> {
		return this.http.post<{ uid: string }>(`${environment.SERVICES.LESSON}classroom-games/${lessonId}/content`, content);
	}

	public createClassroomGame(lessonId: string, game: IClassroomGame) {
		return this.http.post<{ uid: string }>(`${environment.SERVICES.LESSON}classroom-games/${lessonId}/games`, game);
	}

	public createLessonClassroomGame(lessonId: string, game: IClassroomGame, content: IClassroomContent): Observable<ICreateClassroomGameResult | null> {
		return this.createClassroomContent(lessonId, content)
		.pipe(
			switchMap(contentRes => {
				game.content = contentRes.uid;

				return this.createClassroomGame(lessonId, game);
			}),
			switchMap(gameRes => {
				const result: ICreateClassroomGameResult = {
					game: game as IClassroomGameData,
					content: content as IClassroomContentData,
				};

				result.game.id = gameRes.uid;
				result.content.id = game.content;

				return of(result);
			}),
			catchError(error => {
				console.log('CREATE CLASSROOM GAME', error);
				return of(null);
			}),
		)
	}

	public updateClassroomContent(lessonId: string, contentId: string, content: IClassroomContent): Observable<void> {
		return this.http.put<void>(`${environment.SERVICES.LESSON}classroom-games/${lessonId}/content/${contentId}`, content)
	}

	public updateClassroomGame(lessonId: string, gameId: string, game: IClassroomGame): Observable<void> {
		return this.http.put<void>(`${environment.SERVICES.LESSON}classroom-games/${lessonId}/games/${gameId}`, game);
	}

	public updateLessonClassroomGame(lessonId: string, gameId: string, game: IClassroomGame, contentId: string, content: IClassroomContent): Observable<boolean> {
		return this.updateClassroomContent(lessonId, contentId, content)
		.pipe(
			switchMap(() => {
				return this.updateClassroomGame(lessonId, gameId, game);
			}),
			switchMap(() => {
				return of(true);
			}),
			catchError(error => {
				return of(false);
			}),
		)
	}

	public deleteClassroomGame(lessonId: string, gameId: string): Observable<boolean> {
		return this.http.delete<void>(`${environment.SERVICES.LESSON}classroom-games/${lessonId}/games/${gameId}`)
		.pipe(
			switchMap(() => {
				return of(true);
			}),
			catchError(error => {
				console.log('DELETE CLASSROOM GAME', error);
				return of(false);

			}),
		)
	}
}
