import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaChange, MediaObserver,  } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { AddQuizImageDialog } from './add-quiz-image/add-quiz-image.component';
import { EditImageDialog } from 'src/app/shared/components/edit-image/edit-image.component';
import { environment } from 'src/environments/environment';
import { DeleteConfirmationDialog } from 'src/app/shared/components/delete-confirmation/delete-confirmation.component';
import { DIFFICULTY, IImageQuizRow } from 'src/app/shared/models/lesson-content.model';

import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-quiz-image',
  templateUrl: './quiz-image.component.html',
  styleUrls: ['./quiz-image.component.scss']
})
export class QuizImageComponent implements OnInit {

	readonly difficulties = DIFFICULTY;

	images!: IImageQuizRow[];

	@Input() set data(value: IImageQuizRow[]) {
		this.images = value;
	}

	@Output() newData = new EventEmitter<IImageQuizRow[]>();

	pagedSentences!: IImageQuizRow[];
	cols: number = 0;
	gridByBreakpoint = {
		xl: 5,
		lg: 4,
		md: 3,
		sm: 2,
		xs: 1,
	}

	constructor(
		public dialog: MatDialog,
		private observableMedia: MediaObserver,
	) {
		this.cols = 5;
	}

	ngAfterContentInit() {
		this.observableMedia.asObservable().subscribe((change : MediaChange[]) => {
			for (let [key, value] of Object.entries(this.gridByBreakpoint)) {
				if (key === change[0].mqAlias) {
					this.cols = value;
				}
			}
		});
	}

	ngOnInit(): void {

	}

	addQuizImage() {
		const dialogRef = this.dialog.open(AddQuizImageDialog, {
			width: '550px',
			data: {
				uuid: uuidv4(),
				statement: '',
				rightAnswer: '',
				wrongAnswers: [],
				difficulty: 1,
				isGrammar: false,
				imageId: '',
			} as IImageQuizRow,
		})


		dialogRef.afterClosed().subscribe((result: IImageQuizRow) => {
			if (!result) {
				return;
			}

			this.images.push(result);
			this.sendData();
		})
	}

	createImage(id: string) {
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.jpg`;
	}

	editImage(index: number, sent: IImageQuizRow) {
		const dialogRef = this.dialog.open(EditImageDialog, {
			width: '350px',
			data: sent.imageId,
		});

		dialogRef.afterClosed().subscribe((result: string) => {
			if (!result) {
				return;
			}
			sent.imageId = result;
			this.images[index] = sent;
			this.sendData();
		});
	}

	remove(index: number) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
			data: index,
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			this.images.splice(index, 1);
			this.sendData();
		});
	}

	copyImageName(index: number) {
		const img = this.images[index];
		navigator.clipboard.writeText(img.imageId);
	}

	sendData() {
		this.newData.emit(this.images);
	}
}
