<div class="vocabulary-tab-content">
	<div class="table header">
		<mat-toolbar class="header-base" color="primary">
			<button mat-mini-fab color="white" (click)="addVocabulary()">
				<mat-icon>add</mat-icon>
			</button>
		</mat-toolbar>
	</div>

	<div class="grid-container">
		<ng-container *ngFor="let vocab of vocabulary; let indexOfelement=index;">
			<div class="grid-item">
				<mat-card style="text-align: center;">
					<button mat-icon-button matTooltip="Delete" class="delete-button" (click)="remove(indexOfelement)">
						<mat-icon color="warn">delete</mat-icon>
					</button>
					<button mat-icon-button matTooltip="Copy image name" class="copy-button" (click)="copyImageName(indexOfelement)">
						<mat-icon color="primary">collections</mat-icon>
					</button>
					<div class="image-container" (click)="editImage(indexOfelement, vocab)">
						<img mat-card-image [src]="createImage(vocab.imageId)" style="width: 100%;">
						<div class="image-text" style="text-align: center;">Change Image</div>
						<div class="background"></div>
					</div>
					<mat-card-content>
						<ng-container class="vocab-text-content">
							<mat-form-field appearance="outline">
								<mat-label>Text</mat-label>
								<input matInput [(ngModel)]="vocab.text">
								<button *ngIf="vocab.text" matSuffix mat-icon-button aria-label="Clear" (click)="vocab.text=''">
									<mat-icon>close</mat-icon>
								</button>
							</mat-form-field>
						</ng-container>
					</mat-card-content>
				</mat-card>
			</div>
		</ng-container>
	</div>

</div>
