import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastService } from "angular-toastify";
import { ImageFiles, IUploadImage } from "src/app/shared/models/image-upload.model";
import { DIFFICULTY, IImageQuizRow } from "src/app/shared/models/lesson-content.model";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";

@Component({
	selector: "app-add-quiz-image",
	templateUrl: "./add-quiz-image.component.html",
	styleUrls: ["./add-quiz-image.component.scss"],
})
export class AddQuizImageDialog implements OnInit {
	readonly difficulties = DIFFICULTY;

	public files: ImageFiles[] = [];

	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	statementControl = new FormControl(this.data.statement, Validators.required);
	rightAnswerControl = new FormControl(this.data.rightAnswer, Validators.required);
	wrongAnswerControl = new FormControl(this.data.wrongAnswers[0], Validators.required);
	grammarControl = new FormControl(this.data.isGrammar, Validators.required);

	constructor(
		public dialogRef: MatDialogRef<AddQuizImageDialog>,
		@Inject(MAT_DIALOG_DATA) public data: IImageQuizRow,
		private toastService: ToastService,
		private uploadService: ImageUploaderService,
	) {}

	ngOnInit(): void {}

	closeDialog() {
		this.dialogRef.close();
	}

	changeImage(newValue: string) {
		this.data.imageId = newValue;
		this.toVerify = false;
		this.files = [];
		this.imageExists = false;
	}

	verifyImage() {
		this.imageVerify = this.data.imageId;
		this.toVerify = true;
	}

	setFile(file: ImageFiles | null) {
		if (file === null) {
			this.data.imageId = "";
			this.files = [];
			return;
		}
		this.data.imageId = file.name;
		this.files.push(file);
	}

	createQuizImage() {
		let canUpload = true;

		this.data.statement = this.statementControl.value;
		this.data.rightAnswer = this.rightAnswerControl.value;
		this.data.isGrammar = this.grammarControl.value;

		if (this.data.statement === "") {
			this.statementControl.markAsTouched();
			canUpload = false;
		}

		if (this.data.rightAnswer === "") {
			this.rightAnswerControl.markAsTouched();
			canUpload = false;
		}

		if (this.wrongAnswerControl.value === "") {
			this.wrongAnswerControl.markAsTouched();
			canUpload = false;
		} else {
			this.data.wrongAnswers[0] = this.wrongAnswerControl.value;
		}

		if (!this.imageExists && this.files.length === 0) {
			this.toastService.error("No image has been selected.");
			return;
		}

		if (!canUpload) {
			this.toastService.error("Failed to save new quiz image.");
			return;
		}

		if (this.files.length > 0) {
			const uploadFileIds = this.files.map(file => {
				return {
					id: file.name,
					extension: 'jpg',
				} as IUploadImage;
			})

			const fileBlobs = this.files.map(fileInfo => {
				const imageBlob = new Blob([fileInfo.file], {type: `image/jpeg`})
				return imageBlob;
			});

			this.uploadService.uploadImages(fileBlobs, uploadFileIds).subscribe(res => {
				if (!res) {
					this.toastService.error('Failed to upload image');
					return;
				} else {
					this.toastService.success('Image was uploaded successfully');
					this.toastService.success('Image quiz added.')
					this.dialogRef.close(this.data);
				}
			});
		} else {
			this.toastService.success("Image quiz added.");
			this.dialogRef.close(this.data);
		}
	}
}
