<div class="component-body">
    <div class="lesson-select-container">
        <div class="lesson-table-container">

            <mat-form-field appearance="standard">
                <mat-label>Filter</mat-label>
                <input matInput [ngModel]="globalfilter" (ngModelChange)="applyFilter($event)" placeholder="Ex. Pleased to meet you" #input>
            </mat-form-field>

            <table mat-table [dataSource]="lessonDataSource">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let lesson"> {{lesson.displayName}} </td>
                </ng-container>

                <ng-container matColumnDef="cefr">
                    <th mat-header-cell *matHeaderCellDef> Cefr </th>
                    <td mat-cell *matCellDef="let lesson"> {{lesson.cefr}} </td>
                </ng-container>

                <ng-container matColumnDef="age">
                    <th mat-header-cell *matHeaderCellDef> Age </th>
                    <td mat-cell *matCellDef="let lesson"> {{lesson.age}} </td>
                </ng-container>

                <ng-container matColumnDef="caption">
                    <th mat-header-cell *matHeaderCellDef> Caption </th>
                    <td mat-cell *matCellDef="let lesson"> {{lesson.caption}} </td>
                </ng-container>

                <ng-container matColumnDef="pick">
                    <th mat-header-cell *matHeaderCellDef> Pick </th>
                    <td mat-cell *matCellDef="let lesson">
                        <button mat-mini-fab color="primary" (click)="addLesson(lesson)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="lessonColumns"></tr>
                <tr mat-row *matRowDef="let myRowData; columns: lessonColumns"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons #lessonpaginator>
            </mat-paginator>
        </div>

        <div class="lesson-list-container">
            <div class="list">
                <div class="list-item" *ngFor="let lesson of selectedLessons;let lessonIndex = index">
                    <div class="item-index">{{lessonIndex + 1}}</div>
                    <div class="item-text">{{lesson.displayName}}</div>
                    <div class="item-remove">
                        <button mat-icon-button color="primary" (click)="removeLesson(lessonIndex)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <button mat-raised-button color="primary" class="confirm-button" (click)="confirmSelected()">Confirmar</button>
        </div>
    </div>
</div>