import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastService } from 'angular-toastify';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { environment } from 'src/environments/environment';
import { ImageFiles } from '../../models/image-upload.model';
import { ImageService } from '../../services/image.service';

@Component({
	selector: 'app-image-uploader',
	templateUrl: './image-uploader.component.html',
	styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {

	public filesPath: { [key: string]: string } = {};
	public files: File[] = [];

	_imageId!: string;
	_extension: string = 'jpg';
	_isPlacement: boolean = false;

	loading: boolean = true;
	upload: boolean = false;
	useRemote: boolean = false;
	remoteImage!: string;
	fileExists: boolean = false;

	@Input() set extension(value: string) {
		this._extension = value;
	}

	@Input() set isPlacement(value: boolean) {
		this._isPlacement = value === undefined ? false : value;
	}

	@Input() set imageId(value: string) {
		this._imageId = value.toLowerCase()
			.trim()
			.replace(/ +/g, "_");

		if (!this._imageId.startsWith('image_')) {
			this._imageId = `image_${this._imageId}`;
		}
	}

	get imageId(): string {
		return this._imageId;
	}

	@Output() uploadFiles = new EventEmitter<ImageFiles | null>();
	@Output() exists = new EventEmitter<string>();

	constructor(
		private readonly toastService: ToastService,
		private readonly imageService: ImageService,
	) { }

	ngOnInit(): void {
		this.processInputs();
	}

	createImage(id: string) {
		if (this._isPlacement) {
			return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images-placement/${id}.${this._extension}`
		} else {
			return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.${this._extension}`
		};
	}

	onNoButtonClick() {
		this.files = [];
		this.upload = true;
		this.fileExists = false;
	}

	onYesButtonClick() {
		this.fileExists = false;
		this.useRemote = true;
		this.remoteImage = this.createImage(this._imageId);
		this.exists.emit(this._imageId);
	}

	dropped(files: NgxFileDropEntry[]) {
		for (const droppedFile of files) {
			if (droppedFile.fileEntry.isFile && this.isFileAllowed(droppedFile.fileEntry.name)) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {
					this.files.push(file);
					const reader = new FileReader();
					reader.onload = () => {
						let img = new Image();

						img.onload = () => {
							// TODO Verify base width and height
						}

						this.filesPath[file.name] = reader.result as string;
						img.src = reader.result as string;
						let name = file.name.split('.')[0];
						if (!name.startsWith('image_')) {
							name = `image_${name}`;
						} else {
							name = name;
						}
						name = name.toLowerCase()
							.trim()
							.replace(/ +/g, "_");
						this.uploadFiles.emit({
							name,
							path: reader.result,
							file,
						} as ImageFiles);
					}
					if (file) {
						reader.readAsDataURL(file);
					}
				})
			} else {
				this.toastService.error("Only files in '.jpg', '.jpeg', '.png' are allowed.")
			}
		}
	}

	isFileAllowed(fileName: string) {
		let isFileAllowed = false;
		const allowedFiles = ['.jpg', '.jpeg', '.png'];
		const regex = /(?:\.([^.]+))?$/;
		const extension = regex.exec(fileName);
		if (undefined !== extension && null !== extension) {
			for (const ext of allowedFiles) {
				if (ext === extension[0]) {
					isFileAllowed = true;
				}
			}
		}
		return isFileAllowed;
	}

	removeFile(fileName: string) {
		let index = this.files.findIndex(file => file.name == fileName);
		this.files.splice(index);
		delete this.filesPath[fileName];
		this.uploadFiles.emit(null);
	}

	private processInputs(): void {
		// Certifica-se de que ambos os inputs foram definidos
		if (this._imageId !== undefined && this._isPlacement !== undefined) {
			this.loading = true;
			this.upload = false;
			this.useRemote = false;
			this.fileExists = false;

			this.imageService.verifyImageExists(this.createImage(this._imageId)).subscribe(data => {
				this.fileExists = data;
				this.upload = !data;
				this.loading = false;
			});
		}
	}

}
