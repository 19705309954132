import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericStepMedia } from 'src/app/shared/models/steps.model';

@Component({
	selector: 'app-media-selector',
	templateUrl: './media-selector.component.html',
	styleUrls: ['./media-selector.component.scss']
})
export class MediaSelectorComponent implements OnInit {

	constructor(
		private dialogRef: MatDialogRef<MediaSelectorComponent>,
		@Inject(MAT_DIALOG_DATA) public data?: GenericStepMedia,
	) {
	}

	ngOnInit(): void {
	}

	close(media?: GenericStepMedia) {
		this.dialogRef.close(media);
	}

}
