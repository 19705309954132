import { Cefr, Skill } from "src/app/shared/models/placement-question.model";

export const VALID_GAME_TYPES: IGameTypes[] = [
	{
		name: 'Fill The Gaps Text',
		activity: 'text-fill-the-gaps',
		cefr: 'A2',
		skill: 'Reading',
	},
	{
		name: 'Ordering Dialogue',
		activity: 'ordering-dialogue',
		cefr: 'A2',
		skill: 'Reading',
	},
	{
		name: 'Speaking Dialogue',
		activity: 'speaking-dialogue',
		cefr: 'A2',
		skill: 'Speaking',
	},
	{
		name: 'Drag and Drop Placement',
		activity: 'drag-drop-placement',
		cefr: 'A2',
		skill: 'Listening',
	},
	{
		name: 'Multichoice Placement',
		activity: 'multichoice-placement',
		cefr: 'A2',
		skill: 'Listening',
	},
	{
		name: 'Match Game',
		activity: 'bubble-quiz-match',
		cefr: 'A2',
		skill: 'Listening',
	},
	{
		name: 'Coloring Placement',
		activity: 'coloring-placement',
		cefr: 'A2',
		skill: 'Listening',
	},
	{
		name: 'Ordering Letters',
		activity: 'ordering-letters',
		cefr: 'A2',
		skill: 'Reading',
	},
	{
		name: 'Multichoice Fill The Gaps',
		activity: 'multichoice-fill-the-gaps',
		cefr: 'A2',
		skill: 'Reading',
	},
	{
		name: 'Fill The Gaps Image',
		activity: 'fill-the-gaps-image',
		cefr: 'A2',
		skill: 'Reading',
	}
]

export class IGameTypes {
	name!: string;
	activity!: string;
	cefr!: Cefr;
	skill!: Skill;
}
