import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	@Input('title') title !: string;

	constructor(
		private authService: AuthService,
		private router: Router,
	) { }

	ngOnInit(): void {
	}

	home() {
		this.router.navigate(['navbar'])
	}

	async logout() {
		let data = await this.authService.logout();
		if (data) {
			this.router.navigate(['auth/login'])
		}
	}
}
