import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { ILesson } from 'src/app/shared/models/lesson.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { LessonService } from 'src/app/shared/services/lesson.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';

@Component({
	selector: "app-lessons-classroom-games",
	templateUrl: "./lessons-classroom-games.component.html",
	styleUrls: ["./lessons-classroom-games.component.scss"],
})
export class LessonsClassroomGamesComponent implements OnInit, OnDestroy {

	private getLessonSubscription!: Subscription;

	displayedColumns: string[] = ['checkbox','image', 'name','id', 'edit'];

	dataSource!: MatTableDataSource<ILesson>;
	loading: boolean = true;
	selected: string[] = [];

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@ViewChild(MatSort) sort!: MatSort;

	constructor(
		private router: Router,
		private authService: AuthService,
		private toastService: ToastService,
		private lessonService: LessonService
	) {
		this.getLessonSubscription = this.lessonService.getLessons()
		.subscribe(
			{
				next: (res: ILesson[]) => {
					this.dataSource = new MatTableDataSource(res);
					this.loading = false;
					this.dataSource.paginator = this.paginator;
					this.dataSource.sort = this.sort;
				},
				error: (err: Error) => {
					this.toastService.error('Failed to get lessons.')
					console.log('GET LESSONS', err);
					this.dataSource = new MatTableDataSource();
					this.loading = false;
					this.dataSource.paginator = this.paginator;
					this.dataSource.sort = this.sort;
				}
			},
		)
	}

	ngOnDestroy(): void {
		if (this.getLessonSubscription) {
			this.getLessonSubscription.unsubscribe();
		}
	}

	ngOnInit() {}

	edit(lessonId: string) {
		this.router.navigate(["/navbar/classroom-games", lessonId]);
	}

	select(event: MatCheckboxChange, checkedId: string) {
		if (event.checked) {
			if (this.selected.length >= 10) {
				this.toastService.error('Cannot select more than 10 lessons.')
				event.source.toggle()
				return;
			}
			this.selected.push(checkedId);
		} else {
			const index = this.selected.findIndex(id => id === checkedId)
			this.selected.splice(index, 1);
		}
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	exportClassroomGames() {
		let ids = '?ids=';
		for (let id of this.selected) {
			ids += `${id},`
		}
		ids = ids.substr(0, ids.length - 1);
		window.open(`${environment.PRODUCTION_EDITOR}navbar/import/classroom-games${ids}`);
	}

	createImage(id: string) {
		if (!id) {
			return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_default.jpg`;
		}
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.jpg`;
	}

	home() {
		this.router.navigate(['navbar'])
	}

	async logout() {
		let data = await this.authService.logout();
		if (data) {
			this.router.navigate(['auth/login'])
		}
	}
}
