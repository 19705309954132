<ng-container>
  <h3>SPEAKING DIALOGUE</h3>

  <ng-container class="lesson-title-content">
    <mat-form-field appearance="outline">
      <mat-label>Title</mat-label>
      <input matInput [formControl]="titleControl">
      <button *ngIf="titleControl.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="titleControl.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="titleControl.hasError('required')">
        Title is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="profile-select-container">
    <mat-form-field appearance="outline">
      <mat-label>Title Voice</mat-label>
      <mat-select [formControl]="titleVoiceControl">
        <ng-container *ngFor="let actor of validVoiceActors">
          <mat-option [value]="actor">{{ actor }}</mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="titleVoiceControl.hasError('required')">
        Title Voice is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="lesson-image-content">
		<mat-form-field appearance="outline">
			<mat-label>Image ID</mat-label>
			<input matInput [ngModel]="image" (ngModelChange)="changeImage($event)">
			<button *ngIf="image" matSuffix mat-icon-button aria-label="Clear" (click)="changeImage('')">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<button mat-button [disabled]="image === ''" class="verify-button" (click)="verifyImage()">Verify Image</button>
	</ng-container>

	<ng-container>
		<app-image-uploader *ngIf="toVerify" [imageId]="imageVerify" [isPlacement]="true" (uploadFiles)="setFile($event)" (exists)="imageExists=true; image = $event"></app-image-uploader>
	</ng-container>

  <ng-container class="profile-select-container">
    <mat-form-field appearance="outline">
      <mat-label>Char A</mat-label>
      <mat-select [formControl]="profilePhotoAControl">
        <ng-container *ngFor="let photo of profilePhotosRef">
          <mat-option class="profile-option" [value]="photo">
            <img [src]="photo.path" [alt]="photo.name" width="24" height="24">
            <span>{{photo.name}}</span>
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="profilePhotoAControl.hasError('required')">
        Profile photo is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="profile-select-container">
    <mat-form-field appearance="outline">
      <mat-label>Char B</mat-label>
      <mat-select [formControl]="profilePhotoBControl">
        <ng-container *ngFor="let photo of profilePhotosRef">
          <mat-option class="profile-option" [value]="photo">
            <img [src]="photo.path" [alt]="photo.name" width="24" height="24">
            <span>{{photo.name}}</span>
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="profilePhotoBControl.hasError('required')">
        Profile photo is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="profile-select-container">
    <mat-form-field appearance="outline">
      <mat-label>Voice Actor</mat-label>
      <mat-select [formControl]="voiceActorControl">
        <ng-container *ngFor="let actor of validVoiceActors">
          <mat-option [value]="actor">{{ actor }}</mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="voiceActorControl.hasError('required')">
        Voice actor is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <form [formGroup]="dialogueForm">
    <div formArrayName="lines">
      <div *ngFor="let lineGroup of lines.controls; let i = index" class="line-container">
        <div class="line-header-container">
          <span class="text">LINE GROUP {{i + 1}}</span>
          <button type="button" class="close-button" (click)="removeLine(i)">X</button>
        </div>

        <ng-container [formGroupName]="i">
          <div class="lesson-title-content">
            <mat-form-field appearance="outline">
              <mat-label>Line A</mat-label>
              <input matInput formControlName="lineA">
              <mat-error *ngIf="lineGroup.get('lineA')?.errors?.required">
                Line A is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="lesson-title-content">
            <mat-form-field appearance="outline">
              <mat-label>Line B</mat-label>
              <input matInput formControlName="lineB">
              <mat-error *ngIf="lineGroup.get('lineB')?.errors?.required">
                Line B is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>
  </form>

  <div class="action-container">
    <button mat-raised-button color="primary" class="add-line" (click)="addLine()">Add Line</button>
  </div>

</ng-container>