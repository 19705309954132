<div class="quiz-tab-content">
  <div class="table header">
    <mat-toolbar class="header-base" color="primary">
      <button mat-mini-fab color="white" (click)="addQuizQuestion()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div class="grid-container">
    <ng-container *ngFor="let quest of questions; let indexOfelement = index">
      <div class="grid-item">
        <mat-card style="text-align: center">
          <div class="delete-container" (click)="remove(indexOfelement)">
            <mat-icon color="warn">delete</mat-icon>
          </div>
          <mat-card-content style="text-align: center">
            <ng-container class="lesson-name-content">
              <mat-form-field appearance="outline">
                <mat-label>Statement</mat-label>
                <input matInput [(ngModel)]="quest.statement" />
                <button
                  *ngIf="quest.statement"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="quest.statement = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Right Answer</mat-label>
                <input matInput [(ngModel)]="quest.rightAnswer" />
                <button
                  *ngIf="quest.rightAnswer"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="quest.rightAnswer = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Wrong Answer 1</mat-label>
                <input matInput [(ngModel)]="quest.wrongAnswers[0]" />
                <button
                  *ngIf="quest.wrongAnswers[0]"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="quest.wrongAnswers[0] = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Wrong Answer 2</mat-label>
                <input matInput [(ngModel)]="quest.wrongAnswers[1]" />
                <button
                  *ngIf="quest.wrongAnswers[1]"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="quest.wrongAnswers[1] = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Wrong Answer 3</mat-label>
                <input matInput [(ngModel)]="quest.wrongAnswers[2]" />
                <button
                  *ngIf="quest.wrongAnswers[2]"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="quest.wrongAnswers[2] = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Difficulty</mat-label>
                <mat-select [(ngModel)]="quest.difficulty">
                  <ng-container *ngFor="let difficulty of difficulties">
                    <mat-option [value]="difficulty">{{
                      difficulty
                    }}</mat-option>
                  </ng-container>
                </mat-select>
                <button
                  *ngIf="quest.difficulty"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="quest.difficulty = 1"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Is Grammar?</mat-label>
                <mat-select [(ngModel)]="quest.isGrammar">
                  <mat-option [value]="false">No</mat-option>
                  <mat-option [value]="true">Yes</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>
