import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'angular-toastify';
import { Subscription, forkJoin } from 'rxjs';
import { CreateQuestionDTO, UpdateQuestionDTO, AnyGame, IMultichoiceFillTheGapsGame, IMultichoiceFillTheGapsChoices } from 'src/app/shared/models/placement-question.model';
import { PlacementQuestionsService } from 'src/app/shared/services/placement-questions.service';
import { CreateQuestionDialog } from '../create-question.component';
import { IUploadImage, ImageFiles } from 'src/app/shared/models/image-upload.model';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';

interface IFormChoice {
	correct: string;
	wrong: string[];
}

@Component({
	selector: 'app-multichoice-fillthegaps-media',
	templateUrl: './multichoice-fillthegaps-media.component.html',
	styleUrls: ['./multichoice-fillthegaps-media.component.scss']
})
export class MultichoiceFillthegapsMediaComponent implements OnInit {
	@Input() data!: CreateQuestionDTO | UpdateQuestionDTO;
	@Input() isUpdate!: boolean;

	@Output() questionUploaded = new EventEmitter<boolean>();

	form!: FormGroup;
	private addSubscription!: Subscription;
	public files: ImageFiles[] = [];
	uploading: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<CreateQuestionDialog>,
		private placementService: PlacementQuestionsService,
		private imageService: ImageUploaderService,
		private readonly toastService: ToastService,
		private fb: FormBuilder
	) {
		this.addSubscription = new Subscription();

		this.form = this.fb.group({
			text: new FormControl(null, Validators.required),
			imageId: new FormControl(null, Validators.required),
			verifyImage: new FormControl(false, Validators.required),
			choices: this.fb.array([]),
		});
		this.addChoice();
	}

	ngOnInit() {
		if (this.data.game && this.isMultichoiceGame(this.data.game.content)) {
			this.choices.clear();
			this.form.get('text')?.setValue(this.data.game.content.text);
			this.form.get('imageId')?.setValue(this.data.game.content.image);
			this.data.game.content.choices.forEach((data) => {
				this.choices.push(
					this.fb.group({
						wrong: this.fb.array(data.wrong.map(w => new FormControl(w, Validators.required)))
					})
				);
			});
		}
	}

	ngOnDestroy(): void {
		if (this.addSubscription) {
			this.addSubscription.unsubscribe();
		}
	}

	isMultichoiceGame(game: AnyGame): game is IMultichoiceFillTheGapsGame {
		return typeof (game as IMultichoiceFillTheGapsGame).choices !== "undefined";
	}

	get choices(): FormArray {
		return this.form.get('choices') as FormArray;
	}

	createChoiceGroup(): FormGroup {
		return this.fb.group({
			correct: new FormControl(null, Validators.required),
			wrong: this.fb.array([new FormControl(null, Validators.required), new FormControl(null, Validators.required)])
		});
	}

	addChoice(): void {
		this.choices.push(this.createChoiceGroup());
	}

	removeChoice(index: number): void {
		this.choices.removeAt(index);
	}

	getWrongControls(choiceGroup: AbstractControl): FormArray {
		return choiceGroup.get('wrong') as FormArray;
	}

	clearQuestionImage(newValue: string, form: AbstractControl | null) {
		if (form !== null) {
			(form as FormControl)
				.get("imageId")
				?.setValue(newValue, { emitEvent: true });
			(form as FormControl)
				.get("verifyImage")
				?.setValue(false, { emitEvent: true });
		}
	}

	verifyQuestionImage(form: AbstractControl | null) {
		if (form !== null) {
			(form as FormControl)
				.get("verifyImage")
				?.setValue(true, { emitEvent: true });
		}
	}

	setQuestionFile(file: ImageFiles | null, form: AbstractControl | null) {
		if (form !== null) {
			(form as FormControl)
				.get("imageId")
				?.setValue(file?.name ?? "", { emitEvent: true });
			if (file === null) {
				(form as FormControl)
					.get("verifyImage")
					?.setValue(false, { emitEvent: true });
			} else {
				this.files = [file];
			}
		}
	}

	existsFile(form: AbstractControl | null, imageExists: boolean, imageId: string) {
		if (form !== null) {
			(form as FormControl)
				.get("imageId")
				?.setValue(imageId, { emitEvent: true });
			(form as FormControl)
				.get("verifyImage")
				?.setValue(imageExists, { emitEvent: true });
		}
	}

	uploadQuestion() {
		this.uploading = true;
		let canUpload: boolean = true;

		const textValue = this.form.get('text')?.value;
		const choicesValue = this.choices.value;
		const imageId = this.form.get('imageId')?.value;

		// Verificação se o campo de texto está vazio
		if (!textValue || textValue.trim() === "") {
			this.form.get('text')?.markAsTouched();
			this.toastService.error("The text field is required.");
			canUpload = false;
		}

		// Verificação se o campo de ID da imagem está vazio
		if (!imageId || imageId.trim() === "") {
			this.form.get('imageId')?.markAsTouched();
			this.toastService.error("The image ID field is required.");
			canUpload = false;
		}

		// Verificação se não há escolhas criadas
		if (this.choices.controls.length <= 0) {
			this.toastService.error("No choices have been created.");
			canUpload = false;
		}

		// Verificação de respostas erradas vazias
		this.choices.controls.forEach((element) => {
			const wrongArray = element.get('wrong') as FormArray;

			wrongArray.controls.forEach(control => {
				if (!control.value || control.value.trim() === "") {
					control.markAsTouched();
					this.toastService.error("Each wrong answer must be filled out.");
					canUpload = false;
				}
			});
		});

		// Verificação de tags <> no campo de texto
		const tagPattern = /<[^<>]+>/g;
		const tags = textValue.match(tagPattern);

		if (!tags || tags.length === 0) {
			this.toastService.error("The text field must contain at least one <...> tag.");
			canUpload = false;
		} else if (tags.length !== choicesValue.length) {
			this.toastService.error("The number of <...> tags must match the number of choices.");
			canUpload = false;
		}

		if (!canUpload) {
			this.uploading = false;
			this.questionUploaded.emit(false);
			return;
		}

		const fileBlobs: Blob[] = this.files.map(file => new Blob([file.file], { type: "image/jpeg" }));
		const fileIds: IUploadImage[] = this.files.map(file => ({ id: file.name, extension: "jpg" }));

		const uploads = [];
		if (fileBlobs.length > 0) {
			uploads.push(this.imageService.uploadPlacementImage(fileBlobs, fileIds));
		}

		if (uploads.length > 0) {
			forkJoin(uploads).subscribe({
				next: (results) => {
					const allUploadsSuccessful = results.every(result => result === true);
					if (!allUploadsSuccessful) {
						this.uploading = false;
						this.toastService.error("Failed to upload files");
						this.questionUploaded.emit(false);
						return;
					}
					this.toastService.success("Files were uploaded successfully");
					this.finalizeUpload(textValue, choicesValue, imageId);
				},
				error: (error) => {
					this.uploading = false;
					this.toastService.error("An error occurred during file upload");
					console.error('Upload error:', error);
					this.questionUploaded.emit(false);
				},
				complete: () => {
					this.finalizeUpload(textValue, choicesValue, imageId);
				}
			});
		} else {
			this.finalizeUpload(textValue, choicesValue, imageId);
		}
	}

	private finalizeUpload(textValue: string, choicesValue: any, imageId: string) {
		this.data = {
			...this.data,
			game: {
				activity: "multichoice-fill-the-gaps",
				content: {
					image: imageId,
					text: textValue,
					choices: (choicesValue as IFormChoice[]).map((data, index) => ({
						id: index,
						wrong: data.wrong
					}))
				} as IMultichoiceFillTheGapsGame
			}
		};

		if (!this.isUpdate) {
			this.createQuestion(this.data as CreateQuestionDTO);
		} else {
			this.updateQuestion(this.data as UpdateQuestionDTO);
		}
	}

	createQuestion(createData: CreateQuestionDTO) {
		this.addSubscription = this.placementService
			.createQuestion(createData)
			.subscribe({
				next: (res) => {
					this.uploading = false;
					if (!res) {
						this.toastService.error("Failed to create question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					this.uploading = false;
					console.log(error);
					this.toastService.error("Failed to create question.");
					this.questionUploaded.emit(false);
				}
			});
	}

	updateQuestion(updateData: UpdateQuestionDTO) {
		this.addSubscription = this.placementService
			.updateQuestion(updateData, updateData.questionId)
			.subscribe({
				next: (res) => {
					this.uploading = false;
					if (!res) {
						this.toastService.error("Failed to update question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					this.uploading = false;
					console.log(error);
					this.toastService.error("Failed to update question.");
					this.questionUploaded.emit(false);
				}
			});
	}
}
