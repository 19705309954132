import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MediaObserver, MediaChange } from "@angular/flex-layout";
import { MatDialog } from "@angular/material/dialog";
import { DeleteConfirmationDialog } from "src/app/shared/components/delete-confirmation/delete-confirmation.component";
import { EditImageDialog } from "src/app/shared/components/edit-image/edit-image.component";
import { IDialogueRow, IVoiceActor } from "src/app/shared/models/lesson-content.model";
import { environment } from "src/environments/environment";
import { AddDialogueDialog } from "./add-dialogue/add-dialogue.component";

import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: "app-dialogue",
	templateUrl: "./dialogue.component.html",
	styleUrls: ["./dialogue.component.scss"],
})
export class DialogueComponent implements OnInit {

	dialogues!: IDialogueRow[];

	@Input() set data(value: IDialogueRow[]) {
		this.dialogues = value;
	}

	@Output() newData = new EventEmitter<IDialogueRow[]>();

	voiceActors: string[] = Object.values(IVoiceActor);

	cols: number = 0;

	gridByBreakpoint = {
		xl: 6,
		lg: 4,
		md: 3,
		sm: 2,
		xs: 1,
	};

	constructor(
		public dialog: MatDialog,
		private observableMedia: MediaObserver
	) {
		this.cols = 5;
	}

	ngAfterContentInit() {
		this.observableMedia
			.asObservable()
			.subscribe((change: MediaChange[]) => {
				for (let [key, value] of Object.entries(
					this.gridByBreakpoint
				)) {
					if (key === change[0].mqAlias) {
						this.cols = value;
					}
				}
			});
	}

	ngOnInit(): void {}

	createImage(id: string) {
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.jpg`;
	}

	editImage(index: number, dialog: IDialogueRow) {
		const dialogRef = this.dialog.open(EditImageDialog, {
			width: "350px",
			data: dialog.imageId,
		});

		dialogRef.afterClosed().subscribe((result: string) => {
			if (!result) {
				return;
			}
			dialog.imageId = result;
			this.dialogues[index] = dialog;
			this.sendData();
		});
	}

	remove(index: number) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
			data: index,
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			this.dialogues.splice(index, 1);
			this.sendData();
		});
	}

	copyImageName(index: number) {
		const dialog = this.dialogues[index];
		navigator.clipboard.writeText(dialog.imageId);
	}

	addDialogue() {
		const dialogRef = this.dialog.open(AddDialogueDialog, {
			width: "350px",
			data: {
				uuid: uuidv4(),
				subject: "",
				imageId: "",
				lines: [
					{
						character: "",
						voice: IVoiceActor.FEMALE_US,
						text: "",
					},
					{
						character: "",
						voice: IVoiceActor.FEMALE_US,
						text: "",
					},
					{
						character: "",
						voice: IVoiceActor.FEMALE_US,
						text: "",
					},
					{
						character: "",
						voice: IVoiceActor.FEMALE_US,
						text: "",
					},
				],
			} as IDialogueRow,
		});

		dialogRef.afterClosed().subscribe((result: IDialogueRow) => {
			if (!result) {
				return;
			}

			this.dialogues.push(result);
			this.sendData();
		});
	}

	sendData() {
		this.newData.emit(this.dialogues);
	}
}
