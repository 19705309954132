import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { MatDialog } from "@angular/material/dialog";
import { DeleteConfirmationDialog } from "src/app/shared/components/delete-confirmation/delete-confirmation.component";
import { EditImageDialog } from "src/app/shared/components/edit-image/edit-image.component";
import { IVocabularyRow } from "src/app/shared/models/lesson-content.model";
import { environment } from "src/environments/environment";
import { AddVocabularyDialog } from "./add-vocabulary/add-vocabulary.component";

import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: "app-vocabulary",
	templateUrl: "./vocabulary.component.html",
	styleUrls: ["./vocabulary.component.scss"],
})
export class VocabularyComponent implements OnInit {

	vocabulary!: IVocabularyRow[];

	@Input() set data(value: IVocabularyRow[]) {
		this.vocabulary = value;
	}

	@Output() newData = new EventEmitter<IVocabularyRow[]>();

	cols: number = 0;
	gridByBreakpoint = {
		xl: 6,
		lg: 5,
		md: 4,
		sm: 2,
		xs: 1,
	};

	constructor(
		public dialog: MatDialog,
		private observableMedia: MediaObserver
	) {
		this.cols = 5;
	}

	ngAfterContentInit() {
		this.observableMedia
			.asObservable()
			.subscribe((change: MediaChange[]) => {
				for (let [key, value] of Object.entries(
					this.gridByBreakpoint
				)) {
					if (key === change[0].mqAlias) {
						this.cols = value;
					}
				}
			});
	}

	ngOnInit(): void {}

	createImage(id: string) {
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.jpg`;
	}

	editImage(index: number, vocab: IVocabularyRow) {
		const dialogRef = this.dialog.open(EditImageDialog, {
			width: "350px",
			data: vocab.imageId,
		});

		dialogRef.afterClosed().subscribe((result: string) => {
			if (!result) {
				return;
			}
			vocab.imageId = result;
			this.vocabulary[index] = vocab;
			this.sendData();
		});
	}

	remove(index: number) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
			data: index,
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			this.vocabulary.splice(index, 1);
			this.sendData();
		});
	}

	copyImageName(index: number) {
		const vocab = this.vocabulary[index];
		navigator.clipboard.writeText(vocab.imageId);
	}

	addVocabulary() {
		const dialogRef = this.dialog.open(AddVocabularyDialog, {
			width: "350px",
			data: {
				uuid: uuidv4(),
				imageId: "",
				text: "",
			} as IVocabularyRow,
		});

		dialogRef.afterClosed().subscribe((result: IVocabularyRow) => {
			if (!result) {
				return;
			}

			this.vocabulary.push(result);
			this.sendData();
		});
	}

	sendData() {
		this.newData.emit(this.vocabulary);
	}
}
