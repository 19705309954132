import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'angular-toastify';
import { DIFFICULTY, IQuizQuestionRow } from 'src/app/shared/models/lesson-content.model';

@Component({
  selector: 'app-add-quiz-question',
  templateUrl: './add-quiz-question.component.html',
  styleUrls: ['./add-quiz-question.component.scss']
})
export class AddQuizQuestionDialog implements OnInit {

	readonly difficulties = DIFFICULTY;

	statementControl = new FormControl(this.data.statement, Validators.required);
	rightAnswerControl = new FormControl(this.data.rightAnswer, Validators.required);
	wrongAnswerControl = new FormControl(this.data.wrongAnswers[0], Validators.required);
	grammarControl = new FormControl(this.data.isGrammar, Validators.required);

	constructor(
		public dialogRef: MatDialogRef<AddQuizQuestionDialog>,
		@Inject(MAT_DIALOG_DATA) public data: IQuizQuestionRow,
		private toastService: ToastService,
	) {}

	ngOnInit(): void {

	}

	closeDialog() {
		this.dialogRef.close()
	}

	createQuestion() {
		let canUpload = true;

		this.data.statement = this.statementControl.value;
		this.data.rightAnswer = this.rightAnswerControl.value;
		this.data.isGrammar = this.grammarControl.value;

		if (this.data.statement === '') {
			this.statementControl.markAsTouched();
			canUpload = false;
		}

		if (this.data.rightAnswer === '') {
			this.rightAnswerControl.markAsTouched();
			canUpload = false;
		}

		if (this.wrongAnswerControl.value === '') {
			this.wrongAnswerControl.markAsTouched();
			canUpload = false;
		} else {
			this.data.wrongAnswers[0] = this.wrongAnswerControl.value;
		}

		if (!canUpload) {
			this.toastService.error('Failed to save new quiz question.')
			return;
		}

		this.toastService.success('Quiz question saved.')
		this.dialogRef.close(this.data);
	}

}

