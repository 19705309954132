import { Injectable } from '@angular/core';
import { ToastService } from 'angular-toastify';
import { BehaviorSubject, catchError, of } from 'rxjs';
import { ICreateLessonProgramBody, ILessonProgram } from '../../models/programs.model';
import { ProgramService } from '../program.service';

@Injectable({
	providedIn: 'root'
})
export class ProgramStore {

	private _programs = new BehaviorSubject<ILessonProgram[] | null>(null);

	constructor(
		private programService: ProgramService,
		private toastrService: ToastService,
	) { }

	private setPrograms(programs: ILessonProgram[]) {
		this._programs.next(programs);
	}

	listPrograms() {
		if (this._programs.value !== null) {
			this._programs.next(this._programs.value);
		} else {
			this.reloadPrograms();
		}

		return this._programs.asObservable();
	}

	registerProgram(program: ICreateLessonProgramBody) {
		this.programService.registerProgram(program)
		.pipe(
			catchError(err => {
				this.toastrService.error('failed to register program')
				return of(false)
			})
		).subscribe(res => {
			if(res !== false) {
				this.toastrService.success('program registered successfully')
				this.reloadPrograms();
			}
		})
	}

	updateProgram(programId: string, program: ICreateLessonProgramBody) {
		const beforeChange = this._programs.value as ILessonProgram[]
		const changeIndex = beforeChange?.findIndex(program => program.uid === programId)
		if(this._programs.value !== null && changeIndex !=null && changeIndex > -1) {
			const updatedProgram : ILessonProgram = {uid:programId,...program}
			this._programs.value.splice(changeIndex, 1, updatedProgram)
			this.setPrograms(this._programs.value);
		}
		this.programService.updateProgram(programId, program)
		.pipe(
			catchError(err => {
				this.toastrService.error('failed to update program')
				this.setPrograms(beforeChange)
				return of(false)
			})
		).subscribe(res => {
			if(res !== false) {
				this.toastrService.success('program edited successfully')
			}
		})
	}

	deleteProgram(programId: string) {
		this.programService.deleteProgram(programId)
		.pipe(
			catchError(err => {
				this.toastrService.error('failed to delete program')
				return of(false)
			})
		).subscribe(res => {
			if(res !== false) {
				const remainingPrograms = this._programs.value?.filter(program => program.uid !== programId) as ILessonProgram[]
				this.setPrograms(remainingPrograms)
				this.toastrService.success('program was deleted')
			}
		})
	}

	reloadPrograms() {
		this.programService.listPrograms()
		.pipe(
			catchError(err => {
				this.toastrService.error('failed to load programs');
				return of(false)
			})
		).subscribe(res => {
			if (res) {
				this.setPrograms(res as ILessonProgram[]);
			} else {
				this.setPrograms([]);
			}
		})
	}

}
