<div class="content-view">
	<lib-toastify-toast-container position="bottom-right"></lib-toastify-toast-container>
	<div class="header">
		<mat-toolbar class="header" color="primary">
			<button mat-icon-button color="white" (click)="home()">
				<mat-icon>home</mat-icon>
			</button>
			<div class="start-header">
				<h1 class="title">Lessons Classroom Games View</h1>
			</div>
			<div class="end-header">
				<button mat-mini-fab color="white" (click)="logout()">
					<mat-icon>logout</mat-icon>
				</button>
			</div>
		</mat-toolbar>
	</div>

	<div class="lesson-table" >
		<div class="table" >
			<div class="table footer ">
				<mat-toolbar class="footer" color="primary" style="display: flex; flex-direction: row-reverse;">
					<button class="publish-button" [disabled]="!(selected.length > 0)" mat-raised-button (click)="exportClassroomGames()">
						EXPORT
					</button>
				</mat-toolbar>
			</div>

			<div class="table-header">
				<mat-form-field appearance="standard">
					<mat-label>Filter</mat-label>
					<input matInput (keyup)="applyFilter($event)" placeholder="Ex. lesson_id_1" #input>
				</mat-form-field>
			</div>
			
			<table mat-table [dataSource]="dataSource" matSort >

				<ng-container matColumnDef="checkbox">
					<th mat-header-cell *matHeaderCellDef style="width: 60px; text-align: center;"> SELECT </th>
					<td mat-cell *matCellDef="let row" style="width: 60px; text-align: center;">
						<mat-checkbox class="checkbox" (change)="select($event, row.uid)"></mat-checkbox>
					</td>
				</ng-container>
				
				<ng-container matColumnDef="image">
					<th mat-header-cell *matHeaderCellDef style="width: 170px;"> IMAGE </th>
					<td mat-cell *matCellDef="let row" style="width: 170px; padding-top: 5px;">
						<img [src]="createImage(row.image)" style="max-width: 170px; margin-right: 5px; align-self:flex-start; overflow-y: none;">
					</td>
				</ng-container>
	
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
					<td mat-cell *matCellDef="let row" style="text-overflow: ellipsis;"> 
						{{row.uid}}
					</td>
				</ng-container>
	
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> NAME </th>
					<td mat-cell *matCellDef="let row"> {{row.displayName}} </td>
				</ng-container>
				
				<ng-container matColumnDef='edit'>
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell class="edit-button" *matCellDef="let row">
						<button mat-icon-button color="primary" (click)="edit(row.uid)">
							<mat-icon>edit</mat-icon>
						</button>
					</td>
				</ng-container>

				<tr class="table-header" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	
				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
				</tr>
			</table>
			<div style="position: inherit;" class="loading" *ngIf="loading">
				<mat-spinner></mat-spinner>
			</div>
			<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of lessons" [showFirstLastButtons]="true"></mat-paginator>
		</div>
	</div>

</div>