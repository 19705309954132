import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {

	constructor(private http: HttpClient) { }

	uploadStepFile(file: Blob): Observable<{ url: string }> {
	  const formData = new FormData();
	  formData.append('file', file);

	  return this.http.post<{ url: string }>(`${environment.SERVICES.ASSET_DELIVERY}files/steps`, formData);
	}

	deleteStepFile(fileId: string): Observable<boolean | void> {
	  return this.http.delete<void>(`${environment.SERVICES.ASSET_DELIVERY}files/steps/${fileId}`);
	}

  }
