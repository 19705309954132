import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ToastService } from "angular-toastify";
import { DeleteConfirmationDialog } from "src/app/shared/components/delete-confirmation/delete-confirmation.component";
import { IAddClassroomGame, IClassroomGameData, ICreateClassroomGameResult, ILessonClassroomData } from "src/app/shared/models/classroom-games.model";
import { ClassroomGamesService } from "src/app/shared/services/classroom-games.service";
import { LessonService } from "src/app/shared/services/lesson.service";
import { MetagamelinkService } from "src/app/shared/services/metagamelink.service";
import { environment } from "src/environments/environment";
import { AddClassroomGameDialog } from "./add-classroom-game/add-classroom-game.component";

@Component({
	selector: "app-classroom-game",
	templateUrl: "./classroom-game.component.html",
	styleUrls: ["./classroom-game.component.scss"],
})
export class ClassroomGameComponent implements OnInit, OnChanges {
	game!: string;
	classroomGames!: ILessonClassroomData;
	lessonId!: string;
	isDraft!: boolean;
	games: IClassroomGameData[] = [];
	dataSource!: MatTableDataSource<IClassroomGameData>;

	displayedColumns: string[] = [
		"image",
		"name",
		"objective",
		"instructions",
		"tags",
		"skills",
		"content",
		"version",
		"status",
	];

	@Input() set draft(value: boolean) {
		this.isDraft = value;
	}

	@Input() set lesson(value: string) {
		this.lessonId = value;
	}

	@Input() set data(value: ILessonClassroomData) {
		this.classroomGames = value;
	}

	@Input() set gameId(value: string) {
		this.game = value;
	}

	@Output('gamesUpdated') gamesUpdated = new EventEmitter<boolean>();

	cols: number = 0;
	gridByBreakpoint = {
		xl: 6,
		lg: 5,
		md: 4,
		sm: 2,
		xs: 1,
	};

	constructor(
		private dialog: MatDialog,
		private observableMedia: MediaObserver,
		private readonly classroomGameService: ClassroomGamesService,
		private readonly lessonService: LessonService,
		private toastService: ToastService,
		private metagameLinkService: MetagamelinkService,
		) {
		this.cols = 5;
	}

	ngAfterContentInit() {
		this.observableMedia
			.asObservable()
			.subscribe((change: MediaChange[]) => {
				for (let [key, value] of Object.entries(
					this.gridByBreakpoint
				)) {
					if (key === change[0].mqAlias) {
						this.cols = value;
					}
				}
			});
	}

	ngOnInit(): void { }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.data) {
			this.refreshClassroomGame();
		}
	}

	refreshClassroomGame() {
		if (this.classroomGames) {
			this.games = this.classroomGames.games.filter(game => {
				return game.gameType === this.game;
			});
		}

		this.dataSource = new MatTableDataSource(this.games);
	}

	createImage(id: string) {
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.jpg`;
	}

	addClassroomGame() {
		const dialogRef = this.dialog.open(AddClassroomGameDialog, {
			maxWidth: "100vw",
			maxHeight: "100vh",
			height: "100%",
			width: "85%",
			data: {
				contents: this.classroomGames.contents,
				game: {
					lessonId: this.lessonId,
					levelId: '',
					name: '',
					objective: '',
					instruction: '',
					tags: [],
					skills: [],
					imageId: '',
					content: '',
					gameType: this.game,
					version: 0,
					valid: false,
				}
			} as IAddClassroomGame,
		});

		dialogRef.afterClosed().subscribe((result: ICreateClassroomGameResult) => {
			if (!result) {
				return;
			}

			this.lessonService.regenerateLessons([this.lessonId])
			.subscribe({
				next: () => {
					this.toastService.success('Lesson regeneration started successfully!')
					this.gamesUpdated.emit(true);
				},
				error: () => {
					this.toastService.error(`Failed to regenerate lessons!`);
					this.gamesUpdated.emit(true);
				}
			})
		});
	}

	edit(index: number, game: IClassroomGameData) {
		const dialogRef = this.dialog.open(AddClassroomGameDialog, {
			maxWidth: "100vw",
			maxHeight: "100vh",
			height: "100%",
			width: "85%",
			data: {
				contents: this.classroomGames.contents,
				game: {
						lessonId: this.lessonId,
						levelId: game.levelId,
						name: game.name,
						objective: game.objective,
						instruction: game.instruction,
						tags: game.tags,
						skills: game.skills,
						imageId: game.imageId,
						content: game.content,
						gameType: this.game,
						version: game.version,
						valid: game.valid,
				},
				gameId: game.id,
			} as IAddClassroomGame,
		});

		dialogRef.afterClosed().subscribe((result: ICreateClassroomGameResult) => {
			if (!result) {
				return;
			}

			this.lessonService.regenerateLessons([this.lessonId])
			.subscribe({
				next: () => {
					this.toastService.success('Lesson regeneration started successfully!')
					this.gamesUpdated.emit(true);
				},
				error: () => {
					this.toastService.error(`Failed to regenerate lessons!`);
					this.gamesUpdated.emit(true);
				}
			})
		});
	}

	remove(index: number, gameId: string) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
			data: index,
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			this.classroomGameService.deleteClassroomGame(this.lessonId, gameId)
			.subscribe(res => {
				if (!res) {
					this.toastService.error("Failed to delete classroom game.")
					return;
				}

				this.toastService.success("Classroom Game deleted.");

				this.gamesUpdated.emit(true);
			});
		});
	}

	playClassroomGame(game: IClassroomGameData) {
		const gameUrl = this.metagameLinkService.getClassroomGameUrl(
		  this.lessonId,
		  game.gameType,
		  game.levelId,
		  game.version,
		);
		window.open(gameUrl, '_blank');
	  }

}
