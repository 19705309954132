<div class="drag-drop-tab-content">
  <div class="table header">
    <mat-toolbar class="header-base" color="primary">
      <button mat-mini-fab color="white" (click)="addDragDrop()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div class="grid-container">
    <ng-container *ngFor="let drag of dragDrop; let indexOfelement = index">
      <div class="grid-item">
        <mat-card style="text-align: center">
          <div class="image-container">
            <button
              mat-icon-button
              class="edit-button"
              (click)="edit(indexOfelement, drag)"
            >
              <mat-icon color="primary">edit</mat-icon>
            </button>
            <button
              mat-icon-button
              class="delete-button"
              (click)="remove(indexOfelement)"
            >
              <mat-icon color="warn">delete</mat-icon>
            </button>
            <img
              mat-card-image
              [src]="createImage(drag.backgroundId || '')"
              style="width: 100%"
            />
          </div>
          <mat-card-content [style.overflow]="'auto'" [style.height.px]="'180'">
            <ng-container class="drag-statement-content">
              <h3>Statement</h3>
              <h2>{{ drag.statement }}</h2>
            </ng-container>

            <ng-container class="drag-element-type-content">
              <h3>Element Type</h3>
              <h2>{{ drag.elementType }}</h2>
            </ng-container>

            <ng-container class="drag-elements-expansion">
              <mat-expansion-panel
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> Elements </mat-panel-title>
                </mat-expansion-panel-header>
                <div
                  class="drag-element-content"
                  *ngFor="let element of drag.elements"
                >
                  <img
                    mat-card-image
                    [src]="createImage(element.image || '')"
                    style="width: 40%"
                    *ngIf="element.image"
                  />
                  <div class="name-target-element">
                    <span>{{ element.name }}</span>
                    <div class="target-element">
                      <span>Targets </span>
                      <span *ngFor="let target of element.targets"
                        >{{ target + 1 }}
                      </span>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>
