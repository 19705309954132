import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, of, Subject, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { IContentGroup, ICreateContentGroup, ILessonActivity } from "../models/content-group.model";
@Injectable({
    providedIn:'root'
})
export class ContentGroupService {
    constructor(
        private httpClient: HttpClient
    ) {}
    public getContentGroups() : Observable<IContentGroup[]> {
        return this.httpClient.get<IContentGroup[]>(`${environment.SERVICES.CLASS_SESSION}api/content-groups`)
        .pipe(
            catchError(error => {
                console.log('CONTENT GROUPS', error);
                return of([]);
            }),
        )
    }
    public getLessonContentGroups(lessonId: string, classId: string): Observable<IContentGroup[]> {
        return this.httpClient.get<IContentGroup[]>(`${environment.SERVICES.CLASS_SESSION}api/${classId}/groups/${lessonId}`);
    }
    public getActivitiesContentGroup(lessonId: string, layouts: string[]): Observable<ILessonActivity[]> {
        let httpParams = new HttpParams();
        layouts.forEach(layout => {
            httpParams = httpParams.append('description', layout);
        });
        return this.httpClient.get<ILessonActivity[]>(`${environment.SERVICES.LESSON}lesson/${lessonId}/activities`, {params: httpParams});
    }
    public createContentGroup(data: ICreateContentGroup) : Observable<string | null> {
        return this.httpClient.post<{uid: string}>(`${environment.SERVICES.CLASS_SESSION}api/content-groups`, data)
        .pipe(
            switchMap(res => {
                return of(res.uid);
            }),
            catchError(error => {
                console.log('CREATE CONTENT GROUP', error);
                return of(null);
            })
        )
    }
    public updateContentGroup(id: string, data: IContentGroup) : Observable<boolean> {
        const updateContent: ICreateContentGroup = Object.assign({}, data);
        delete updateContent.id;
        return this.httpClient.put<void>(`${environment.SERVICES.CLASS_SESSION}api/content-groups/${id}`, data)
        .pipe(
            switchMap(() => {
                return of(true);
            }),
            catchError(error => {
                console.log('UPDATE CONTENT GROUP', error);
                return of(false);
            })
        )
    }
    public removeContentGroup(id: string) : Observable<boolean> {
        return this.httpClient.delete<boolean>(`${environment.SERVICES.CLASS_SESSION}api/content-groups/${id}`)
        .pipe(
            switchMap(() => {
                return of(true);
            }),
            catchError(error => {
                console.log('DELETE CONTENT GROUP', error);
                return of(false);
            })
        )
    }
}
