<div mat-dialog-title color="primary">
	CREATE CARD
</div>
<lib-toastify-toast-container
	position="bottom-right"
></lib-toastify-toast-container>
<div mat-dialog-content class='dialog-content' style="align-items: center;">

	<ng-container class="lesson-id-content">
		<mat-form-field appearance="fill">
			<mat-label>Text</mat-label>
			<input matInput [formControl]="textControl">
			<button *ngIf="textControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="textControl.reset()">
				<mat-icon>close</mat-icon>
			</button>
			<mat-error *ngIf="textControl.hasError('required')">
				Text is <strong>required</strong>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<ng-container class="lesson-image-content">
		<mat-form-field appearance="fill">
			<mat-label>Image</mat-label>
			<input matInput [ngModel]="data.imageId" (ngModelChange)="changeImage($event)">
			<button *ngIf="data.imageId" matSuffix mat-icon-button aria-label="Clear" (click)="changeImage('')">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<button mat-button [disabled]="data.imageId === ''" class="verify-button" (click)="verifyImage()">Verify Image</button>
	</ng-container>

	<ng-container>
		<app-image-uploader *ngIf="toVerify" [imageId]="imageVerify" (uploadFiles)="setFile($event)" (exists)="imageExists=true; data.imageId=$event"></app-image-uploader>
	</ng-container>

</div>

<div mat-dialog-actions>
	<button mat-button (click)="closeDialog()">Discard</button>
	<button mat-button (click)="createVocab()" cdkFocusInitial>Save</button>
</div>