import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'angular-toastify';
import { Subscription, forkJoin } from 'rxjs';
import { IUploadImage, ImageFiles } from 'src/app/shared/models/image-upload.model';
import { CreateQuestionDTO, UpdateQuestionDTO, AnyGame, IFillTheGapsImageGame } from 'src/app/shared/models/placement-question.model';
import { PlacementQuestionsService } from 'src/app/shared/services/placement-questions.service';
import { CreateQuestionDialog } from '../create-question.component';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';

@Component({
	selector: 'app-fill-the-gaps-image-media',
	templateUrl: './fill-the-gaps-image-media.component.html',
	styleUrls: ['./fill-the-gaps-image-media.component.scss']
})
export class FillTheGapsImageMediaComponent implements OnInit, OnDestroy {
	@Input() data!: CreateQuestionDTO | UpdateQuestionDTO;
	@Input() isUpdate!: boolean;

	@Output() questionUploaded = new EventEmitter<boolean>();

	form!: FormGroup;
	private addSubscription!: Subscription;

	constructor(
		public dialogRef: MatDialogRef<CreateQuestionDialog>,
		private placementService: PlacementQuestionsService,
		private imageService: ImageUploaderService,
		private readonly toastService: ToastService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef
	) {
		this.addSubscription = new Subscription();

		this.form = this.fb.group({
			text: new FormControl(null, Validators.required),
			imageId: new FormControl(null, Validators.required),
			verifyImage: new FormControl(false, Validators.required),
			imageExists: new FormControl(false, Validators.required),
			file: new FormControl(null, Validators.required),
			choices: this.fb.array([]),
			wrongChoices: this.fb.array([
				this.createWrongChoiceGroup(),
				this.createWrongChoiceGroup()
			], Validators.required)
		});
		this.addChoice();
	}

	ngOnInit() {
		if (this.data.game && this.isFillTheGapsImageGame(this.data.game.content)) {
			this.choices.clear();
			this.form.get('text')?.setValue(this.data.game.content.text);
			this.form.get('imageId')?.setValue(this.data.game.content.image);
			this.form.get('imageExists')?.setValue(true);
			this.form.get('file')?.setValue(null);
			this.data.game.content.choices.forEach((data) => {
				this.choices.push(
					this.fb.group({
						image: new FormControl(data.image, Validators.required),
						verifyImage: new FormControl(false, Validators.required),
						imageExists: new FormControl(true, Validators.required),
						file: new FormControl(null, Validators.required),
					})
				);
			});
			this.form.setControl('wrongChoices', this.fb.array(
				this.data.game.content.wrongChoices.map(wrongChoice => this.createWrongChoiceGroup(wrongChoice))
			));
		}
	}

	ngOnDestroy(): void {
		if (this.addSubscription) {
			this.addSubscription.unsubscribe();
		}
	}

	isFillTheGapsImageGame(game: AnyGame): game is IFillTheGapsImageGame {
		return typeof (game as IFillTheGapsImageGame).choices !== "undefined";
	}

	get choices(): FormArray {
		return this.form.get('choices') as FormArray;
	}

	get wrongChoices(): FormArray {
		return this.form.get('wrongChoices') as FormArray;
	}

	createChoiceGroup(): FormGroup {
		return this.fb.group({
			image: new FormControl(null, Validators.required),
			verifyImage: new FormControl(false, Validators.required),
			imageExists: new FormControl(false, Validators.required),
			file: new FormControl(null, Validators.required),
		});
	}

	createWrongChoiceGroup(wrongChoice?: { id: number, text: string, image: string }): FormGroup {
		return this.fb.group({
			id: new FormControl(wrongChoice?.id ?? null, Validators.required),
			text: new FormControl(wrongChoice?.text ?? null, Validators.required),
			image: new FormControl(wrongChoice?.image ?? null, Validators.required),
			verifyImage: new FormControl(false, Validators.required),
			imageExists: new FormControl(wrongChoice?.image ? true : false, Validators.required),
			file: new FormControl(null, Validators.required),
		});
	}

	addChoice(): void {
		if (this.choices.length < 7) {
			this.choices.push(this.createChoiceGroup());
		} else {
			this.toastService.error("You can add up to 7 choices only.");
		}
	}

	removeChoice(index: number): void {
		this.choices.removeAt(index);
	}

	clearQuestionImage(newValue: string, form: AbstractControl | null) {
		if (form !== null) {
			(form as FormControl)
				.get("imageId")
				?.setValue(newValue, { emitEvent: true });
			(form as FormControl)
				.get("verifyImage")
				?.setValue(false, { emitEvent: true });
			(form as FormControl)
				.get("imageExists")
				?.setValue(false, { emitEvent: true });
			(form as FormControl)
				.get("file")
				?.setValue(null, { emitEvent: true });
		}
	}

	verifyQuestionImage(form: AbstractControl | null) {
		if (form !== null) {
			(form as FormControl)
				.get("verifyImage")
				?.setValue(true, { emitEvent: true });
			this.cdr.detectChanges();
		}
	}

	clearChoiceImage(index: number, arrayName: 'choices' | 'wrongChoices') {
		const array = this.form.get(arrayName) as FormArray;
		const choiceGroup = array.at(index);
		if (choiceGroup) {
			choiceGroup.get('image')?.setValue('', { emitEvent: true });
			choiceGroup.get('verifyImage')?.setValue(false, { emitEvent: true });
			choiceGroup.get('imageExists')?.setValue(false, { emitEvent: true });
			choiceGroup.get('file')?.setValue(null, { emitEvent: true });
		}
		this.cdr.detectChanges();
	}

	verifyChoiceImage(index: number, arrayName: 'choices' | 'wrongChoices') {
		const array = this.form.get(arrayName) as FormArray;
		const choiceGroup = array.at(index);
		if (choiceGroup) {
			choiceGroup.get('verifyImage')?.setValue(true, { emitEvent: true });
		}
		this.cdr.detectChanges();
	}

	setChoiceFile(file: ImageFiles | null, index: number, arrayName: 'choices' | 'wrongChoices') {
		const array = this.form.get(arrayName) as FormArray;
		const choiceGroup = array.at(index);
		if (choiceGroup) {
			choiceGroup.get('image')?.setValue(file?.name ?? '', { emitEvent: true });
			choiceGroup.get('file')?.setValue(file, { emitEvent: true });

			if (file === null) {
				choiceGroup.get('verifyImage')?.setValue(false, { emitEvent: true });
				choiceGroup.get('imageExists')?.setValue(false, { emitEvent: true });
			}
		}
		this.cdr.detectChanges();
	}

	existsChoiceFile(index: number, imageExists: boolean, imageId: string, arrayName: 'choices' | 'wrongChoices') {
		const array = this.form.get(arrayName) as FormArray;
		const choiceGroup = array.at(index);
		if (choiceGroup) {
			choiceGroup.get('image')?.setValue(imageId, { emitEvent: true });
			choiceGroup.get('imageExists')?.setValue(imageExists, { emitEvent: true });
		}
		this.cdr.detectChanges();
	}

	setQuestionFile(file: ImageFiles | null, form: AbstractControl | null) {
		if (form !== null) {
			(form as FormControl)
				.get("imageId")
				?.setValue(file?.name ?? "", { emitEvent: true });
			(form as FormControl)
				.get("file")
				?.setValue(file, { emitEvent: true });
			if (file === null) {
				(form as FormControl)
					.get("verifyImage")
					?.setValue(false, { emitEvent: true });
				(form as FormControl)
					.get("imageExists")
					?.setValue(false, { emitEvent: true });
			}
		}
		this.cdr.detectChanges();
	}

	existsFile(form: AbstractControl | null, imageExists: boolean, imageId: string) {
		if (form !== null) {
			(form as FormControl)
				.get("imageId")
				?.setValue(imageId, { emitEvent: true });
			(form as FormControl)
				.get("imageExists")
				?.setValue(imageExists, { emitEvent: true });
		}
		this.cdr.detectChanges();
	}

	uploadQuestion() {
		let canUpload: boolean = true;

		const textValue = this.form.get('text')?.value;
		const choicesValue = this.choices.value;
		const wrongChoicesValue = this.wrongChoices.value;

		let allImages = true;
		const fileBlobs: Blob[] = [];
		const fileIds: IUploadImage[] = [];

		// Verificação se o campo de texto está vazio
		if (!textValue || textValue.trim() === "") {
			this.form.get('text')?.markAsTouched();
			this.toastService.error("The text field is required.");
			canUpload = false;
		}

		// Verificação se o campo de ID da imagem está vazio
		if (!this.form.get('imageId')?.value || this.form.get('imageId')?.value.trim() === "" ) {
			this.form.get('imageId')?.markAsTouched();
			this.toastService.error("The image ID field is required.");
			canUpload = false;
		}

		const exists = this.form.get("imageExists");
		const file = this.form.get("file");

		if ((!exists || !exists?.value) && (!file || !file?.value)) {
			allImages = false;
			canUpload = false;
			this.form.get('imageId')?.markAsTouched();
			this.toastService.error("The image ID field is required.");
		}

		if (file?.value) {
			fileBlobs.push(
				new Blob([this.form.get("file")?.value.file], {
					type: "image/jpeg",
				})
			);

			fileIds.push({
				id: this.form.get("file")?.value.name,
				extension: "jpg",
			} as IUploadImage);
		}

		// Verificação se não há escolhas criadas
		if (this.choices.controls.length < 1 || this.choices.controls.length > 7) {
			this.toastService.error("You must have between 1 and 7 choices.");
			canUpload = false;
		}

		// Verificação de respostas erradas vazias e imagens associadas a cada escolha
		this.choices.controls.forEach((element) => {
			const imageId = element.get("image");
			const exists = element.get("imageExists");
			const file = element.get("file");
			let errors = false;
			if (!imageId || !imageId.value || imageId.value.trim() == "") {
				imageId?.markAsTouched();
				canUpload = false;
				this.toastService.error("Each choice must have an associated image.");
			}

			if ((!exists || !exists.value) && (!file || !file.value)) {
				allImages = false;
				errors = true;
				canUpload = false;
			}

			if (!errors && file?.value) {
				fileBlobs.push(
					new Blob([file.value.file], {
						type: "image/jpeg",
					})
				);

				fileIds.push({
					id: file.value.name,
					extension: "jpg",
				} as IUploadImage);
			}
		});

		// Verificação das respostas erradas
		if (this.wrongChoices.controls.length !== 2) {
			this.toastService.error("There must be exactly 2 wrong choices.");
			canUpload = false;
		} else {
			this.wrongChoices.controls.forEach((element) => {
				const textForm = element.get('text');
				const imageId = element.get("image");
				const exists = element.get("imageExists");
				const file = element.get("file");
				let errors = false;

				if (!textForm?.value || textForm.value.trim() === "") {
					textForm?.markAsTouched();
					this.toastService.error("Each wrong choice must have text.");
					canUpload = false;
				}

				if (!imageId || !imageId.value || imageId.value.trim() == "") {
					imageId?.markAsTouched();
					this.toastService.error("Each wrong choice must have an associated image.");
					canUpload = false;
				}

				if ((!exists || !exists.value) && (!file || !file.value)) {
					allImages = false;
					errors = true;
					canUpload = false;
				}

				if (!errors && file?.value) {
					fileBlobs.push(
						new Blob([file.value.file], {
							type: "image/jpeg",
						})
					);

					fileIds.push({
						id: file.value.name,
						extension: "jpg",
					} as IUploadImage);
				}
			});
		}

		// Verificação de tags <> no campo de texto
		const tagPattern = /<[^<>]+>/g;
		const tags = textValue.match(tagPattern);

		if (!tags || tags.length === 0) {
			this.toastService.error("The text field must contain at least one <...> tag.");
			canUpload = false;
		} else if (tags.length !== choicesValue.length) {
			this.toastService.error("The number of <...> tags must match the number of choices.");
			canUpload = false;
		}

		if (!allImages) {
			this.toastService.error(`There are one or more images missing.`);
		}

		if (!canUpload) {
			this.toastService.error("There are errors in the form. Please fix them and try again.");
			this.questionUploaded.emit(false);
			return;
		}

		const uploads = [];
		if (fileBlobs.length > 0) {
			uploads.push(this.imageService.uploadPlacementImage(fileBlobs, fileIds));
		}

		if (uploads.length > 0) {
			forkJoin(uploads).subscribe({
				next: (results) => {
					const allUploadsSuccessful = results.every(result => result === true);
					if (!allUploadsSuccessful) {
						this.toastService.error("Failed to upload files");
						this.questionUploaded.emit(false);
						return;
					}
					this.toastService.success("Files were uploaded successfully");
					this.finalizeUpload(textValue, choicesValue, wrongChoicesValue);
				},
				error: (error) => {
					this.toastService.error("An error occurred during file upload");
					console.error('Upload error:', error);
					this.questionUploaded.emit(false);
				},
				complete: () => {
					this.finalizeUpload(textValue, choicesValue, wrongChoicesValue);
				}
			});
		} else {
			this.finalizeUpload(textValue, choicesValue, wrongChoicesValue);
		}
	}

	private finalizeUpload(textValue: string, choicesValue: any, wrongChoicesValue: any) {
		this.data = {
			...this.data,
			game: {
				activity: "fill-the-gaps-image",
				content: {
					image: this.form.get('imageId')?.value,
					text: textValue,
					choices: choicesValue.map((data: any, index: number) => ({
						id: index,
						image: data.image
					})),
					wrongChoices: wrongChoicesValue.map((data: any, index: number) => ({
						id: index,
						text: data.text,
						image: data.image
					}))
				} as IFillTheGapsImageGame
			}
		};

		if (!this.isUpdate) {
			this.createQuestion(this.data as CreateQuestionDTO);
		} else {
			this.updateQuestion(this.data as UpdateQuestionDTO);
		}
	}

	createQuestion(createData: CreateQuestionDTO) {
		this.addSubscription = this.placementService
			.createQuestion(createData)
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to create question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to create question.");
					this.questionUploaded.emit(false);
				}
			});
	}

	updateQuestion(updateData: UpdateQuestionDTO) {
		this.addSubscription = this.placementService
			.updateQuestion(updateData, updateData.questionId)
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to update question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to update question.");
					this.questionUploaded.emit(false);
				}
			});
	}
}
