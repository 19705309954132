import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IAccount } from '../models/account.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
	  private http: HttpClient,
  ) { }

  listAccounts() {
	  return this.http.get<IAccount[]>(`${environment.SERVICES.MANAGEMENT}accounts`)
  }
}
