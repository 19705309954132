import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DeleteConfirmationDialog } from 'src/app/shared/components/delete-confirmation/delete-confirmation.component';
import { EditImageDialog } from 'src/app/shared/components/edit-image/edit-image.component';
import { WatchVideoDialog } from 'src/app/shared/components/watch-video/watch-video.component';
import { IVideoConfig, IVideoSessionRow } from 'src/app/shared/models/lesson-content.model';
import { environment } from 'src/environments/environment';
import { AddVideoSessionDialog } from './add-video-session/add-video-session.component';

import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'app-video-session',
	templateUrl: './video-session.component.html',
	styleUrls: ['./video-session.component.scss']
})
export class VideoSessionComponent implements OnInit {

	videos!: IVideoSessionRow[];

	@Input() set data(value: IVideoSessionRow[]) {
		this.videos = value || [];
		this.sendData()
	}

	@Output() newData = new EventEmitter<IVideoSessionRow[]>();

	cols: number = 0;
	gridByBreakpoint = {
		xl: 5,
		lg: 4,
		md: 3,
		sm: 2,
		xs: 1,
	}

	constructor(
		public dialog: MatDialog,
		private observableMedia: MediaObserver,
		private _sanitizer: DomSanitizer,
	) {
		this.cols = 5;
	}
	ngOnInit(): void {

	}

	ngAfterContentInit() {
		this.observableMedia.asObservable().subscribe((change : MediaChange[]) => {
			for (let [key, value] of Object.entries(this.gridByBreakpoint)) {
				if (key === change[0].mqAlias) {
					this.cols = value;
				}
			}
		});
	}

	createVideo(id: string) {
		return this._sanitizer.bypassSecurityTrustResourceUrl(`http://img.youtube.com/vi/${id}/0.jpg`);
	}

	createImage(id: string) {
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.jpg`;
	}

	editImage(index: number, video: IVideoSessionRow) {
		const dialogRef = this.dialog.open(EditImageDialog, {
			width: "350px",
			data: video.thumbnail,
		});

		dialogRef.afterClosed().subscribe((result: string) => {
			if (!result) {
				return;
			}
			video.thumbnail = result;
			this.videos[index] = video;
			this.sendData();
		});
	}

	watchVideo(index: number, video: IVideoSessionRow) {
		const dialogRef = this.dialog.open(WatchVideoDialog, {
			width: '750px',
			data: {
				videoId: video.videoId,
				videoStart: video.videoStart,
				videoEnd: video.videoEnd,
			} as IVideoConfig,
		});

		dialogRef.afterClosed().subscribe((result: IVideoSessionRow) => {
			if (!result) {
				return;
			}
			video.videoId = result.videoId;
			video.videoStart = result.videoStart;
			video.videoEnd = result.videoEnd;
			this.videos[index] = video;
			this.sendData();
		});
	}

	addVideoSession() {
		const dialogRef = this.dialog.open(AddVideoSessionDialog, {
			width: '550px',
			data: {
				uuid: uuidv4(),
				videoId: '',
				videoStart: 0,
				videoEnd: 0,
				thumbnail: '',
			} as IVideoSessionRow,
		})


		dialogRef.afterClosed().subscribe((result: IVideoSessionRow) => {
			if (!result) {
				return;
			}

			this.videos.push(result);
			this.sendData();
		})
	}

	remove(index: number) {
		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
			data: index,
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			this.videos.splice(index, 1);
			this.sendData();
		});
	}

	copyImageName(index: number) {
		const video = this.videos[index];
		navigator.clipboard.writeText(video.thumbnail);
	}

	sendData() {
		this.newData.emit(this.videos);
	}
}
