<div class="dialogue-tab-content">
  <div class="table header">
    <mat-toolbar class="header-base" color="primary">
      <button mat-mini-fab color="white" (click)="addDialogue()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div class="grid-container">
    <ng-container
      *ngFor="let dialogue of dialogues; let indexOfelement = index"
    >
      <div class="grid-item">
        <mat-card style="text-align: center">
          <button
            mat-icon-button
            matTooltip="Delete"
            color="warn"
            class="delete-button"
            (click)="remove(indexOfelement)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Copy image name"
            class="copy-button"
            (click)="copyImageName(indexOfelement)"
          >
            <mat-icon color="primary">collections</mat-icon>
          </button>
          <div
            class="image-container"
            (click)="editImage(indexOfelement, dialogue)"
          >
            <img
              mat-card-image
              [src]="createImage(dialogue.imageId)"
              style="width: 100%"
            />
            <div class="image-text" style="text-align: center">
              Change Image
            </div>
            <div class="background"></div>
          </div>
          <mat-card-content>
            <ng-container class="dialogue-subject-content">
              <mat-form-field appearance="outline">
                <mat-label>Subject</mat-label>
                <input matInput [(ngModel)]="dialogue.subject" />
                <button
                  *ngIf="dialogue.subject"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="dialogue.subject = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </ng-container>
            <ng-container
              class="dialogue-lines-content"
              *ngFor="let line of dialogue.lines; let lineIndex = index"
            >
              <p>LINE {{ lineIndex + 1 }}</p>
              <mat-form-field appearance="outline">
                <mat-label>Character</mat-label>
                <input matInput [(ngModel)]="line.character" />
                <button
                  *ngIf="line.character"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="line.character = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Voice Actor</mat-label>
                <mat-select [(ngModel)]="line.voice">
                  <mat-option
                    *ngFor="let voice of voiceActors"
                    [value]="voice"
                    >{{ voice }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Line</mat-label>
                <input matInput [(ngModel)]="line.text" />
                <button
                  *ngIf="line.text"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="line.text = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>
