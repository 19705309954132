import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'angular-toastify';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LessonService } from 'src/app/shared/services/lesson.service';
import { CloudFunctionNames, errorStates, ILessonUploadData, LessonUpdateStatus } from 'src/app/shared/models/lesson.model';
import { map, Observable, Subscription } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ImportService } from 'src/app/shared/services/import.service';

interface ParserRunStatus {
	version: number;
	status : LessonUpdateStatus,
	color : string,
	startTime: string;
	endTime?: string;
	steps: ParserRunStep[]
}

interface ParserRunStep {
	name: string;
	status: LessonUpdateStatus,
	color: string;
}

@Component({
	selector: 'app-upload-state',
	templateUrl: './upload-state.component.html',
	styleUrls: ['./upload-state.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})


export class UploadStateDialog implements OnInit, OnDestroy {

	reRunPipeline: boolean = false;

	uploadStatus!: Observable<ParserRunStatus[]>;
	expandedElementIndex!: number | null;

	contentSubscription!: Subscription;
	saveSubscription!: Subscription;

	errorStates = errorStates;

	columns = ['version','creation-time' ,'start-time', 'end-time', 'status', 're-run'];

	constructor(
		public dialogRef: MatDialogRef<UploadStateDialog>,
		@Inject(MAT_DIALOG_DATA) public data: string,
		private lessonService: LessonService,
		private importService: ImportService,
		private toastService: ToastService,
		private afs: AngularFirestore,
	) {
		this.uploadStatus = (this.afs.collection('lesson-upload-state', ref => ref.where("lessonId", "==", data))
			.valueChanges() as unknown as Observable<ILessonUploadData[]>)
			.pipe(
				map((parserRuns) => {
					return parserRuns
					.sort((a,b) => {
						const aTime = a.creationTime  ?? a.startTime;
						const bTime = b.creationTime ?? b.startTime;

						return bTime.toMillis() - aTime.toMillis();
					})
					.slice(0,11)
					.map(run => ({
						version: run.version,
						status : this.getStatus(run),
						color : this.getStyle(this.getStatus(run)),
						startTime: this.getDate(run.startTime),
						creationTime: run.creationTime ? this.getDate(run.creationTime) : this.getDate(run.startTime),
						endTime: this.getDate(run.endTime),
						steps: this.convertToParserRunStep(run)
					}))
				}),
			);
	}
	ngOnDestroy(): void {
		if (this.contentSubscription) {
			this.contentSubscription.unsubscribe();
		}
		if (this.saveSubscription) {
			this.saveSubscription.unsubscribe();
		}
	}

	ngOnInit(): void {

	}

	convertToParserRunStep(data: ILessonUploadData): ParserRunStep[] {

		return Object.values(CloudFunctionNames)
			.map(fn => ({
				name: fn,
				status: data[fn],
				color: this.getStyle(data[fn])
			}));
	}

	getDate(date: Timestamp | undefined) {
		if (!date) {
			return ' - '
		}
		return DateTime.fromJSDate(date.toDate()).toFormat('dd/LLL/yyyy - HH:mm');
	}

	getStyle(value: LessonUpdateStatus) {
		let color = 'white';
		switch (value) {
			case "Waiting":
				color = 'yellow';
				break;
			case "Doing":
				color = 'yellow';
				break;
			case "ExecutionError":
			case "NoAudioError":
			case "Timeout":
				color = 'red';
				break;
			case "Done":
				color = 'green';
				break;
			default:
				color = 'white';
				break;
		}
		return `background-color: ${color}`;
	}

	getStatus(row: ILessonUploadData): LessonUpdateStatus {
		if (!row.endTime) {
			return "Doing";
		} else {
			return this.executionStatus(row);
		}
	}

	executionStatus(row: ILessonUploadData): LessonUpdateStatus {
		let status: LessonUpdateStatus = "Done";
		const allFunctionNames = Object.values(CloudFunctionNames);
		allFunctionNames.map((functionName) => {
			if (errorStates.has(row[functionName])) {
				status = row[functionName];
			}
		})
		return status;
	}

	forceUploadLesson() {
		if (this.reRunPipeline) {
			this.toastService.error("Another pipeline is in progress, please wait for it to finish.")
			return;
		}
		if (this.contentSubscription) {
			this.contentSubscription.unsubscribe();
		}
		if (this.saveSubscription) {
			this.saveSubscription.unsubscribe();
		}
		this.saveSubscription = this.lessonService.regenerateLessons([this.data])
		.subscribe(res => {
			this.toastService.success(`Pipeline has started.`);
			this.reRunPipeline = true;
		})
	}

	closeDialog() {
		this.dialogRef.close();
	}

}
