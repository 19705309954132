
export interface ILessonClassroomData {
	contents: IClassroomContentData[];
	games: IClassroomGameData[]
}

export interface IClassroomContentData {
	id: string;
    lessonId: string;
    name: string;
    cards: ICard[];
}

export interface ICard {
	text: string;
    imageId: string;
}

export interface IClassroomGameData {
    id: string;
    lessonId: string;
    levelId: string;
    name: string;
    objective: string;
    instruction: string;
    tags: string[];
    skills: string[];
    imageId: string;
    content: string;
    gameType: string;
	version: number;
	valid: boolean;
}


// CREATE AND UPDATE MODELS
export interface IClassroomContent {
    lessonId: string;
    name: string;
    cards: ICard[];
}

export interface IClassroomGame {
    lessonId: string;
    levelId: string;
    name: string;
    objective: string;
    instruction: string;
    tags: string[];
    skills: string[];
    imageId: string;
    content: string;
    gameType: string;
	version: number;
	valid: boolean;
}

export interface IAddClassroomGame {
	contents: IClassroomContentData[];
	game: IClassroomGame;
	gameId?: string;
}

export interface IGetLessonClassroomResult {
	lessonId: string;
	classroomData: ILessonClassroomData;
}

export interface IClassroomGameResolveResult {
	lessonId: string;
	classroomData: ILessonClassroomData;
}

export interface ICreateClassroomGameResult {
	content: IClassroomContentData;
	game: IClassroomGameData;
}

export interface IClassroomGameResolver {
	lessonId: string;
	isDraft: boolean;
}

export const VALID_SKILLS = ['Reading', 'Listening', 'Writing', 'Speaking'];
export type Skill = typeof VALID_SKILLS[number];

export const VALID_TAGS = ['Welcome', 'Lead-In', 'Language Input', 'Practice', 'Free Practice', 'Grammar Focus', 'Projects', 'Closing'];
export type Tags = typeof VALID_TAGS[number];

export interface IImportClassroomGames {
	lessonId: string;
	classroomGames: ILessonClassroomData;
	lessonExists: boolean;
}
