import { Age, Cefr } from "../constants";

export const VALID_SKILLS = ['Reading', 'Listening', 'Writing', 'Speaking'];
export type Skill = typeof VALID_SKILLS[number];

export const VALID_MOMENTS = ['Welcome','Lead-in', 'Language Input', 'Practice', 'Free Practice', 'Project', 'Closing'] as const;
export type MomentType = typeof VALID_MOMENTS[number];

export const VALID_MEDIA = ['image', 'youtube', 'classroomGame', 'activity'];
export type Media = typeof VALID_SKILLS[number];

export interface Step {
	uid?: string;
	name?: string;
	instructions?: string;
	objective?: string;
	material?: string;
	time?: number;
	cefr?: Cefr[];
	age?: Age[];
	skills?: string[];
	moment?: string;
	media?: GenericStepMedia;
	lastUpdate?: string;
	lessons?: string[];
	attachments?: { fileName: string; url: string }[];
}

export interface CreateStepDTO {
	name: string;
	instructions: string;
	objective: string;
	material: string;
	time: number;
	cefr: Cefr[];
	age: Age[];
	skills: string[];
	moment: string;
	media: GenericStepMedia;
	lessons?: string[];
	attachments?: { fileName: string; url: string }[];
}

export interface UpdateStepDTO {
	uid: string;
	name: string;
	instructions: string;
	objective: string;
	material: string;
	time: number;
	cefr: Cefr[];
	age: Age[];
	skills: string[];
	moment: string;
	lessons: string[];
	media: GenericStepMedia;
	attachments?: { fileName: string; url: string }[];
}

export interface GenericImageStepMedia {
    type: "image";
    thumbnailUrl: string;
    content: {
        url: string;
    };
}

export interface GenericYoutubeStepMedia {
    type: "youtube";
    thumbnailUrl: string;
    content: {
        videoId: string;
        startTime: number;
        endTime: number;
    };
}
export interface GenericClassroomGameStepMedia {
    type: "classroomGame";
    thumbnailUrl: string;
    content: {
        name: string;
        gameType: string;
    };
}

export interface GenericActivityStepMedia {
    type: "activity";
    thumbnailUrl: string;
    content: {
		descriptionName : string,
        descriptionId : string,
		groupId ?: string; // legacy
		groupName ?: string; // legacy
		qtdActivities: number;
    };
}

export type GenericStepMedia = GenericImageStepMedia | GenericYoutubeStepMedia | GenericClassroomGameStepMedia | GenericActivityStepMedia;
