import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirebaseError } from '@firebase/util';
import { ToastService } from 'angular-toastify';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(
		public readonly auth: AngularFireAuth,
		public readonly toastService: ToastService,
	) {}

	async loginWithEmailAndPassword(email: string, password: string) {
		try {
			return await this.auth.signInWithEmailAndPassword(email, password);
		} catch(err) {
			this.toastService.error(`${this.convertError((err as FirebaseError).code, 'Failed to login')}`);
			return null;
		}
	}

	async logout() {
		try {
			await this.auth.signOut();
			return true;
		} catch (err) {
			this.toastService.error(`Failed to logout. ${err}`);
			return false;
		}
	}

	convertError(errorCode: string, backup: string): string {
		switch(errorCode) {
			case 'auth/user-not-found':
				return 'User not Found';
			case 'auth/wrong-password':
				return 'Wrong username or Password';
			default:
				return backup;
		}
	}
}
