<div mat-dialog-container>
	<div mat-dialog-title color="primary" class="title" *ngIf="!isUpdate">
		CREATE QUESTION
	</div>
	<div mat-dialog-title color="primary" class="title" *ngIf="isUpdate">
		EDIT QUESTION
	</div>
	<lib-toastify-toast-container position="bottom-right"></lib-toastify-toast-container>
	<div mat-dialog-content class='dialog-content' style="align-items: center;" *ngIf="step === 1">

		<ng-container class="lesson-cefr-content">
			<mat-form-field appearance="outline">
				<mat-label>CEFR</mat-label>
				<mat-select [(ngModel)]="data.cefr" [formControl]="cefrControl">
					<ng-container *ngFor="let cefr of cefrs">
						<mat-option [value]="cefr">{{cefr}}</mat-option>
					</ng-container>
				</mat-select>
				<mat-error *ngIf="cefrControl.hasError('required')">
					Question cefr is <strong>required</strong>
				</mat-error>
			</mat-form-field>
		</ng-container>

		<ng-container class="lesson-game-content">
			<mat-form-field appearance="outline">
				<mat-label>Game</mat-label>
				<mat-select [(ngModel)]="gameType">
					<ng-container *ngFor="let game of gameTypes">
						<mat-option [value]="game" *ngIf="game.cefr === cefrControl.value">{{game.name}}</mat-option>
					</ng-container>
				</mat-select>
				<mat-error *ngIf="gameType === undefined">
					Game type is <strong>required</strong>
				</mat-error>
			</mat-form-field>
		</ng-container>

		<ng-container class="lesson-name-content">
			<mat-form-field appearance="outline">
				<mat-label>Name</mat-label>
				<input matInput [(ngModel)]="data.name" [formControl]="nameControl">
				<button *ngIf="data.name" matSuffix mat-icon-button aria-label="Clear" (click)="data.name=''">
					<mat-icon>close</mat-icon>
				</button>
				<mat-error *ngIf="nameControl.hasError('required')">
					Question name is <strong>required</strong>
				</mat-error>
			</mat-form-field>
		</ng-container>

	</div>

	<div mat-dialog-content class='dialog-content' style="align-items: center;"
		*ngIf="step === 2 && gameType?.activity === 'text-fill-the-gaps';">
		<app-text-media [data]="data" [isUpdate]="isUpdate"
			(questionUploaded)="questionUploaded($event)"></app-text-media>
	</div>

	<div mat-dialog-content class='dialog-content' style="align-items: center;"
		*ngIf="step === 2 && gameType?.activity === 'ordering-dialogue';">
		<app-dialogue-media [data]="data" [isUpdate]="isUpdate"
			(questionUploaded)="questionUploaded($event)"></app-dialogue-media>
	</div>

	<div mat-dialog-content class='dialog-content' style="align-items: center;"
		*ngIf="step === 2 && gameType?.activity === 'speaking-dialogue';">
		<app-speaking-dialogue-media [data]="data" [isUpdate]="isUpdate"
			(questionUploaded)="questionUploaded($event)"></app-speaking-dialogue-media>
	</div>

	<div mat-dialog-content class='dialog-content'
		*ngIf="step === 2 && gameType?.activity === 'multichoice-placement';">
		<app-multichoice-placement-media [data]="data" [isUpdate]="isUpdate"
			(questionUploaded)="questionUploaded($event)"></app-multichoice-placement-media>
	</div>

	<div mat-dialog-content class='dialog-content' *ngIf="step === 2 && gameType?.activity === 'bubble-quiz-match';">
		<app-match-game-media [data]="data" [isUpdate]="isUpdate"
			(questionUploaded)="questionUploaded($event)"></app-match-game-media>
	</div>

	<div mat-dialog-content class='dialog-content' style="align-items: center;"
		*ngIf="step === 2 && gameType?.activity === 'drag-drop-placement';">
		<app-drag-drop-media [data]="data" [isUpdate]="isUpdate"
			(questionUploaded)="questionUploaded($event)"></app-drag-drop-media>
	</div>

	<div mat-dialog-content class='dialog-content' style="align-items: center;"
		*ngIf="step === 2 && gameType?.activity === 'coloring-placement';">
		<app-coloring-media [data]="data" [isUpdate]="isUpdate"
			(questionUploaded)="questionUploaded($event)"></app-coloring-media>
	</div>

	<div mat-dialog-content class='dialog-content' style="align-items: center;"
		*ngIf="step === 2 && gameType?.activity === 'ordering-letters';">
		<app-ordering-letters-media [data]="data" [isUpdate]="isUpdate"
			(questionUploaded)="questionUploaded($event)"></app-ordering-letters-media>
	</div>

	<div mat-dialog-content class='dialog-content' style="align-items: center;"
		*ngIf="step === 2 && gameType?.activity === 'multichoice-fill-the-gaps';">
		<app-multichoice-fillthegaps-media [data]="data" [isUpdate]="isUpdate"
			(questionUploaded)="questionUploaded($event)"></app-multichoice-fillthegaps-media>
	</div>

	<div mat-dialog-content class='dialog-content' style="align-items: center;"
		*ngIf="step === 2 && gameType?.activity === 'fill-the-gaps-image';">
		<app-fill-the-gaps-image-media [data]="data" [isUpdate]="isUpdate"
			(questionUploaded)="questionUploaded($event)"></app-fill-the-gaps-image-media>
	</div>

	<div mat-dialog-actions class="actions-container">
		<button [disabled]="isUploading" mat-button (click)="closeDialog()">Discard</button>
		<button [disabled]="gameType === undefined || cefrControl.value === undefined || nameControl.value === ''"
			mat-button (click)="nextStep()" *ngIf="step === 1" cdkFocusInitial>Next</button>
		<button [disabled]="isUploading" mat-button (click)="backStep()" *ngIf="step === 2">Back</button>
		<button [disabled]="isUploading" mat-button (click)="verifyAndUpload()" *ngIf="step === 2"
			cdkFocusInitial>Save</button>
		<mat-spinner *ngIf="isUploading" [diameter]="50"></mat-spinner>
	</div>