import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { ToastService } from "angular-toastify";
import { Subscription } from "rxjs";
import { VALID_AGES, VALID_CEFRS } from "src/app/shared/constants";
import { Skill } from "src/app/shared/models/classroom-games.model";
import { ILesson } from "src/app/shared/models/lesson.model";
import {
	Step,
	VALID_MOMENTS,
	MomentType,
	UpdateStepDTO,
	CreateStepDTO,
	GenericStepMedia,
} from "src/app/shared/models/steps.model";
import { FileUploaderService } from "src/app/shared/services/file-uploader.service";
import { LessonService } from "src/app/shared/services/lesson.service";
import { StepsService } from "src/app/shared/services/steps.service";
import { StepsStore } from "src/app/shared/services/stores/steps.store.service";
import { environment } from "src/environments/environment";
import { LessonSelectorComponent } from "./lesson-selector/lesson-selector.component";
import { MediaSelectorComponent } from "./media-selector/media-selector.component";

@Component({
	selector: "app-step-editor",
	templateUrl: "./step-editor.component.html",
	styleUrls: ["./step-editor.component.scss"],
})
export class StepEditorComponent implements OnInit, OnDestroy {
	stepId!: string;
	selectedMedia: GenericStepMedia | undefined;
	selectedLessons!: ILesson[];

	stepGroup = new FormGroup({
		name: new FormControl("", [Validators.required]),
		objective: new FormControl("", [Validators.required]),
		material: new FormControl("", [Validators.required]),
		instructions: new FormControl("", [Validators.required]),
		moment: new FormControl("", [Validators.required]),
		cefr: new FormControl([], [Validators.required]),
		age: new FormControl([], [Validators.required]),
		time: new FormControl(0, [Validators.required, Validators.min(1)]),
		skills: new FormControl(
			[],
			[Validators.required, Validators.minLength(1)]
		),
		lessons: new FormControl(""),
		attachments: new FormControl([]),
	});

	resourceUrl!: string | undefined;
	validMoments = VALID_MOMENTS;
	validCefrs = VALID_CEFRS;
	validAges = VALID_AGES;
	stepLoading = true;
	attachmentLoading = false;

	subscriptions: Subscription;

	constructor(
		private route: ActivatedRoute,
		private toastService: ToastService,
		private dialog: MatDialog,
		private stepsStore: StepsStore,
		private stepsService: StepsService,
		private lessonService: LessonService,
		private fileUploadServer: FileUploaderService
	) {
		this.subscriptions = new Subscription();
	}

	ngOnInit() {
		const param = this.route.snapshot.paramMap.get("stepId");
		if (param) {
			this.stepId = param;
			const stepSub = this.stepsService
				.getStep(this.stepId)
				.subscribe((res) => {
					// this.step = res;
					this.stepGroup.get("name")?.setValue(res.name);
					this.stepGroup.get("objective")?.setValue(res.objective);
					this.stepGroup.get("material")?.setValue(res.material);
					this.stepGroup.get("cefr")?.setValue(res.cefr);
					this.stepGroup.get("age")?.setValue(res.age);
					this.stepGroup
						.get("instructions")
						?.setValue(res.instructions);
					this.stepGroup.get("moment")?.setValue(res.moment);
					this.stepGroup.get("time")?.setValue(res.time);
					this.stepGroup.get("skills")?.setValue(res.skills);
					if (res.attachments) {
						this.stepGroup
							.get("attachments")
							?.setValue(res.attachments);
					}
					this.selectedMedia = res.media;
					if (this.selectedMedia) {
						this.setStepResourceUrl(this.selectedMedia);
					}
					if (res.lessons != null && res.lessons.length > 0) {
						this.loadLessons(res);
					} else {
						this.selectedLessons = [];
					}
					this.stepLoading = false;
				});
			this.subscriptions.add(stepSub);
		} else {
			this.selectedLessons = [];
			this.stepLoading = false;
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	loadLessons(step: Step) {
		this.lessonService.getLessons().subscribe((res) => {
			this.selectedLessons = res.filter((lesson) =>
				step.lessons?.includes(lesson.uid)
			);
		});
	}

	openMediaSelector(media?: GenericStepMedia) {
		// open dialog with media: GenericStepMedia if media is defined
		this.dialog
			.open(MediaSelectorComponent, {
				data: media,
			})
			.afterClosed()
			.subscribe((media: GenericStepMedia | undefined) => {
				if (media) {
					this.selectedMedia = media;
					this.setStepResourceUrl(media);
				}
			});
	}

	removeMedia() {
		this.selectedMedia = undefined;
	}

	setSkill(skill: string) {
		let skills: Skill[] = [...this.stepGroup.get("skills")?.value];
		if (skills.includes(skill)) {
			skills.splice(skills?.indexOf(skill), 1);
			this.stepGroup.get("skills")?.setValue(skills);
			// this.step.skills?.splice(this.step.skills?.indexOf(skill), 1);
		} else {
			// this.step.skills?.push(skill);
			this.stepGroup.get("skills")?.setValue([...skills, skill]);
		}
	}

	uploadStep() {
		if (!this.stepGroup.valid) {
			Object.keys(this.stepGroup.controls).forEach((control) => {
				const controlErrors = this.stepGroup.get(control)?.errors;
				console.log(controlErrors);
				if (controlErrors != null) {
					this.toastService.error(`failed to select ${control}.`);
					this.stepGroup.get("name")?.markAsTouched();
				}
			});
			return;
		}

		if (!this.selectedMedia) {
			this.toastService.error("failed to select media.");
			return;
		}

		if (this.stepId) {
			this.updateStep();
		} else {
			this.createStep();
		}
	}

	setStepResourceUrl(media: GenericStepMedia) {
		if (media.type === "youtube") {
			this.resourceUrl = `https://www.youtube.com/embed/${media.content.videoId}?start=${media.content.startTime}&end=${media.content.endTime}`;
		} else {
			this.resourceUrl = media.thumbnailUrl;
		}
	}

	private createStep() {
		const createBody: CreateStepDTO = {
			age: this.stepGroup.get("age")?.value,
			name: this.stepGroup.get("name")?.value,
			objective: this.stepGroup.get("objective")?.value,
			material: this.stepGroup.get("material")?.value,
			instructions: this.stepGroup.get("instructions")?.value,
			cefr: this.stepGroup.get("cefr")?.value,
			moment: this.stepGroup.get("moment")?.value,
			time: this.stepGroup.get("time")?.value,
			lessons: this.selectedLessons.map((lesson) => lesson.uid),
			skills: this.stepGroup.get("skills")?.value,
			media: this.selectedMedia as GenericStepMedia,
			attachments: this.stepGroup.get("attachments")?.value,
		};
		this.stepsStore.createStep(createBody);
	}

	private updateStep() {
		const updateBody: UpdateStepDTO = {
			uid: this.stepId,
			age: this.stepGroup.get("age")?.value,
			name: this.stepGroup.get("name")?.value,
			objective: this.stepGroup.get("objective")?.value,
			material: this.stepGroup.get("material")?.value,
			instructions: this.stepGroup.get("instructions")?.value,
			cefr: this.stepGroup.get("cefr")?.value,
			moment: this.stepGroup.get("moment")?.value,
			time: this.stepGroup.get("time")?.value,
			lessons: this.selectedLessons.map((lesson) => lesson.uid),
			skills: this.stepGroup.get("skills")?.value,
			media: this.selectedMedia as GenericStepMedia,
			attachments: this.stepGroup.get("attachments")?.value,
		};

		this.stepsStore.updateStep(updateBody, this.stepId);
	}

	setMomentClass(moment: MomentType) {
		if (moment === "Welcome") {
			return "welcome";
		} else if (moment === "Lead-in") {
			return "lead-in";
		} else if (moment === "Language Input") {
			return "language-input";
		} else if (moment === "Practice") {
			return "practice";
		} else if (moment === "Free Practice") {
			return "free-practice";
		} else if (moment === "Project") {
			return "project";
		} else {
			return "closing";
		}
	}

	getQtdActivities() {
		if (this.selectedMedia?.type === "activity") {
			return this.selectedMedia?.content.qtdActivities;
		}

		return 0;
	}

	getContentName() {
		if (this.selectedMedia?.type === "activity") {
			return this.selectedMedia?.content.descriptionName;
		}

		if (this.selectedMedia?.type === "classroomGame") {
			return this.selectedMedia?.content.name;
		}

		return "Buscar conteúdo";
	}

	openLessonSelector() {
		this.dialog
			.open(LessonSelectorComponent, { data: this.selectedLessons })
			.afterClosed()
			.subscribe((lessons: ILesson[] | undefined) => {
				if (!lessons) {
					return;
				}
				this.selectedLessons = lessons;
				this.stepGroup
					.get("cefr")
					?.setValue(
						this.selectedLessons.map((lesson) => lesson.cefr)
					);
				this.stepGroup
					.get("age")
					?.setValue(
						this.selectedLessons.map((lesson) => lesson.age)
					);
			});
	}

	getSelectedLessons() {
		if (this.selectedLessons && this.selectedLessons.length === 0) {
			return "Nenhuma lição selecionada";
		}

		return this.selectedLessons
			.map((lesson) => lesson.displayName)
			.join(",");
	}

	uploadFile(event: any) {
		this.attachmentLoading = true;
		const file = event.target.files[0];
		if (file.type !== "application/pdf") {
			this.toastService.error("File should be PDF");
			this.attachmentLoading = false;
			return;
		}

		if (file.size > 15000000) {
			this.toastService.error("File size should be less than 10MB");
			this.attachmentLoading = false;
			return;
		}

		this.fileUploadServer.uploadStepFile(file).subscribe((res) => {
			if (res) {
				this.attachmentLoading = false;
				this.stepGroup
					.get("attachments")
					?.value.push({ fileName: file.name, url: res.url });
				if (this.stepId) {
					this.updateStep();
				}
				this.attachmentLoading = false;
			} else {
				this.toastService.error("File upload failed");
				this.attachmentLoading = false;
			}
		});
	}

	deleteFile(index: number) {
		this.attachmentLoading = true;
		const splitUrl =
			this.stepGroup.get("attachments")?.value[index].url.split("/") ||
			[];
		let fileId = splitUrl[5];
		fileId = fileId?.substring(0, fileId.lastIndexOf("."));
		this.fileUploadServer.deleteStepFile(fileId).subscribe((res) => {
			if (res !== false) {
				this.stepGroup.get("attachments")?.value.splice(index, 1);
				if (this.stepId) {
					this.updateStep();
				}
				this.attachmentLoading = false;
			} else {
				this.toastService.error("File delete failed");
				this.attachmentLoading = false;
			}
		});
	}
}
