import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularToastifyModule, ToastService } from 'angular-toastify';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarRoutingModule } from '../navbar-routing.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { LessonsClassroomGamesComponent } from "./lessons-classroom-games.component";

@NgModule({
	declarations: [LessonsClassroomGamesComponent],
	imports: [
		NavbarRoutingModule,
		AngularFirestoreModule,
		AngularToastifyModule,
		CommonModule,
		FormsModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatDialogModule,
		MatIconModule,
		MatInputModule,
		MatPaginatorModule,
		MatSelectModule,
		MatSortModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatToolbarModule,
		MatGridListModule,
		NgxFileDropModule,
		ReactiveFormsModule,
		SharedModule,
	],
	providers: [ToastService],
})
export class LessonsClassroomGamesModule {}
