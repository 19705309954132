import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICreateLessonProgramBody, ILessonProgram } from '../models/programs.model';

@Injectable({
	providedIn: 'root'
})
export class ProgramService {

	constructor(
		private http: HttpClient,
	) { }

	public listPrograms(): Observable<ILessonProgram[]> {
		return this.http.get<ILessonProgram[]>(`${environment.SERVICES.LESSON}programs`);
	}

	public registerProgram(body: ICreateLessonProgramBody): Observable<{ programId: string }> {
		return this.http.post<{ programId: string }>(`${environment.SERVICES.LESSON}programs`, body);
	}

	public updateProgram(programId: string, body: ICreateLessonProgramBody): Observable<void> {
		return this.http.put<void>(`${environment.SERVICES.LESSON}programs/${programId}`, body);
	}

	public deleteProgram(programId: string): Observable<void> {
		return this.http.delete<void>(`${environment.SERVICES.LESSON}programs/${programId}`);
	}
}
