<ng-container>
  <h3>FILL THE GAPS TEXT</h3>

  <ng-container class="lesson-text-content">
    <mat-form-field appearance="outline">
      <mat-label>Text</mat-label>
      <textarea matInput [formControl]="textControl"></textarea>
      <button *ngIf="textControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="textControl.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="textControl.hasError('required')">
        Text is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="lesson-image-content">
		<mat-form-field appearance="outline">
			<mat-label>Image ID</mat-label>
			<input matInput [ngModel]="image" (ngModelChange)="changeImage($event)">
			<button *ngIf="image" matSuffix mat-icon-button aria-label="Clear" (click)="changeImage('')">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<button mat-button [disabled]="image === ''" class="verify-button" (click)="verifyImage()">Verify Image</button>
	</ng-container>

	<ng-container>
		<app-image-uploader *ngIf="toVerify" [imageId]="imageVerify" [isPlacement]="true" (uploadFiles)="setFile($event)" (exists)="imageExists=true; image = $event"></app-image-uploader>
	</ng-container>

</ng-container>