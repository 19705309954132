<div class="content-view">
	<lib-toastify-toast-container
		position="bottom-right"
	></lib-toastify-toast-container>
	<div class="header">
		<mat-toolbar class="header" color="primary">
			<button mat-icon-button color="white" (click)="home()">
				<mat-icon>home</mat-icon>
			</button>
			<div class="start-header">
				<h1 class="title">{{title}}</h1>
			</div>
			<div class="end-header">
				<button mat-mini-fab color="white" (click)="logout()">
					<mat-icon>logout</mat-icon>
				</button>
			</div>
		</mat-toolbar>
	</div>

</div>