import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastService } from "angular-toastify";
import { finalize } from "rxjs";
import { Age, Skill, VALID_AGES, VALID_SKILLS } from "src/app/shared/constants";
import { IActivityDescription } from "src/app/shared/models/activity-description.model";

import { GenericActivityStepMedia, GenericStepMedia } from "src/app/shared/models/steps.model";
import { ActivityDescriptionService } from "src/app/shared/services/activity-description.service";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-activity-selector",
	templateUrl: "./activity-selector.component.html",
	styleUrls: ["./activity-selector.component.scss"],
})
export class ActivitySelectorComponent implements OnInit {
	@Input() data?: GenericStepMedia;
	@Output() mediaSubmitted: EventEmitter<GenericStepMedia> = new EventEmitter();

	activityDescriptions!: IActivityDescription[];
	filteredActivityDescriptions!: IActivityDescription[];
	activitiesLoading = false;
	uploadingImg = false;

	currentKeyword = "";
	availableSkills: Set<Skill> = new Set(VALID_SKILLS);
	availableAges: Set<Age> = new Set(VALID_AGES);
	filterGroup = new FormGroup({
		skills: new FormControl(''),
		ages: new FormControl(''),
		content: new FormControl(""),
	});

	selectedActivity!: IActivityDescription;
	imageUrl!: string;
	qtdActivities = new FormControl(0, [Validators.min(1)]);

	imageToUpload: File | null = null;

	constructor(
		private imageUploadServer: ImageUploaderService,
		private toastService: ToastService,
		private activityDescriptionService: ActivityDescriptionService
	) { }

	ngOnInit() {
		if(this.data && this.data.type === 'activity') {
			this.imageUrl = this.data.thumbnailUrl;
			const input = document.querySelector(".file-upload-wrapper");
			if (input) {
				input.setAttribute("data-text", "Alterar imagem");
			}
			this.qtdActivities.setValue(this.data.content.qtdActivities);
		}
		this.activityDescriptions = [];
		this.filteredActivityDescriptions = [];
		this.loadActivityDescriptions();
	}

	private loadActivityDescriptions() {
		this.activitiesLoading = true;

		this.activityDescriptionService
			.getActivityDescriptions()
			.pipe(
				finalize(() => this.activitiesLoading = false)
			).subscribe((res) => {
				this.activityDescriptions = res;
				this.filteredActivityDescriptions = this.activityDescriptions;
				if(this.data && this.data.type === 'activity') {
					this.selectedActivity = this.activityDescriptions.find((activity) => activity.uid === (this.data as GenericActivityStepMedia).content.descriptionId) as IActivityDescription;
				}
			})
	}

	public selectActivity(activity : IActivityDescription) {
		this.selectedActivity = activity;
	}

	filterActivities() {
		console.log('filtering');
		const filters: ((param: any) => boolean)[] = [
			this.skillFilter.bind(this),
			this.ageFilter.bind(this),
			this.contentFilter.bind(this),
		];

		this.filteredActivityDescriptions = this.activityDescriptions.filter((activity) =>
			filters.every((filter) => filter(activity))
		);
	}

	private skillFilter(activity: IActivityDescription): boolean {
		const targetSkill = this.filterGroup.get("skills")?.value as string;

		if(targetSkill === '' || targetSkill === undefined) {
			return true;
		}

		if (activity.skill === targetSkill) {
			return true;
		}

		return false;
	}

	private ageFilter(activity : IActivityDescription) : boolean {
		const targetAge = this.filterGroup.get("ages")?.value as string;

		if(targetAge === '' || targetAge === undefined) {
			return true;
		}

		if (activity.ages.includes(targetAge as Age)) {
			return true;
		}

		return false;

	}

	private contentFilter(activity: IActivityDescription): boolean {
		if (this.currentKeyword.length <= 0) {
			return true;
		}

		if (activity.name.toLowerCase().includes(this.currentKeyword)) {
			return true;
		}

		return false;
	}

	filterByKeyword(keyword: string) {
		this.currentKeyword = keyword.toLowerCase();
		this.filterActivities();
	}

	setIcon(skill: string) {
		if (skill === "listening") {
			return "hearing";
		} else if (skill === "reading") {
			return "chrome_reader_mode";
		} else if (skill === "writing") {
			return "create";
		} else {
			return "mic";
		}
	}

	handleImageInput(event: Event) {
		const target = event.target as HTMLInputElement;
		const files = target.files as FileList;
		this.imageToUpload = files.item(0);

		const input = document.querySelector(".file-upload-wrapper");
		if (input) {
			input.setAttribute("data-text", this.imageToUpload?.name ?? 'Selecione uma imagem');
		}
	}

	submitActivitiesStep() {
		let canUpload = true;

		if (this.qtdActivities.value === 0) {
			this.toastService.error('Activity quantity is missing.');
			this.qtdActivities.markAsTouched();
			canUpload = false;
		}

		if (!canUpload) {
			return;
		}

		if (this.imageToUpload) {
			this.uploadWithImage();
		} else if (!this.imageUrl) {
			this.imageUrl = `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_dungeon_activities.png`;

			this.mediaSubmitted.emit({ type: 'activity', thumbnailUrl: this.imageUrl as string, content: { descriptionId: this.selectedActivity.uid, descriptionName: this.selectedActivity.name, qtdActivities: this.qtdActivities.value as number } });
		} else {
			this.mediaSubmitted.emit({ type: 'activity', thumbnailUrl: this.imageUrl as string, content: { descriptionId: this.selectedActivity.uid, descriptionName: this.selectedActivity.name, qtdActivities: this.qtdActivities.value as number } });
		}
	}


	private uploadWithImage() {
		this.uploadingImg = true;
		if (this.imageToUpload) {
			const imageBlob = new Blob([this.imageToUpload], { type: this.imageToUpload.type });

			if (!(imageBlob.type === 'image/jpeg' || imageBlob.type === 'image/png')) {
				this.toastService.error('File should be JPEG or PNG image');
				this.uploadingImg = false;
				return;
			}

			this.imageUploadServer.uploadStepImage(imageBlob).subscribe(res => {
				this.imageUrl = res.url;
				this.uploadingImg = false;
				this.mediaSubmitted.emit({ type: 'activity', thumbnailUrl: this.imageUrl as string, content: { descriptionId: this.selectedActivity.uid, descriptionName: this.selectedActivity.name, qtdActivities: this.qtdActivities.value as number } });
			})
		}
	}
}
