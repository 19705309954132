<div class="audio-content" style="align-items: center; margin-top: 15px;">
  <ng-container style="position: inherit;">
    <mat-spinner *ngIf="loading"></mat-spinner>
  </ng-container>
  <ng-container class="step-one" *ngIf="!loading && audioURL === undefined">
    <ngx-file-drop 
    dropZoneLabel="Drop files here"
    [multiple]="false"
    (onFileDrop)="dropped($event)"
    class="filedrop">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <ng-container style="position: inherit;">
          <mat-spinner *ngIf="audioUploading"></mat-spinner>
        </ng-container>
        <div
            class="file-container"
            *ngIf="!audioUploading"
            (click)="openFileSelector()"
          >
            <div class="space"></div>
            <span class="info">Arraste até aqui ou anexe um áudio</span>
            <mat-icon>attach_file</mat-icon>
            <span class="action-title" >Anexar</span>
          </div>

      </ng-template>   
    </ngx-file-drop>
  </ng-container>

  <ng-container class="step-two" *ngIf="!loading && audioURL !== undefined">
    <div class="audio-card">
      <mat-icon class="audio-icon">volume_up</mat-icon>
      <div class="info">
        <span class="title">{{ audioName }}</span>
      </div>
      <mat-icon
        (click)="deleteAudio()"
        class="remove"
        nbTooltipStatus="primary"
      >close</mat-icon>
    </div>
  </ng-container>
</div>