<div class="dialog">
  <span mat-dialog-title color="primary" class="media-title">DRAG AND DROP TEXT</span>

  <div mat-dialog-content class="drag-drop-content" style="align-items: center">
    <mat-stepper orientation="vertical" [linear]="true" #stepper>
      <mat-step [completed]="completed1">
        <ng-template matStepLabel>Step 1 - Select Background</ng-template>
        <ng-template matStepContent>
          <ng-container class="image-content">
            <mat-form-field appearance="outline">
              <mat-label>ID</mat-label>
              <input matInput [ngModel]="image" (ngModelChange)="changeImage($event)" />
              <button *ngIf="image" matSuffix mat-icon-button aria-label="Clear" (click)="changeImage('')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <button mat-button [disabled]="image === ''" class="verify-button" (click)="verifyImage()">
              Verify Image
            </button>
          </ng-container>

          <ng-container>
            <app-image-uploader *ngIf="imageToVerify" [isPlacement]="true" [extension]="'png'" [imageId]="imageVerify"
              (uploadFiles)="setFile($event)" (exists)="
                imageExists = true;
                image = $event;
                completed1 = true
              ">
            </app-image-uploader>
          </ng-container>

          <div>
            <button mat-button matStepperNext [disabled]="!completed1">
              Next
            </button>
          </div>
        </ng-template>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Step 2 - Add Targets</ng-template>
        <ng-template matStepContent>
          <div class="target-content">
            <div class="image-bound-box">
              <div id="bound-box" class="image-container" cdkDrop>
                <img [src]="getImage()" style="width: 100%; height: 100%" />
                <div #dragElement *ngFor="let target of targets; let lineIndex = index" class="drag-element"
                  cdkDragBoundary=".image-container" cdkDrag (cdkDragEnded)="onDragEnded($event, target, lineIndex)"
                  mwlResizable [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event, target, lineIndex)">
                  TARGET {{ lineIndex + 1 }}
                  <div class="resize-handle-top" mwlResizeHandle [resizeEdges]="{ top: true }"></div>
                  <div class="resize-handle-left" mwlResizeHandle [resizeEdges]="{ left: true }"></div>
                  <div class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
                  <div class="resize-handle-bottom" mwlResizeHandle [resizeEdges]="{ bottom: true }"></div>
                </div>
              </div>
            </div>
            <div class="target-creator">
              <ng-container class="statement-content">

                <h4>Audio Tutorial A</h4>
                <ng-container>
                  <app-audio-uploader [audioId]="audioTutorialAUrl"
                    (audioUploaded)="audioTutorialAUploaded($event)"></app-audio-uploader>
                </ng-container>

                <h4>Audio Tutorial B</h4>
                <ng-container>
                  <app-audio-uploader [audioId]="audioTutorialBUrl"
                    (audioUploaded)="audioTutorialBUploaded($event)"></app-audio-uploader>
                </ng-container>

                <h4>Audio Content</h4>
                <ng-container>
                  <app-audio-uploader [audioId]="audioUrl" (audioUploaded)="audioUploaded($event)"></app-audio-uploader>
                </ng-container>
              </ng-container>
              <ng-container class="target">
                <div class="header">
                  <mat-toolbar class="header" color="primary">
                    <div class="start-header" style="width: 50%">
                      <h1 class="title">Targets</h1>
                    </div>
                    <div class="end-header" style="
                        display: flex;
                        flex-direction: row-reverse;
                        width: 50%;
                      ">
                      <button mat-mini-fab color="white" (click)="addTarget()">
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </mat-toolbar>
                </div>
              </ng-container>
              <ng-container class="targets" *ngFor="let target of targets; let lineIndex = index">
                <mat-card class="drag-drop-target">
                  <div class="target-info-div">
                    <div style="margin-right: 20px">
                      <p style="font-size: 20px; font-weight: bold">
                        Target {{ lineIndex + 1 }}
                      </p>
                    </div>
                    <div style="font-size: 16px">
                      {{ getTargetInfo(target) }}
                    </div>
                  </div>
                  <div class="delete-div">
                    <button mat-icon-button class="delete-button" (click)="removeTarget(lineIndex)">
                      <mat-icon color="warn">delete</mat-icon>
                    </button>
                  </div>
                </mat-card>
              </ng-container>
            </div>
          </div>

          <div>
            <button mat-button matStepperNext>Next</button>
          </div>
        </ng-template>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Step 3 - Add Elements</ng-template>
        <ng-template matStepContent>
          <div class="header">
            <mat-toolbar class="header" color="primary">
              <div class="start-header" style="width: 50%">
                <h1 class="title">Elements</h1>
              </div>
              <div class="end-header" style="display: flex; flex-direction: row-reverse; width: 50%">
                <button mat-mini-fab color="white" (click)="addElement()">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </mat-toolbar>
          </div>
          <div class="elements-accordion">
            <mat-accordion>
              <mat-expansion-panel hideToggle *ngFor="let element of elements; let lineIndex = index">
                <mat-expansion-panel-header>
                  <mat-panel-title style="align-items: center">
                    Element {{ lineIndex + 1 }}
                  </mat-panel-title>
                  <mat-panel-description style="align-items: center">
                    <div style="display: flex; width: 95%">
                      {{ element.text }}
                    </div>
                    <div class="delete-div">
                      <button mat-icon-button class="delete-button" (click)="removeElement(lineIndex)">
                        <mat-icon color="warn">delete</mat-icon>
                      </button>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                  <ng-container class="name-content">
                    <mat-form-field appearance="outline">
                      <mat-label>Name</mat-label>
                      <input matInput [(ngModel)]="element.text" />
                      <button *ngIf="element.text" matSuffix mat-icon-button aria-label="Clear"
                        (click)="element.text = ''">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>

                    <ng-container class="name-content">
                      <mat-form-field appearance="outline">
                        <mat-label>Targets</mat-label>
                        <mat-select multiple [value]="getSelected(element)">
                          <mat-option *ngFor="
                              let target of targets;
                              let targetIndex = index
                            " [value]="getSelectionValue(targetIndex)" (onSelectionChange)="
                              toggleTarget($event, targetIndex, lineIndex)
                            ">Target {{ targetIndex + 1 }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <div>
            <div class="header">
              <mat-toolbar class="header" color="primary">
                <div class="start-header" style="width: 50%">
                  <h1 class="title">Wrong Elements</h1>
                </div>
                <div class="end-header" style="display: flex; flex-direction: row-reverse; width: 50%">
                  <button mat-mini-fab color="white" (click)="addWrongElement()">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </mat-toolbar>
            </div>
            <mat-accordion>
              <mat-expansion-panel hideToggle *ngFor="let wrongElement of wrongElements; let wrongIndex = index">
                <mat-expansion-panel-header>
                  <mat-panel-title style="align-items: center">
                    Wrong Element {{ wrongIndex + 1 }}
                  </mat-panel-title>
                  <mat-panel-description style="align-items: center">
                    <div style="display: flex; width: 95%">
                      {{ wrongElement }}
                    </div>
                    <div class="delete-div">
                      <button mat-icon-button class="delete-button" (click)="removeWrongElement(wrongIndex)">
                        <mat-icon color="warn">delete</mat-icon>
                      </button>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                  <ng-container class="name-content">
                    <mat-form-field appearance="outline">
                      <mat-label>Name</mat-label>
                      <input matInput [(ngModel)]="wrongElements[wrongIndex]" />
                      <button *ngIf="wrongElements[wrongIndex]" matSuffix mat-icon-button aria-label="Clear"
                        (click)="wrongElements[wrongIndex] = ''">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </ng-container>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>
</div>