<div class="dialog">
  <span mat-dialog-title color="primary" class="media-title">COLORING</span>

  <div mat-dialog-content class="drag-drop-content" style="align-items: center">
    <mat-stepper orientation="vertical" [linear]="true" #stepper>
      <mat-step [completed]="completed1">
        <ng-template matStepLabel>Step 1 - Select Background</ng-template>
        <ng-template matStepContent>
          <ng-container class="image-content">
            <mat-form-field appearance="outline">
              <mat-label>ID</mat-label>
              <input matInput [ngModel]="image" (ngModelChange)="changeImage($event)" />
              <button *ngIf="image" matSuffix mat-icon-button aria-label="Clear" (click)="changeImage('')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <button mat-button [disabled]="image === ''" class="verify-button" (click)="verifyImage()">
              Verify Image
            </button>
          </ng-container>

          <ng-container>
            <app-image-uploader *ngIf="imageToVerify" [isPlacement]="true" [imageId]="imageVerify"
              (uploadFiles)="setFile($event)" (exists)="
                imageExists = true;
                image = $event;
                completed1 = true
              ">
            </app-image-uploader>
          </ng-container>

          <div>
            <button mat-button matStepperNext [disabled]="!completed1">
              Next
            </button>
          </div>
        </ng-template>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Step 2 - Add Targets</ng-template>
        <ng-template matStepContent>
          <div class="target-content">
            <div class="image-bound-box">
              <div #boundBox id="bound-box" class="image-container" cdkDrop (click)="addPointToGroup($event)"
                style="position: relative;">
                <img [src]="getImage()" style="width: 100%; height: 100%" />
                <ng-container *ngIf="selectedGroupId !== null">
                  <div *ngFor="let point of targetGroups[selectedGroupId].points" class="point"
                  [ngStyle]="{
                    'left': ((point.x + 1) / 2 * boundBox.clientWidth) + 'px',
                    'top': ((1 - point.y) / 2 * boundBox.clientHeight) + 'px',
                    'background-color': targetGroups[selectedGroupId].color
                  }">
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="target-creator">
              <ng-container class="statement-content">

                <h4>Audio Tutorial A</h4>

                <div class="audio-uploader">
                  <app-audio-uploader [audioId]="audioTutorialAUrl" (audioUploaded)="audioTutorialAUploaded($event)"></app-audio-uploader>
                </div>

                <h4>Audio Tutorial B</h4>

                <div class="audio-uploader">
                  <app-audio-uploader [audioId]="audioTutorialBUrl" (audioUploaded)="audioTutorialBUploaded($event)"></app-audio-uploader>
                </div>

                <h4>Audio Content</h4>

                <div class="audio-uploader">
                  <app-audio-uploader [audioId]="audioUrl" (audioUploaded)="audioUploaded($event)"></app-audio-uploader>
                </div>
              </ng-container>

              <ng-container class="target">
                <div class="header">
                  <mat-toolbar class="header" color="primary">
                    <div class="start-header" style="width: 50%">
                      <h1 class="title">Targets</h1>
                    </div>
                    <div class="end-header" style="
                        display: flex;
                        flex-direction: row-reverse;
                        width: 50%;
                      ">
                      <button mat-mini-fab color="white" (click)="addTargetStart()">
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </mat-toolbar>
                  <mat-toolbar class="body" color="primary">
                    <div class="colors">
                      <button mat-mini-fab class="colorButton" (click)="addTargetGroup(0)"
                        style="background-color: #FF0D0D;" [disabled]="!colorSelector">
                      </button>
                      <button mat-mini-fab class="colorButton" (click)="addTargetGroup(1)"
                        style="background-color: #FF00E4;" [disabled]="!colorSelector">
                      </button>
                      <button mat-mini-fab class="colorButton" (click)="addTargetGroup(2)"
                        style="background-color: #409CE9;" [disabled]="!colorSelector">
                      </button>
                      <button mat-mini-fab class="colorButton" (click)="addTargetGroup(3)"
                        style="background-color: #47A237;" [disabled]="!colorSelector">
                      </button>
                      <button mat-mini-fab class="colorButton" (click)="addTargetGroup(4)"
                        style="background-color: #FFCE21;" [disabled]="!colorSelector">
                      </button>
                      <button mat-mini-fab class="colorButton" (click)="addTargetGroup(5)"
                        style="background-color: #FF8E00;" [disabled]="!colorSelector">
                      </button>
                      <button mat-mini-fab class="colorButton" (click)="addTargetGroup(6)"
                        style="background-color: #743212;" [disabled]="!colorSelector">
                      </button>
                      <button mat-mini-fab class="colorButton" (click)="addTargetGroup(7)"
                        style="background-color: #6C635D;" [disabled]="!colorSelector">
                      </button>
                    </div>

                  </mat-toolbar>
                </div>
              </ng-container>
              <div *ngFor="let group of targetGroups; let i = index">
                <mat-card class="target-group-card" (click)="selectTargetGroup(i)"
                  [class.selected]="i === selectedGroupId">
                  Grupo de Alvos {{ i + 1 }} - Cor: {{ group.color }}
                  <button mat-icon-button class="delete-button"
                    (click)="removeTargetGroup(i); $event.stopPropagation();">
                    <mat-icon color="warn">delete</mat-icon>
                  </button>
                </mat-card>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>
</div>
