import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EditImageDialog } from 'src/app/shared/components/edit-image/edit-image.component';
import { VALID_AGES, VALID_CEFRS } from 'src/app/shared/constants';
import { IAccount } from 'src/app/shared/models/account.model';
import { ILesson} from 'src/app/shared/models/lesson.model';
import { AccountStore } from 'src/app/shared/services/stores/account.store.service';
import { environment } from 'src/environments/environment';
import { Owner } from 'src/environments/environment.model';

@Component({
	selector: 'app-lesson-info',
	templateUrl: './lesson-info.component.html',
	styleUrls: ['./lesson-info.component.scss']
})
export class LessonInfoComponent implements OnInit, OnDestroy {

	readonly ages = VALID_AGES;
	readonly cefrs = VALID_CEFRS;
	owners !: Owner[];

	info!: ILesson;

	@Input() set data(value: ILesson) {
		this.info = value;
	}

	displayNameControl = new FormControl(null, Validators.required);
	cefrControl = new FormControl(null, Validators.required);
	ageControl = new FormControl(null, Validators.required);
	captionControl = new FormControl(null, Validators.required);
	vocabularyControl = new FormControl(null, Validators.required);
	grammarControl = new FormControl(null, Validators.required);
	aimControl = new FormControl(null, Validators.required);
	ownerControl = new FormControl(null, Validators.required);
	subscriptions : Subscription;

	constructor(
		public dialog: MatDialog,
		private accountStore : AccountStore,
	) {
		this.subscriptions = new Subscription();
	}

	ngOnInit(): void {
		const accountSub = this.accountStore.listAccounts()
			.subscribe(res => {
				const systemOwner: Owner = { displayName: 'SYSTEM', id: 'system/system' };
				const devOwner: Owner = { displayName: 'DEV', id: 'dev/dev' };
				const placementOwner: Owner = { displayName: 'PLACEMENT', id: 'placement/placement' };
				const accountOwners: Owner[] = (res as IAccount[])
					.sort((a, b) => a.displayName.localeCompare(b.displayName))
					.map(acc => ({ id: `accounts/${acc.uid}`, displayName: acc.displayName }));
				this.owners = [systemOwner, devOwner, placementOwner, ...accountOwners];
			});

		this.subscriptions.add(accountSub);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	createImage(id: string) {
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.jpg`;
	}

	editImage() {
		const dialogRef = this.dialog.open(EditImageDialog, {
			width: '350px',
			data: this.info.image,
		});

		dialogRef.afterClosed().subscribe((result: string) => {
			if (!result) {
				return;
			}
			this.info.image = result;
		});
	}

	copyImageName() {
		navigator.clipboard.writeText(this.info.image);
	}

	getDefault() {
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_default.jpg`;
	}

}
