<div mat-dialog-title color="primary" *ngIf="!isEdit">CREATE ACTIVITY DESCRIPTION</div>
<div mat-dialog-title color="primary" *ngIf="isEdit">EDIT ACTIVITY DESCRIPTION</div>
<lib-toastify-toast-container
  position="bottom-right"
></lib-toastify-toast-container>
<div mat-dialog-content class="dialog-content" style="align-items: center">
  <ng-container class="content-group">
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput [formControl]="nameControl" />
      <button
        *ngIf="nameControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="nameControl.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="nameControl.hasError('required')">
        Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="fill">
      <mat-label>Habilidade</mat-label>
      <mat-select [(ngModel)]="data.skill">
        <mat-option value="reading">Reading</mat-option>
        <mat-option value="listening">Listening</mat-option>
        <mat-option value="writing">Writing</mat-option>
        <mat-option value="speaking">Speaking</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="fill">
      <mat-label>Idades</mat-label>
      <mat-select
        [(ngModel)]="data.ages"
        (ngModelChange)="(data.ages)"
        name="data.ages"
        multiple
      >
        <mat-option *ngFor="let age of validAges" [value]="age">
          {{ age }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="fill">
      <mat-label>Cognition</mat-label>
      <mat-select [formControl]="cognitionControl">
        <mat-option *ngFor="let cognition of validCognitions" [value]="cognition">
          {{ cognition }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="cognitionControl.hasError('required')">
        Cognition is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content">
    <mat-form-field appearance="fill">
      <mat-label>Descrição</mat-label>
      <input matInput [formControl]="descriptionControl" />
      <button
        *ngIf="descriptionControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="descriptionControl.reset()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="descriptionControl.hasError('required')">
        Description is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Discard</button>
  <button mat-button (click)="createContentGroup()" cdkFocusInitial>
    Save
  </button>
</div>
