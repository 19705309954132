<div class="dialog">
  <div mat-dialog-title color="primary">CREATE DRAG DROP</div>
  <lib-toastify-toast-container
    position="bottom-right"
  ></lib-toastify-toast-container>

  <div mat-dialog-content class="drag-drop-content" style="align-items: center">
    <mat-stepper orientation="vertical" [linear]="true" #stepper>
      <mat-step [completed]="completed1">
        <ng-template matStepLabel>Step 1 - Select Background</ng-template>
        <ng-template matStepContent>
          <ng-container class="image-content">
            <mat-form-field appearance="outline">
              <mat-label>ID</mat-label>
              <input
                matInput
                [ngModel]="data.backgroundId"
                (ngModelChange)="changeImage($event)"
              />
              <button
                *ngIf="data.backgroundId"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="changeImage('')"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <button
              mat-button
              [disabled]="data.backgroundId === ''"
              class="verify-button"
              (click)="verifyImage()"
            >
              Verify Image
            </button>
          </ng-container>

          <ng-container>
            <app-image-uploader
              *ngIf="toVerify"
              [extension]="'png'"
              [imageId]="imageVerify"
              (uploadFiles)="setFile($event)"
              (exists)="
                imageExists = true;
                data.backgroundId = $event;
                completed1 = true
              "
            >
            </app-image-uploader>
          </ng-container>

          <div>
            <button mat-button matStepperNext [disabled]="!completed1">
              Next
            </button>
          </div>
        </ng-template>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Step 2 - Add Targets</ng-template>
        <ng-template matStepContent>
          <div class="target-content">
            <div class="image-bound-box">
              <div id="bound-box" class="image-container" cdkDrop>
                <img [src]="getImage()" style="width: 100%; height: 100%" />
                <div
                  #dragElement
                  *ngFor="let target of data.targets; let lineIndex = index"
                  class="drag-element"
                  cdkDragBoundary=".image-container"
                  cdkDrag
                  (cdkDragEnded)="onDragEnded($event, target, lineIndex)"
                  mwlResizable
                  [enableGhostResize]="true"
                  (resizeEnd)="onResizeEnd($event, target, lineIndex)"
                >
                  TARGET {{ lineIndex + 1 }}
                  <img
                    class="target-img"
                    *ngIf="
                      data.elementType === 'image' && data.elements[lineIndex]
                    "
                    [src]="getTargetImage(lineIndex)"
                  />
                  <div
                    class="resize-handle-top"
                    mwlResizeHandle
                    [resizeEdges]="{ top: true }"
                  ></div>
                  <div
                    class="resize-handle-left"
                    mwlResizeHandle
                    [resizeEdges]="{ left: true }"
                  ></div>
                  <div
                    class="resize-handle-right"
                    mwlResizeHandle
                    [resizeEdges]="{ right: true }"
                  ></div>
                  <div
                    class="resize-handle-bottom"
                    mwlResizeHandle
                    [resizeEdges]="{ bottom: true }"
                  ></div>
                </div>
              </div>
            </div>
            <div class="target-creator">
              <ng-container class="statement-content">
                <mat-form-field appearance="outline">
                  <mat-label>Statement</mat-label>
                  <input matInput [(ngModel)]="data.statement" />
                  <button
                    *ngIf="data.statement"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="data.statement = ''"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </ng-container>
              <ng-container class="element-type-content">
                <mat-form-field appearance="outline">
                  <mat-label>Element Type</mat-label>
                  <mat-select [(ngModel)]="data.elementType">
                    <mat-option value="image">Image</mat-option>
                    <mat-option value="text">Text</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-container class="target">
                <div class="header">
                  <mat-toolbar class="header" color="primary">
                    <div class="start-header" style="width: 50%">
                      <h1 class="title">Targets</h1>
                    </div>
                    <div
                      class="end-header"
                      style="
                        display: flex;
                        flex-direction: row-reverse;
                        width: 50%;
                      "
                    >
                      <button mat-mini-fab color="white" (click)="addTarget()">
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </mat-toolbar>
                </div>
              </ng-container>
              <ng-container
                class="targets"
                *ngFor="let target of data.targets; let lineIndex = index"
              >
                <mat-card class="drag-drop-target">
                  <div class="target-info-div">
                    <div style="margin-right: 20px">
                      <p style="font-size: 20px; font-weight: bold">
                        Target {{ lineIndex + 1 }}
                      </p>
                    </div>
                    <div style="font-size: 16px">
                      {{ getTargetInfo(target) }}
                    </div>
                  </div>
                  <div class="delete-div">
                    <button
                      mat-icon-button
                      class="delete-button"
                      (click)="removeTarget(lineIndex)"
                    >
                      <mat-icon color="warn">delete</mat-icon>
                    </button>
                  </div>
                </mat-card>
              </ng-container>
            </div>
          </div>

          <div>
            <button mat-button matStepperNext>Next</button>
          </div>
        </ng-template>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Step 3 - Add Elements</ng-template>
        <ng-template matStepContent>
          <div class="header">
            <mat-toolbar class="header" color="primary">
              <div class="start-header" style="width: 50%">
                <h1 class="title">Elements</h1>
              </div>
              <div
                class="end-header"
                style="display: flex; flex-direction: row-reverse; width: 50%"
              >
                <button mat-mini-fab color="white" (click)="addElement()">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </mat-toolbar>
          </div>
          <div>
            <mat-accordion>
              <mat-expansion-panel
                hideToggle
                *ngFor="let element of data.elements; let lineIndex = index"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title style="align-items: center">
                    Element {{ lineIndex + 1 }}
                  </mat-panel-title>
                  <mat-panel-description style="align-items: center">
                    <div style="display: flex; width: 95%">
                      {{ element.name }} - {{ data.elementType }}
                    </div>
                    <div class="delete-div">
                      <button
                        mat-icon-button
                        class="delete-button"
                        (click)="removeElement(lineIndex)"
                      >
                        <mat-icon color="warn">delete</mat-icon>
                      </button>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                  <ng-container class="name-content">
                    <mat-form-field appearance="outline">
                      <mat-label>Name</mat-label>
                      <input matInput [(ngModel)]="element.name" />
                      <button
                        *ngIf="element.name"
                        matSuffix
                        mat-icon-button
                        aria-label="Clear"
                        (click)="element.name = ''"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>

                    <ng-container class="name-content">
                      <mat-form-field appearance="outline">
                        <mat-label>Targets</mat-label>
                        <mat-select multiple [value]="getSelected(element)">
                          <mat-option
                            *ngFor="
                              let target of data.targets;
                              let targetIndex = index
                            "
                            [value]="getSelectionValue(targetIndex)"
                            (onSelectionChange)="
                              toggleTarget($event, targetIndex, lineIndex)
                            "
                            >Target {{ targetIndex + 1 }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </ng-container>

                    <ng-container
                      class="image-content"
                      *ngIf="data.elementType === 'image'"
                    >
                      <mat-form-field appearance="outline">
                        <mat-label>Image</mat-label>
                        <input
                          matInput
                          [ngModel]="element.image"
                          (ngModelChange)="
                            changeElementImage($event, lineIndex)
                          "
                        />
                        <button
                          *ngIf="element.image"
                          matSuffix
                          mat-icon-button
                          aria-label="Clear"
                          (click)="changeElementImage('', lineIndex)"
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                      <button
                        mat-button
                        [disabled]="element.image === ''"
                        class="verify-button"
                        (click)="verifyElementImage(lineIndex)"
                      >
                        Verify Image
                      </button>
                    </ng-container>

                    <ng-container>
                      <app-image-uploader
                        *ngIf="
                          element.verifyImage && data.elementType === 'image'
                        "
                        [extension]="'png'"
                        [imageId]="element.image"
                        (uploadFiles)="setElementFile($event, lineIndex)"
                        (exists)="
                          element.imageExists = true; element.image = $event
                        "
                      >
                      </app-image-uploader>
                    </ng-container>
                  </ng-container>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div>
            <button
              mat-button
              [disabled]="uploading"
              (click)="createDragDrop()"
              style="margin-top: 15px"
              cdkFocusInitial
            >
              Save
            </button>
            <mat-spinner *ngIf="uploading" [diameter]="50"></mat-spinner>
          </div>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="close()">Discard</button>
  </div>
</div>
