import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { FormControl } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ToastService } from "angular-toastify";
import { Subscription } from "rxjs";
import { DeleteConfirmationDialog } from "src/app/shared/components/delete-confirmation/delete-confirmation.component";
import { VALID_AGES, VALID_SKILLS } from "src/app/shared/constants";
import { IActivityDescription, ICreateActivityDescription } from "src/app/shared/models/activity-description.model";
import { ActivityDescriptionService } from "src/app/shared/services/activity-description.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { ContentGroupService } from "src/app/shared/services/content-group.service";
import { environment } from "src/environments/environment";
import { AddActivityDescriptionDialog } from "./add-activity-description/add-activity-description.component";

@Component({
	selector: "app-activity-description",
	templateUrl: "./activity-description.component.html",
	styleUrls: ["./activity-description.component.scss"],
})
export class ActivityDescriptionComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
	activityDescriptions: IActivityDescription[] = [];

	loading: boolean = true;

	private createSubscription!: Subscription;
	private updateSubscription!: Subscription;

	skills = VALID_SKILLS;
	ages = VALID_AGES;
	cognitions = ["0", "1", "2", "3", "4"];

	displayedColumns: string[] = ['name', 'actions', 'skill', 'ages', 'cognition', 'description'];
	dataSource!: MatTableDataSource<IActivityDescription>;
	defaultPageSize!: number;

	skillControl: FormControl;
	ageControl: FormControl;
	cognitionControl: FormControl;

	globalfilter = '';
	filteredValues = {
		skill: '',
		age: '',
		cognition: ''
	}

	readonly owners = environment.KNOWN_OWNERS;

	@ViewChild(MatPaginator) paginator!: MatPaginator;

	subscriptions: Subscription;

	constructor(
		private dialog: MatDialog,
		private router: Router,
		private authService: AuthService,
		private activityDescriptionService: ActivityDescriptionService,
		private observableMedia: MediaObserver,
		private toastService: ToastService,
	) {
		this.dataSource = new MatTableDataSource(this.activityDescriptions);
		this.skillControl = new FormControl();
		this.ageControl = new FormControl();
		this.cognitionControl = new FormControl();
		this.subscriptions = new Subscription();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.descriptions) {
			this.dataSource = new MatTableDataSource(this.activityDescriptions);

			this.dataSource.filterPredicate = this.customFilterPredicate();

			this.globalfilter = sessionStorage.getItem('descriptionFilter') ?? '';
			this.defaultPageSize = +(sessionStorage.getItem('descriptionsPageSize') ?? 5);
		}
	}

	ngOnInit() {

		this.loadDescriptions();

		this.globalfilter = sessionStorage.getItem('descriptionFilter') ?? '';
		this.defaultPageSize = +(sessionStorage.getItem('descriptionPageSize') ?? 5);

		const skillSub = this.skillControl.valueChanges.subscribe(skillValue => {
			this.filteredValues['skill'] = skillValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const ageSub = this.ageControl.valueChanges.subscribe(ageValue => {
			this.filteredValues['age'] = ageValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const cognitionSub = this.cognitionControl.valueChanges.subscribe(cognitionValue => {
			this.filteredValues['cognition'] = cognitionValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		this.subscriptions.add(ageSub);
		this.subscriptions.add(skillSub);
	}

	ngAfterViewInit(): void {
		this.setPaginator();
	}

	ngOnDestroy(): void {
		if (this.createSubscription) {
			this.createSubscription.unsubscribe();
		}
		this.subscriptions.unsubscribe();
	}

	loadDescriptions() {
		return new Promise(() => {
			const sub = this.activityDescriptionService.getActivityDescriptions().subscribe(res => {
				if (res) {
					this.activityDescriptions = res;
					this.dataSource = new MatTableDataSource(this.activityDescriptions);
					this.dataSource.filterPredicate = this.customFilterPredicate();
					if (this.paginator) {
						this.setPaginator();
					}
					this.loading = false;
				}
			});

			this.subscriptions.add(sub);
		});
	}

	private setPaginator() {
		this.dataSource.filter = JSON.stringify(this.filteredValues);
		this.dataSource.paginator = this.paginator;
		this.paginator.pageIndex = +(sessionStorage.getItem('descriptionPageIndex') ?? 0);
	}

	customFilterPredicate() {
		const descriptionFilter = (data: IActivityDescription, filter: string): boolean => {

			let parsedSearch = JSON.parse(filter);
			const selectedSkill = parsedSearch.skill;
			let hasSkill = selectedSkill ? data.skill?.includes(selectedSkill) ? true : false : true;
			const selectedAge = parsedSearch.age;
			let hasAge = selectedAge ? data.ages?.includes(selectedAge) ? true : false : true;
			const selectedCognition = parsedSearch.cognition;
			let hasCognition = selectedCognition ? data.cognition?.includes(selectedCognition) ? true : false : true;

			let selectMatch = hasSkill && hasAge && hasCognition;
			let globalMatch = !this.globalfilter;

			if (this.globalfilter) {
				const lowerCaseFilter = this.globalfilter.toLowerCase();
				const nameIncludes = data.name != null && data.name.toLowerCase().includes(lowerCaseFilter);
				//filter description
				const descriptionIncludes = data.description != null && data.description.toLowerCase().includes(lowerCaseFilter);
				let gameDescIncludes = false;

				globalMatch = nameIncludes || descriptionIncludes || gameDescIncludes;
			}

			return globalMatch && selectMatch;
		}
		return descriptionFilter;
	}

	applyFilter(filter: string) {
		sessionStorage.setItem('descriptionFilter', filter);
		this.globalfilter = filter;
		this.dataSource.filter = JSON.stringify(this.filteredValues);

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	paginatorChanged(event: any) {
		sessionStorage.setItem('descriptionPageIndex', event.pageIndex);
		sessionStorage.setItem('descriptionsPageSize', event.pageSize);
	}

	editDescription(index: number, content: IActivityDescription) {
		if (this.updateSubscription) {
			this.updateSubscription.unsubscribe();
		}

		const dialogRef = this.dialog.open(AddActivityDescriptionDialog, {
			width: "650px",
			data: {
				name: content.name,
				description: content.description,
				skill: content.skill,
				cognition: content.cognition,
				ages: content.ages,
				legacyGroupId: content.legacyGroupId ?? null
			} as IActivityDescription,
		});

		dialogRef.afterClosed().subscribe((result: IActivityDescription) => {
			if (!result) {
				return;
			}

			this.updateSubscription = this.activityDescriptionService.updateActivityDescription(content.uid, result).subscribe(updated => {
				if (!updated) {
					this.toastService.error('Failed to update activity description.')
				} else {
					this.toastService.success(`Activity description ${result.uid} was updated successfully`);
					this.activityDescriptions[index] = result;
					this.dataSource = new MatTableDataSource(this.activityDescriptions);
					this.dataSource.filterPredicate = this.customFilterPredicate();
					this.dataSource.filter = JSON.stringify(this.filteredValues);
					if (this.paginator) {
						this.setPaginator();
					}
				}
			});;
		});
	}

	createDescription() {
		if (this.createSubscription) {
			this.createSubscription.unsubscribe();
		}

		const dialogRef = this.dialog.open(AddActivityDescriptionDialog, {
			width: "650px",
			data: {
				name: "",
				description: "",
				skill: "reading",
				cognition: "",
				ages: []
			} as ICreateActivityDescription,
		});

		dialogRef.afterClosed().subscribe((result: ICreateActivityDescription) => {
			if (!result) {
				return;
			}
			this.createSubscription = this.activityDescriptionService.createActivityDescription(result).subscribe(id => {
				if (!id) {
					this.toastService.error('Failed to create activity description.')
				} else {
					this.toastService.success(`Activity description ${id} was created successfully`);
					result.uid = id;
					this.activityDescriptions.push(result as IActivityDescription);
					this.dataSource = new MatTableDataSource(this.activityDescriptions);
					this.dataSource.filterPredicate = this.customFilterPredicate();
					if (this.paginator) {
						this.setPaginator();
					}
				}
			});
		});
	}

	home() {
		this.router.navigate(["navbar"]);
	}

	async logout() {
		let data = await this.authService.logout();
		if (data) {
			this.router.navigate(["auth/login"]);
		}
	}
}
