<div class="lesson-image-content" style="align-items: center; margin-top: 15px;">
	<ng-container style="position: inherit;">
		<mat-spinner *ngIf="loading"></mat-spinner>
	</ng-container>

	<div *ngIf="!loading && fileExists" class="file-exists">
		<div class="confirm-text">
			<mat-card style="text-align: center;">
				This file exists. Do you want to use it? If not, please enter an image with a different name.
				<img [src]="createImage(_imageId)" style="width: 100%; margin-top: 10px;">
			</mat-card>
		</div>
		<div class="confirm-buttons" style="margin-top: 10px;">
			<button mat-button class="no-button" (click)="onNoButtonClick()" style="margin-right: 10px;">No</button>
			<button mat-button class="yes-button" (click)="onYesButtonClick()">Yes</button>
		</div>
	</div>

	<div *ngIf="!loading && useRemote" class="remote-image">
		<img [src]="createImage(_imageId)" style="width: 100%;">
	</div>

	<ng-container *ngIf="!loading && upload" class="file-upload">
		<ngx-file-drop *ngIf="!(files.length > 0) " dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" [multiple]="false">
			<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
				Drag and drop files. Or
				<button class="upload" mat-mini-fab matSuffix color="primary" (click)="openFileSelector()">
					<mat-icon>attach_file</mat-icon>
				</button>
			</ng-template>
		</ngx-file-drop>
		<mat-form-field *ngIf="(files.length > 0)" appearance="fill">
			<mat-label>Selected Images</mat-label>
			<ng-container *ngFor="let item of files">
				<input matInput disabled> {{item.name}}
				<button matSuffix mat-icon-button color="primary" (click)="removeFile(item.name)">
					<mat-icon>close</mat-icon>
				</button>
			</ng-container>
		</mat-form-field>
	</ng-container>

</div>