import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastService } from "angular-toastify";
import { IVideoConfig } from "../../models/lesson-content.model";

@Component({
	selector: "app-watch-video",
	templateUrl: "./watch-video.component.html",
	styleUrls: ["./watch-video.component.scss"],
})
export class WatchVideoDialog implements OnInit {
	videoIdControl = new FormControl(null, Validators.required);
	videoEndControl = new FormControl(null, Validators.required);

	constructor(
		public dialogRef: MatDialogRef<WatchVideoDialog>,
		@Inject(MAT_DIALOG_DATA) public data: IVideoConfig,
		private toastService: ToastService,
		private _sanitizer: DomSanitizer
	) {}

	ngOnInit(): void {}

	createVideo() {
		return this._sanitizer.bypassSecurityTrustResourceUrl(
			`https://www.youtube.com/embed/${this.data.videoId}?start=${this.data.videoStart}&end=${this.data.videoEnd}`
		);
	}

	changeVideo(newVideo: string) {
		let link: string[]; // link vector
		let shortLink = "https://youtu.be/"; // short link model
		let longLink = "https://www.youtube.com/"; // long link model
		if (newVideo.slice(0, 24) === longLink) {
			link = newVideo.split('=');
			this.data.videoId = link[1]; // video id present in link vector index 1
		} else if (newVideo.slice(0, 17) === shortLink) {
			link = newVideo.split('/');
			this.data.videoId = link[3]; // video id present in link vector index 3
		} else {
			this.data.videoId = newVideo; // if enter the video id directly
		}
	}

	closeDialog() {
		this.dialogRef.close();
	}

	saveAndClose() {
		let save = true;

		if (this.data.videoId === "") {
			this.videoIdControl.markAsTouched();
			save = false;
		}

		if (this.data.videoEnd === 0) {
			this.videoEndControl.markAsTouched();
			save = false;
		}

		if (this.data.videoEnd <= this.data.videoStart) {
			this.toastService.error('Video start cannot be after video end.');
			save = false;
		}

		if (!save) {
			this.toastService.error('Failed to save video.')
			return;
		}

		this.dialogRef.close(this.data);
	}
}
