import { Injectable } from '@angular/core';
import { ToastService } from 'angular-toastify';
import { BehaviorSubject, catchError, of } from 'rxjs';
import { IAccount } from '../../models/account.model';
import { AccountService } from '../account.service';

@Injectable({
	providedIn: 'root'
})
export class AccountStore {

	private _accounts = new BehaviorSubject<IAccount[] | null>(null);

	constructor(
		private accountService: AccountService,
		private toastrService: ToastService,
	) { }

	private setAccounts(accounts: IAccount[]) {
		this._accounts.next(accounts);
	}

	listAccounts() {
		if (this._accounts.value !== null) {
			this._accounts.next(this._accounts.value);
		} else {
			this.accountService.listAccounts()
				.pipe(
					catchError(err => {
						this.toastrService.error('failed to load accounts');
						return of(false)
					})
				).subscribe(res => {
					if (res) {
						this.setAccounts(res as IAccount[]);
					} else {
						this.setAccounts([]);
					}
				})
		}

		return this._accounts.asObservable();
	}

}
