import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ToastService } from "angular-toastify";
import { GenericStepMedia } from "src/app/shared/models/steps.model";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";
import { environment } from "src/environments/environment";
import { IClassroomGameGeneric, IClassroomGamesGenerics } from "./classroom-games.model";

@Component({
	selector: "app-classroom-game-selector",
	templateUrl: "./classroom-game-selector.component.html",
	styleUrls: ["./classroom-game-selector.component.scss"],
})
export class ClassroomGameSelectorComponent {

	@Output() mediaSubmitted: EventEmitter<GenericStepMedia> = new EventEmitter();

	games = IClassroomGamesGenerics;
	selectedGame!: IClassroomGameGeneric;

	imageToUpload: File | null = null;
	imageUrl!: string;
	uploadingImg = false;

	constructor(
		private imageUploadServer: ImageUploaderService,
		private toastService: ToastService,
		) {	}

	selectGame(game: IClassroomGameGeneric) {
		this.selectedGame = game;
	}

	handleImageInput(event: Event) {
		const target = event.target as HTMLInputElement;
      	const files = target.files as FileList;
		this.imageToUpload = files.item(0);

		console.log(files);

		const input = document.querySelector(".file-upload-wrapper");
		if(input){
			input.setAttribute("data-text", this.imageToUpload?.name ?? 'Selecione uma imagem');
		}
	}

	submitClassroomGameStep() {
		if (this.imageToUpload) {
			this.uploadWithImage();
		} else {
		this.mediaSubmitted.emit({ type: 'classroomGame', thumbnailUrl: this.selectedGame.imageUrl as string, content: { name: this.selectedGame.name as string, gameType: this.selectedGame.gameType as string } });
		}
	}

	private uploadWithImage() {
		this.uploadingImg = true;
		if (this.imageToUpload) {
			const imageBlob = new Blob([this.imageToUpload], { type: this.imageToUpload.type });

			if (!(imageBlob.type === 'image/jpeg' || imageBlob.type === 'image/png')) {
				this.toastService.error('File should be JPEG or PNG image');
				this.uploadingImg = false;
				return;
			}

			this.imageUploadServer.uploadStepImage(imageBlob).subscribe(res => {
				this.imageUrl = res.url;
				this.uploadingImg = false;
				this.mediaSubmitted.emit({ type: 'classroomGame', thumbnailUrl: this.imageUrl as string, content: { name: this.selectedGame.name as string, gameType: this.selectedGame.gameType as string } });
			})
		}
	}
}
