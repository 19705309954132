import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { Subscription } from 'rxjs';
import { ILesson } from 'src/app/shared/models/lesson.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LessonService } from 'src/app/shared/services/lesson.service';
import { AccountStore } from 'src/app/shared/services/stores/account.store.service';


@Component({
	selector: 'app-lessons',
	templateUrl: './lessons.component.html',
	styleUrls: ['./lessons.component.scss']
})
export class LessonsComponent implements OnInit, OnDestroy {

	draftLessons !: ILesson[];
	liveLessons !: ILesson[];
	subscriptions : Subscription;
	accountsLoaded = false;

	constructor(
		private router: Router,
		private authService: AuthService,
		private lessonService: LessonService,
		private toastService: ToastService,
		private accountStore: AccountStore,
	) {
		this.subscriptions = new Subscription();
	 }

	ngOnInit(): void {
		this.loadLessons();
		const sub = this.accountStore.listAccounts().subscribe(res => {
			if(res !== null) {
				this.accountsLoaded = true;
			}
		})
		this.subscriptions.add(sub);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	home() {
		this.router.navigate(['navbar'])
	}

	async logout() {
		let data = await this.authService.logout();
		if (data) {
			this.router.navigate(['auth/login'])
		}
	}

	loadLessons() {
		this.loadDraftLessons();
		this.loadLiveLessons();
	}

	private loadDraftLessons() {
		this.lessonService.getDraftLessons()
			.subscribe({
				next: (res) => {
					this.draftLessons = res;
				},
				error: (err) => {
					console.error(err);
					this.toastService.error('Failed to load draft lessons');
					this.draftLessons = [];
				}
			});
	}

	private loadLiveLessons() {
		this.lessonService.getLessons()
			.subscribe({
				next: (res) => {
					this.liveLessons = res;
				},
				error: (err) => {
					console.error(err);
					this.toastService.error('Failed to load live lessons');
					this.liveLessons = [];
				}
			});
	}
}
