import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import {
	AnyGame,
	CreateQuestionDTO,
	ITextGame,
	UpdateQuestionDTO,
} from "src/app/shared/models/placement-question.model";
import { PlacementQuestionsService } from "src/app/shared/services/placement-questions.service";
import { CreateQuestionDialog } from "../create-question.component";
import { MatDialogRef } from "@angular/material/dialog";
import {
	IUploadImage,
	ImageFiles,
} from "src/app/shared/models/image-upload.model";
import { ToastService } from "angular-toastify";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";

@Component({
	selector: "app-text-media",
	templateUrl: "./text-media.component.html",
	styleUrls: ["./text-media.component.scss"],
})
export class TextMediaComponent implements OnInit, OnDestroy {
	@Input() data!: CreateQuestionDTO | UpdateQuestionDTO;
	@Input() isUpdate!: boolean;

	@Output() questionUploaded = new EventEmitter<boolean>();

	private uploadSubscription: Subscription;
	private addSubscription!: Subscription;

	public files: ImageFiles[] = [];

	image = "";
	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	textControl = new FormControl("", Validators.required);

	constructor(
		public dialogRef: MatDialogRef<CreateQuestionDialog>,
		private placementQuestionsService: PlacementQuestionsService,
		private uploadService: ImageUploaderService,
		private toastService: ToastService
	) {
		this.addSubscription = new Subscription();
		this.uploadSubscription = new Subscription();
	}

	ngOnInit(): void {
		if (this.data.game && this.isTextGame(this.data.game.content)) {
			this.textControl.setValue(this.data.game.content.text);
			this.image = this.data.game.content.image;
			this.imageVerify = this.image;
			this.toVerify = true;
			this.imageExists = true;
		}
	}

	ngOnDestroy(): void {
		if (this.addSubscription) {
			this.addSubscription.unsubscribe();
		}

		if (this.uploadSubscription) {
			this.uploadSubscription.unsubscribe();
		}
	}

	isTextGame(game: AnyGame): game is ITextGame {
		return (
			(game as ITextGame).text !== undefined &&
			(game as ITextGame).image !== undefined
		);
	}

	changeImage(newValue: string) {
		this.image = newValue;
		this.toVerify = false;
		this.files = [];
		this.imageExists = false;
	}

	verifyImage() {
		this.imageVerify = this.image;
		this.toVerify = true;
	}

	setFile(file: ImageFiles | null) {
		if (file === null) {
			this.image = "";
			this.files = [];
			return;
		}
		this.image = file.name;
		this.files.push(file);
	}

	uploadQuestion() {
		let canUpload = true;

		if (
			!this.textControl ||
			!this.textControl.value ||
			this.textControl.value.trim() === ""
		) {
			this.textControl.markAsTouched();
			canUpload = false;
		}

		if (!this.imageExists && this.files.length === 0) {
			this.toastService.error("No image has been selected.");
			canUpload = false;
		}

		if (!canUpload) {
			this.toastService.error(
				"There are one or more errors in upload form. Please fix them and try again."
			);
			this.questionUploaded.emit(false);
			return;
		}

		this.data = {
			...this.data,
			game: {
				activity: "text-fill-the-gaps",
				content: {
					text: this.textControl.value,
					image: this.image,
				} as ITextGame,
			},
		};

		if (this.files.length > 0) {
			const uploadFileIds = this.files.map((file) => {
				return {
					id: file.name,
					extension: "jpg",
				} as IUploadImage;
			});

			const fileBlobs = this.files.map((fileInfo) => {
				const imageBlob = new Blob([fileInfo.file], {
					type: `image/jpeg`,
				});
				return imageBlob;
			});

			this.uploadSubscription = this.uploadService
				.uploadPlacementImage(fileBlobs, uploadFileIds)
				.subscribe((res) => {
					if (!res) {
						this.toastService.error("Failed to upload image");
						this.questionUploaded.emit(false);
						return;
					} else {
						this.toastService.success(
							"Image was uploaded successfully"
						);
						if (!this.isUpdate) {
							this.createQuestion(this.data as CreateQuestionDTO);
						} else {
							this.updateQuestion(this.data as UpdateQuestionDTO);
						}
					}
				});
		} else {
			if (!this.isUpdate) {
				this.createQuestion(this.data as CreateQuestionDTO);
			} else {
				this.updateQuestion(this.data as UpdateQuestionDTO);
			}
		}
	}

	createQuestion(createData: CreateQuestionDTO) {
		this.addSubscription = this.placementQuestionsService
			.createQuestion(createData)
			.pipe()
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to create question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to create question.");
					this.questionUploaded.emit(false);
				},
			});
	}

	updateQuestion(updateData: UpdateQuestionDTO) {
		this.addSubscription = this.placementQuestionsService
			.updateQuestion(updateData, updateData.questionId)
			.pipe()
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to update question.");
						this.questionUploaded.emit(false);
						return;
					}
					this.questionUploaded.emit(true);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to update question.");
					this.questionUploaded.emit(false);
				},
			});
	}
}
