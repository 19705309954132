import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramsComponent } from './programs.component';
import { AngularToastifyModule } from 'angular-toastify';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddProgramsDialog } from './add-programs/add-programs.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatStepperModule } from '@angular/material/stepper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    ProgramsComponent,
    AddProgramsDialog
  ],
  imports: [
    CommonModule,
	AngularToastifyModule,
	FormsModule,
	ReactiveFormsModule,
	MatIconModule,
	MatTableModule,
	MatPaginatorModule,
	MatCheckboxModule,
	MatFormFieldModule,
	MatInputModule,
	MatToolbarModule,
	MatButtonModule,
	MatDialogModule,
	MatSelectModule,
	MatDividerModule,
	MatStepperModule,
	DragDropModule,
	MatProgressSpinnerModule,
	SharedModule
  ]
})
export class ProgramsModule { }
