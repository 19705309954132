import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastService } from "angular-toastify";
import { ImageFiles } from "src/app/shared/models/image-upload.model";
import { DIFFICULTY, IVideoQuizRow } from "src/app/shared/models/lesson-content.model";

@Component({
  selector: 'app-add-quiz-video',
  templateUrl: './add-quiz-video.component.html',
  styleUrls: ['./add-quiz-video.component.scss']
})
export class AddQuizVideoDialog implements OnInit {

	readonly difficulties = DIFFICULTY;

	public files: ImageFiles[] = [];

	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	statementControl = new FormControl(this.data.statement, Validators.required);
	rightAnswerControl = new FormControl(this.data.rightAnswer, Validators.required);
	wrongAnswerControl = new FormControl(this.data.wrongAnswers[0], Validators.required);
	grammarControl = new FormControl(this.data.isGrammar, Validators.required);
	videoEndControl = new FormControl(this.data.videoEnd, Validators.required);
	videoIdControl = new FormControl(this.data.videoId, Validators.required);

	constructor(
		public dialogRef: MatDialogRef<AddQuizVideoDialog>,
		@Inject(MAT_DIALOG_DATA) public data: IVideoQuizRow,
		private toastService: ToastService,
		private _sanitizer: DomSanitizer,
	) {}

	ngOnInit(): void {}

	closeDialog() {
		this.dialogRef.close();
	}

	changeVideo(newVideo: string) {
		if (newVideo.length == 43) {
			this.videoIdControl.setValue(newVideo.slice(32));
		} else if (newVideo.length == 28) {
			this.videoIdControl.setValue(newVideo.slice(17));
		} else {
			this.videoIdControl.setValue(newVideo);
		}
	}

	createVideo(id: string) {
		return this._sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${id}?start=${this.data.videoStart}&end=${this.data.videoEnd}`);
	}

	createQuizVideo() {
		let canUpload = true;

		this.data.statement = this.statementControl.value;
		this.data.rightAnswer = this.rightAnswerControl.value;
		this.data.isGrammar = this.grammarControl.value;
		this.data.videoEnd = this.videoEndControl.value;
		this.data.videoId = this.videoIdControl.value;

		if (this.data.statement === "") {
			this.statementControl.markAsTouched();
			canUpload = false;
		}

		if (this.data.rightAnswer === "") {
			this.rightAnswerControl.markAsTouched();
			canUpload = false;
		}

		if (this.wrongAnswerControl.value === "") {
			this.wrongAnswerControl.markAsTouched();
			canUpload = false;
		} else {
			this.data.wrongAnswers[0] = this.wrongAnswerControl.value;
		}

		if (this.data.videoEnd === 0) {
			this.videoEndControl.markAsTouched();
			canUpload = false;
		}

		if (this.data.videoId === "") {
			this.videoIdControl.markAsTouched();
			canUpload = false;
		}

		if (!canUpload) {
			this.toastService.error("Failed to save new quiz video.");
			return;
		}

		this.toastService.success("Quiz video saved.");
		this.dialogRef.close(this.data);
	}
}
