<ng-container>
  <h3>MULTICHOICE FILL THE GAPS</h3>

  <form [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Text</mat-label>
      <textarea matInput formControlName="text"></textarea>
      <mat-error *ngIf="form.get('text')?.errors?.required">
        Text is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <div class="lesson-image-content">
      <mat-form-field appearance="outline">
        <mat-label>Image</mat-label>
        <input matInput formControlName="imageId">
        <button *ngIf="form.get('imageId')?.value" matSuffix mat-icon-button aria-label="Clear"
          (click)="clearQuestionImage('', form)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button mat-button [disabled]="!form.get('imageId')?.value" class="verify-button"
        (click)="verifyQuestionImage(form)">Verify Image</button>
    </div>

    <app-image-uploader *ngIf="form.get('verifyImage')?.value" [isPlacement]="true"
      [imageId]="form.get('imageId')?.value" (uploadFiles)="setQuestionFile($event, form)"
      (exists)="existsFile(form, true, $event)">
    </app-image-uploader>

    <div class="header">
      <mat-toolbar class="header" color="primary">
        <div class="start-header">
          <h2 class="title">Choices</h2>
        </div>
        <div class="end-header">
          <button mat-mini-fab color="white" (click)="addChoice()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-toolbar>
    </div>

    <div formArrayName="choices">
      <mat-accordion>
        <mat-expansion-panel hideToggle *ngFor="let choiceGroup of choices.controls; let i = index" [formGroupName]="i"
          class="choice-container">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Choice {{i + 1}}
            </mat-panel-title>
            <mat-panel-description>
              <div class="description-container"> {{ choiceGroup.get('correct')?.value }} </div>
              <div class="delete-div">
                <button mat-icon-button class="delete-button" (click)="removeChoice(i)">
                  <mat-icon color="warn">delete</mat-icon>
                </button>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div formArrayName="wrong" class="wrong-choices">
            <div *ngFor="let wrongControl of getWrongControls(choiceGroup).controls; let j = index">
              <mat-form-field appearance="outline">
                <mat-label>Wrong {{j + 1}}</mat-label>
                <input matInput [formControlName]="j">
                <mat-error *ngIf="wrongControl.errors?.required">
                  Wrong choice is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </form>
</ng-container>