import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	email = new FormControl('', [Validators.required, Validators.email]);
	password = new FormControl('', [Validators.required]);

	hide = true;

	constructor(
		public authService: AuthService,
		public router: Router,
	) {

	}

	ngOnInit(): void {

	}

	async login() {
		let missing = false;

		if (this.email.value === '') {
			this.email.markAsTouched();
			missing = true;
		}
		if (this.password.value === '') {
			this.password.markAsTouched();
			missing = true;
		}

		if (missing) { return; }

		let data = await this.authService.loginWithEmailAndPassword(this.email.value, this.password.value);
		if (data != null) {
			this.router.navigate(['navbar']);
		}
	}

	getErrorMessage() {
		if (this.email.hasError('required')) {
		  return 'Email is required';
		}

		return this.email.hasError('email') ? 'Not a valid email' : '';
	  }

}
