import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularToastifyModule, ToastService } from 'angular-toastify';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarRoutingModule } from '../navbar-routing.module';
import { LessonsComponent } from './lessons.component';
import { AddLessonDialog } from './add-lesson/add-lesson.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { UploadStateDialog } from './upload-state/upload-state.component';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';
import { MatStepperModule } from '@angular/material/stepper';
import { CopyLessonDialog } from './copy-lesson/copy-lesson.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UpdateAllDialog } from './update-all/update-all.component';
import { LessonListComponent } from './lesson-list/lesson-list.component';
import { ContentValidationDialog } from './content-validation/content-validation.component';

@NgModule({
	declarations: [
		AddLessonDialog,
		LessonsComponent,
		UploadStateDialog,
		CopyLessonDialog,
		UpdateAllDialog,
  		LessonListComponent,
  		ContentValidationDialog,
	],
	imports: [
		NavbarRoutingModule,
		AngularFirestoreModule,
		AngularToastifyModule,
		CommonModule,
		FormsModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatExpansionModule,
		MatDialogModule,
		MatFormFieldModule,
		MatGridListModule,
		MatExpansionModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatPaginatorModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatSelectModule,
		MatStepperModule,
		MatProgressSpinnerModule,
		MatSortModule,
		MatTableModule,
		NgxFileDropModule,
		ReactiveFormsModule,
		SharedModule,
	],
	providers: [
		ToastService,
		ImageUploaderService,
	]
})
export class LessonsModule { }
