<div mat-dialog-title color="primary">
	Content validation - {{data.uid}}
</div>

<div mat-dialog-content class='dialog-content'>

    <ng-container *ngIf="!data.invalidContent">
        <h3>Lesson content is normal.</h3>
    </ng-container>

    <ng-container *ngIf="data.invalidContent && data.invalidContent.length > 0">

        <ng-container *ngFor="let entry of data.invalidContent" >
            <div class="content">
                <h3>
                    {{entry}}
                </h3>
                <br>
            </div>
        </ng-container>

    </ng-container>

</div>

<div mat-dialog-actions>
	<button mat-button (click)="closeDialog()">Close</button>
</div>