import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from 'angular-toastify';
import { IContentGroup, ILessonParametersList } from 'src/app/shared/models/content-group.model';
import { ContentGroupService } from 'src/app/shared/services/content-group.service';

@Component({
  selector: 'app-lesson-parameters',
  templateUrl: './lesson-parameters.component.html',
  styleUrls: ['./lesson-parameters.component.scss']
})
export class LessonParametersComponent implements OnInit {

	lessonParameters: string[] = [];

	@Input() set data(value: string[]) {
		if (!value) {
			this.lessonParameters = [];
		} else {
			this.lessonParameters = value;
		}
		this.setContentGroups();
	}

	@Output() newData = new EventEmitter<string[]>();

	tabGroups: IContentGroup[] = [];
	parametersList : ILessonParametersList[] = [];

	constructor(
		private contentGroupService: ContentGroupService,
		private toastService: ToastService,
	) {

	}

	ngOnInit(): void {

	}

	getLayouts(param: ILessonParametersList) {
		let list = '';
		const contentGroup = this.tabGroups.find(group => group.id === param.id);
		if (!contentGroup) {
			return list;
		}
		for (let layout of contentGroup.layouts) {
			list += layout + '\n';
		}
		return list;
	}

	setContentGroups() {
		this.parametersList = [];
		this.contentGroupService.getContentGroups().subscribe(groups => {
			this.tabGroups = groups;
			for (let group of this.tabGroups) {
				if (this.lessonParameters.includes(group.id)) {
					this.parametersList.push({
						checked: true,
						name: group.displayName,
						id: group.id,
					})
				} else {
					this.parametersList.push({
						checked: false,
						name: group.displayName,
						id: group.id,
					})
				}
			}

			this.parametersList.sort((a,b) => a.name.localeCompare(b.name))
		})
	}

	select(checked: boolean, checkedId: string) {
		if (checked) {
			this.lessonParameters.push(checkedId);
		} else {
			const index = this.lessonParameters.findIndex(id => id === checkedId)
			this.lessonParameters.splice(index, 1);
		}
		this.sendData();
	}

	sendData() {
		this.newData.emit(this.lessonParameters);
	}
}
