import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'angular-toastify';
import { DateTime, Interval } from 'luxon';
import { Observable, Subscription } from 'rxjs';
import { ILessonContent } from 'src/app/shared/models/lesson-content.model';
import { CloudFunctionNames, errorStates, ILesson, ILessonUploadData, LessonUpdateStatus } from 'src/app/shared/models/lesson.model';
import { ImportService } from 'src/app/shared/services/import.service';
import { LessonService } from 'src/app/shared/services/lesson.service';
import { UploadStateDialog } from '../upload-state/upload-state.component';

interface ILessonUpdateStatus {
	info: ILesson;
	content?: ILessonContent;
	uploadStatus: Observable<ILessonUploadData[]> | null;
	status: ReRunStatus;
	reRun: boolean;
	reqError: boolean;
}

type ReRunStatus = 'Waiting' | 'Started' | 'Done' | 'Error';

@Component({
	selector: 'app-update-all',
	templateUrl: './update-all.component.html',
	styleUrls: ['./update-all.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})
export class UpdateAllDialog implements OnInit, OnDestroy {

	saveSubscription!: Subscription;
	getSubscription!: Subscription;

	uploadComplete: boolean = false;

	startTime!: DateTime;

	currentIndex: number = 0;

	columns = ['name', 'version', 'status', 're-run'];

	updateStatus: ILessonUpdateStatus[] = [];
	expandedElement!: ILessonUploadData | null;
	expandedIndex: number = -1;

	errorStates = errorStates;

	constructor(
		public dialogRef: MatDialogRef<UploadStateDialog>,
		@Inject(MAT_DIALOG_DATA) public data: ILesson[],
		private lessonService: LessonService,
		private importService: ImportService,
		private toastService: ToastService,
		private afs: AngularFirestore,
	) {
		this.dialogRef.disableClose = true;

		for (let lesson of data) {
			this.updateStatus.push({
				info: lesson,
				status: 'Waiting',
				uploadStatus: null,
				reRun: false,
				reqError: false,
			})
		}

		this.startTime = DateTime.now();
		this.startReUpload(this.currentIndex);
	}
	ngOnDestroy(): void {
		this.unsubscribeAll();
	}

	ngOnInit(): void {

	}

	unsubscribeAll() {
		if (this.getSubscription && !this.getSubscription.closed) {
			this.getSubscription.unsubscribe();
		}

		if (this.saveSubscription && !this.saveSubscription.closed) {
			this.saveSubscription.unsubscribe();
		}
	}

	startReUpload(index: number) {
		const info = this.updateStatus[index].info;

		this.unsubscribeAll();

		this.getSubscription = this.lessonService.getLessonContent(info.uid).subscribe(activeLesson => {
			if (!activeLesson) {
				if (index === this.currentIndex) {
					this.updateStatus[index].reqError = true;
					this.toastService.error(`Failed to retrieve lesson ${info.displayName} for re-upload.`)
				}
				return;
			}

			this.updateStatus[index].content = activeLesson.content;

			if (this.saveSubscription && !this.saveSubscription.closed) {
				this.saveSubscription.unsubscribe();
			}

			if (index !== this.currentIndex) {
				return;
			}

			this.saveSubscription = this.importService.updateImportedLesson(info.uid, info, activeLesson.content)
			.subscribe(res => {
				if (!res) {
					if (index === this.currentIndex) {
						this.updateStatus[index].reqError = true;
						this.toastService.error(`Failed to start pipeline for lesson ${info.displayName}`);
					}
					this.unsubscribeAll();
					return;
				}

				this.updateStatus[index].uploadStatus = (this.afs.collection('lesson-upload-state', ref => ref.where("lessonId", "==", info.uid).orderBy("startTime", "desc").limit(1)).valueChanges() as unknown as Observable<ILessonUploadData[]>);
				this.unsubscribeAll();
			})
		});
	}

	getStyle(value: any) {
		let color = 'white';
		switch(value) {
			case "Doing":
				color = 'yellow';
				break;
			case "ExecutionError":
			case "NoAudioError":
			case "Timeout":
			case "ReqError":
				color = 'red';
				break;
			case "Done":
				color = 'green';
				break;
			case "Waiting" :
			default:
				color = 'white';
				break;
		}
		return `background-color: ${color}`;
	}

	getStatus(row: ILessonUploadData[] | null, index: number): LessonUpdateStatus {
		if (this.updateStatus.length > 0 && this.updateStatus[index] && this.updateStatus[index].reqError) {
			return 'ReqError';
		}
		if (row === null) {
			return 'Waiting';
		} else {
			if (row.length > 0 && !row[0].endTime) {
				return "Doing";
			} else {
				return this.executionStatus(row[0], index);
			}
		}
	}

	executionStatus(row: ILessonUploadData, index: number) : LessonUpdateStatus {
		let status: LessonUpdateStatus = "Done";
		const allFunctionNames = Object.values(CloudFunctionNames);
		allFunctionNames.map((functionName) => {
			if (errorStates.has(row[functionName])) {
				status = row[functionName];
			}
		})

		if (!this.uploadComplete && this.currentIndex === index) {
			this.currentIndex++;

			if (errorStates.has(status)) {
				this.toastService.error(`Failed to re-upload lesson ${this.updateStatus[index].info.displayName}`);
			}

			if (this.currentIndex >= this.updateStatus.length) {
				this.uploadComplete = true;
				this.toastService.info("Re-Upload has finished.");
				const endTime = DateTime.now();
				const duration = Interval.fromDateTimes(this.startTime, endTime);
				this.toastService.info(`Re-Upload has taken ${ Math.round((duration.length('minutes') + Number.EPSILON) * 100) / 100}m`)
			} else {
				this.startReUpload(this.currentIndex);
			}
		}
		return status;
	}

	forceUploadLesson(index: number) {
		if (this.updateStatus[index].reRun) {
			this.toastService.error("Another pipeline is in progress, please wait for it to finish.")
			return;
		}

		const info = this.updateStatus[index].info;
		this.updateStatus[index].reRun = true;

		this.importService.updateImportedLesson(info.uid, info, this.updateStatus[index].content as ILessonContent)
		.subscribe(res => {
			this.updateStatus[index].reRun = false;
			this.updateStatus[index].reqError = false;
			if (!res) {
				this.toastService.error(`Failed to start pipeline for lesson ${info.displayName}`);
				this.updateStatus[index].reqError = true;
				return;
			}
			this.updateStatus[index].uploadStatus = (this.afs.collection('lesson-upload-state', ref => ref.where("lessonId", "==", info.uid).orderBy("startTime", "desc").limit(1)).valueChanges() as unknown as Observable<ILessonUploadData[]>);
		})
	}

	forceResume() {
		this.currentIndex++;
		this.startReUpload(this.currentIndex);
	}

	closeDialog() {
		this.dialogRef.close();
	}

}
