<div mat-dialog-title color="primary">
	EDIT IMAGE
</div>
<lib-toastify-toast-container
	position="bottom-right"
></lib-toastify-toast-container>
<div mat-dialog-content class="dialog-content" style="align-items: center;">
	<ng-container class="image-content">
		<mat-form-field appearance="outline">
			<mat-label>Image</mat-label>
			<input matInput [ngModel]="image" (ngModelChange)="changeImage($event)">
			<button *ngIf="image" matSuffix mat-icon-button aria-label="Clear" (click)="changeImage('')">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<button mat-button [disabled]="image === ''" class="verify-button" (click)="verifyImage()">Verify Image</button>
	</ng-container>

	<ng-container>
		<app-image-uploader *ngIf="toVerify" [imageId]="imageVerify" (uploadFiles)="setFile($event)" (exists)="imageExists=true; image=$event"></app-image-uploader>
	</ng-container>
</div>
<div mat-dialog-actions>
	<button mat-button (click)="closeDialog()">Discard</button>
	<button mat-button (click)="verifyAndUpload()" cdkFocusInitial>Save</button>
</div>
