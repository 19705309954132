import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsComponent } from './steps.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AngularToastifyModule } from 'angular-toastify';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { StepEditorComponent } from './step-editor/step-editor.component';
import { CustomPipesModule } from 'src/app/shared/pipes/custom-pipes.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MediaSelectorComponent } from './step-editor/media-selector/media-selector.component';
import { ImageSelectorComponent } from './step-editor/image-selector/image-selector.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { YoutubeSelectorComponent } from './step-editor/youtube-selector/youtube-selector.component';
import { ClassroomGameSelectorComponent } from './step-editor/classroom-game-selector/classroom-game-selector.component';
import { ActivitySelectorComponent } from './step-editor/activity-selector/activity-selector.component';
import { LessonSelectorComponent } from './step-editor/lesson-selector/lesson-selector.component';

@NgModule({
	declarations: [
		StepsComponent,
		StepEditorComponent,
		MediaSelectorComponent,
		ImageSelectorComponent,
		YoutubeSelectorComponent,
		ClassroomGameSelectorComponent,
		ActivitySelectorComponent,
		LessonSelectorComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		AngularToastifyModule,
		FormsModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatExpansionModule,
		MatGridListModule,
		MatTabsModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatPaginatorModule,
		MatToolbarModule,
		MatTooltipModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		MatSortModule,
		MatTableModule,
		ReactiveFormsModule,
		NgxFileDropModule,
		CustomPipesModule,
		SharedModule,
	],
})
export class StepsModule { }
