<div mat-dialog-title color="primary" class="title">WATCH AND CHANGE VIDEO</div>

<div mat-dialog-content class="dialog-content" style="align-items: center">
  <<ng-container class="dialog-content">
    <mat-form-field appearance="fill">
      <mat-label>Video Link</mat-label>
      <input matInput [(ngModel)]="data.videoId" (ngModelChange)="changeVideo($event)" [formControl]="videoIdControl" />
      <button *ngIf="data.videoId" matSuffix mat-icon-button aria-label="Clear" (click)="data.videoId = ''; changeVideo('')">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="videoIdControl.hasError('required')">
        Video Link is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="start-content">
    <mat-form-field appearance="fill">
      <mat-label>Video Start</mat-label>
      <input type="number" matInput [(ngModel)]="data.videoStart" (ngModelChange)="changeVideo(data.videoId)"/>
      <button *ngIf="data.videoStart" matSuffix mat-icon-button aria-label="Clear" (click)="data.videoStart = 0; changeVideo(data.videoId)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-container>

  <ng-container class="id-content">
    <mat-form-field appearance="fill">
      <mat-label>Video End</mat-label>
      <input type="number" matInput [(ngModel)]="data.videoEnd" (ngModelChange)="changeVideo(data.videoId)" [formControl]="videoEndControl" />
      <button *ngIf="data.videoEnd" matSuffix mat-icon-button aria-label="Clear" (click)="data.videoEnd = 1; changeVideo(data.videoId)">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="videoEndControl.hasError('required')">
        Video End is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container class="dialog-content" *ngIf="data">
    <iframe [src]="createVideo()" frameborder="0" allowfullscreen></iframe>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-button class="button" (click)="closeDialog()">Close</button>
  <button mat-button (click)="saveAndClose()" cdkFocusInitial>Save</button>
</div>