import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, of, Subject, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { IActivityDescription, ICreateActivityDescription } from "../models/activity-description.model";

@Injectable({
	providedIn: 'root'
})
export class ActivityDescriptionService {

	constructor(
		private httpClient: HttpClient
	) { }

	public getActivityDescriptions(): Observable<IActivityDescription[]> {
		return this.httpClient.get<IActivityDescription[]>(`${environment.SERVICES.LESSON}activity-description`)
			.pipe(
				catchError(error => {
					console.log('ACTIVITY DESCRIPTIONS', error);
					return of([]);
				}),
			)
	}

	public getActivityDescription(id: string): Observable<IActivityDescription | null> {
		return this.httpClient.get<IActivityDescription>(`${environment.SERVICES.LESSON}activity-description/${id}`)
			.pipe(
				catchError(error => {
					console.log('ACTIVITY DESCRIPTION', error);
					return of(null);
				}),
			)
	}

	public createActivityDescription(data: ICreateActivityDescription): Observable<string | null> {
		return this.httpClient.post<{ uid: string }>(`${environment.SERVICES.LESSON}activity-description`, data)
			.pipe(
				switchMap(res => {
					return of(res.uid);
				}),
				catchError(error => {
					console.log('CREATE ACTIVITY DESCRIPTION', error);
					return of(null);
				})
			)
	}

	public updateActivityDescription(id: string, data: IActivityDescription): Observable<boolean> {
		const update: ICreateActivityDescription = Object.assign({}, data);

		delete update.uid;

		return this.httpClient.put<void>(`${environment.SERVICES.LESSON}activity-description/${id}`, data)
			.pipe(
				switchMap(() => {
					return of(true);
				}),
				catchError(error => {
					console.log('UPDATE ACTIVITY DESCRIPTION', error);
					return of(false);
				})
			)
	}

	public removeActivityDescription(id: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${environment.SERVICES.LESSON}activity-description/${id}`)
			.pipe(
				switchMap(() => {
					return of(true);
				}),
				catchError(error => {
					console.log('DELETE ACTIVITY DESCRIPTION', error);
					return of(false);
				})
			)
	}
}
