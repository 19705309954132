import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { CreateStepDTO, Step, UpdateStepDTO } from '../../models/steps.model';
import { StepsService } from '../steps.service';

@Injectable({
	providedIn: 'root'
})
export class StepsStore {

	private _steps = new BehaviorSubject<Step[] | null>(null);

	constructor(
		private stepsService: StepsService,
		private toastrService: ToastService,
		private router: Router,
	) { }

	setSteps(steps: Step[]) {
		this._steps.next(steps);
	}

	private reloadSteps() {
		this.stepsService.getAllSteps()
			.pipe(
				catchError(err => {
					this.toastrService.error('Failed to load steps');
					return of(false)
				})
			).subscribe(res => {
				if (res) {
					this.setSteps(res as Step[]);
				}
			})
	}

	listSteps(): Observable<Step[] | null> {
		if (this._steps.value !== null) {
			this._steps.next(this._steps.value);
		} else {
			this.reloadSteps();
		}

		return this._steps.asObservable();
	}

	createStep(step: CreateStepDTO) {
		this.stepsService.createStep(step)
			.pipe(
				catchError(err => {
					console.log(err)
					this.toastrService.error('Failed to create step');
					return of(null)
				})
			)
			.subscribe(res => {
				if (res !== null) {
					this.reloadSteps();
					this.router.navigate(['/navbar/steps']);
					this.toastrService.success('Step created');
				}
			})
	}

	updateStep(step: UpdateStepDTO, stepId: string) {
		this.stepsService.updateStep(step, stepId)
			.pipe(
				catchError(err => {
					console.log(err);
					this.toastrService.error('Failed to update step');
					return of(false)
				})
			)
			.subscribe(res => {
				if (res !== false) {
					this.toastrService.success('Step updated');
					this.reloadSteps();
				}
			})
	}

	removeStep(stepId: string) {
		this.stepsService.deleteStep(stepId)
			.pipe(
				catchError(err => {
					console.log(err);
					this.toastrService.error('Failed to delete step');
					return of(false);
				})
			)
			.subscribe(res => {
				if (res !== false) {
					this.reloadSteps();
					this.toastrService.success('Step deleted');
				}
			})
	}
}
