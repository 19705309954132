import { Component } from '@angular/core';

@Component({
	selector: 'app-root',
	template: `
	<lib-toastify-toast-container></lib-toastify-toast-container>
	<router-outlet></router-outlet>
	`
})
export class AppComponent { }
