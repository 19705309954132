import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { Subscription } from 'rxjs';
import { DeleteConfirmationDialog } from 'src/app/shared/components/delete-confirmation/delete-confirmation.component';
import { VALID_AGES, VALID_CEFRS } from 'src/app/shared/constants';
import { Step, VALID_MEDIA, VALID_MOMENTS, VALID_SKILLS } from 'src/app/shared/models/steps.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { StepsStore } from 'src/app/shared/services/stores/steps.store.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-steps',
	templateUrl: './steps.component.html',
	styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {

	steps: Step[] = [];
	loading: boolean = true;

	skills = VALID_SKILLS;
	moments = VALID_MOMENTS;
	media = VALID_MEDIA;
	cefrs = VALID_CEFRS;
	ages = VALID_AGES;

	displayedColumns: string[] = ['name', 'actions', 'image', 'skills', 'attachments', 'moment', 'media', 'cefr','age','time'];
	dataSource!: MatTableDataSource<Step>;
	stepsSelected: { [key: string]: boolean } = {};
	selected: string[] = [];
	defaultPageSize!: number;

	skillControl: FormControl;
	momentControl: FormControl;
	cefrControl : FormControl;
	ageControl : FormControl;
	mediaControl: FormControl;

	globalfilter = '';
	filteredValues = {
		skill: '',
		moment: '',
		media: '',
		cefr : '',
		age : ''
	}

	readonly owners = environment.KNOWN_OWNERS;

	@ViewChild(MatPaginator) paginator!: MatPaginator;

	subscriptions: Subscription;

	constructor(
		private stepsStore: StepsStore,
		private dialog: MatDialog,
		private router: Router,
		private authService: AuthService,
		private toastService: ToastService,
	) {
		this.dataSource = new MatTableDataSource(this.steps);
		this.skillControl = new FormControl();
		this.momentControl = new FormControl();
		this.mediaControl = new FormControl();
		this.cefrControl = new FormControl();
		this.ageControl = new FormControl();
		this.subscriptions = new Subscription();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.steps) {
			this.dataSource = new MatTableDataSource(this.steps);

			this.dataSource.filterPredicate = this.customFilterPredicate();

			this.globalfilter = sessionStorage.getItem('stepFilter') ?? '';
			this.defaultPageSize = +(sessionStorage.getItem('stepsPageSize') ?? 5);
		}
	}

	ngOnInit() {

		this.loadSteps();

		this.globalfilter = sessionStorage.getItem('stepFilter') ?? '';
		this.defaultPageSize = +(sessionStorage.getItem('stepPageSize') ?? 5);

		const skillSub = this.skillControl.valueChanges.subscribe(skillValue => {
			this.filteredValues['skill'] = skillValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const momentSub = this.momentControl.valueChanges.subscribe(momentValue => {
			this.filteredValues['moment'] = momentValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const cefrSub = this.cefrControl.valueChanges.subscribe(cefrValue => {
			this.filteredValues['cefr'] = cefrValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const ageSub = this.ageControl.valueChanges.subscribe(ageValue => {
			this.filteredValues['age'] = ageValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		const mediaSub = this.mediaControl.valueChanges.subscribe(mediaValue => {
			this.filteredValues['media'] = mediaValue;
			this.dataSource.filter = JSON.stringify(this.filteredValues);
		});

		this.subscriptions.add(cefrSub);
		this.subscriptions.add(ageSub);
		this.subscriptions.add(skillSub);
		this.subscriptions.add(momentSub);
		this.subscriptions.add(mediaSub);
	}

	ngAfterViewInit(): void {
		this.setPaginator();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	loadSteps() {
		return new Promise(() => {
			const sub = this.stepsStore.listSteps()
				.subscribe(res => {
					if (res) {
						this.steps = res;
						this.dataSource = new MatTableDataSource(this.steps);
						this.steps.forEach(step => { this.stepsSelected[step.uid ?? 0] = false; });
						this.dataSource.filterPredicate = this.customFilterPredicate();
						if (this.paginator) {
							this.setPaginator();
						}
						this.loading = false;
					}
				});

			this.subscriptions.add(sub);
		});
	}

	home() {
		this.router.navigate(['navbar'])
	}

	async logout() {
		let data = await this.authService.logout();
		if (data) {
			this.router.navigate(['auth/login'])
		}
	}

	private setPaginator() {
		this.dataSource.filter = JSON.stringify(this.filteredValues);
		this.dataSource.paginator = this.paginator;
		this.paginator.pageIndex = +(sessionStorage.getItem('stepPageIndex') ?? 0);
	}

	customFilterPredicate() {
		const stepFilter = (data: Step, filter: string): boolean => {

			let parsedSearch = JSON.parse(filter);
			const selectedSkill = parsedSearch.skill;
			let hasSkill = selectedSkill ? data.skills?.includes(selectedSkill) ? true : false : true;
			const selectedMoment = parsedSearch.moment;
			let hasMoment = selectedMoment ? data.moment?.includes(selectedMoment) ? true : false : true;
			const selectedCefr = parsedSearch.cefr;
			let hasCefr = selectedCefr ? data.cefr?.includes(selectedCefr) ? true : false : true;
			const selectedAge = parsedSearch.age;
			let hasAge = selectedAge ? data.age?.includes(selectedAge) ? true : false : true;
			const selectedMedia = parsedSearch.media;
			const hasTime = selectedMedia ? data.media?.type === selectedMedia : true;

			let selectMatch = hasSkill && hasMoment && hasTime && hasCefr && hasAge;
			let globalMatch = !this.globalfilter;

			if (this.globalfilter) {
				const lowerCaseFilter = this.globalfilter.toLowerCase();
				const nameIncludes = data.name != null && data.name.toLowerCase().includes(lowerCaseFilter);
				let gameDescIncludes = false;

				if(data.media?.type === 'activity' && data.media.content.descriptionName) {
					gameDescIncludes = data.media.content.descriptionName.toLocaleLowerCase().includes(lowerCaseFilter);
				}

				if(data.media?.type === 'classroomGame') {
					gameDescIncludes = data.media.content.name.toLocaleLowerCase().includes(lowerCaseFilter);
				}

				globalMatch = nameIncludes || gameDescIncludes;
			}

			return globalMatch && selectMatch;
		}
		return stepFilter;
	}

	applyFilter(filter: string) {
		sessionStorage.setItem('stepFilter', filter);
		this.globalfilter = filter;
		this.dataSource.filter = JSON.stringify(this.filteredValues);

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	paginatorChanged(event: any) {
		sessionStorage.setItem('stepPageIndex', event.pageIndex);
		sessionStorage.setItem('stepsPageSize', event.pageSize);
	}

	selectAll(event: MatCheckboxChange) {
		for (let stepId in this.stepsSelected) {
			this.stepsSelected[stepId] = event.checked;
		}
	}

	select(event: MatCheckboxChange, checkedId: string) {
		this.stepsSelected[checkedId] = event.checked;
	}

	editStep(stepId: string) {
		this.router.navigate(['/navbar/steps', stepId]);
	}

	createStep() {
		this.router.navigate(['/navbar/steps/create']);
	}

	removeSelectedSteps() {
		const selectedIds = this.getSelected();
		if (selectedIds.length === 0) {
			this.toastService.error(`You must select at least a single step to delete!`);
			return;
		}

		if (selectedIds.length > 5) {
			this.toastService.error(`You can only delete 5 steps at a time!`);
			return;
		}

		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			selectedIds.forEach((id: string) => {
				this.stepsStore.removeStep(id);
				this.loadSteps();
			});
		});
	}

	private getSelected() {
		const selectedIds = [];

		for (let id in this.stepsSelected) {
			const isFiltered = this.dataSource.filteredData.find(step => step.uid === id);
			if (this.stepsSelected[id] && isFiltered) {
				selectedIds.push(id);
			}
		}

		return selectedIds;
	}

	setMomentClass(moment: string) {
		if (moment === 'Welcome') {
			return 'welcome';
		} else if (moment === 'Language Input') {
			return 'language-input';
		} else if (moment === 'Practice') {
			return 'practice';
		} else if (moment === 'Free Practice') {
			return 'free-practice';
		} else if (moment === 'Project') {
			return 'project';
		} else {
			return 'closing';
		}
	}

	getMediaDescription(step : Step) {
		if(step.media?.type === 'activity') {
			return step.media.content.descriptionName;
		}

		if(step.media?.type === 'classroomGame') {
			return step.media.content.name;
		}

		return ''
	}
}
