import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastService } from "angular-toastify";
import {
	ImageFiles,
	IUploadImage,
} from "src/app/shared/models/image-upload.model";
import {
	IVoiceActor,
	IDialogueRow,
	IDialogueLine,
} from "src/app/shared/models/lesson-content.model";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";

@Component({
	selector: "app-add-dialogue",
	templateUrl: "./add-dialogue.component.html",
	styleUrls: ["./add-dialogue.component.scss"],
})
export class AddDialogueDialog implements OnInit {
	voiceActors: string[] = Object.values(IVoiceActor);

	public files: ImageFiles[] = [];

	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	subjectControl = new FormControl(this.data.subject, Validators.required);
	characterControl = new FormControl(null, Validators.required);
	lineControl = new FormControl(null, Validators.required);

	constructor(
		public dialogRef: MatDialogRef<AddDialogueDialog>,
		@Inject(MAT_DIALOG_DATA) public data: IDialogueRow,
		private toastService: ToastService,
		private uploadService: ImageUploaderService
	) {}

	ngOnInit(): void {}

	changeImage(newValue: string) {
		this.data.imageId = newValue;
		this.toVerify = false;
		this.files = [];
		this.imageExists = false;
	}

	verifyImage() {
		this.imageVerify = this.data.imageId;
		this.toVerify = true;
	}

	setFile(file: ImageFiles | null) {
		if (file === null) {
			this.data.imageId = "";
			this.files = [];
			return;
		}
		this.data.imageId = file.name;
		this.files.push(file);
	}

	closeDialog() {
		this.dialogRef.close();
	}

	createDialogue() {
		let canUpload = true;

		this.data.subject = this.subjectControl.value;

		if (this.data.subject === "") {
			this.subjectControl.markAllAsTouched();
			canUpload = false;
		}

		for (let line of this.data.lines) {
			if (line.character === "") {
				this.characterControl.markAllAsTouched();
				canUpload = false;
			}

			if (line.text === "") {
				this.lineControl.markAllAsTouched();
				canUpload = false;
			}
		}

		if (!this.imageExists && this.files.length === 0) {
			this.toastService.error("No image has been selected.");
			return;
		}

		if (!canUpload) {
			this.toastService.error("Failed to save new lesson.");
			return;
		}

		if (this.files.length > 0) {
			const uploadFileIds = this.files.map((file) => {
				return {
					id: file.name,
					extension: "jpg",
				} as IUploadImage;
			});

			const fileBlobs = this.files.map((fileInfo) => {
				const imageBlob = new Blob([fileInfo.file], {
					type: `image/jpeg`,
				});
				return imageBlob;
			});

			this.uploadService.uploadImages(fileBlobs, uploadFileIds).subscribe(res => {
				if (!res) {
					this.toastService.error('Failed to upload image');
					return;
				} else {
					this.toastService.success('Image was uploaded successfully');
					this.toastService.success('Dialogue added.')
					this.dialogRef.close(this.data);
				}
			});
		} else {
			this.toastService.success("Dialogue added.");
			this.dialogRef.close(this.data);
		}
	}
}
