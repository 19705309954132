<div class="body">
  <div class="selector">
    <div class="filters">
        <mat-form-field [formGroup]="filterGroup" appearance="standard">
          <mat-label>Palavra-chave</mat-label>
          <input formControlName="content" #keywordInput (keyup)="filterByKeyword(keywordInput.value)" matInput />
          <mat-icon class="search-icon" matSuffix>search</mat-icon>
        </mat-form-field>

        <mat-form-field  [formGroup]="filterGroup" class="filter-field" appearance="fill">
          <mat-label>Habilidades</mat-label>
          <mat-select formControlName="skills" (selectionChange)="filterActivities()">
            <mat-option>nenhum</mat-option>
            <mat-option *ngFor="let skill of availableSkills" [value]="skill">
              {{ skill }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field  [formGroup]="filterGroup" class="filter-field" appearance="fill">
          <mat-label>Idade</mat-label>
          <mat-select  formControlName="ages" (selectionChange)="filterActivities()">
            <mat-option>nenhum</mat-option>
            <mat-option *ngFor="let age of availableAges" [value]="age">
              {{ age }}
            </mat-option>
          </mat-select>
        </mat-form-field>
    </div>
    <div class="list-frame spinner" *ngIf="activitiesLoading || uploadingImg">
				<mat-spinner></mat-spinner>
    </div>
    <div class="list-frame" *ngIf="!activitiesLoading && !uploadingImg">
      <div class="content" *ngFor="let activity of filteredActivityDescriptions" (click)="selectActivity(activity)" [ngClass]="{ active: selectedActivity && activity.uid === selectedActivity.uid }">
        <span class="item-name">{{ activity.name }}</span>
        <div class="item-skill">
          <mat-icon>{{setIcon(activity.skill)}}</mat-icon>
        </div>
      </div>
      <span class="not-found-warning" *ngIf="filteredActivityDescriptions.length === 0">Nenhuma atividade encontrada.</span>
    </div>
  </div>

  <div class="action-buttons">
    <mat-form-field appearance="standard">
      <mat-label>Quantidade de Atividades</mat-label>
      <input matInput type="number" [formControl]="qtdActivities" />
    </mat-form-field>

    <div class="image-upload">
      <div class="file-upload-wrapper" data-text="Selecione uma imagem">
        <input class="file-upload-field"
           type="file"
           id="file"
           (change)="handleImageInput($event)">
      </div>
    </div>
    
    <button mat-raised-button color="primary" class="finish-button" [disabled]="!selectedActivity" (click)="submitActivitiesStep()">Finalizar</button>
  </div>
</div>
