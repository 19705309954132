<ng-container>
  <h3>MULTICHOICE LISTENING</h3>

  <ng-container>
    <div class="header">
      <mat-toolbar class="header" color="primary">
        <div class="start-header" style="width: 50%">
          <h1 class="title">Questions</h1>
        </div>
        <div class="end-header" style="display: flex; flex-direction: row-reverse; width: 50%">
          <button mat-mini-fab color="white" (click)="addLine()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-toolbar>
    </div>

    <form [formGroup]="questionsForm">
      <div formArrayName="questions">

        <mat-accordion>

          <mat-expansion-panel hideToggle *ngFor="let questionGroup of questions.controls; let i = index"
            class="line-container">

            <mat-expansion-panel-header>

              <mat-panel-title style="align-items: center;">
                Question {{i + 1}}
              </mat-panel-title>

              <mat-panel-description style="align-items: center">

                <div style="display: flex; width: 95%"> {{ questionGroup.get('question')?.value }} </div>

                <div class="delete-div">
                  <button mat-icon-button class="delete-button" (click)="removeLine(i)">
                    <mat-icon color="warn">delete</mat-icon>
                  </button>
                </div>

              </mat-panel-description>

            </mat-expansion-panel-header>

            <ng-container [formGroupName]="i">

              <div class="lesson-title-content">
                <mat-form-field appearance="outline">
                  <mat-label>Question</mat-label>
                  <input matInput formControlName="question">
                  <mat-error *ngIf="questionGroup.get('question')?.errors?.required">
                    Question is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <form [formGroup]="getFormGroup(questionGroup)">
                <div formArrayName="images">
                  <mat-accordion>
                    <mat-expansion-panel hideToggle
                      *ngFor="let imageGroup of getImagesArray(questionGroup).controls; let j = index">
                      <mat-expansion-panel-header>
                        <mat-panel-title style="align-items: center;" *ngIf="imageGroup.get('isCorrect')?.value">
                          Correct
                        </mat-panel-title>
                        <mat-panel-title style="align-items: center;" *ngIf="!imageGroup.get('isCorrect')?.value">
                          Wrong {{j}}
                        </mat-panel-title>
                        <mat-panel-description style="align-items: center">

                          <div style="display: flex; width: 95%"> {{imageGroup.get('imageId')?.value ??
                            'No Image Selected'}} </div>
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <ng-container [formGroupName]="j">

                        <ng-container class="lesson-image-content">
                          <mat-form-field appearance="outline">
                            <mat-label>Image</mat-label>
                            <input matInput formControlName="imageId">
                            <button *ngIf="imageGroup.get('imageId')?.value" matSuffix mat-icon-button
                              aria-label="Clear" (click)="clearQuestionImage('', imageGroup)">
                              <mat-icon>close</mat-icon>
                            </button>
                          </mat-form-field>
                          <button mat-button [disabled]="!imageGroup.get('imageId')?.value" class="verify-button"
                            (click)="verifyQuestionImage(imageGroup)">Verify Image</button>
                        </ng-container>

                        <ng-container>
                          <app-image-uploader *ngIf="imageGroup.get('verifyImage')?.value"
                            [imageId]="imageGroup.get('imageId')?.value" [isPlacement]="true"
                            (uploadFiles)="setQuestionFile($event, imageGroup)"
                            (exists)="existsFile(imageGroup, true, $event)"></app-image-uploader>
                        </ng-container>

                      </ng-container>

                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </form>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </form>

    <h4>Audio Tutorial A</h4>

    <ng-container class="lesson-image-content">
      <app-audio-uploader [audioId]="audioTutorialAUrl"
        (audioUploaded)="audioTutorialAUploaded($event)"></app-audio-uploader>
    </ng-container>

    <h4>Audio Tutorial B</h4>

    <ng-container class="lesson-image-content">
      <app-audio-uploader [audioId]="audioTutorialBUrl"
        (audioUploaded)="audioTutorialBUploaded($event)"></app-audio-uploader>
    </ng-container>

    <h4>Audio Content</h4>

    <ng-container class="lesson-image-content">
      <app-audio-uploader [audioId]="audioUrl" (audioUploaded)="audioUploaded($event)"></app-audio-uploader>
    </ng-container>

  </ng-container>
</ng-container>