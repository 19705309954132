import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { NavbarRoutingModule } from './navbar-routing.module';
import { AngularToastifyModule, ToastService } from 'angular-toastify';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { LessonsModule } from './lessons/lessons.module';
import { LessonModule } from './lesson/lesson.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivityDescriptionModule } from './activity-description/activity-description.module';
import { ClassroomGamesModule } from './classroom-games/classroom-games.module';
import { LessonsClassroomGamesModule } from './lessons-classroom-games/lessons-classroom-games.module';
import { ProgramsModule } from './programs/programs.module';
import { StepsModule } from './steps/steps.module';
import { PlacementQuestionsModule } from './placement-questions/placement-questions.module';

@NgModule({
	declarations: [
		NavbarComponent,
	],
	imports: [
		NavbarRoutingModule,
		AngularToastifyModule,
		CommonModule,
		FormsModule,
		LessonModule,
		LessonsModule,
		ActivityDescriptionModule,
		LessonsClassroomGamesModule,
		ClassroomGamesModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatIconModule,
		MatListModule,
		MatToolbarModule,
		MatSidenavModule,
		ReactiveFormsModule,
		ProgramsModule,
		StepsModule,
		PlacementQuestionsModule
	],
	providers: [
		ToastService
	]
})
export class NavbarModule { }
