export const colors = [
	{
		color: "#FF0D0D", id: 0,
	},
	{
		color: "#FF00E4", id: 1,
	},
	{
		color: "#409CE9", id: 2,
	},
	{
		color: "#47A237", id: 3,
	},
	{
		color: "#FFCE21", id: 4,
	},
	{
		color: "#FF8E00", id: 5,
	},
	{
		color: "#743212", id: 6,
	},
	{
		color: "#6C635D", id: 7,
	}
];

